import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { Referent, ResultType } from '@/models';
import { Direction } from '@/models/Direction';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class ReferentState {
	public referents: Nullable<ResultType<Referent>> = null;
	public referent: Nullable<Referent> = null;
}

export default {
	state: ReferentState,
	reducer: mapReducer([
		'referents',
		'referent'
	]),
} as SubStore;

// MUTATIONS

export const setReferents = (referents: Nullable<ResultType<Referent>>) => (dispatch: DispatchApp) => {
	dispatch({ state: ReferentState, type: MapReducerAction.MAP, referents });
};
export const setReferent = (referent: Nullable<Referent>) => (dispatch: DispatchApp) => {
	dispatch({ state: ReferentState, type: MapReducerAction.MAP, referent });
};

// ACTIONS

export const findReferents = (
	page: number,
	order: string,
	direction: Direction,
	limit: number,
	filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<Referent>> => {
	const referents = await caller.get<ResultType<Referent>>(`/api/referents${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);

	dispatch(setReferents(referents));

	return referents;
});

export const findReferentById = (
	id: string,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Referent> => {
	if (getState().referent.referent?.id !== id) {
		dispatch(setReferent(null));
	}
	const referent = await caller.get<Referent>(`/api/referents/${encodeURIComponent(id)}`);
	dispatch(setReferent(referent));
	return referent;
});
