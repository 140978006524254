import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '@/libs/redux';

export function useDisconnectedPage() {
	const connected = useSelector(state => state.user.connected);
	const navigate = useNavigate();
	
	useEffect(() => {
		if (connected) {
			navigate('/home');
		}
	}, [ connected, navigate ]);
}
