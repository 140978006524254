import { Card, CardContent } from '@mui/material';
import { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import { useEffectTimeout, usePropState } from '@/libs/hooks';
import { CardProps } from '@mui/material/Card/Card';
import { UserSyncicat } from '@/models';
import CrudUserSyncicatSelect from '@/components/crud/userSyncicat/Select/Select';
import TextField from '@/components/inputs/TextField/TextField';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

export interface CrudAnnuaireLogementFiltersProps extends CardProps {
	value?: Nullable<any>;
	onChange?: (value: Nullable<any>) => any,
}

export default function CrudAnnuaireLogementFilters(
	{
		value,
		onChange,
		className = '',
		...rest
	}: CrudAnnuaireLogementFiltersProps
) {

	const [ filters, setFilters ] = usePropState<any>(value, onChange, {
		numero: '',
		batiment: '',
		proprietaireName: '',
		syndicat: [],
	});

	const [ numero, setNumero ] = useState<string>(filters.numero);
	const [ batiment, setBatiment ] = useState<string>(filters.batiment);
	const [ proprietaireName, setProprietaireName ] = useState<string>(filters.proprietaireName);
	const [ syndicat, setSyndicat ] = useState<UserSyncicat[]>(filters.syndicat);
	const [ open, setOpen] = useState<boolean>(false);

	useEffectTimeout(() => {
		if (
			filters.numero !== numero ||
			filters.batiment !== batiment ||
			filters.proprietaireName !== proprietaireName ||
			filters.syndicat !== syndicat
		) {
			setFilters({
				numero,
				batiment,
				proprietaireName,
				syndicat,
			})
		}
	}, 500, [
		numero,
		batiment,
		proprietaireName,
		syndicat,
	]);

	return (
		<Card
			{...rest}
			className={[
				className,
				'mt-2',
				'mb-1',
				'c-Crud-AnnuaireLogement-Filters'
			].join(' ')}
		>
			<CardContent className="p-1">
				<div onClick={() => setOpen(!open)}>
					<h5 className="m-0" style={{cursor: 'pointer'}}>  {open ? <KeyboardArrowUp style={{ verticalAlign: '-7px' }} /> : <KeyboardArrowDown style={{ verticalAlign: '-7px' }} />}<span>Filtres :</span></h5>
				</div>
				{open && (
					<Grid container spacing={1}>
						<Grid xs={12} md={3}>
							<CrudUserSyncicatSelect
								{...model(syndicat, setSyndicat)}
								multiple
								clearable
								size="small"
								variant="standard"
								label="Syndicats"
							/>
						</Grid>
						<Grid xs={12} md={3}>
							<TextField
								{...model(numero, setNumero)}
								clearable
								size="small"
								variant="standard"
								label="Numéro"
							/>
						</Grid>
						<Grid xs={12} md={3}>
							<TextField
								{...model(batiment, setBatiment)}
								clearable
								size="small"
								variant="standard"
								label="Batiment"
							/>
						</Grid>
						<Grid xs={12} md={3}>
							<TextField
								{...model(proprietaireName, setProprietaireName)}
								clearable
								size="small"
								variant="standard"
								label="Propriétaire"
							/>
						</Grid>
					</Grid>
				)}
			</CardContent>
		</Card>
	)
}
