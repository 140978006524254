import Layout from '../layout';
import { Outlet } from 'react-router-dom';
import { wrapperRouter, wrapperStore, wrapperMui } from '@/libs/wrapper';
import { routes } from '@/router';
import { firstLoad, firstRender, store } from '@/stores';
import { useEffectOnce } from 'react-use';
import { useDispatch } from '@/libs/redux';
import { initAuth } from '@/stores/user';
import CheckRightsOutlet from '@/components/CheckRightsOutlet/CheckRightsOutlet';
import { usePageView } from '@/libs/hooks';

let oneCalled = false;

let App = function({ ...props }: any) {

	const dispatch = useDispatch();

	usePageView();
	
	useEffectOnce(() => {
		(async () => {
			if (!oneCalled) { // Fix and force only one call
				oneCalled = true;
				await dispatch(firstLoad());
				try {
					await Promise.all([
						dispatch(initAuth()),
					]);
				} catch (e) {
					console.error(e);
				}
				await dispatch(firstRender());
			}
		})();
	});

	return (
		<Layout {...props}>
			<CheckRightsOutlet {...props}>
				<Outlet context={props} />
			</CheckRightsOutlet>
		</Layout>
	);
}

App = wrapperMui(App);
App = wrapperRouter(App, routes);
App = wrapperStore(App, store);

export default App;
