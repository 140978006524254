import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { TriAcqui, ResultType, Acquisition } from '@/models';
import { Direction } from '@/models/Direction';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class TriAcquiState {
    public triAcquis: Nullable<ResultType<TriAcqui>> = null;
    public triAcqui: Nullable<TriAcqui> = null;
    public currentAcquisitionId: Nullable<number> = null; // Store the current acquisition ID
}

export default {
    state: TriAcquiState,
    reducer: mapReducer([
        'triAcquis',
        'triAcqui',
        'currentAcquisitionId' // Register the new state property
    ]),
} as SubStore;

// MUTATIONS

export const setTriAcquis = (triAcquis: Nullable<ResultType<TriAcqui>>) => (dispatch: DispatchApp) => {
    dispatch({ state: TriAcquiState, type: MapReducerAction.MAP, triAcquis });
};
export const setTriAcqui = (triAcqui: Nullable<TriAcqui>) => (dispatch: DispatchApp) => {
    dispatch({ state: TriAcquiState, type: MapReducerAction.MAP, triAcqui });
};
export const setCurrentAcquisitionId = (id: Nullable<number>) => (dispatch: DispatchApp) => {
    dispatch({ state: TriAcquiState, type: MapReducerAction.MAP, currentAcquisitionId: id });
};

// ACTIONS

export const findTriAcquis = (
    page: number,
    order: string,
    direction: Direction,
    limit: number,
    filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<TriAcqui>> => {
    const triAcquis = await caller.get<ResultType<TriAcqui>>(`/api/tri-acquis${UriHelper.queries({
        page,
        order,
        direction,
        limit,
        filters: JSON.stringify(filters)
    })}`);

    dispatch(setTriAcquis(triAcquis));

    return triAcquis;
});

export const updateAcquisitionList = (acquisitionId: number) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<void> => {
    await caller.post(`/api/acquisition/update-list/${acquisitionId}`);
    // After updating, you might want to refresh your acquisition list or handle the UI accordingly.
    dispatch(setCurrentAcquisitionId(null));  // Clear the current ID as it may no longer be relevant.
});

export const findTriAcqui = (
	id: number,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<TriAcqui> => {
	dispatch(setTriAcqui(null));
	const triAcqui = await caller.get<TriAcqui>(`/api/tri-acquis/${id}`);
	dispatch(setTriAcqui(triAcqui));
	return triAcqui;
});

export const deleteAllAcquisitions = () => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<void> => {
	await caller.delete<Acquisition>(`/api/acquisitions`);
	return;
});
