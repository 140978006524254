import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { FileSlot, hFileSlot, ResultType } from '@/models';
import { Direction } from '@/models/Direction';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class FileSlotState {
	public fileSlots: Nullable<ResultType<FileSlot>> = null;
	public fileSlot: Nullable<FileSlot> = null;
}

export default {
	state: FileSlotState,
	reducer: mapReducer([
		'fileSlots',
		'fileSlot',
	]),
} as SubStore;

// MUTATIONS

export const setFileSlots = (fileSlots: Nullable<ResultType<FileSlot>>) => (dispatch: DispatchApp) => {
	dispatch({ state: FileSlotState, type: MapReducerAction.MAP, fileSlots });
};
export const setFileSlot = (fileSlot: Nullable<FileSlot>) => (dispatch: DispatchApp) => {
	dispatch({ state: FileSlotState, type: MapReducerAction.MAP, fileSlot });
};

// ACTIONS

export const findFileSlots = (
	page: number,
	order: string,
	direction: Direction,
	limit: number,
	filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<FileSlot>> => {
	const fileSlots = await caller.get<ResultType<FileSlot>>(`/api/file-slots${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);
	
	dispatch(setFileSlots(fileSlots));
	
	return fileSlots;
});

export const findFileSlotById = (id: number) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Nullable<FileSlot>> => {
	const fileSlot = await caller.get<FileSlot>(`/api/file-slots/${id}`);
	dispatch(setFileSlot(fileSlot));
	return fileSlot;
});


export const deleteFileSlotById = (id: number) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<FileSlot> => {
	const fileSlot = await caller.delete<FileSlot>(`/api/file-slots/${id}`);
	dispatch(setFileSlot(fileSlot));
	return fileSlot;
});

export const putFileSlotById = (
	id: number,
	fileSlot: Partial<FileSlot>
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Nullable<FileSlot>> => {
	const result = await caller.put<FileSlot>(`/api/file-slots/${id}`, hFileSlot(fileSlot).toSend());
	dispatch(setFileSlot(result));
	return result;
});

export const postFileSlot = (fileSlot: Partial<FileSlot>) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Nullable<FileSlot>> => {
	const result = await caller.post<FileSlot>(`/api/file-slots`, hFileSlot(fileSlot).toSend());
	dispatch(setFileSlot(result));
	return result;
});
