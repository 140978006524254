import { buildModelHelper } from '@/libs/modelHelper';

export interface Telephone {
	id: number;
	type: number;
	telephone1: string;
	commentaire: string;
	source: string;
	$reference: number;
	pertinence: number;
	dateMaj: string;
}

export const hTelephone = buildModelHelper<Telephone>({
	get canEdit(): boolean {
		return this.type !== 1;
	}
});
export type HTelephone = ReturnType<typeof hTelephone>;
