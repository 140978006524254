import './State.scss';
import { useDispatch, useSelector } from '@/libs/redux';
import { useEffectOnChange, usePageable, useSnackMessage } from '@/libs/hooks';
import { useCallback } from 'react';
import { Container } from '@mui/material';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TooltipElm from '@/components/Tooltip/TooltipElm';
import { toDate } from '@/libs/utils';
import { EtatMaj } from '@/models';
import { findEtatMajs } from '@/stores/etatMaj';

export interface PageBaseStateItemProps {
	item: EtatMaj
}

export function PageBaseStateItem(
	{
		item,
	}: PageBaseStateItemProps
) {
	return (
		<TableRow>
			<TableCell>
				<TooltipElm message={item.syndicat} />
			</TableCell>
			<TableCell>
				<TooltipElm message={item.nomSyndicat} />
			</TableCell>
			<TableCell>
				<TooltipElm message={toDate(new Date(item.dateMajPresence))} />
			</TableCell>
			<TableCell>
				<TooltipElm message={toDate(new Date(item.dateMajBalance))} />
			</TableCell>
		</TableRow>
	);
}

export default function PageBaseState() {

	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const etatMajs = useSelector(state => state.etatMaj.etatMajs);
	const pageable = usePageable();

	const refresh = useCallback(() => {
		(async () => {
			try {
				await dispatch(findEtatMajs(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage));
			} catch (e) {
				console.error(e);
				pushMessage('Une erreur est survenue.', 'error');
			}
		})();
	}, [ pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage ])

	useEffectOnChange(() => {
		refresh();
	}, [ refresh ]);

	return (
		<Container className="p-base-State">

			<SortableTable
				heads={[
					{ name: 'Syndicat', sort: 'syndicat' },
					{ name: 'Nom', sort: 'nomSyndicat' },
					{ name: 'Maj. Présence', sort: 'dateMajPresence' },
					{ name: 'Maj. Compta', sort: 'dateMajBalance' },
				]}
				result={etatMajs}
				renderItem={(item) => (
					<PageBaseStateItem
						item={item}
					/>
				)}
				pageable={pageable}
			/>

		</Container>
	);
}

PageBaseState.getMetadata = () => {
	return {
		rights: [ 'BASE_STATE' ],
		title: 'État de mise à jour'
	}
}
