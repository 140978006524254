import './Recouvrement.scss';
import { useDispatch, useSelector } from '@/libs/redux';
import { useEffectOnChange, useIsSyndicat, usePageable, useSnackMessage } from '@/libs/hooks';
import { useCallback, useState } from 'react';
import { findRecouvrements, setRecouvrement, setRecouvrements } from '@/stores/recouvrement';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import { Direction, Recouvrement, ResultType } from '@/models';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Container, Dialog, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import IconModeEdit from '@mui/icons-material/ModeEdit';
import CrudRecouvrementFilters from '@/components/crud/recouvrement/Filters/Filters';
import { model } from '@/libs/candy';
import { formatNumber } from '@/libs/utils';
import CrudRecouvrementRecordEdit from '@/components/crud/recouvrementRecord/Edit/Edit';
import TooltipElm from '@/components/Tooltip/TooltipElm';
import IconCheck from '@mui/icons-material/Check';
import { useStore } from 'react-redux';
import { State } from '@/stores';
import { rgb } from 'polished';

export interface PageUsersIndexUserProps {
	item: Recouvrement
	onDelete?: () => any,
	onUpdate?: () => any,
}

export function PageSaisieRecouvrementItem(
	{
		item,
		onUpdate = () => {},
	}: PageUsersIndexUserProps
) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isSyndicat = useIsSyndicat();
	
	return (
		<TableRow>
			<TableCell>
				<TooltipElm message={item.syndicat} />
			</TableCell>
			<TableCell className="u-cell-sticky-left">
				<TooltipElm message={item.proprietaire} />
			</TableCell>
			<TableCell>
				<TooltipElm message={item.civilite !== null ? item.civilite : '-'} />
			</TableCell>
			<TableCell>
				<TooltipElm message={item.nom !== null ? item.nom : '-'} />
			</TableCell>
			<TableCell className="u-t-center">
				<TooltipElm message={item.solde !== null ? `${formatNumber(item.solde, { decimal: 0 })}€` : '-'} />
			</TableCell>
			<TableCell>
				<TooltipElm message={item.statutOccupation !== null ? item.statutOccupation : '-'} />
			</TableCell>
			<TableCell className="u-t-center">
				<TooltipElm message={item.nombreLots !== null ? formatNumber(item.nombreLots) : '-'} />
			</TableCell>
			<TableCell>
				<TooltipElm message={item.categorieImpayes !== null ? item.categorieImpayes : '-'} />
			</TableCell>
			<TableCell className="u-t-center">
				<TooltipElm message={item.appels12m !== null ? `${formatNumber(item.appels12m, { decimal: 0 })}€` : '-'} />
			</TableCell>
			<TableCell className="u-t-center">
				<TooltipElm message={item.versements12m !== null ? `${formatNumber(item.versements12m, { decimal: 0 })}€` : '-'} />
			</TableCell>
			<TableCell className="u-t-center">
				<TooltipElm message={item.retard !== null ? formatNumber(item.retard) : '-'} />
			</TableCell>
			<TableCell className="u-t-center">{item.am ? <span style={{ fontSize: '1.5em', color: rgb(0, 161, 154) }}>●</span> : '-'}</TableCell>
			<TableCell className="u-t-center">{item.pr ? <span style={{ fontSize: '1.5em', color: rgb(0, 161, 154) }}>●</span> : '-'}</TableCell>
			<TableCell className="u-t-center">{item.ex ? <span style={{ fontSize: '1.5em', color: rgb(0, 161, 154) }}>●</span> : '-'}</TableCell>
			<TableCell className="u-t-center">{item.si ? <span style={{ fontSize: '1.5em', color: rgb(0, 161, 154) }}>●</span> : '-'}</TableCell>
			<TableCell className="u-t-center">{item.cs ? <span style={{ fontSize: '1.5em', color: rgb(0, 161, 154) }}>●</span> : '-'}</TableCell>
			<TableCell className="u-cell-sticky-right u-d-flex">
				<Tooltip title="Editer">
				<IconButton
					color="primary"
					onClick={() => dispatch(setRecouvrement(item))}
					disabled={!isSyndicat([item.syndicat])}
				>
					<IconModeEdit />
				</IconButton>
				</Tooltip>
			</TableCell>

		</TableRow>
	);
}

export default function PageSaisieRecouvrement() {

	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const store = useStore<State>();
	const recouvrement = useSelector(state => state.recouvrement.recouvrement);
	const recouvrements = useSelector(state => state.recouvrement.recouvrements);
	const pageable = usePageable({
		order: 'solde',
		direction: Direction.DESC,
	});
	const isSyndicat = useIsSyndicat();

	const [ filters, setFilters ] = useState({
		proprietaire: '',
		syndicat: [],
		nom: '',
		statutOccupation: [],
		categorieImpayes: [],
		solde: { min: null, max: null },
	});

	const refresh = useCallback(() => {
		(async () => {
			try {
				await dispatch(findRecouvrements(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, filters));
			} catch (e) {
				console.error(e);
				pushMessage('Une erreur est survenue.', 'error');
			}
		})();
	}, [ filters, pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage ])

	useEffectOnChange(() => {
		refresh();
	}, [ refresh ]);
	
	const waitRecouvrements = async (): Promise<ResultType<Recouvrement>> => {
		const recouvrements = store.getState().recouvrement.recouvrements;
		if (recouvrements) {
			return recouvrements;
		}
		
		await new Promise(r => setTimeout(r, 50));
		return waitRecouvrements();
	};

	const searchPrevPage = async (cPage: Nullable<number> = null) => {
		cPage = cPage !== null ? cPage : pageable.page;
		if (cPage - 1 < 0) {
			pushMessage('Vous avez atteint le premier element modifiable', 'warning')
		} else {
			dispatch(setRecouvrements(null));
			pageable.setPage(cPage - 1);
			const recouvrements = await waitRecouvrements();
			let newRecouvrement: Nullable<Recouvrement> = null;
			for (let i = recouvrements.data.length - 1; i >= 0; i--) {
				const item = recouvrements.data[i];
				if (isSyndicat([item.syndicat])) {
					newRecouvrement = item;
					break;
				}
			}
			if (newRecouvrement) {
				dispatch(setRecouvrement(null));
				await new Promise(r => setTimeout(r, 10));
				dispatch(setRecouvrement(newRecouvrement));
			} else {
				await searchPrevPage(cPage - 1);
			}
		}
		if (!recouvrements?.data?.length) {
			dispatch(setRecouvrements(null));
			pageable.setPage(cPage);
			pushMessage('Vous avez atteint le dernier element modifiable', 'warning')
		} else {
			let newRecouvrement: Nullable<Recouvrement> = null;
			for (let i = 0; i < recouvrements.data.length; i++) {
				const item = recouvrements.data[i];
				if (isSyndicat([item.syndicat])) {
					newRecouvrement = item;
					break;
				}
			}
			if (newRecouvrement) {
				dispatch(setRecouvrement(null));
				await new Promise(r => setTimeout(r, 10));
				dispatch(setRecouvrement(newRecouvrement));
			} else {
				await searchNextPage(cPage + 1);
			}
		}
	}
	
	const handlePrev = async () => {
		if (recouvrements?.data && recouvrement) {
			const currentIndex = recouvrements.data.map(r => r.id).indexOf(recouvrement.id);
			let newRecouvrement: Nullable<Recouvrement> = null;
			for (let i = currentIndex - 1; i >= 0; i--) {
				const item = recouvrements.data[i];
				if (isSyndicat([item.syndicat])) {
					newRecouvrement = item;
					break;
				}
			}

			if (newRecouvrement) {
				dispatch(setRecouvrement(null));
				await new Promise(r => setTimeout(r, 10));
				dispatch(setRecouvrement(newRecouvrement));
			} else {
				await searchPrevPage();
			}
		}
	}

	const searchNextPage = async (cPage: Nullable<number> = null) => {
		cPage = cPage !== null ? cPage : pageable.page;
		dispatch(setRecouvrements(null));
		pageable.setPage(cPage + 1);
		const recouvrements = await waitRecouvrements();
		if (!recouvrements?.data?.length) {
			dispatch(setRecouvrements(null));
			pageable.setPage(cPage);
			pushMessage('Vous avez atteint le dernier element modifiable', 'warning')
		} else {
			let newRecouvrement: Nullable<Recouvrement> = null;
			for (let i = 0; i < recouvrements.data.length; i++) {
				const item = recouvrements.data[i];
				if (isSyndicat([item.syndicat])) {
					newRecouvrement = item;
					break;
				}
			}
			if (newRecouvrement) {
				dispatch(setRecouvrement(null));
				await new Promise(r => setTimeout(r, 10));
				dispatch(setRecouvrement(newRecouvrement));
			} else {
				await searchNextPage(cPage + 1);
			}
		}
	}

	const handleNext = async () => {
		if (recouvrements?.data && recouvrement) {
			const currentIndex = recouvrements.data.map(r => r.id).indexOf(recouvrement.id);
			let newRecouvrement: Nullable<Recouvrement> = null;
			for (let i = currentIndex + 1; i < recouvrements.data.length; i++) {
				const item = recouvrements.data[i];
				if (isSyndicat([item.syndicat])) {
					newRecouvrement = item;
					break;
				}
			}

			if (newRecouvrement) {
				dispatch(setRecouvrement(null));
				await new Promise(r => setTimeout(r, 10));
				dispatch(setRecouvrement(newRecouvrement));
			} else {
				await searchNextPage();
			}
		}
	}
	
	return (
		<Container className="p-saisie-Recouvrement">

			<CrudRecouvrementFilters
				{...model(filters, setFilters)}
			/>
			
			<SortableTable
				heads={[
					{ name: 'Syndicat', sort: 'syndicat' },
					{ name: 'ID Propriétaire', sort: 'proprietaire', stickyLeft: true },
					{ name: 'Civilite', sort: 'civilite' },
					{ name: 'Nom', sort: 'nom' },
					{ name: 'Solde', sort: 'solde', align: 'center' },
					{ name: 'Occupation', sort: 'statusOccupation' },
					{ name: 'Nb. lots', sort: 'nombreLots', align: 'center' },
					{ name: 'Cat. impayés', sort: 'categorieImpayes' },
					{ name: <>Appels<br />(12 mois)</>, sort: 'appels', align: 'center' },
					{ name: <>Versements<br />(12 mois)</>, sort: 'versements', align: 'center' },
					{ name: <>Retard<br />(trim.)</>, sort: 'retard', align: 'center' },
					{ name: 'Amia.', sort: 'am' },
					{ name: 'Proc.', sort: 'pr' },
					{ name: 'Exec.', sort: 'ex' },
					{ name: 'SI', sort: 'si' },
					{ name: 'Surend.', sort: 'cs' },
					{ name: 'Actions', stickyRight: true },
				]}
				result={recouvrements}
				renderItem={(item) => (
					<PageSaisieRecouvrementItem
						item={item}
						onUpdate={refresh}
					/>
				)}
				pageable={pageable}
			/>

			<Dialog
				open={!!recouvrement}
				onClose={() => dispatch(setRecouvrement(null))}
				maxWidth={'xl'}
			>	
				<CrudRecouvrementRecordEdit
					onClose={() => dispatch(setRecouvrement(null))}
					onUpdate={() => refresh()}
					onPrev={handlePrev}
					onNext={handleNext}
				/>
			</Dialog>
			
		</Container>
	);
}

PageSaisieRecouvrement.getMetadata = () => {
	return {
		rights: [ 'SAISIE_RECOUVREMENT' ],
		title: 'Recouvrement - Liste des copropriétaires'
	}
}
