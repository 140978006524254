import { RouteObject } from 'react-router';
import Login from '@/pages/Login/Login';
import ResetPassword from '@/pages/ResetPassword/ResetPassword';
import ResetPasswordConfirm from '@/pages/ResetPasswordConfirm/ResetPasswordConfirm';
import Home from '@/pages/Home/Home';
import NotFound from '@/pages/NotFound/NotFound';
import { habilitation } from '@/router/habilitation';
import { annuaire } from '@/router/annuaire';
import { base } from '@/router/base';
import { documentaire } from '@/router/documentaire';
import { saisie } from '@/router/saisie';
import { consultation } from '@/router/consultation';
import { observatoire } from '@/router/observatoire';

export const routes: RouteObject[]&any = [
	{
		path: '/',
		element: <Login/>
	},
	{
		path: '/reset-password',
		element: <ResetPassword/>
	},
	{
		path: '/reset-password-confirm/:email',
		element: <ResetPasswordConfirm/>
	},
	{
		path: '/home',
		element: <Home/>
	},
	...habilitation,
	...annuaire,
	...base,
	...saisie,
	...consultation,
	...observatoire,
	...documentaire,
	{
		path: '*',
		element: <NotFound/>
	},
];
