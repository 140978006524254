import Select, { SelectProps } from '@/components/inputs/Select/Select';
import { enumToValueText } from '@/libs/utils/EnumHelper';
import { DocumentTypeMode } from '@/models';
import { useTranslation } from 'react-i18next';

export interface CrudDocumentTypeModeSelectProp extends Omit<SelectProps, 'items'|'itemText'|'itemValue'> {
}

export default function CrudDocumentTypeModeSelect(
	props: CrudDocumentTypeModeSelectProp
) {
	const { t } = useTranslation();

	return (
		<Select
			items={enumToValueText(t, 'models.document_type_mode.values', DocumentTypeMode)}
			{...props}
		/>
	);
}
