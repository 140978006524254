import { FormRule } from '@/libs/form';
import { Fragment, ReactNode, RefObject, useCallback, useEffect, useState } from 'react';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';
import { usePropRef, usePropState } from '@/libs/hooks';
import { IconButton, InputAdornment } from '@mui/material';
import IconClose from '@mui/icons-material/Close';
import { Uuid } from '@/libs/utils';

export interface FieldFormProps {
	rules?: FormRule[];
	onChange?: (value: any) => any,
	ref?: RefObject<any>,
}

export interface FieldAroundProps {
	clearable?: boolean;
	appendIcon?: ReactNode;
	prependIcon?: ReactNode;
	appendIconProps?: IconButtonProps,
	prependIconProps?: IconButtonProps,
	appendSlot?: ReactNode;
	prependSlot?: ReactNode;
}

export interface FieldFormParams {
	className: string;
	rules: FormRule[];
	ref?: RefObject<any>;
	value?: any;
	onChange?: (value: any) => any;
	defaultValue?: any;
	error?: boolean;
	helperText?: Nullable<any>;
}

export function useFieldForm(
	{
		className,
		rules,
		ref,
		value,
		onChange,
		defaultValue = null,
		error,
		helperText,
	}: FieldFormParams
) {
	const syncRef = usePropRef(ref, null);
	const [ sync, setSync ] = usePropState(value, onChange, defaultValue);
	const [ touched, setTouched ] = usePropState(false);

	const markClear = useCallback(() => {
		setSync(defaultValue);
		setTouched(false);
	}, [ defaultValue, setSync, setTouched ]);

	const markTouched =useCallback(() => {
		setTouched(true);
	}, [ setTouched ]);

	useEffect(() => {
		if (syncRef.current) {
			syncRef.current.setAttribute('mui-form-func', '1');
			syncRef.current['muiFormMarkClear'] = () => {
				markClear();
			}
			syncRef.current['muiFormMarkTouched'] = () => {
				markTouched();
			}
		}
	}, [ syncRef, markClear, markTouched ]);

	const results = rules
		.map((rule => rule(value)))
		.filter(result => result !== true)
	;
	const isValid = !results.length;
	if (touched) {
		error = error || !!results.length;
		if (results.length) {
			helperText = results[0];
		}
	}

	const props = {
		value: sync,
		onChange: setSync,
	};

	return {
		...props,
		ref: syncRef,
		props,
		isValid,
		markClear,
		markTouched,
		error,
		helperText,
		className: [
			'c-inputs-Field',
			error ? 'c-inputs-Field--error' : '',
			isValid ? 'c-inputs-Field--isValid' : '',
			className
		].join(' '),
	}
}

export function useFormId(id?: string) {
	const [ idSync, setIdSync ] = useState(id);
	useEffect(() => {
		if (!id) {
			setIdSync(Uuid.generate())
		}
	}, [ id ]);
	
	return idSync;
}

export interface SelectFormParams extends FieldFormParams{
	items: any[],
	itemText: string,
	itemValue: string,
	returnObject: boolean,
	multiple: boolean,
}

export function useFormSelect(
	{
		items,
		itemText,
		itemValue,
		returnObject,
		multiple,
		...rest
	}: SelectFormParams
) {
	const props = useFieldForm(rest);

	const isScalar = (value: any): boolean => {
		return typeof value === 'string' ||
			typeof value === 'number' ||
			typeof value === 'boolean'
			;
	};

	const findItemFromValue = (value: any): any => {
		if (!isScalar(value)) {
			return value;
		}
		for (const item of items) {
			const valueItem = typeof item === 'string' ? item : item[itemValue];
			if (valueItem === value) {
				return item;
			}
		}
		return null;
	};

	const getFlatValueOnItem = (item: any): string => {
		if (returnObject) {
			return item[itemValue];
		}
		if (isScalar(item)) {
			const itemSearch = findItemFromValue(item);
			if (itemSearch) {
				return isScalar(itemSearch) ? itemSearch : itemSearch[itemValue];
			}
		}
		return item ? (isScalar(item) ? item : item[itemValue]) : '';
	};

	const getFlatTextOnItem = (item: any): string => {
		if (returnObject) {
			return item[itemText];
		}
		if (isScalar(item)) {
			const itemSearch = findItemFromValue(item);
			if (itemSearch) {
				return isScalar(itemSearch) ? itemSearch : itemSearch[itemText];
			}
		}
		return item ? (isScalar(item) ? item : item[itemText]) : '';
	};

	const itemEquals = (a: any, b: any): boolean => {
		if (multiple) {
			a = a || null;
			b = b || null;
		}
		return a === b ||
			(
				a !== null &&
				b !== null &&
				getFlatValueOnItem(a) === getFlatValueOnItem(b)
			)
		;
	};

	const isSelected = (item: any): boolean => {
		if (multiple) {
			return props.value && !!(props.value as any[]).filter(i => itemEquals(i, item)).length;
		}
		return itemEquals(props.value, item);
	};

	const select = (item: any): any => {
		let value = props.value;
		if (multiple) {
			const index = (value as any[]).map(item => getFlatValueOnItem(item)).indexOf(getFlatValueOnItem(item));
			if (index === -1) {
				(value as any[]).push(returnObject ? item : getFlatValueOnItem(item));
			} else {
				(value as any[]).splice(index, 1);
			}
			value = [...value];
		} else {
			value = returnObject ? item : getFlatValueOnItem(item);
		}
		props.onChange(value);
		return value;
	};

	const [hovered, setHovered] = useState(-1);

	const arrowDown = (): number => {
		if (items.length && hovered < items.length - 1) {
			const value = hovered + 1;
			setHovered(value);
			return value;
		}
		return hovered;
	};
	const arrowUp = (): number => {
		if (items.length && hovered > 0) {
			const value = hovered - 1;
			setHovered(value);
			return value;
		}
		return hovered;
	};
	
	const getFlatValue = (defaultValue: any = null): any => {
		if (multiple) {
			return props.value?.map((item: any) => getFlatValueOnItem(item)) || [];
		}
		return props.value ? getFlatValueOnItem(props.value) : defaultValue;
	};

	return {
		...props,
		isSelected,
		getFlatValue,
		getFlatValueOnItem,
		getFlatTextOnItem,
		findItemFromValue,
		select,
		hovered,
		setHovered,
		arrowDown,
		arrowUp,
	};
}

export interface FieldAroundParams {
	clearable: boolean;
	appendIcon: ReactNode;
	prependIcon: ReactNode;
	appendIconProps: IconButtonProps,
	prependIconProps: IconButtonProps,
	appendSlot: ReactNode;
	prependSlot: ReactNode;
	markClear: () => any
}

export function useFieldAround(
	{
		clearable,
		appendIcon,
		prependIcon,
		appendIconProps,
		prependIconProps,
		appendSlot,
		prependSlot,
		markClear,
	}: FieldAroundParams
) {

	let startAdornment = null;
	const startAdornmentContent = [];
	if (prependIcon) {
		startAdornmentContent.push(
			<IconButton
				key={startAdornmentContent.length}
				{...prependIconProps}
			>
				{prependIcon}
			</IconButton>
		);
	}
	if (prependSlot) {
		startAdornmentContent.push(
			<Fragment key={startAdornmentContent.length}>
				{prependSlot}
			</Fragment>
		);
	}

	let endAdornment = null;
	const endAdornmentContent = [];
	if (clearable) {
		endAdornmentContent.push(
			<IconButton
				size="small"
				key={endAdornmentContent.length}
				aria-label="clear"
				onClick={() => markClear()}
			>
				<IconClose />
			</IconButton>
		);
	}
	if (appendIcon) {
		endAdornmentContent.push(
			<IconButton
				key={endAdornmentContent.length}
				{...appendIconProps}
			>
				{appendIcon}
			</IconButton>
		);
	}
	if (appendSlot) {
		endAdornmentContent.push(
			<Fragment key={endAdornmentContent.length}>
				{appendSlot}
			</Fragment>
		);
	}

	if (startAdornmentContent.length) {
		startAdornment = (
			<InputAdornment position="start">
				{startAdornmentContent}
			</InputAdornment>
		);
	}

	if (endAdornmentContent.length) {
		endAdornment = (
			<InputAdornment position="end">
				{endAdornmentContent}
			</InputAdornment>
		);
	}

	return {
		startAdornment,
		endAdornment,
	}
}
