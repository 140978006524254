import { buildModelHelper } from '@/libs/modelHelper';
import { Proprietaire } from '@/models/Proprietaire';

export interface TriAcqui {
	id: number;
	syndicat: Nullable<string>;
	lotReference: Nullable<string>;
	nombreLots: Nullable<number>;
	adresse: Nullable<string>;
	etage: Nullable<string>;
	typeVente: Nullable<string>;
	statutDossier: Nullable<string>;
	secteurSyndicat: Nullable<string>;
	structure: Nullable<string>;
	responsable: Nullable<string>;
	proprietaire: Nullable<string>;
	civiliteProprietaire: Nullable<string>;
	nomProprietaire: Nullable<string>;
	adresseRefProprietaire: Nullable<string>;
	emailRefProprietaire: Nullable<string>;
	telRefProprietaire: Nullable<string>; // renommé en accord avec l'entité Doctrine
	occupationProprietaire: Nullable<string>;
	soldeSdc: Nullable<number>;
	soldeSp: Nullable<number>;
	presenceSDC: Nullable<string>;
	occupation: Nullable<string>;
	etatVisiteRef: Nullable<string>;
	prixm2: Nullable<number>;
	prixm2AmiableLibre: Nullable<number>;
	prixm2AmiableOccupe: Nullable<number>;
	prixAmiable: Nullable<number>;
	prixAmiableLibre: Nullable<number>;
	prixAmiableOccupe: Nullable<number>;
	indemniteRemploi: Nullable<number>;
	indemniteRemploiLibre: Nullable<number>;
	indemniteRemploiOccupe: Nullable<number>;
	dateDia: Nullable<string>;
	dateLimitePreemption: Nullable<string>;
	prixDia: Nullable<number>;
	contexteAcquisition: Nullable<string>;
	proprietaireObj: Nullable<Proprietaire>; // Champs liés à d'autres tables à la fin
	dateMaj: Nullable<string>;
}

export const hTriAcqui = buildModelHelper<TriAcqui>({});

export type HTriAcqui = ReturnType<typeof hTriAcqui>;
