import { Card, CardActions, CardContent, CardHeader, Dialog, DialogProps } from '@mui/material';
import { usePropState } from '@/libs/hooks';
import { ReactNode, useState } from 'react';
import { LoadingButton } from '@mui/lab';

export interface ConfirmProps extends Omit<DialogProps, 'open'|'onChange'|'title'> {
	title?: ReactNode;
	message?: ReactNode;
	value?: boolean;
	confirmText?: ReactNode;
	cancelText?: ReactNode;
	onChange?: (v: boolean) => any;
	onConfirm?: () => Promise<any>;
	onCancel?: () => Promise<any>;
}

export default function Confirm(
	{
		value,
		onChange,
		title,
		message,
		confirmText = 'Valider',
		cancelText = 'Annuler',
		onConfirm,
		onCancel,
		...rest
	}: ConfirmProps
) {

	const [ open, setOpen ] = usePropState(value, onChange, false);
	const [ loading, setLoading ] = useState(false);

	const handleCancel = async () => {
		setLoading(true);
		try  {
			if (onCancel) {
				const result = await onCancel();
				if (typeof result === 'undefined' || result) {
					setOpen(false);
				}
			} else {
				setOpen(false);
			}
		} catch (e) {
			console.error(e);
		}
		setLoading(false);
	};

	const handleConfirm = async () => {
		setLoading(true);
		try  {
			if (onConfirm) {
				const result = await onConfirm();
				if (typeof result === 'undefined' || result) {
					setOpen(false);
				}
			} else {
				setOpen(false);
			}
		} catch (e) {
			console.error(e);
		}
		setLoading(false);
	};

	return (
		<Dialog
			open={open}
			onClose={handleCancel}
			{...rest}
		>
			<Card>
				{title && (
					<CardHeader
						title={title}
						className="CardHeader--primary"
					/>
				)}
				{message && (
					<CardContent>
						{message}
					</CardContent>
				)}
				<CardActions className="u-d-block">
					<LoadingButton
						loading={loading}
						onClick={handleCancel}
					>
						{cancelText}
					</LoadingButton>
					<LoadingButton
						color="primary"
						variant="contained"
						loading={loading}
						style={{float: 'right'}}
						onClick={handleConfirm}
					>
						{confirmText}
					</LoadingButton>
				</CardActions>
			</Card>
		</Dialog>
	);
}
