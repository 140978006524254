import { HTMLAttributes } from 'react';
import { useSelector } from '@/libs/redux';
import './Mains.scss';

export interface MainProps extends HTMLAttributes<HTMLElement> {
}

export default function Main(
	{
		className,
		...props
	}: MainProps
) {
	const menuOpen = useSelector(state => state.page.menuOpen);

	return (
		<main
			className={[
				'c-Main',
				menuOpen ? 'c-Main--open' : '',
				className,
			].join(' ')}
			{...props}
		/>
	);
}
