import { useState } from 'react';
import { Direction } from '@/models';

export function usePageable(
	{
		order: initialOrder = '',
		direction: initialDirection = Direction.ASC,
		page: initialPage = 0,
		rowsPerPage: initialRowsPerPage = 100,
	}: {
		order?: string
		direction?: Direction,
		page?: number
		rowsPerPage?: number
	} = {}
) {
	const [ order, setOrder ] = useState<string>(initialOrder);
	const [ direction, setDirection ] = useState<Direction>(initialDirection);
	const [ page, setPage ] = useState(initialPage);
	const [ rowsPerPage, setRowsPerPage ] = useState(initialRowsPerPage);
	
	return {
		order, setOrder,
		direction, setDirection,
		page, setPage,
		rowsPerPage, setRowsPerPage,
	};
}
