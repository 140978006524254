import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { AnnuaireProprietaire, ResultType } from '@/models';
import { Direction } from '@/models/Direction';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';
import { AnyAction } from '@reduxjs/toolkit';

export class AnnuaireProprietaireState {
    public annuaireProprietaires: Nullable<ResultType<AnnuaireProprietaire>> = null;
    public annuaireProprietaire: Nullable<AnnuaireProprietaire> = null;
}

export default {
    state: AnnuaireProprietaireState,
    reducer: mapReducer([
        'annuaireProprietaires',
        'annuaireProprietaire',
    ], (state: AnnuaireProprietaireState, action: AnyAction) => {
        switch (action.type) {
            case MapReducerAction.MAP:
                return { ...state, ...action.state };
        }
        return state;
    }),
} as SubStore;

// MUTATIONS

export const setAnnuaireProprietaires = (annuaireProprietaires: Nullable<ResultType<AnnuaireProprietaire>>) => (dispatch: DispatchApp) => {
    dispatch({ state: AnnuaireProprietaireState, type: MapReducerAction.MAP, annuaireProprietaires });
};

export const setAnnuaireProprietaire = (annuaireProprietaire: Nullable<AnnuaireProprietaire>) => (dispatch: DispatchApp) => {
    dispatch({ state: AnnuaireProprietaireState, type: MapReducerAction.MAP, annuaireProprietaire });
};

// ACTIONS

export const findAnnuaireProprietaires = (
    page: number,
    order: string,
    direction: Direction,
    limit: number,
    filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<AnnuaireProprietaire>> => {
    const annuaireProprietaires = await caller.get<ResultType<AnnuaireProprietaire>>(`/api/annuaire-proprietaires${UriHelper.queries({
        page,
        order,
        direction,
        limit,
        filters: JSON.stringify(filters)
    })}`);
    
    dispatch(setAnnuaireProprietaires(annuaireProprietaires));
    
    return annuaireProprietaires;
});

export const findAnnuaireProprietaireById = (
    id: string,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<AnnuaireProprietaire> => {
    dispatch(setAnnuaireProprietaire(null));
    const annuaireProprietaire = await caller.get<AnnuaireProprietaire>(`/api/annuaire-proprietaires/${id}`);
    dispatch(setAnnuaireProprietaire(annuaireProprietaire));
    return annuaireProprietaire;
});
