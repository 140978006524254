import { FormEvent } from 'react';

export type StateType<T> = [T, (v: T) => void ];

export type FormRule = (value?: any) => true | string;

export interface IFormField {
	rules: FormRule[];
	[name: string]: any;
}

function searchMuiFunc(el: HTMLElement): Nullable<any> {
	if (!el) {
		return null;
	}
	if (el.hasAttribute('mui-form-func')) {
		return el;
	}
	return el.querySelector('[mui-form-func]') || null;
}

export class Form {

	public static readonly ROOT_FIELD = 'ROOT_FIELD';

	public allToMainError: boolean = false;

	public constructor(
		private _stateIsValid: StateType<boolean>,
		private _stateNoError: StateType<boolean>,
		private _stateLoading: StateType<boolean>,
		private _stateErrors: StateType<Record<string, string[]>>,
		private _staticFieldsData: Record<string, any>,
		private _genericErrorLabel: string,
		private _mainErrorField: string,
		public handle: (event: FormEvent) => any,
		private _valid: boolean,
	) {
	}

	public get loading(): boolean {
		return this._stateLoading[0];
	}

	public set loading(value: boolean) {
		this._stateLoading[1](value);
	}

	public get mainRulesErrors(): string[] {
		const errors = [];
		if (this.allToMainError) {
			for (const [ name, data ] of Object.entries(this._staticFieldsData)) {
				const fieldErrors = this._stateErrors[0][name] || [];
				const rules = [
					...(data.rules || []),
					...(fieldErrors.map(error => () => error))
				];
				for (const rule of rules) {
					const error = rule(data.value);
					if (typeof error === 'string') {
						errors.push(error);
					}
				}
			}
		}
		return errors;
	}

	public get rootFieldErrors(): string[] {
		return [
			...(this._stateErrors[0][Form.ROOT_FIELD] || []),
			...this.mainRulesErrors
		];
	}

	public rootElement: Nullable<HTMLFormElement> = null;

	public get fields(): Record<string, any> {
		const fields: Record<string, IFormField> = {};

		for (const [ name, data ] of Object.entries(this._staticFieldsData)) {
			const errors = this._stateErrors[0][name] || [];
			
			const rules = !this.allToMainError ? [
				...(data.rules || []),
				...(errors.map(error => () => error))
			] : [];

			fields[name] = {
				...data,
				...(data.onChange ? {
					onChange: (...args: any) => {
						data.onChange(...args);
						this.updateState();
					}
				} : {}),
				rules,
			};
		}

		return fields;
	}

	public get formFields(): HTMLElement[] {
		if (this.rootElement) {
			return [
				...(this.rootElement.querySelectorAll('.c-inputs-Field') as any)
			];
		}
		return [];
	}

	public clearErrors(): this {
		this._stateErrors[1]({});
		this._stateErrors[0] = {};
		return this;
	}

	public markTouched(): this {
		this.formFields.forEach((el: any) => {
			const target = searchMuiFunc(el);
			if (target?.muiFormMarkTouched) {
				target.muiFormMarkTouched();
			}
		})
		return this;
	}

	public clear(): this {
		this.formFields.forEach((el: any) => {
			const target = searchMuiFunc(el);
			if (target?.muiFormMarkClear) {
				target.muiFormMarkClear();
			}
		})
		return this;
	}

	public get isValid(): boolean {
		return this._stateIsValid[0] && this._valid && !this.mainRulesErrors.length;
	}

	public get noError(): boolean {
		return this._stateNoError[0] && !this.mainRulesErrors.length;
	}

	public updateState(): void {
		const isValid = this.formFields.reduce((a, el) => a && el.classList.contains('c-inputs-Field--isValid'), true) as any;
		const noError = this.formFields.reduce((a, el) => a && !el.classList.contains('c-inputs-Field--error'), true) as any;

		if (this._stateIsValid[0] !== isValid) { this._stateIsValid[0] = isValid; this._stateIsValid[1](isValid); }
		if (this._stateNoError[0] !== noError) { this._stateNoError[0] = noError; this._stateNoError[1](noError); }
	}

	public addError(name: string, error: string): this {
		name = !this.allToMainError && this._staticFieldsData[name] ? name : this._mainErrorField;
		const errors = { ...this._stateErrors[0] };
		if (!errors[name]) {
			errors[name] = [];
		}
		errors[name].push(error);
		this._stateErrors[0] = errors;
		this._stateErrors[1](errors);
		return this;
	}

	public addMainError(error: string): this {
		return this.addError(this._mainErrorField, error);
	}

	public async scrollToError(): Promise<void> {
		await new Promise(r => setTimeout(r, 200));
		try {
			if (this.rootElement) {
				const fields = this.rootElement.querySelectorAll('.c-inputs-Field--error, .c-forms-FormError');
				let field: HTMLDivElement = null as any;
				fields.forEach((f: any) => {
					field = field || f;
					field = f.offsetTop < field.offsetTop ? f : field;
				})
				if (field) {
					if (field.classList.contains('c-forms-FormError')) {
						field?.parentElement?.scrollIntoView({
							behavior: "smooth"
						});
					} else {
						field.parentElement?.parentElement?.scrollIntoView({
							behavior: "smooth"
						});
					}
				}
			}
		} catch (e) {
			console.error(e);
		}
	}

	public async call(callback: () => Promise<void>, options: {
		scrollToError?: boolean,
		cbError?: () => void,
		cbFrontError?: (e?: any) => void,
		cbBackError?: (e?: any) => void
	} = {}): Promise<boolean> {

		options = {
			scrollToError: true,
			...options
		}
		this._stateLoading[1](true);

		try {
			this.clearErrors();
			await this.markTouched();
			this.updateState();
			if (this.noError) {
				await callback();
				return true;
			} else {
				if (options.cbFrontError) { options.cbFrontError(); }
				if (options.cbError) { options.cbError(); }
				if (options.scrollToError) { this.scrollToError(); }
				return false;
			}
		} catch (e) {
			this.fromError(e);
			if (options.cbBackError) { options.cbBackError(e); }
			if (options.cbError) { options.cbError(); }
			if (options.scrollToError) { this.scrollToError(); }
			return false;
		} finally {
			this._stateLoading[1](false);
		}
	}

	public fromError(e: any): void {
		if (e?.response?.data) {
			this.fromJsonError(e.response.data);
		} else {
			console.error(e);
			this.addMainError(this._genericErrorLabel);
		}
	}

	public fromJsonError(data: any): void {
		if (typeof data === 'string') {
			this.addMainError(data);
		} else
		if (typeof data === 'object') {
			if (
				typeof data.class !== 'undefined' &&
				typeof data.code !== 'undefined' &&
				typeof data.file !== 'undefined' &&
				typeof data.message !== 'undefined' &&
				typeof data.stack !== 'undefined'
			) {
				this.addMainError(data.message);
				return;
			}

			for (const name of Object.keys(data)) {
				const message = data[name];
				if (typeof message === 'string') {
					this.addError(name, message);
				} else
				if (Array.isArray(message)) {
					for (const m of message) {
						this.addError(name, m);
					}
				} else {
					this.addMainError(this._genericErrorLabel);
				}
			}
		}
	}
}
