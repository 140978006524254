import Select, { SelectProps } from '@/components/inputs/Select/Select';
import { enumToValueText } from '@/libs/utils/EnumHelper';
import { DocumentTypeFormat } from '@/models';
import { useTranslation } from 'react-i18next';

export interface CrudDocumentTypeFormatSelectProp extends Omit<SelectProps, 'items'|'itemText'|'itemValue'> {
}

export default function CrudDocumentTypeFormatSelect(
	props: CrudDocumentTypeFormatSelectProp
) {
	const { t } = useTranslation();
	
	return (
		<Select
			items={enumToValueText(t, 'models.file_slot_format.values', DocumentTypeFormat)}
			{...props}
		/>
	);
}
