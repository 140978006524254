import { Card, CardContent } from '@mui/material';
import { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import { useEffectTimeout, usePropState } from '@/libs/hooks';
import { CardProps } from '@mui/material/Card/Card';
import { UserSyncicat } from '@/models';
import CrudUserSyncicatSelect from '@/components/crud/userSyncicat/Select/Select';
import TextField from '@/components/inputs/TextField/TextField';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Select from '@/components/inputs/Select/Select';

export interface CrudAnnuaireProprietaireFiltersProps extends CardProps {
	value?: Nullable<any>;
	onChange?: (value: Nullable<any>) => any,
}

export default function CrudAnnuaireProprietaireFilters(
	{
		value,
		onChange,
		className = '',
		...rest
	}: CrudAnnuaireProprietaireFiltersProps
) {

	const [ filters, setFilters ] = usePropState<any>(value, onChange, {
		id: '',
		nom: '',
		type: [],
		presenceSyndicats: [],
	});

	const [ id, setId ] = useState<string>(filters.id);
	const [ nom, setNom ] = useState<string>(filters.nom);
	const [ type, setType ] = useState<string>(filters.type);
	const [ presenceSyndicats, setPresenceSyndicats ] = useState<UserSyncicat[]>(filters.presenceSyndicats);
	const [ open, setOpen] = useState<boolean>(false);

	useEffectTimeout(() => {
		if (
			filters.id !== id ||
			filters.nom !== nom ||
			filters.type !== type ||
			filters.presenceSyndicats !== presenceSyndicats
		) {
			setFilters({
				id,
				nom,
				type,
				presenceSyndicats,
			})
		}
	}, 500, [
		id,
		nom,
		type,
		presenceSyndicats,
	]);

	return (
		<Card
			{...rest}
			className={[
				className,
				'mt-2',
				'mb-1',
				'c-Crud-AnnuaireProprietaire-Filters'
			].join(' ')}
		>
			<CardContent className="p-1"> 
				<div onClick={() => setOpen(!open)}>
					<h5 className="m-0" style={{cursor: 'pointer'}}>  {open ? <KeyboardArrowUp style={{ verticalAlign: '-7px' }} /> : <KeyboardArrowDown style={{ verticalAlign: '-7px' }} />}<span>Filtres :</span></h5>
				</div>
				{open && (
					<Grid container spacing={1}>
						<Grid xs={12} md={3}>
							<TextField
								{...model(id, setId)}
								clearable
								size="small"
								variant="standard"
								label="ID"
							/>
						</Grid>
						<Grid xs={12} md={3}>
							<TextField
								{...model(nom, setNom)}
								clearable
								size="small"
								variant="standard"
								label="Nom"
							/>
						</Grid>
						<Grid xs={12} md={3}>
							<Select
								{...model(type, setType)}
								items={[
									'Professionnel',
									'Institutionnel',
									'Individuel',
									'Syndicat de copro.',
									'Regroupement',
								]}
								multiple
								clearable
								size="small"
								variant="standard"
								label="Type"
							/>
						</Grid>
						<Grid xs={12} md={3}>
							<CrudUserSyncicatSelect
								{...model(presenceSyndicats, setPresenceSyndicats)}
								multiple
								clearable
								size="small"
								variant="standard"
								label="Syndicats"
							/>
						</Grid>
					</Grid>
				)}
			</CardContent>
		</Card>
	)
}
