import { useDispatch, useSelector } from '@/libs/redux';
import { AppBar as MUIAppBar, IconButton, Toolbar, Typography, Tooltip } from '@mui/material';
import IconMenu from '@mui/icons-material/Menu';
import IconLogout from '@mui/icons-material/Logout';
import { setMenuOpen } from '@/stores/page';
import { useNavigate } from 'react-router-dom';
import { logout } from '@/stores/token';
import './AppBar.scss';
import { Feedback } from '@/components/Feedback/Feedback';

export interface AppBarProps {
	title?: string;
}

export default function AppBar(
	{
		title,
	}: AppBarProps
) {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const menuOpen = useSelector(state => state.page.menuOpen);
	
	title = useSelector(state => state.page.titles[window.location.pathname]) || title;

	const handleLogout = async () => {
		await dispatch(logout());
		navigate('/');
	};

	return (
		<MUIAppBar
			className={[
				'c-AppBar',
				menuOpen ? 'c-AppBar--open' : ''
			].join(' ')}
		>
			<Toolbar>
				<IconButton
					className="c-AppBar-burger"
					color="inherit"
					aria-label="open drawer"
					onClick={() => dispatch(setMenuOpen(!menuOpen)) }
					edge="start"
				>
					<IconMenu/>
				</IconButton>
				<Typography
					className="c-AppBar-title"
					variant="h6"
					component="h1"
				>
					{title || 'Grigny 2'}
				</Typography>
				<div
					id="c-AppBar-portal"
					className="c-AppBar-portal"
				/>
				<Feedback />
				<Tooltip arrow title="Déconnexion">
					<IconButton
						color="inherit"
						aria-label="logout"
						onClick={handleLogout}
					>
						<IconLogout/>
					</IconButton>
				</Tooltip>
				
			</Toolbar>
		</MUIAppBar>
	);
}
