import './Dictionnaire.scss';
import { Container } from '@mui/material';

export default function Dictionnaire() {
	return (
		<Container className="p-documentaire-Dictionnaire">
			Dictionnaire
		</Container>
	);
}
