import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import { Adresse, Referent, User } from '@/models';
import { useState } from 'react';
import { useForm } from '@/libs/form';
import { useDispatch } from '@/libs/redux';
import { LoadingButton } from '@mui/lab';
import Form from '@/components/forms/Form/Form';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import { useValidators } from '@/libs/validators';
import TextField from '@/components/inputs/TextField/TextField';
import { postAdresse, putAdresse } from '@/stores/adresse';

export interface CrudAdresseEditProps {
    referent: Referent;
    value: Adresse;
    onSave: (adresse: Adresse) => any;
    onClose: () => any;
    currentUser: Nullable<User>; // Ajouter currentUser comme prop
}

export function CrudAdresseEdit({
    referent,
    value,
    onSave,
    onClose,
    currentUser, // Recevoir currentUser
}: CrudAdresseEditProps) {
    const dispatch = useDispatch();
    const validators = useValidators();
    const [adresse1, setAdresse1] = useState(value.adresse1);
    const [adresse2, setAdresse2] = useState(value.adresse2);
    const [adresse3, setAdresse3] = useState(value.adresse3);
    const [commentaire, setCommentaire] = useState(value.commentaire);
    const [dateMaj, setDateMaj] = useState(value.dateMaj); // Ajouter dateMaj comme state
    const [source, setSource] = useState(value.source || '');
	// const [pertinence, setPertinence] = useState(value.pertinence); // Ajouter pertinence comme state
    const hiddenStyle = { display: 'none' };

    const form = useForm({
        handle: async () => {
            await form.call(async () => {
                const newSource = `${currentUser?.firstName} ${currentUser?.lastName}`;
                const newAdresse = {
                    ...value,
                    adresse1,
                    adresse2,
                    adresse3,
                    commentaire,
                    source: newSource,
                    dateMaj,
					// pertinence,
                };
                if (newAdresse.id) {
                    const result = await dispatch(putAdresse(newAdresse));
                    onSave(result);
                } else {
                    referent = { ...referent, adresses: [newAdresse] };
                    const result = await dispatch(postAdresse(referent, newAdresse));
                    onSave(result);
                }
            });
        },
        fields: {
            adresse1: {
                label: 'Numéro, rue',
                ...model(adresse1, setAdresse1),
                rules: [validators.required()],
            },
            adresse2: {
                label: 'Complément d\'adresse',
                ...model(adresse2, setAdresse2),
            },
            adresse3: {
                label: 'Code postal, ville',
                ...model(adresse3, setAdresse3),
            },
            commentaire: {
                label: 'Commentaire',
                ...model(commentaire, setCommentaire),
                multiline: true,
                rows: 5,
            },
            source: {
                label: 'Source',
                ...model(source, setSource),
                disabled: true, // Désactiver l'édition du champ source
                hidden: true,
            },
            dateMaj: {
                label: 'Date de mise à jour',
                ...model(dateMaj, setDateMaj),
                disabled: true, // Désactiver l'édition du champ dateMaj
            },
			// pertinence: {
			// 	label: 'Pertinence',
			// 	...model(pertinence, setPertinence),
			// },
        },
    });

    return (
        <Form form={form}>
            <Card>
                <CardHeader
                    title={value.id ? "Édition de l'adresse" : "Création d'une adresse"}
                    className="CardHeader--primary"
                />
                <CardContent>
                    <FormMainErrors form={form} />

                    <Grid container spacing={1}>
                        <Grid xs={12} md={6}>
                            <Grid container spacing={1}>
                                <Grid xs={12}>
                                    <TextField {...form.fields.adresse1} />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField {...form.fields.adresse2} />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField {...form.fields.adresse3} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <TextField
                                {...form.fields.commentaire}
                                multiline
                                rows={7}
                            />
                        </Grid>
                        <Grid xs={12} md={6} style={hiddenStyle}>
                            <TextField
                                {...form.fields.source}
                            />
                        </Grid>
						{/* <Grid xs={12} md={6}>
                            <TextField
                                {...form.fields.pertinence}
                            />
                        </Grid> */}
                    </Grid>
                </CardContent>
                <CardActions className="u-d-block">
                    <LoadingButton
                        loading={form.loading}
                        onClick={onClose}
                    >
                        Fermer
                    </LoadingButton>
                    <LoadingButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        loading={form.loading}
                        style={{ float: 'right' }}
                        onClick={(event) => {
                            event.preventDefault();
                            form.handle(event);
                        }}
                    >
                        Enregistrer
                    </LoadingButton>
                </CardActions>
            </Card>
        </Form>
    );
}
