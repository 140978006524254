import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '@/libs/redux';
import { CharteText } from './CharteText';
import Checkbox from '@/components/inputs/Checkbox/Checkbox';
import { model } from '@/libs/candy';
import { LoadingButton } from '@mui/lab';
import { useForm } from '@/libs/form';
import Form from '@/components/forms/Form/Form';
import { useValidators } from '@/libs/validators';
import { patchCharte } from '@/stores/user';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';

export function Charte() {

	const validators = useValidators();
	const dispatch = useDispatch();
	const [ open, setOpen ] = useState(false);
	const [ charte, setCharte ] = useState(false);
	const me = useSelector(state => state.user.me);
	
	const form = useForm({
		async handle() {
			await form.call(async () => {
				await dispatch(patchCharte());
			});
		},
		fields: {
			charte: {
				...model(charte, setCharte),
				label: 'J\'accepte la charte d’utilisation',
				rules: [
					validators.required()
				]
			}
		}
	})
	
	useEffect(() => {
		if (me && !me.charte) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	}, [ me ]);
	
	return (
		<Dialog open={open} maxWidth={'lg'}>
			<DialogTitle>
				Charte d’utilisation de BIG 2
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<CharteText />
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Form form={form} className="my-2">
					<FormMainErrors form={form} />
					<div className="u-t-center mb-1">
						<Checkbox {...form.fields.charte} style={{ width: '280px' }}/>
					</div>
					<div className="u-t-center">
						<LoadingButton
							type="submit"
							variant="contained"
							loading={form.loading}
						>
							Valider
						</LoadingButton>
					</div>
				</Form>
			</DialogActions>
		</Dialog>
	);
}
