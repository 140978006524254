import './Edit.scss';
import { HEmail, HIndividu, HSuiviSocial, Referent, TriEvalSociale, HAdresse, HTelephone, TriAcqui, hTriAcqui } from '@/models';
import type { AccompagnementSocial, HAccompagnementSocial } from '@/models/AccompagnementSocial';
import { hReferent } from '@/models/Referent';
import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Dialog, IconButton, Typography, Paper, Box, Table, TableBody, TableRow, TableCell, InputAdornment, TableHead, Tooltip, Checkbox } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from '@/libs/redux';
import { useEffectOnChange, useSnackMessage } from '@/libs/hooks';
import { findReferentById, setReferent } from '@/stores/referent';
import IconDelete from '@mui/icons-material/Delete';
import IconModeEdit from '@mui/icons-material/ModeEdit';
import Confirm from '@/components/dialog/Confirm/Confirm';
import CrudAdresseList from '@/components/crud/adresse/List/List';
import CrudTelephoneList from '@/components/crud/telephone/List/List';
import { deleteIndividu } from '@/stores/individu';
import { dateToDash, formatNumber } from '@/libs/utils';
import { CrudIndividuEdit } from '@/components/crud/individut/Edit/Edit';
import CrudEmailList from '@/components/crud/email/List/List';
import { CrudChargesMenageEdit } from '@/components/crud/chargesMenage/Edit/Edit';
import { deleteSuiviSocial } from '@/stores/suiviSocial';
import { CrudSuiviSocialEdit } from '@/components/crud/suiviSocial/Edit/Edit';
import Form from '@/components/forms/Form/Form';
import { useForm } from '@/libs/form';
import { model, number } from '@/libs/candy';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import accompagnementSocial, { findAccompagnementSocials, findAccompagnementSocialById, deleteAccompagnementSocial, postAccompagnementSocial, putAccompagnementSocial } from '@/stores/accompagnementSocial';
import Select from '@/components/inputs/Select/Select';
import DatePicker from '@/components/inputs/DatePicker/DatePicker';
import TextField from '@/components/inputs/TextField/TextField';
import CrudReferentEditHeadChart from '../EditHeadChart/EditHeadChart';
import { grey } from '@mui/material/colors';

import { CrudAdresseEdit } from '../../adresse/Edit/Edit';
import { CrudEmailEdit } from '../../email/Edit/Edit';
import { CrudTelephoneEdit } from '../../telephone/Edit/Edit';
import recouvrement from '@/stores/recouvrement';
import { CrudReferentSelect } from '../Select/Select';
import { Form as FormObject } from '@/libs/form';
import { User } from '@/models';
// import { InformationsProps } from '../../referent/Edit/Edit';
import { getMe } from '@/stores/user';
import { deleteAdresse, postAdresse, putAdresse } from '@/stores/adresse';
import { deleteTelephone, postTelephone, putTelephone } from '@/stores/telephone';
import { deleteEmail, postEmail, putEmail } from '@/stores/email';
export interface CrudReferentEditProps {
	onClose: () => any;
	onUpdate: () => any;
	onPrev: () => Promise<any>;
	onNext: () => Promise<any>;
	item: Nullable<TriEvalSociale>;
}

// export interface AccompagnementSocialProps {
//     onRefresh: () => any;
// }

// function AccompagnementSocials({ onRefresh }: AccompagnementSocialProps) {
//     const [selectReferent, setSelectReferent] = useState(false);
//     const [remove, setRemove] = useState<Nullable<Referent>>(null);
//     const [showContent, setShowContent] = useState(true);
//     const [canSelectReferent, setCanSelectReferent] = useState(false);
//     const dispatch = useDispatch();
//     const referent = useSelector((state: any) => state.referent.referent);
//     const accompagnementSocial = referent.accompagnementSocial || {};
//     const pushMessage = useSnackMessage();

//     const toggleContent = () => {
//         setShowContent(!showContent);
//     };

//     useEffect(() => {
//         const timer = setTimeout(() => {
//             setCanSelectReferent(true);
//         }, 200);

//         return () => clearTimeout(timer);
//     }, []);

//     const form = useForm({
//         handle: async () => {
//             await form.call(async () => {
//                 const newAccompagnementSocial: Partial<HAccompagnementSocial> = {
//                     id: accompagnementSocial.id,
//                     // referents: form.fields.referents.value,
//                     // Ajoutez ici les autres champs nécessaires pour créer un accompagnement social
//                 };

//                 if (accompagnementSocial.id) {
//                     await dispatch(putAccompagnementSocial(newAccompagnementSocial));
//                 } else {
//                     await dispatch(postAccompagnementSocial(newAccompagnementSocial));
//                 }
//                 dispatch(setReferent(referent));
//                 onRefresh();
//                 pushMessage('Accompagnement social enregistré avec succès.', 'success');
//             });
//         },
//         fields: {
//             referents: {
//                 ...model(referent.referents || [], () => {}),
//             },
//             // Ajoutez ici les autres champs nécessaires pour créer un accompagnement social
//         },
//     });

//     return (
//         <Form form={form}>
//             <Card elevation={4} className="mb-1">
//                 <CardHeader
//                     className="py-1 mb-1"
//                     title="Référents concernés par l'accompagnement social"
//                     titleTypographyProps={{ className: 'u-t-size-19' }}
//                     action={
//                         <IconButton onClick={toggleContent} style={{ color: 'white' }}>
//                             {showContent ? <ExpandLessIcon /> : <AddIcon />}
//                         </IconButton>
//                     }
//                 />
//                 {showContent && (
//                     <CardContent className="pt-0">
//                         <Table className="dense-table">
//                             <TableHead>
//                                 <TableRow>
//                                     <TableCell>Nom</TableCell>
//                                     <TableCell>Actions</TableCell>
//                                 </TableRow>
//                             </TableHead>
//                             <TableBody>
//                                 {form.fields.referents.value.length ? (
//                                     form.fields.referents.value.map((referent: Referent) => (
//                                         <TableRow key={referent.id}>
//                                             <TableCell>{referent.nom}</TableCell>
//                                             <TableCell>
//                                                 <Tooltip title="Supprimer">
//                                                     <IconButton onClick={() => setRemove(referent)}>
//                                                         <IconDelete />
//                                                     </IconButton>
//                                                 </Tooltip>
//                                             </TableCell>
//                                         </TableRow>
//                                     ))
//                                 ) : (
//                                     <TableRow>
//                                         <TableCell colSpan={2}>
//                                             <div className="u-t-center">
//                                                 <i>Aucun référent sélectionné</i>
//                                             </div>
//                                         </TableCell>
//                                     </TableRow>
//                                 )}
//                             </TableBody>
//                         </Table>
//                         <div className="mt-2 u-t-right">
//                             <Button
//                                 variant="contained"
//                                 size="small"
//                                 onClick={() => setSelectReferent(true)}
//                                 disabled={!canSelectReferent}
//                             >
//                                 Ajouter un référent
//                             </Button>
//                         </div>
//                         <Confirm
//                             value={!!remove}
//                             onChange={() => setRemove(null)}
//                             title="Enlever le référent"
//                             message={`Êtes-vous sûr de vouloir enlever le référent ${remove?.nom} ?`}
//                             onConfirm={async () => {
//                                 const index = form.fields.referents.value.map((r: Referent) => r.id).indexOf(remove?.id);
//                                 if (index !== -1) {
//                                     form.fields.referents.value.splice(index, 1);
//                                     form.fields.referents.onChange([...form.fields.referents.value]);
//                                     dispatch(setReferent(null));
//                                 }
//                             }}
//                         />
//                         <Dialog open={selectReferent} onClose={() => setSelectReferent(false)} maxWidth="lg">
//                             <CrudReferentSelect
//                                 {...model(form.fields.referents.value, (v: Referent[]) => {
//                                     form.fields.referents.onChange(v);
//                                     dispatch(setReferent(v[0] || null));
//                                 })}
//                                 onClose={() => setSelectReferent(false)}
//                             />
//                         </Dialog>
//                     </CardContent>
//                 )}
//             </Card>
//         </Form>
//     );
// }

export interface InformationsProps {
	onRefresh: () => any;
}

function EvaluationSocialeInfos({ onRefresh }: InformationsProps) {
	const dispatch = useDispatch();
	const referent = hReferent(useSelector(state => state.referent.referent));
	const evaluation: Partial<HAccompagnementSocial> = referent?.accompagnementSocial || {};
	const [referentId, setReferentId] = useState(referent.id);
	const [structureEnCharge, setStructureEnCharge] = useState(evaluation.structureEnCharge || '');
	const [dateEvalSociale, setDateEvalSociale] = useState<Nullable<Date>>(evaluation.dateEvalSociale || null);
	const [originePriseContact, setOriginePriseContact] = useState(evaluation.originePriseContact || '');
	const [planBdf, setPlanBdf] = useState(evaluation.planBdf || '');
	const [nombreDossiersDeposes, setNombreDossiersDeposes] = useState(evaluation.nombreDossiersDeposes || '');
	const [typeAccompagnement, setTypeAccompagnement] = useState(evaluation.typeAccompagnement || '');
	const [protocole, setProtocole] = useState(evaluation.protocole || '');
	const [fsl, setFsl] = useState(evaluation.fsl || '');
	const [fslVerse, setFslVerse] = useState<Nullable<number>>(evaluation.fslVerse || null);
	const [actionLogement, setActionLogement] = useState(evaluation.actionLogement || '');
	const [ouvertureDroits, setOuvertureDroits] = useState(evaluation.ouvertureDroits || '');
	const [aidesExceptionnelles, setAidesExceptionnelles] = useState(evaluation.aidesExceptionnelles || '');
	const [insertionProfessionnelle, setInsertionProfessionnelle] = useState(evaluation.insertionProfessionnelle || '');
	const [synthese, setSynthese] = useState(evaluation.synthese || '');
	const [numeroSecu, setNumeroSecu] = useState(evaluation.numeroSecu || '');
	const [numeroCaf, setNumeroCaf] = useState(evaluation.numeroCaf || '');
	const [anneeAcquisition, setAnneeAcquisition] = useState(evaluation.anneeAcquisition || '');
	const [prixAchat, setPrixAchat] = useState(evaluation.prixAchat || '');
	const [montantCreditImmo, setMontantCreditImmo] = useState(evaluation.montantCreditImmo || '');
	const [dureeCreditImmo, setDureeCreditImmo] = useState(evaluation.dureeCreditImmo || '');
	const [banqueCreditImmo, setBanqueCreditImmo] = useState(evaluation.banqueCreditImmo || '');
	const [montantCreditConso, setMontantCreditConso] = useState(evaluation.montantCreditConso || '');
	const [montantDetteFiscale, setMontantDetteFiscale] = useState(evaluation.montantDetteFiscale || '');
	const [montantAutresDettes, setMontantAutresDettes] = useState(evaluation.montantAutresDettes || '');
	const [fiscaleLbl, setFiscale] = useState(evaluation.fiscaleLbl || '');
	const [showContent, setShowContent] = useState(true);

	const toggleContent = () => {
		setShowContent(!showContent);
	};

	const form = useForm({
		handle: async () => {
			await form.call(async () => {
				let newEval: Partial<AccompagnementSocial> = {
					id: evaluation.id,
					referentId: referent.id,
					structureEnCharge,
					dateEvalSociale: dateEvalSociale ? dateEvalSociale.toISOString() : null,
					originePriseContact,
					planBdf,
					nombreDossiersDeposes,
					typeAccompagnement,
					protocole,
					fsl,
					fslVerse: fslVerse ? parseFloat(fslVerse.toString()) : null,
					actionLogement,
					ouvertureDroits,
					aidesExceptionnelles,
					insertionProfessionnelle,
					synthese,
					numeroSecu,
					numeroCaf,
					anneeAcquisition,
					prixAchat: prixAchat ? parseFloat(prixAchat) : null,
					montantCreditImmo: montantCreditImmo ? parseFloat(montantCreditImmo) : null,
					dureeCreditImmo,
					banqueCreditImmo,
					montantCreditConso: montantCreditConso ? parseFloat(montantCreditConso) : null,
					montantDetteFiscale: montantDetteFiscale ? parseFloat(montantDetteFiscale) : null,
					montantAutresDettes: montantAutresDettes ? parseFloat(montantAutresDettes) : null,
				} as any;

				if (referent.accompagnementSocial?.id) {
					newEval = await dispatch(putAccompagnementSocial(newEval));
				}
				referent.accompagnementSocial = newEval;
				dispatch(setReferent(referent));
				onRefresh();
			});
		},
		fields: {
			structureEnCharge: {
				...model(structureEnCharge, setStructureEnCharge),
				label: "Structure en charge de l'ES",
				items: ['', 'SOLIHA', 'URBANIS', 'L. CHATELAIN'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			dateEvalSociale: {
				...model(dateEvalSociale, setDateEvalSociale),
				label: 'Date de réalisation de l\'ES',
			},
			originePriseContact: {
				...model(originePriseContact, setOriginePriseContact),
				label: 'Origine prise de contact',
				items: ['', 'Repérage GTI', 'Orientation acquisitions', 'Orientation partenaires', 'Sollicitation du ménage'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			typeAccompagnement: {
				...model(typeAccompagnement, setTypeAccompagnement),
				label: 'Type accompagnement social envisagé',
				items: ['', 'Redressement - Maintien autonome', 'Redressement - Maintien accompagné', 'Redressement - Maintien compromis', 'Redressement - Changement de statut/vente libre', 'Recyclage - Vente bloquée', 'Recyclage - Vente avec désendettement préalable', 'Recyclage - Vente autonome'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			protocole: {
				...model(protocole, setProtocole),
				label: 'Protocole d\'apurement',
				items: ['', 'En cours d\'élaboration', 'Moratoire', 'PCR', 'Mesures imposées', 'Rétablissement personnel', 'Plan caduc'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			fsl: {
				...model(fsl, setFsl),
				label: 'FSL',
				items: ['', 'En cours d\'élaboration', 'Dossier déposé', 'Dossier accepté', 'Dossier refusé', 'FSL versé'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			fslVerse: {
				...model(fslVerse, setFslVerse),
				label: 'Montant FSL versé (€)',
				type: 'number',
			},
			actionLogement: {
				...model(actionLogement, setActionLogement),
				label: 'Action Logement',
				items: ['', 'Orienté vers AL', 'Suivi par AL', 'Dossier accepté', 'Dossier refusé', 'AL versé'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			ouvertureDroits: {
				...model(ouvertureDroits, setOuvertureDroits),
				label: 'Ouverture de droits',
				items: ['', 'Oui', 'Non concerné', 'En cours d\'élaboration', 'A faire'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			aidesExceptionnelles: {
				...model(aidesExceptionnelles, setAidesExceptionnelles),
				label: 'Aides exceptionnelles',
			},
			insertionProfessionnelle: {
				...model(insertionProfessionnelle, setInsertionProfessionnelle),
				label: 'Insertion professionnelle',
				items: ['', 'Antenne Emploi', 'Autres'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			synthese: {
				...model(synthese, setSynthese),
				label: 'Synthèse de la situation',
			},
			numeroSecu: {
				...model(numeroSecu, setNumeroSecu),
				label: 'Numéro de Sécurité Sociale',
			},
			numeroCaf: {
				...model(numeroCaf, setNumeroCaf),
				label: 'Numéro CAF',
			},
			dateAcquisition: {
				...model(anneeAcquisition, setAnneeAcquisition),
				label: 'Année d\'acquisition',
				type: 'number',
			},
			prixAchat: {
				...model(prixAchat, setPrixAchat),
				label: 'Prix d’achat (€)',
				type: 'number',
			},
			montantCreditImmo: {
				...model(montantCreditImmo, setMontantCreditImmo),
				label: 'Montant du crédit immobilier (€)',
				type: 'number',
			},
			dureeCreditImmo: {
				...model(dureeCreditImmo, setDureeCreditImmo),
				label: 'Durée du crédit immobilier (années)',
				type: 'number',
			},
			banqueCreditImmo: {
				...model(banqueCreditImmo, setBanqueCreditImmo),
				label: 'Banque du crédit immobilier',
			},
			typeDetteFiscale: {
				...model(montantDetteFiscale, setMontantDetteFiscale),
				label: 'Montant de la dette fiscale (€)',
				type: 'number',
			},
			fiscaleLbl: {
				...model(fiscaleLbl, setFiscale),
				label: 'Fiscale ?',
			},
			planBdf: {
				...model(planBdf, setPlanBdf),
				label: 'Plan BDF',
				items: ['', 'Information demandée en attente', 'Dossier déposé', 'Dossier recevable', 'Dossier rejeté', 'Moratoire', 'Plan conventionnel de redressement', 'Mesures imposées', 'Rétablissement personnel', 'Plan Caduc'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			nombreDossiersDeposes: {
				...model(nombreDossiersDeposes, setNombreDossiersDeposes),
				label: 'Nombre dossiers déposés',
				items: ['', '1', '2', '3', '4', '5+'].map((value) => ({
					text: value || <i>Aucun</i>,
					value,
				})),
			},
			montantAutresDettes: {
				...model(montantAutresDettes, setMontantAutresDettes),
				label: 'Montant autres dettes',
				type: 'number',
			},
			montantCreditConso: {
				...model(montantCreditConso, setMontantCreditConso),
				label: 'Montant crédit à la consommation',
				type: 'number',
			},
			montantDetteFiscale: {
				...model(montantDetteFiscale, setMontantDetteFiscale),
				label: 'Montant dette fiscale',
				type: 'number',
			},
			referentId: {
				...model(referentId, setReferentId),
				type: 'hidden',
			},
		},
	});

	return (
		<Form form={form}>
			<Card elevation={4} className="p-1 mb-1">
				<Grid container spacing={1} rowSpacing={2}>
					<Grid xs={12} md={4}>
						<Select {...form.fields.structureEnCharge} />
					</Grid>
					<Grid xs={12} md={4}>
						<DatePicker {...form.fields.dateEvalSociale} />
					</Grid>
					<Grid xs={12} md={4}>
						<Select {...form.fields.originePriseContact} />
					</Grid>
					<Grid xs={12} md={12}></Grid>
				</Grid>
			</Card>

			<Card elevation={4} className="mb-1">
				<CardHeader
					className="py-1 mb-1"
					title="Informations sur le propriétaire"
					titleTypographyProps={{ className: 'u-t-size-19' }}
					action={
						<IconButton onClick={toggleContent} style={{ color: 'white' }}>
							{showContent ? <ExpandLessIcon /> : <AddIcon />}
						</IconButton>
					}
				/>
				{showContent && (
					<CardContent className="pt-0">
						<Grid container spacing={1} className="customGridContainer">
							<Grid xs={12} md={6}>
								<TextField {...form.fields.numeroSecu} />
							</Grid>
							<Grid xs={12} md={6}>
								<TextField {...form.fields.numeroCaf} />
							</Grid>
						</Grid>
						<Grid container spacing={1} rowSpacing={2} className="customGridContainer">
							<Grid xs={12} md={2.4}>
								<TextField {...form.fields.dateAcquisition} />
							</Grid>
							<Grid xs={12} md={2.4}>
								<TextField {...form.fields.prixAchat} />
							</Grid>
							<Grid xs={12} md={2.4}>
								<TextField {...form.fields.montantCreditImmo} />
							</Grid>
							<Grid xs={12} md={2.4}>
								<TextField {...form.fields.dureeCreditImmo} />
							</Grid>
							<Grid xs={12} md={2.4}>
								<TextField {...form.fields.banqueCreditImmo} />
							</Grid>
						</Grid>
						<Grid container spacing={1}>
							<Grid xs={12}>
								<Grid container spacing={1} rowSpacing={2} className="customGridContainer">
									<Grid xs={12} md={12}>
										<Box className="customBox">
											<Grid container alignItems="center">
												<Grid xs={12} md={3}>
													<Typography variant="h6">Type de dettes :</Typography>
												</Grid>
												<Grid xs={12} md={3}>
													<Typography>Crédit consommation ?</Typography>
												</Grid>
												<Grid xs={12} md={3}>
													<Typography>Fiscales ?</Typography>
												</Grid>
												<Grid xs={12} md={3}>
													<Typography>Autres ?</Typography>
												</Grid>
											</Grid>
										</Box>
										<Grid container spacing={2} alignItems="center">
											<Grid xs={12} md={3}>
												<Typography variant="h6">Montant (en €) :</Typography>
											</Grid>
											<Grid xs={3} md={3}>
												<TextField {...form.fields.montantCreditConso} />
											</Grid>
											<Grid xs={3} md={3}>
												<TextField {...form.fields.montantDetteFiscale} />
											</Grid>
											<Grid xs={3} md={3}>
												<TextField {...form.fields.montantAutresDettes} />
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid container spacing={1} rowSpacing={2} className="customGridContainer">
							<Grid xs={12} md={4.2}>
								<Select {...form.fields.planBdf} />
							</Grid>
							<Grid xs={12} md={4}>
								<Select {...form.fields.nombreDossiersDeposes} />
							</Grid>
						</Grid>
					</CardContent>
				)}
			</Card>
			<Card elevation={4} className="mb-1">
				<CardHeader
					className="py-1 mb-1"
					title="Évaluation Sociale"
					titleTypographyProps={{ className: 'u-t-size-19' }}
					action={
						<IconButton onClick={toggleContent} style={{ color: 'white' }}>
							{showContent ? <ExpandLessIcon /> : <AddIcon />}
						</IconButton>
					}
				/>
				{showContent && (
					<CardContent className="pt-0">
						<FormMainErrors form={form} />
						<Grid container spacing={1} rowSpacing={2} className="customGridContainer">
							<Grid xs={12}>
								<strong>Type d'accompagnement social envisagé</strong>
							</Grid>
							<Grid xs={12} md={6}>
								<Select {...form.fields.typeAccompagnement} />
							</Grid>
						</Grid>
						<Grid container spacing={1} rowSpacing={2} className="customGridContainer">
							<Grid xs={12}>
								<strong>Outils désendettement / Mesures engagées</strong>
							</Grid>
							<Grid xs={12} md={4}>
								<Select {...form.fields.protocole} />
							</Grid>
							<Grid xs={12} md={4}>
								<Select {...form.fields.fsl} />
							</Grid>
							<Grid xs={12} md={4}>
								<TextField {...form.fields.fslVerse} />
							</Grid>
							<Grid xs={12} md={3}>
								<Select {...form.fields.actionLogement} />
							</Grid>
							<Grid xs={12} md={3}>
								<Select {...form.fields.ouvertureDroits} />
							</Grid>
							<Grid xs={12} md={3}>
								<TextField {...form.fields.aidesExceptionnelles} />
							</Grid>
							<Grid xs={12} md={3}>
								<Select {...form.fields.insertionProfessionnelle} />
							</Grid>
							<Grid xs={12}>
								<TextField
									multiline
									rows={5}
									{...form.fields.synthese}
									placeholder="Vos données de commentaire seront traitées conformément à notre politique de confidentialité et aux réglementations de la RGPD."
									helperText="Cette zone de commentaires est visible sur les fiches copropriétaires et dans les compte-rendus, diffusés aux instances de copropriété : conformément au RGPD, les informations collectées et renseignées sur les personnes doivent être adéquates, pertinentes et non excessives au regard de la finalité du traitement envisagé. Les commentaires ne doivent donc pas être inappropriés, subjectifs et insultants."
								/>
							</Grid>
						</Grid>
					</CardContent>
				)}
			</Card>
			<Grid container spacing={1} rowSpacing={2} className="myGridContainerFormButtons">
				<Grid xs={12} sx={{ textAlign: 'center' }}>
					<LoadingButton className="loadingButton" loading={form.loading} variant="contained" type="submit">
						Enregistrer
					</LoadingButton>
				</Grid>
			</Grid>
		</Form>
	);
}

export interface CompositionsProps {
	onRefresh: () => any;
}

export interface ChargesProps {
	onRefresh: () => any;
}

export interface SuiviSocialProps {
	onRefresh: () => any;
}

function SuiviSocial({ onRefresh }: CompositionsProps) {
	const dispatch = useDispatch();
	const referent = hReferent(useSelector((state) => state.referent.referent));
	const [edit, setEdit] = useState<Nullable<HSuiviSocial>>(null);
	const [remove, setRemove] = useState<Nullable<HSuiviSocial>>(null);
	const [showContent, setShowContent] = useState(true);
	const filteredReferenta = referent?.id;
	// console.log('filteredReferenta: ', filteredReferenta);
	// console.log('referent: ', referent);
	const toggleContent = () => {
		setShowContent(!showContent);
	};

	const refresh = useCallback(async () => {
		setLoading(true);
		try {
			if (filteredReferenta) {
				await dispatch(findReferentById(filteredReferenta));
			}
		} catch (e) {
			console.error(e);
		}
		setLoading(false);
	}, [dispatch, filteredReferenta]);

	return (
		<Card elevation={4} className="mb-1">
			<CardHeader
				className="py-1 mb-1"
				title="Suivi social"
				titleTypographyProps={{ className: 'u-t-size-19' }}
				action={
					<IconButton onClick={toggleContent} style={{ color: 'white' }}>
						{showContent ? <ExpandLessIcon /> : <AddIcon />}
					</IconButton>
				}
			/>
			{showContent && (
				<CardContent className="pt-0">
					<div style={{ overflow: 'auto' }}>
						{referent?.suiviSocials?.length ? (
							<div style={{ overflow: 'auto' }}>
								<table className="c-Crud-Referent-Edit-suiviSocial">
									<thead>
										<tr>
											<th>Date</th>
											<th>Type contact</th>
											<th>Démarche</th>
											<th>À faire</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody>
										{referent.suiviSocials.map((ss: HSuiviSocial) => (
											<tr key={ss.id}>
												<td>{dateToDash(ss.date)}</td>
												<td>{ss.typeContact}</td>
												<td>{ss.demarches}</td>
												<td>{ss.aFaire}</td>
												<td>
													<IconButton onClick={() => setEdit(ss)}>
														<IconModeEdit />
													</IconButton>
													<IconButton onClick={() => setRemove(ss)}>
														<IconDelete />
													</IconButton>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						) : (
							<div className="u-t-center">
								<i>Aucun suivi sur le ménage</i>
							</div>
						)}
					</div>

					<br />

					<Button
						variant="contained"
						onClick={() =>
							setEdit({
								date: new Date(),
								typeContact: '',
								aFaire: '',
								demarches: '',
							})
						}
					>
						Créer un nouveau suivi
					</Button>
					<Dialog open={edit} onClose={() => setEdit(null)} maxWidth={'md'}>
						<CrudSuiviSocialEdit
							referent={filteredReferenta}
							suivi={edit}
							onSave={() => {
								setEdit(null);
								onRefresh();
							}}
							onClose={() => setEdit(null)}
						/>
					</Dialog>
					<Confirm
						value={!!remove}
						onChange={() => setRemove(null)}
						title="Suppression d'un suivi"
						message={`Êtes--vous sûr de vouloir supprimer le suivi de ${dateToDash(remove?.date)} ?`}
						onConfirm={async () => {
							await dispatch(deleteSuiviSocial(remove));
							onRefresh();
						}}
					/>
				</CardContent>
			)}
		</Card>
	);
}

function formatDate(dateString: string): string {
	const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
	return new Date(dateString).toLocaleDateString('fr-FR', options);
}

export interface CrudReferentEditProps {
	onClose: () => any;
	onUpdate: () => any;
	onPrev: () => Promise<any>;
	onNext: () => Promise<any>;
	item: Nullable<TriEvalSociale>;
}

export interface InformationsProps {
	onRefresh: () => any;
}

// function useReferentById(referentId: string) {
// 	const dispatch = useDispatch();
// 	const [referent, setReferent] = useState<Nullable<Referent>>(null);
// 	const [loading, setLoading] = useState(false);
// 	const fetchReferent = useCallback(async () => {
// 		setLoading(true);
// 		try {
// 			const result = await dispatch(findReferentById(referentId));
// 			setReferent(result);
// 		} catch (e) {
// 			console.error(e);
// 		}
// 		setLoading(false);
// 	}, [dispatch, referentId]);
// 	useEffect(() => {
// 		fetchReferent();
// 	}, [fetchReferent]);
// 	return { referent, loading, refresh: fetchReferent };
// }

function Part99({ onRefresh }: InformationsProps) {
	const dispatch = useDispatch();
	const triAcqui = hTriAcqui(useSelector((state) => state.triAcqui.triAcqui));
	const filteredReferenta = triAcqui?.proprietaire;
	const referent = hReferent(useSelector((state) => state.referent.referent));

	const [currentUser, setCurrentUser] = useState<Nullable<User>>(null);
	const [editAdresse, setEditAdresse] = useState<Nullable<HAdresse>>(null);
	const [editTelephone, setEditTelephone] = useState<Nullable<HTelephone>>(null);
	const [editEmail, setEditEmail] = useState<Nullable<HEmail>>(null);
	const [removeAdresse, setRemoveAdresse] = useState<Nullable<HAdresse>>(null);
	const [removeTelephone, setRemoveTelephone] = useState<Nullable<HTelephone>>(null);
	const [removeEmail, setRemoveEmail] = useState<Nullable<HEmail>>(null);
	const [showContent, setShowContent] = useState(false);
	const [addresses, setAddresses] = useState<HAdresse[]>([]);
	const [emails, setEmails] = useState<HEmail[]>([]);
	const [telephones, setTelephones] = useState<HTelephone[]>([]);

	const toggleContent = () => {
		setShowContent(!showContent);
	};

	const refresh = useCallback(async () => {
		setLoading(true);
		try {
			if (filteredReferenta) {
				await dispatch(findReferentById(filteredReferenta));
			}
		} catch (e) {
			console.error(e);
		}
		setLoading(false);
	}, [dispatch, filteredReferenta]);

	useEffect(() => {
		const fetchData = async () => {
			await refresh();
			const user = await dispatch(getMe());
			setCurrentUser(user);
		};

		fetchData();
	}, [dispatch, refresh]);

	const getTypeLabel = (type: any) => {
		switch (type) {
			case 1:
				return "Donnée syndic";
			case 2:
				return "Saisie manuelle";
			default:
				return "";
		}
	};

	const concatAddresses = (adresse1: string | null, adresse2: string | null, adresse3: string | null) => {
		return [adresse1, adresse2, adresse3].filter(Boolean).join(' | ');
	};

	const getSource = (type: number, source: string | null) => {
		return source || '';
	};

	const filterAndSortItems = <T extends { type: number; dateMaj: string; pertinence: number; coche: boolean }>(items: T[], key: keyof T) => {
		return items.sort((a, b) => {
			if (a.coche !== b.coche) {
				return (b.coche ? 1 : 0) - (a.coche ? 1 : 0);
			} else if (a.type !== b.type) {
				return b.type - a.type;
			} else if (new Date(a.dateMaj).getTime() !== new Date(b.dateMaj).getTime()) {
				return new Date(b.dateMaj).getTime() - new Date(a.dateMaj).getTime();
			} else {
				return b.pertinence - a.pertinence;
			}
		});
	};

	const submitPertinenceChange = async (newItem: any, previousItem: any, putFunction: any) => {
		try {
			if (previousItem) {
				const updatedPreviousItem = { ...previousItem, pertinence: null };
				await dispatch(putFunction(updatedPreviousItem));
			}

			if (newItem) {
				await dispatch(putFunction(newItem));
			}
		} catch (error) {
			console.error('Error updating item:', error);
		}
	};

	const handleCheckboxChangeForAddress = async (index: number) => {
		const previousRelevantItem = addresses.find(item => item.pertinence === 1);

		const updatedList = addresses.map((item, i) => ({
			...item,
			coche: i === index,
			pertinence: i === index ? 1 : (item.pertinence === 1 ? 0 : item.pertinence),
		}));

		const relevantItem = updatedList.find(item => item.pertinence === 1);

		await submitPertinenceChange(relevantItem, previousRelevantItem, putAdresse);

		setAddresses(filterAndSortItems(updatedList, 'dateMaj'));
	};

	const handleCheckboxChangeForEmail = async (index: number) => {
		const previousRelevantItem = emails.find(item => item.pertinence === 1);

		const updatedList = emails.map((item, i) => ({
			...item,
			coche: i === index,
			pertinence: i === index ? 1 : (item.pertinence === 1 ? 0 : item.pertinence),
		}));

		const relevantItem = updatedList.find(item => item.pertinence === 1);

		await submitPertinenceChange(relevantItem, previousRelevantItem, putEmail);

		setEmails(filterAndSortItems(updatedList, 'dateMaj'));
	};

	const handleCheckboxChangeForTelephone = async (index: number) => {
		const previousRelevantItem = telephones.find(item => item.pertinence === 1);

		const updatedList = telephones.map((item, i) => ({
			...item,
			coche: i === index,
			pertinence: i === index ? 1 : (item.pertinence === 1 ? null : item.pertinence),
		}));

		const relevantItem = updatedList.find(item => item.pertinence === 1);

		await submitPertinenceChange(relevantItem, previousRelevantItem, putTelephone);

		setTelephones(filterAndSortItems(updatedList, 'dateMaj'));
	};

	useEffect(() => {
		if (referent) {
			setAddresses(filterAndSortItems(referent.adresses?.map((adresse: { pertinence: number; }) => ({ ...adresse, coche: adresse.pertinence === 1, pertinence: adresse.pertinence ?? null })) || [], 'adresse1'));
			setEmails(filterAndSortItems(referent.emails?.map((email: { pertinence: number; }) => ({ ...email, coche: email.pertinence === 1, pertinence: email.pertinence ?? null })) || [], 'email1'));
			setTelephones(filterAndSortItems(referent.telephones?.map((telephone: { pertinence: number; }) => ({ ...telephone, coche: telephone.pertinence === 1, pertinence: telephone.pertinence ?? null })) || [], 'telephone1'));
		}
	}, [referent]);

	const columnWidths = {
		type: '10%',
		address: '35%',
		comment: '20%',
		source: '10%',
		dateMaj: '10%',
		actions: '10%',
		reference: '5%'
	};

	return (
		<>
			<Card elevation={4} className="mb-1">
				<CardHeader
					className="py-1 mb-1"
					title="Coordonnées"
					titleTypographyProps={{ className: 'u-t-size-19' }}
					action={
						<IconButton onClick={toggleContent} style={{ color: 'white' }}>
							{showContent ? <AddIcon /> : <AddIcon />}
						</IconButton>
					}
				/>
				{showContent && (
					<CardContent className="pt-0">
						{/* ------------------------------ ADRESSES --------------------------------------------- */}
						<Grid container spacing={1} className="customGridContainer">
							<Grid xs={12}>
								<div style={{ overflow: 'auto' }}>
									<strong>Adresse(s) :</strong> <br />
									<table className="c-Crud-Referent-Edit-suiviSocial">
										<thead>
											<tr>
												<th style={{ width: columnWidths.address }}>Adresse</th>
												<th style={{ width: columnWidths.comment }}>Commentaire</th>
												<th style={{ width: columnWidths.source, textAlign: 'center' }}>Source</th>
												<th style={{ width: columnWidths.type, textAlign: 'center' }}>Type</th>
												<th style={{ width: columnWidths.dateMaj }}>Date maj</th>
												<th style={{ width: columnWidths.reference, textAlign: 'center' }}>
													<Tooltip title="Marquer comme adresse de référence">
														<span>Réf</span>
													</Tooltip>
												</th>
												<th style={{ width: columnWidths.actions, textAlign: 'center' }}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{addresses.length ? (
												addresses.map((adresse: HAdresse, index) => (
													<Tooltip
														key={adresse.id}
														title="Adresse de référence"
														disableHoverListener={adresse.pertinence !== 1}
													>
														<tr style={index === 0 ? { backgroundColor: '#f0f0f0' } : {}}>
															<td style={{ width: columnWidths.address }}>
																{concatAddresses(adresse.adresse1, adresse.adresse2, adresse.adresse3)}
															</td>
															<td style={{ width: columnWidths.comment }}>{adresse.commentaire}</td>
															<td style={{ width: columnWidths.source, textAlign: 'center' }}>
																{getSource(adresse.type, adresse.source)}
															</td>
															<td style={{ width: columnWidths.type, textAlign: 'center' }}>
																{getTypeLabel(adresse.type)}
															</td>
															<td style={{ width: columnWidths.dateMaj }}>{formatDate(adresse.dateMaj)}</td>
															<td style={{ width: columnWidths.reference, textAlign: 'center' }}>
																<Checkbox
																	checked={adresse.coche}
																	onChange={() => handleCheckboxChangeForAddress(index)}
																	inputProps={{ 'aria-label': 'Adresse de référence' }}
																/>
															</td>
															<td style={{ width: columnWidths.actions, textAlign: 'center' }}>
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
																	{adresse.type !== 1 && (
																		<>
																			<IconButton onClick={() => setEditAdresse(adresse)}>
																				<IconModeEdit />
																			</IconButton>
																			<IconButton onClick={() => setRemoveAdresse(adresse)}>
																				<IconDelete />
																			</IconButton>
																		</>
																	)}
																</div>
															</td>

														</tr>
													</Tooltip>
												))
											) : (
												<tr>
													<td colSpan={7} className="u-t-center">
														<i>Aucune adresse</i>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</Grid>
							<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
								<Button
									variant="contained"
									size="small"
									onClick={() =>
										setEditAdresse({
											id: 0,
											referent: referent?.id,
											adresse1: '',
											adresse2: '',
											adresse3: '',
											commentaire: '',
											source: '',
											pertinence: 0, // Ajout de pertinence par défaut pour une nouvelle adresse
											dateMaj: new Date().toISOString(), // Ajout de la date de mise à jour pour une nouvelle adresse
										})
									}
								>
									+
								</Button>
							</Grid>
							<Dialog open={editAdresse !== null} onClose={() => setEditAdresse(null)} maxWidth={'md'}>
								{editAdresse && (
									<CrudAdresseEdit
										referent={referent as Referent}
										value={editAdresse}
										onSave={() => {
											setEditAdresse(null);
											refresh();
										}}
										onClose={() => setEditAdresse(null)}
										currentUser={currentUser}
									/>
								)}
							</Dialog>
							<Confirm
								value={!!removeAdresse}
								onChange={() => setRemoveAdresse(null)}
								title="Suppression d'une adresse"
								message={`Êtes-vous sûr de vouloir supprimer cette adresse ?`}
								onConfirm={async () => {
									if (removeAdresse) {
										await dispatch(deleteAdresse(removeAdresse.id));
										setRemoveAdresse(null);
										refresh();
									}
								}}
							/>
						</Grid>
						{/* ------------------------------ EMAILS ----------------------------------------------- */}
						<Grid container spacing={1} className="customGridContainer">
							<Grid xs={12}>
								<div style={{ overflow: 'auto' }}>
									<strong>Email(s) :</strong> <br />
									<table className="c-Crud-Referent-Edit-suiviSocial">
										<thead>
											<tr>
												<th style={{ width: columnWidths.type, textAlign: 'center' }}>Type</th>
												<th style={{ width: columnWidths.address }}>Email</th>
												<th style={{ width: columnWidths.comment }}>Commentaire</th>
												<th style={{ width: columnWidths.source, textAlign: 'center' }}>Source</th>
												<th style={{ width: columnWidths.dateMaj }}>Date maj</th>
												<th style={{ width: columnWidths.reference, textAlign: 'center' }}>
													<Tooltip title="Marquer comme email de référence">
														<span>Réf</span>
													</Tooltip>
												</th>
												<th style={{ width: columnWidths.actions, textAlign: 'center' }}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{emails.length ? (
												emails.map((email: HEmail, index) => (
													<Tooltip
														key={email.id}
														title="Email de référence"
														disableHoverListener={email.pertinence !== 1}
													>
														<tr key={email.id} style={index === 0 ? { backgroundColor: '#f0f0f0' } : {}}>
															<td style={{ width: columnWidths.type, textAlign: 'center' }}>
																{getTypeLabel(email.type)}
															</td>
															<td style={{ width: columnWidths.address }}>{email.email1}</td>
															<td style={{ width: columnWidths.comment }}>{email.commentaire}</td>
															<td style={{ width: columnWidths.source, textAlign: 'center' }}>
																{getSource(email.type, email.source)}
															</td>
															<td style={{ width: columnWidths.dateMaj }}>{formatDate(email.dateMaj)}</td>
															<td style={{ width: columnWidths.reference, textAlign: 'center' }}>
																<Checkbox
																	checked={email.coche}
																	onChange={() => handleCheckboxChangeForEmail(index)}
																	inputProps={{ 'aria-label': 'Email de référence' }}
																/>
															</td>
															<td style={{ width: columnWidths.actions, textAlign: 'center' }}>
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
																	{email.type !== 1 && (
																		<>
																			<IconButton onClick={() => setEditEmail(email)}>
																				<IconModeEdit />
																			</IconButton>
																			<IconButton onClick={() => setRemoveEmail(email)}>
																				<IconDelete />
																			</IconButton>
																		</>
																	)}
																</div>
															</td>
														</tr>
													</Tooltip>
												))
											) : (
												<tr>
													<td colSpan={7} className="u-t-center">
														<i>Aucun email</i>
													</td>
												</tr>
											)}
										</tbody>

									</table>
								</div>
							</Grid>
							<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
								<Button
									variant="contained"
									size="small"
									onClick={() =>
										setEditEmail({
											id: 0,
											referent: referent?.id,
											email1: '',
											commentaire: '',
											source: '',
											pertinence: 0, // Ajout de pertinence par défaut pour un nouvel email
											dateMaj: new Date().toISOString(), // Ajout de la date de mise à jour pour un nouvel email
										})
									}
								>
									+
								</Button>
							</Grid>
							<Dialog open={editEmail !== null} onClose={() => setEditEmail(null)} maxWidth={'md'}>
								{editEmail && (
									<CrudEmailEdit
										referent={referent as Referent}
										value={editEmail}
										onSave={() => {
											setEditEmail(null);
											refresh();
										}}
										onClose={() => setEditEmail(null)}
										currentUser={currentUser}
									/>
								)}
							</Dialog>
							<Confirm
								value={!!removeEmail}
								onChange={() => setRemoveEmail(null)}
								title="Suppression d'un email"
								message={`Êtes-vous sûr de vouloir supprimer cet email ?`}
								onConfirm={async () => {
									if (removeEmail) {
										await dispatch(deleteEmail(removeEmail.id));
										setRemoveEmail(null);
										refresh();
									}
								}}
							/>
						</Grid>
						{/* ------------------------------ TELEPHONES ------------------------------------------- */}
						<Grid container spacing={1} className="customGridContainer">
							<Grid xs={12}>
								<div style={{ overflow: 'auto' }}>
									<strong>Numéro(s) de téléphone :</strong> <br />
									<table className="c-Crud-Referent-Edit-suiviSocial">
										<thead>
											<tr>
												<th style={{ width: columnWidths.type, textAlign: 'center' }}>Type</th>
												<th style={{ width: columnWidths.address }}>Numéro</th>
												<th style={{ width: columnWidths.comment }}>Commentaire</th>
												<th style={{ width: columnWidths.source, textAlign: 'center' }}>Source</th>
												<th style={{ width: columnWidths.dateMaj }}>Date maj</th>
												<th style={{ width: columnWidths.reference, textAlign: 'center' }}>
													<Tooltip title="Marquer comme téléphone de référence">
														<span>Réf</span>
													</Tooltip>
												</th>
												<th style={{ width: columnWidths.actions, textAlign: 'center' }}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{telephones.length ? (
												telephones.map((telephone: HTelephone, index) => (
													<Tooltip
														key={telephone.id}
														title="Téléphone de référence"
														disableHoverListener={telephone.pertinence !== 1}
													>
														<tr key={telephone.id} style={index === 0 ? { backgroundColor: '#f0f0f0' } : {}}>
															<td style={{ width: columnWidths.type, textAlign: 'center' }}>
																{getTypeLabel(telephone.type)}
															</td>
															<td style={{ width: columnWidths.address }}>{telephone.telephone1}</td>
															<td style={{ width: columnWidths.comment }}>{telephone.commentaire}</td>
															<td style={{ width: columnWidths.source, textAlign: 'center' }}>
																{getSource(telephone.type, telephone.source)}
															</td>
															<td style={{ width: columnWidths.dateMaj }}>{formatDate(telephone.dateMaj)}</td>
															<td style={{ width: columnWidths.reference, textAlign: 'center' }}>
																<Checkbox
																	checked={telephone.coche}
																	onChange={() => handleCheckboxChangeForTelephone(index)}
																	inputProps={{ 'aria-label': 'Téléphone de référence' }}
																/>
															</td>
															<td style={{ width: columnWidths.actions, textAlign: 'center' }}>
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
																	{telephone.type !== 1 && (
																		<>
																			<IconButton onClick={() => setEditTelephone(telephone)}>
																				<IconModeEdit />
																			</IconButton>
																			<IconButton onClick={() => setRemoveTelephone(telephone)}>
																				<IconDelete />
																			</IconButton>
																		</>
																	)}
																</div>
															</td>
														</tr>
													</Tooltip>
												))
											) : (
												<tr>
													<td colSpan={7} className="u-t-center">
														<i>Aucun téléphone</i>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</Grid>
							<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
								<Button
									variant="contained"
									size="small"
									onClick={() =>
										setEditTelephone({
											id: 0,
											referent: referent?.id,
											telephone1: '',
											commentaire: '',
											source: '',
											pertinence: 0, // Ajout de pertinence par défaut pour un nouveau téléphone
											dateMaj: new Date().toISOString(), // Ajout de la date de mise à jour pour un nouveau téléphone
										})
									}
								>
									+
								</Button>
							</Grid>
							<Dialog open={editTelephone !== null} onClose={() => setEditTelephone(null)} maxWidth={'md'}>
								{editTelephone && (
									<CrudTelephoneEdit
										referent={referent as Referent}
										value={editTelephone}
										onSave={() => {
											setEditTelephone(null);
											refresh();
										}}
										onClose={() => setEditTelephone(null)}
										currentUser={currentUser}
									/>
								)}
							</Dialog>
							<Confirm
								value={!!removeTelephone}
								onChange={() => setRemoveTelephone(null)}
								title="Suppression d'un téléphone"
								message={`Êtes-vous sûr de vouloir supprimer ce téléphone ?`}
								onConfirm={async () => {
									if (removeTelephone) {
										await dispatch(deleteTelephone(removeTelephone.id));
										setRemoveTelephone(null);
										refresh();
									}
								}}
							/>
						</Grid>
					</CardContent>
				)}
			</Card>
		</>
	);
}
export interface InformationsProps {
	onRefresh: () => any;
  }
  
  function useReferentById(referentId: string) {
	const dispatch = useDispatch();
	const [referent, setReferent] = useState<Nullable<Referent>>(null);
	const [loading, setLoading] = useState(false);
  
	const fetchReferent = useCallback(async () => {
	  setLoading(true);
	  try {
		const result = await dispatch(findReferentById(referentId));
		setReferent(result);
	  } catch (e) {
		console.error(e);
	  }
	  setLoading(false);
	}, [dispatch, referentId]);
  
	useEffect(() => {
	  fetchReferent();
	}, [fetchReferent]);
  
	return { referent, loading, refresh: fetchReferent };
  }
  
  function Part9({ onRefresh }: InformationsProps) {
	const dispatch = useDispatch();
	const triAcqui = hTriAcqui(useSelector((state) => state.triAcqui.triAcqui));
	const filteredReferenta = triAcqui?.proprietaire;
	const referents = hReferent(useSelector((state) => state.referent.referent));
	const { referent, loading, refresh } = useReferentById(referents.id);
	const [currentUser, setCurrentUser] = useState<Nullable<User>>(null);
	const [editAdresse, setEditAdresse] = useState<Nullable<HAdresse>>(null);
	const [editTelephone, setEditTelephone] = useState<Nullable<HTelephone>>(null);
	const [editEmail, setEditEmail] = useState<Nullable<HEmail>>(null);
	const [removeAdresse, setRemoveAdresse] = useState<Nullable<HAdresse>>(null);
	const [removeTelephone, setRemoveTelephone] = useState<Nullable<HTelephone>>(null);
	const [removeEmail, setRemoveEmail] = useState<Nullable<HEmail>>(null);
	const [showContent, setShowContent] = useState(false);
	const [addresses, setAddresses] = useState<HAdresse[]>([]);
	const [emails, setEmails] = useState<HEmail[]>([]);
	const [telephones, setTelephones] = useState<HTelephone[]>([]);
  
	const toggleContent = () => {
	  setShowContent(!showContent);
	};
  
	useEffect(() => {
	  refresh();
	  dispatch(getMe()).then((user: Nullable<User>) => {
		setCurrentUser(user);
	  });
	}, [refresh, dispatch]);
  
	const getTypeLabel = (type: any) => {
	  switch (type) {
		case 1:
		  return "Donnée syndic";
		case 2:
		  return "Saisie manuelle";
		default:
		  return "";
	  }
	};
  
	const concatAddresses = (adresse1: string | null, adresse2: string | null, adresse3: string | null) => {
	  return [adresse1, adresse2, adresse3].filter(Boolean).join(' | ');
	};
  
	const getSource = (type: number, source: string | null) => {
	  return source || '';
	};
  
	const filterAndSortItems = <T extends { type: number; dateMaj: string; pertinence: number; coche: boolean }>(items: T[], key: keyof T) => {
	  return items.sort((a, b) => {
		if (a.coche !== b.coche) {
		  return (b.coche ? 1 : 0) - (a.coche ? 1 : 0);
		} else if (a.type !== b.type) {
		  return b.type - a.type;
		} else if (new Date(a.dateMaj).getTime() !== new Date(b.dateMaj).getTime()) {
		  return new Date(b.dateMaj).getTime() - new Date(a.dateMaj).getTime();
		} else {
		  return b.pertinence - a.pertinence;
		}
	  });
	};
  
	const submitPertinenceChange = async (newItem: any, previousItem: any, putFunction: any) => {
	  try {
		if (previousItem) {
		  const updatedPreviousItem = { ...previousItem, pertinence: null };
		  await dispatch(putFunction(updatedPreviousItem));
		}
  
		if (newItem) {
		  await dispatch(putFunction(newItem));
		}
	  } catch (error) {
		console.error('Error updating item:', error);
	  }
	};
  
	const handleCheckboxChangeForAddress = async (index: number) => {
	  const previousRelevantItem = addresses.find(item => item.pertinence === 1);
  
	  const updatedList = addresses.map((item, i) => ({
		...item,
		coche: i === index,
		pertinence: i === index ? 1 : (item.pertinence === 1 ? 0 : item.pertinence),
	  }));
  
	  const relevantItem = updatedList.find(item => item.pertinence === 1);
  
	  await submitPertinenceChange(relevantItem, previousRelevantItem, putAdresse);
  
	  setAddresses(filterAndSortItems(updatedList, 'dateMaj'));
	};
  
	const handleCheckboxChangeForEmail = async (index: number) => {
	  const previousRelevantItem = emails.find(item => item.pertinence === 1);
  
	  const updatedList = emails.map((item, i) => ({
		...item,
		coche: i === index,
		pertinence: i === index ? 1 : (item.pertinence === 1 ? 0 : item.pertinence),
	  }));
  
	  const relevantItem = updatedList.find(item => item.pertinence === 1);
  
	  await submitPertinenceChange(relevantItem, previousRelevantItem, putEmail);
  
	  setEmails(filterAndSortItems(updatedList, 'dateMaj'));
	};
  
	const handleCheckboxChangeForTelephone = async (index: number) => {
	  const previousRelevantItem = telephones.find(item => item.pertinence === 1);
  
	  const updatedList = telephones.map((item, i) => ({
		...item,
		coche: i === index,
		pertinence: i === index ? 1 : (item.pertinence === 1 ? null : item.pertinence),
	  }));
  
	  const relevantItem = updatedList.find(item => item.pertinence === 1);
  
	  await submitPertinenceChange(relevantItem, previousRelevantItem, putTelephone);
  
	  setTelephones(filterAndSortItems(updatedList, 'dateMaj'));
	};
  
	useEffect(() => {
	  if (referent) {
		setAddresses(filterAndSortItems(referent.adresses?.map(adresse => ({
		  ...adresse,
		  coche: adresse.pertinence === 1,
		  pertinence: adresse.pertinence ?? null
		})) || [], 'adresse1'));
		setEmails(filterAndSortItems(referent.emails?.map(email => ({
		  ...email,
		  coche: email.pertinence === 1,
		  pertinence: email.pertinence ?? null
		})) || [], 'email1'));
		setTelephones(filterAndSortItems(referent.telephones?.map(telephone => ({
		  ...telephone,
		  coche: telephone.pertinence === 1,
		  pertinence: telephone.pertinence ?? null
		})) || [], 'telephone1'));
	  }
	}, [referent]);
  
	const columnWidths = {
	  type: '10%',
	  address: '35%',
	  comment: '20%',
	  source: '10%',
	  dateMaj: '10%',
	  actions: '10%',
	  reference: '5%'
	};
  
	return (
	  <>
		<Card elevation={4} className="mb-1">
		  <CardHeader
			className="py-1 mb-1"
			title="Coordonnées"
			titleTypographyProps={{ className: 'u-t-size-19' }}
			action={
			  <IconButton onClick={toggleContent} style={{ color: 'white' }}>
				{showContent ? <ExpandLessIcon /> : <AddIcon />}
			  </IconButton>
			}
		  />
		  {showContent && (
			<CardContent className="pt-0">
			  {/* ------------------------------ ADRESSES --------------------------------------------- */}
			  <Grid container spacing={1} className="customGridContainer">
				<Grid xs={12}>
				  <div style={{ overflow: 'auto' }}>
					<strong>Adresse(s) :</strong> <br />
					<table className="c-Crud-Referent-Edit-suiviSocial">
					  <thead>
						<tr>
						  <th style={{ width: columnWidths.address }}>Adresse</th>
						  <th style={{ width: columnWidths.comment }}>Commentaire</th>
						  <th style={{ width: columnWidths.source, textAlign: 'center' }}>Source</th>
						  <th style={{ width: columnWidths.type, textAlign: 'center' }}>Type</th>
						  <th style={{ width: columnWidths.dateMaj }}>Date maj</th>
						  <th style={{ width: columnWidths.reference, textAlign: 'center' }}>
							<Tooltip title="Marquer comme adresse de référence">
							  <span>Réf</span>
							</Tooltip>
						  </th>
						  <th style={{ width: columnWidths.actions, textAlign: 'center' }}>Actions</th>
						</tr>
					  </thead>
					  <tbody>
						{addresses.length ? (
						  addresses.map((adresse: HAdresse, index) => (
							<Tooltip
							  key={adresse.id}
							  title="Adresse de référence"
							  disableHoverListener={adresse.pertinence !== 1}
							>
							  <tr style={index === 0 ? { backgroundColor: '#f0f0f0' } : {}}>
								<td style={{ width: columnWidths.address }}>
								  {concatAddresses(adresse.adresse1, adresse.adresse2, adresse.adresse3)}
								</td>
								<td style={{ width: columnWidths.comment }}>{adresse.commentaire}</td>
								<td style={{ width: columnWidths.source, textAlign: 'center' }}>
								  {getSource(adresse.type, adresse.source)}
								</td>
								<td style={{ width: columnWidths.type, textAlign: 'center' }}>
								  {getTypeLabel(adresse.type)}
								</td>
								<td style={{ width: columnWidths.dateMaj }}>{formatDate(adresse.dateMaj)}</td>
								<td style={{ width: columnWidths.reference, textAlign: 'center' }}>
								  <Checkbox
									checked={adresse.coche}
									onChange={() => handleCheckboxChangeForAddress(index)}
									inputProps={{ 'aria-label': 'Adresse de référence' }}
								  />
								</td>
								<td style={{ width: columnWidths.actions, textAlign: 'center' }}>
								  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									{adresse.type !== 1 && (
									  <>
										<IconButton onClick={() => setEditAdresse(adresse)}>
										  <IconModeEdit />
										</IconButton>
										<IconButton onClick={() => setRemoveAdresse(adresse)}>
										  <IconDelete />
										</IconButton>
									  </>
									)}
								  </div>
								</td>
							  </tr>
							</Tooltip>
						  ))
						) : (
						  <tr>
							<td colSpan={7} className="u-t-center">
							  <i>Aucune adresse</i>
							</td>
						  </tr>
						)}
					  </tbody>
					</table>
				  </div>
				</Grid>
				<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
				  <Button
					variant="contained"
					size="small"
					onClick={() =>
					  setEditAdresse({
						id: 0,
						referent: referent?.id,
						adresse1: '',
						adresse2: '',
						adresse3: '',
						commentaire: '',
						source: '',
						pertinence: 0, // Ajout de pertinence par défaut pour une nouvelle adresse
						dateMaj: new Date().toISOString(), // Ajout de la date de mise à jour pour une nouvelle adresse
					  })
					}
				  >
					+
				  </Button>
				</Grid>
				<Dialog open={editAdresse !== null} onClose={() => setEditAdresse(null)} maxWidth={'md'}>
				  {editAdresse && (
					<CrudAdresseEdit
					  referent={referent as Referent}
					  value={editAdresse}
					  onSave={() => {
						setEditAdresse(null);
						refresh();
					  }}
					  onClose={() => setEditAdresse(null)}
					  currentUser={currentUser}
					/>
				  )}
				</Dialog>
				<Confirm
				  value={!!removeAdresse}
				  onChange={() => setRemoveAdresse(null)}
				  title="Suppression d'une adresse"
				  message={`Êtes-vous sûr de vouloir supprimer cette adresse ?`}
				  onConfirm={async () => {
					if (removeAdresse) {
					  await dispatch(deleteAdresse(removeAdresse.id));
					  setRemoveAdresse(null);
					  refresh();
					}
				  }}
				/>
			  </Grid>
			  {/* ------------------------------ EMAILS ----------------------------------------------- */}
			  <Grid container spacing={1} className="customGridContainer">
				<Grid xs={12}>
				  <div style={{ overflow: 'auto' }}>
					<strong>Email(s) :</strong> <br />
					<table className="c-Crud-Referent-Edit-suiviSocial">
					  <thead>
						<tr>
						  <th style={{ width: columnWidths.type, textAlign: 'center' }}>Type</th>
						  <th style={{ width: columnWidths.address }}>Email</th>
						  <th style={{ width: columnWidths.comment }}>Commentaire</th>
						  <th style={{ width: columnWidths.source, textAlign: 'center' }}>Source</th>
						  <th style={{ width: columnWidths.dateMaj }}>Date maj</th>
						  <th style={{ width: columnWidths.reference, textAlign: 'center' }}>
							<Tooltip title="Marquer comme email de référence">
							  <span>Réf</span>
							</Tooltip>
						  </th>
						  <th style={{ width: columnWidths.actions, textAlign: 'center' }}>Actions</th>
						</tr>
					  </thead>
					  <tbody>
						{emails.length ? (
						  emails.map((email: HEmail, index) => (
							<Tooltip
							  key={email.id}
							  title="Email de référence"
							  disableHoverListener={email.pertinence !== 1}
							>
							  <tr key={email.id} style={index === 0 ? { backgroundColor: '#f0f0f0' } : {}}>
								<td style={{ width: columnWidths.type, textAlign: 'center' }}>
								  {getTypeLabel(email.type)}
								</td>
								<td style={{ width: columnWidths.address }}>{email.email1}</td>
								<td style={{ width: columnWidths.comment }}>{email.commentaire}</td>
								<td style={{ width: columnWidths.source, textAlign: 'center' }}>
								  {getSource(email.type, email.source)}
								</td>
								<td style={{ width: columnWidths.dateMaj }}>{formatDate(email.dateMaj)}</td>
								<td style={{ width: columnWidths.reference, textAlign: 'center' }}>
								  <Checkbox
									checked={email.coche}
									onChange={() => handleCheckboxChangeForEmail(index)}
									inputProps={{ 'aria-label': 'Email de référence' }}
								  />
								</td>
								<td style={{ width: columnWidths.actions, textAlign: 'center' }}>
								  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									{email.type !== 1 && (
									  <>
										<IconButton onClick={() => setEditEmail(email)}>
										  <IconModeEdit />
										</IconButton>
										<IconButton onClick={() => setRemoveEmail(email)}>
										  <IconDelete />
										</IconButton>
									  </>
									)}
								  </div>
								</td>
							  </tr>
							</Tooltip>
						  ))
						) : (
						  <tr>
							<td colSpan={7} className="u-t-center">
							  <i>Aucun email</i>
							</td>
						  </tr>
						)}
					  </tbody>
					</table>
				  </div>
				</Grid>
				<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
				  <Button
					variant="contained"
					size="small"
					onClick={() =>
					  setEditEmail({
						id: 0,
						referent: referent?.id,
						email1: '',
						commentaire: '',
						source: '',
						pertinence: 0, // Ajout de pertinence par défaut pour un nouvel email
						dateMaj: new Date().toISOString(), // Ajout de la date de mise à jour pour un nouvel email
					  })
					}
				  >
					+
				  </Button>
				</Grid>
				<Dialog open={editEmail !== null} onClose={() => setEditEmail(null)} maxWidth={'md'}>
				  {editEmail && (
					<CrudEmailEdit
					  referent={referent as Referent}
					  value={editEmail}
					  onSave={() => {
						setEditEmail(null);
						refresh();
					  }}
					  onClose={() => setEditEmail(null)}
					  currentUser={currentUser}
					/>
				  )}
				</Dialog>
				<Confirm
				  value={!!removeEmail}
				  onChange={() => setRemoveEmail(null)}
				  title="Suppression d'un email"
				  message={`Êtes-vous sûr de vouloir supprimer cet email ?`}
				  onConfirm={async () => {
					if (removeEmail) {
					  await dispatch(deleteEmail(removeEmail.id));
					  setRemoveEmail(null);
					  refresh();
					}
				  }}
				/>
			  </Grid>
			  {/* ------------------------------ TELEPHONES ------------------------------------------- */}
			  <Grid container spacing={1} className="customGridContainer">
				<Grid xs={12}>
				  <div style={{ overflow: 'auto' }}>
					<strong>Numéro(s) de téléphone :</strong> <br />
					<table className="c-Crud-Referent-Edit-suiviSocial">
					  <thead>
						<tr>
						  <th style={{ width: columnWidths.type, textAlign: 'center' }}>Type</th>
						  <th style={{ width: columnWidths.address }}>Numéro</th>
						  <th style={{ width: columnWidths.comment }}>Commentaire</th>
						  <th style={{ width: columnWidths.source, textAlign: 'center' }}>Source</th>
						  <th style={{ width: columnWidths.dateMaj }}>Date maj</th>
						  <th style={{ width: columnWidths.reference, textAlign: 'center' }}>
							<Tooltip title="Marquer comme téléphone de référence">
							  <span>Réf</span>
							</Tooltip>
						  </th>
						  <th style={{ width: columnWidths.actions, textAlign: 'center' }}>Actions</th>
						</tr>
					  </thead>
					  <tbody>
						{telephones.length ? (
						  telephones.map((telephone: HTelephone, index) => (
							<Tooltip
							  key={telephone.id}
							  title="Téléphone de référence"
							  disableHoverListener={telephone.pertinence !== 1}
							>
							  <tr key={telephone.id} style={index === 0 ? { backgroundColor: '#f0f0f0' } : {}}>
								<td style={{ width: columnWidths.type, textAlign: 'center' }}>
								  {getTypeLabel(telephone.type)}
								</td>
								<td style={{ width: columnWidths.address }}>{telephone.telephone1}</td>
								<td style={{ width: columnWidths.comment }}>{telephone.commentaire}</td>
								<td style={{ width: columnWidths.source, textAlign: 'center' }}>
								  {getSource(telephone.type, telephone.source)}
								</td>
								<td style={{ width: columnWidths.dateMaj }}>{formatDate(telephone.dateMaj)}</td>
								<td style={{ width: columnWidths.reference, textAlign: 'center' }}>
								  <Checkbox
									checked={telephone.coche}
									onChange={() => handleCheckboxChangeForTelephone(index)}
									inputProps={{ 'aria-label': 'Téléphone de référence' }}
								  />
								</td>
								<td style={{ width: columnWidths.actions, textAlign: 'center' }}>
								  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									{telephone.type !== 1 && (
									  <>
										<IconButton onClick={() => setEditTelephone(telephone)}>
										  <IconModeEdit />
										</IconButton>
										<IconButton onClick={() => setRemoveTelephone(telephone)}>
										  <IconDelete />
										</IconButton>
									  </>
									)}
								  </div>
								</td>
							  </tr>
							</Tooltip>
						  ))
						) : (
						  <tr>
							<td colSpan={7} className="u-t-center">
							  <i>Aucun téléphone</i>
							</td>
						  </tr>
						)}
					  </tbody>
					</table>
				  </div>
				</Grid>
				<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
				  <Button
					variant="contained"
					size="small"
					onClick={() =>
					  setEditTelephone({
						id: 0,
						referent: referent?.id,
						telephone1: '',
						commentaire: '',
						source: '',
						pertinence: 0, // Ajout de pertinence par défaut pour un nouveau téléphone
						dateMaj: new Date().toISOString(), // Ajout de la date de mise à jour pour un nouveau téléphone
					  })
					}
				  >
					+
				  </Button>
				</Grid>
				<Dialog open={editTelephone !== null} onClose={() => setEditTelephone(null)} maxWidth={'md'}>
				  {editTelephone && (
					<CrudTelephoneEdit
					  referent={referent as Referent}
					  value={editTelephone}
					  onSave={() => {
						setEditTelephone(null);
						refresh();
					  }}
					  onClose={() => setEditTelephone(null)}
					  currentUser={currentUser}
					/>
				  )}
				</Dialog>
				<Confirm
				  value={!!removeTelephone}
				  onChange={() => setRemoveTelephone(null)}
				  title="Suppression d'un téléphone"
				  message={`Êtes-vous sûr de vouloir supprimer ce téléphone ?`}
				  onConfirm={async () => {
					if (removeTelephone) {
					  await dispatch(deleteTelephone(removeTelephone.id));
					  setRemoveTelephone(null);
					  refresh();
					}
				  }}
				/>
			  </Grid>
			</CardContent>
		  )}
		</Card>
	  </>
	);
  }
  
  export default function CrudReferentEdit({ onClose, onUpdate, onPrev, onNext, item }: CrudReferentEditProps) {
	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const referent = useSelector((state: any) => state.referent.referent);
	const [loading, setLoading] = useState(false);
  
	const refresh = useCallback(() => {
	  (async () => {
		setLoading(true);
		try {
		  if (item) {
			await dispatch(findReferentById(item.id));
		  }
		} catch (e) {
		  console.error(e);
		  pushMessage('Une erreur est survenue', 'error');
		}
		setLoading(false);
	  })();
	}, [dispatch, item, pushMessage]);
  
	useEffectOnChange(() => {
	  refresh();
	}, [refresh]);
  
	const handlePrev = async () => {
	  setLoading(true);
	  try {
		await onPrev();
	  } catch (e) {
		console.error(e);
		pushMessage('Une erreur est survenue.', 'error');
	  }
	  setLoading(false);
	};
  
	const handleNext = async () => {
	  setLoading(true);
	  try {
		await onNext();
	  } catch (e) {
		console.error(e);
		pushMessage('Une erreur est survenue.', 'error');
	  }
	  setLoading(false);
	};
  
	return (
	  <div className="c-Crud-Referent-Edit">
		{referent ? (
		  <Card>
			<CardContent
			  style={{
				maxHeight: 'calc(100vh - 200px)',
				overflow: 'auto',
			  }}
			>
			  <Card sx={{ p: 2, mb: 2, overflow: 'hidden', boxShadow: 3 }}>
				<Grid container spacing={2}>
				  <Grid xs={12}>
					<Typography variant="h6" sx={{ mb: 2 }}>
					  Ménages: {`${item?.id || ''} - ${item?.nomProprietaire || ''}`}
					</Typography>
				  </Grid>
				  <Grid xs={12} md={4} lg={4}>
					<Table size="small">
					  <TableBody>
						<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						  <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
							Type :
						  </TableCell>
						  <TableCell>{item?.typeProprietaire}</TableCell>
						</TableRow>
						<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						  <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
							Solde SDC :
						  </TableCell>
						  <TableCell>{item?.soldeSdc}</TableCell>
						</TableRow>
						<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						  <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
							Solde SP :
						  </TableCell>
						  <TableCell>{item?.soldeSp}</TableCell>
						</TableRow>
					  </TableBody>
					</Table>
				  </Grid>
				</Grid>
			  </Card>
  
			  <EvaluationSocialeInfos onRefresh={refresh} />
			  <SuiviSocial onRefresh={refresh} />
			  <Part9 onRefresh={refresh} />
			</CardContent>
			<CardActions className="u-d-block">
			  <Button onClick={onClose}>Annuler et Fermer</Button>
			  <LoadingButton style={{ float: 'right' }} type="button" loading={loading} onClick={handleNext}>
				Suivant
			  </LoadingButton>
			  <LoadingButton style={{ float: 'right' }} type="button" loading={loading} onClick={handlePrev}>
				Précédent
			  </LoadingButton>
			</CardActions>
		  </Card>
		) : (
		  <div className="p-4 u-t-center">
			<CircularProgress size={150} thickness={2} />
		  </div>
		)}
	  </div>
	);
  }
  
  function setLoading(arg0: boolean) {
	throw new Error('Function not implemented.');
  }
  