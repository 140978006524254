import { buildModelHelper } from '@/libs/modelHelper';

export interface RefreshToken {
	id: string;
	lifetime: number;
	createTime: number;
}


export const hRefreshToken = buildModelHelper<RefreshToken>({
	get isValid(): boolean {
		return (new Date()).getTime() <= (this.lifetime + this.createTime) * 1000;
	}
})

export type HRefreshToken = ReturnType<typeof hRefreshToken>;
