import { buildModelHelper } from '@/libs/modelHelper';

export interface Email {
	id: number;
	type: number;
	email1: string;
	commentaire: string;
	source: string;
	$reference: number;
	pertinence: number;
	dateMaj: string;
}

export const hEmail = buildModelHelper<Email>({
	get canEdit(): boolean {
		return this.type !== 1;
	}
});
export type HEmail = ReturnType<typeof hEmail>;
