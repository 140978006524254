import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Button, Tooltip, Typography } from '@mui/material';

// Fonction pour filtrer les couleurs par initiale
function getFilteredColorsByInitial(colors: any[], palette: string) {
    return colors.filter((color: any) => color.id.charAt(0) === palette);
}

// Fonction pour obtenir les couleurs distinctes par initiale
function getDistinctInitials(colors: any[]) {
    return colors.map((color: any) => color.id.charAt(0))
        .filter((initial: any, index: any, self: any) => self.indexOf(initial) === index);
}

export function FiltreColor(props: any) {
    // Préparer les données pour le rendu
    const distinctInitials = getDistinctInitials(props.Colors);
    const filteredColors = getFilteredColorsByInitial(props.Colors, props.Pallette);

    // Fonction pour gérer le changement de palette
    const handlePalleteChange = (initial: string) => {
        props.handlePalleteChange(initial); // Appel de la méthode du parent
    };

    // Fonction pour gérer le changement de couleur
    const handleColorChange = (key: string, hex: string) => {
        props.handleColorChange(key, hex); // Appel de la méthode du parent pour changer la couleur
    };

    // Préparer le JSX des boutons de palette
    const paletteButtons = distinctInitials.map((initial: any, i: any) => (
        <Button className='colorButtons' variant='contained' key={i} onClick={() => handlePalleteChange(initial)}>
            {'Palette : ' + initial}
        </Button>
    ));

    // Préparer le JSX des boutons de couleur
    const colorButtons = filteredColors.map((color: any, i: any) => (
        <Button key={i} style={{ backgroundColor: color.hex }} onClick={() => handleColorChange(props.currentKey, color.hex)}>
            {'couleur: ' + color.id}
        </Button>
    ));

    return (
        <div className='Margin Aligner'>
            {Object.keys(props.Bg_color).map((key, index) => (
                props.Bg_color[key] !== '' ? (
                    <div key={index} className='Aligner test'>
                        <Tooltip
                            disableFocusListener
                            arrow
                            placement="bottom"
                            title={
                                <div>
                                    <Typography color="inherit">Couleurs</Typography>
                                    <div className='PaletteButtonsContainer'>
                                        {paletteButtons}
                                    </div>
                                    <div className='colorButtonsContainer'>
                                        {filteredColors.map((color: any, i: any) => (
                                            <Button
                                                key={i}
                                                style={{ backgroundColor: color.hex }}
                                                onClick={() => handleColorChange(key, color.hex)}
                                            >
                                                {'couleur: ' + color.id}
                                            </Button>
                                        ))}
                                    </div>
                                </div>
                            }
                        >
                            <div className='filtreColor' style={{ backgroundColor: props.Bg_color[key] }} />
                        </Tooltip>
                        <p className='bold fontupdate'>{key}</p>
                    </div>
                ) : null
            ))}
        </div>
    );
}

export function FiltreBatiment(props: any) {
    return (
        <div className='FormController' style={{ display: props.hide ? '' : 'none' }}>
            <FormControl className='FormControl'>
                <InputLabel id="Tranche">Tranche</InputLabel>
                <Select
                    labelId="Tranche"
                    id="Tranche"
                    value={props.selectedTranche}
                    label="Tranche"
                    onChange={props.handleTrancheChange}
                >
                    {props.Data.map((logement: any) => logement.syndicat)
                        .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
                        .map((item: any, index: any) => (
                            <MenuItem key={index} value={item}>
                                {item} - {props.etatMajs.find((etatMaj: any) => etatMaj.syndicat === item)?.nomSyndicat}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <FormControl className='FormControl'>
                <InputLabel id="Adresse de Batiment">Adresse de Batiment</InputLabel>
                <Select
                    labelId="Adresse de Batiment"
                    id="Adresse de Batiment"
                    value={props.selectedAdresse}
                    label="Adresse de Batiment"
                    onChange={props.handleAdresseChange}
                >
                    <MenuItem value={''}>{'All'}</MenuItem>
                    {props.Data.filter((logement: any) => logement.syndicat === props.selectedTranche)
                        .map((logement: any) => logement.adresse)
                        .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
                        .map((item: any, index: any) => (
                            <MenuItem key={index} value={item}>{item}</MenuItem>
                        ))}
                </Select>
            </FormControl>
        </div>
    );
}

// Fonction pour obtenir les logements filtrés
export function getFilteredLogements(logementsSansE: any[], order: { [x: string]: number }) {
    return logementsSansE
        .sort((a, b) => order[a.position] - order[b.position] || a.position.localeCompare(b.position))
        .filter((logement) => Object.keys(order).includes(logement.position) || Object.keys(order).find(key => key.startsWith(logement.position)));
}

// Fonction pour obtenir la couleur de fond pour un logement
export function getBackgroundColor(logement: any, filtre: string, Bg_color: { [x: string]: any }) {
    const filtreMaj = filtre.charAt(0).toUpperCase() + filtre.slice(1);
    if (logement.hasOwnProperty(`nomCategorie${filtreMaj}`) && Bg_color[logement[`nomCategorie${filtreMaj}`]]) {
        return Bg_color[logement[`nomCategorie${filtreMaj}`]];
    } else if (Bg_color[logement[filtre]]) {
        return Bg_color[logement[filtre]];
    }
    return '';
}



export function filterLogements(logements: any[], selectedTranche: string, selectedAdresse: string) {
    const filteredLogements = selectedTranche !== ''
        ? logements.filter((item: { syndicat: string }) => item.syndicat === selectedTranche)
        : logements;
    return filterLogementsByAdresse(filteredLogements, selectedAdresse);
}

export function groupLogements(filteredLogements: any[], selectedAdresse: string) {
    return groupLogementsByEscalier(filterLogementsByAdresse(filteredLogements, selectedAdresse));
}

function filterLogementsByAdresse(logements: {
    escalier: any; batiment: string, adresse: string
}[], selectedAdresse: string) {
    return logements.filter((logement) => {
        if (selectedAdresse !== '') {
            return logement.adresse === selectedAdresse;
        } else {
            return true;
        }
    });
}

function groupLogementsByEscalier(logements: { escalier: string }[]) {
    return Object.values(
        logements
            .sort((a, b) => a.escalier.localeCompare(b.escalier))
            .reduce((acc: { [key: string]: any[] }, logement) => {
                (acc[logement.escalier] = acc[logement.escalier] || []).push(logement);
                return acc;
            }, {})
    );
}

export function getOrderedPositions(logementsGrouped: any[], orderedItems: any) {
    return Array.from(new Set(logementsGrouped.map((position: any) => position.position)))
        .concat("E")
        .filter((item: any) => orderedItems.hasOwnProperty(item))
        .sort((a: any, b: any) => orderedItems[a] - orderedItems[b]);
}

export function getMaxEtage(logements: any[]) {
    return Math.max(...logements.map(logement => parseInt(logement.etage) || -1)) + 1;
}

export function getOrderEtage(maxEtage: number) {
    return Object.fromEntries(
        Array.from({ length: maxEtage }, (_, i) => [i.toString(), i])
    );
}

export function sortData(data: any[], order: { syndicat: string, batiment: string }) {
    return data.sort((a, b) => {
        if (a.syndicat === b.syndicat) {
            return a.batiment.localeCompare(b.batiment);
        }
        return a.syndicat.localeCompare(b.syndicat);
    });
}
