import { buildModelHelper } from '@/libs/modelHelper';

export interface RessourcesIndividuelles {
	id: number;
	salaire: Nullable<number>;
	indemnitesPe: Nullable<number>;
	retraite: Nullable<number>;
	pensionReversion: Nullable<number>;
	rsa: Nullable<number>;
	primeActivite: Nullable<number>;
	prestationsFamiliales: Nullable<number>;
	apa: Nullable<number>;
	aah: Nullable<number>;
	ij: Nullable<number>;
	pensionInvalidite: Nullable<number>;
	pensionAlimentaire: Nullable<number>;
	bourse: Nullable<number>;
	autres: Nullable<number>;
}

export const hRessourcesIndividuelles = buildModelHelper<RessourcesIndividuelles>({
});
export type HRessourcesIndividuelles = ReturnType<typeof hRessourcesIndividuelles>;
