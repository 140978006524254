import { DocumentType, DocumentTypeType, CustomRowType } from '@/models';
import { Button, Card, CardActions, CardContent, CardHeader, Tooltip } from '@mui/material';
import { useState } from 'react';
import TextField from '@/components/inputs/TextField/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { useForm } from '@/libs/form';
import Form from '@/components/forms/Form/Form';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import { model } from '@/libs/candy';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from '@/libs/redux';
import { useValidators } from '@/libs/validators';
import { postDocumentType, putDocumentTypeById } from '@/stores/documentType';
import CrudDocumentTypeModeSelect from '@/components/crud/documentTypeMode/Select/Select';
import CrudDocumentTypeFormatSelect from '@/components/crud/documentTypeFormat/Select/Select';
import './Edit.scss';
import CrudDocumentTypeTypeTypeSelect from '@/components/crud/documentTypeType/Select/Select';
import Checkbox from '@/components/inputs/Checkbox/Checkbox';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CustomTypeSelect from '@/components/crud/customType/Select';
import { Uuid } from '@/libs/utils';


export interface CrudDocumentTypeEditProps {
	value?: Nullable<DocumentType>;
	onClose: () => any,
	onUpdate: () => any,
}

export default function CrudDocumentTypeEdit(
	{
		value = null,
		onClose,
		onUpdate,
	}: CrudDocumentTypeEditProps
) {

	const dispatch = useDispatch();
	const validators = useValidators();

	const [name, setName] = useState(value?.name || '');
	const [code, setCode] = useState(value?.code || '');
	const [type, setType] = useState<Nullable<DocumentTypeType>>(value?.type || null);
	const [isPublic, setPublic] = useState(!!value?.public);
	const [mode, setMode] = useState(value?.mode || null);
	const [formats, setFormats] = useState(value?.formats || []);
	const [modeLabel, setModeLabel] = useState(value?.modeLabel || '');
	const [isPromote, setPromote] = useState(!!value?.isPromote);

	const [customRows, setCustomRows] = useState(value?.customRows || []);

	const addCustomRow = () => {
		if (customRows.length < 10) {
			setCustomRows
			([
				...customRows,
				{
					value: '',
					field: {
						uuid: Uuid.generate(),
						customType: null,
						customLabel: ''
					},
				  }
			]);
		}
	};

	const removeCustomRow = (index: number) => {
		const updatedRows = [...customRows];
		updatedRows.splice(index, 1);
		setCustomRows(updatedRows);
	};

	const form = useForm({
		handle: async () => {
			await form.call(async () => {
				const documentType = {
					name,
					code,
					type,
					public: isPublic,
					mode,
					modeLabel,
					formats,
					customRows,
					promote: isPromote,
				} as Partial<DocumentType>;

				if (value) {
					await dispatch(putDocumentTypeById(value.id, documentType));
				} else {
					await dispatch(postDocumentType(documentType));
				}
				onUpdate();
				onClose();
			});
		},
		fields: {
			name: {
				label: 'Nom',
				...model(name, setName),
				rules: [
					validators.required(),
				]
			},
			code: {
				label: 'Code',
				...model(code, setCode),
				rules: [
					validators.required(),
				]
			},
			type: {
				label: 'Type',
				...model(type, setType),
				rules: [
					validators.required(),
				]
			},
			public: {
				label: 'Document Stocké',
				...model(isPublic, setPublic),
			},
			promote: {
				label: 'Mettre en avant',
				...model(isPromote, setPromote),
			},
			mode: {
				label: 'Mode',
				...model(mode, setMode),
				rules: [
					validators.required(),
				],
				disabled: !!value?.used
			},
			modeLabel: {
				label: 'Label du mode',
				helperText: 'Sépararer par "|" pour le mode "Date début / Date fin"',
				...model(modeLabel, setModeLabel),
			},
			formats: {
				label: 'Formats',
				...model(formats, setFormats),
				rules: [
					validators.required(),
				]
			},
		}

	});

	return (
		<div className="c-Crud-DocumentType-Edit" >
			<Form form={form}>
				<Card>
					<CardHeader
						title={value ? (
							<>Édition du type de document {value.name}</>
						) : (
							<>Création d'un type de document</>
						)}
					/>
					<CardContent
						style={{
							maxHeight: 'calc(100vh - 200px)',
							overflow: 'auto',
						}}
					>
						<FormMainErrors form={form} />

						<Grid container spacing={2}>
							<Grid xs={12} md={6}>
								<TextField {...form.fields.name} />
							</Grid>
							<Grid xs={12} md={6}>
								<TextField {...form.fields.code} />
							</Grid>
							<Grid xs={12} md={6}>
								<CrudDocumentTypeTypeTypeSelect {...form.fields.type} />
							</Grid>
							<Grid xs={12} md={6}>
								<Grid container spacing={1}>
									<Grid xs={6}>
										<Checkbox {...form.fields.public} />
									</Grid>
									<Grid xs={6}>
										<Checkbox {...form.fields.promote} />
									</Grid>
								</Grid>
							</Grid>
							<Grid xs={12} md={4}>
								<CrudDocumentTypeModeSelect {...form.fields.mode} />
							</Grid>
							<Grid xs={12} md={4}>
								<TextField {...form.fields.modeLabel} />
							</Grid>
							<Grid xs={12} md={4}>
								<CrudDocumentTypeFormatSelect {...form.fields.formats} multiple />
							</Grid>
						</Grid>

						<Button
							className="mb-2 mt-2"
							variant="outlined"
							color="primary"
							onClick={addCustomRow}
							startIcon={<AddIcon />}
						>
							Ajouter une ligne
						</Button>
						{customRows.map((row, index) => (
							<Grid container spacing={3} key={index}>

								<Grid xs={6}>
									<TextField
										label="Label"
										rules={[validators.required()]}
										value={row.field.customLabel}
										onChange={(value) => {
											const updatedRows = [...customRows];
											updatedRows[index].field.customLabel = value;
											setCustomRows(updatedRows);
										}}
									/>
								</Grid>
								<Grid xs={5}>
									<CustomTypeSelect
										label="Type"
										rules={[validators.required()]} 
										value={customRows[index].field.customType}
										onChange={(value) => {
											const updatedRows = [...customRows];
											updatedRows[index].field.customType = value;
											setCustomRows(updatedRows);
										}}
									/>

								</Grid>
								<Grid xs={1}>
									<Tooltip title="Supprimer" arrow>
										<span>
											<IconButton
												aria-label="Supprimer"
												onClick={() => removeCustomRow(index)}
												// Supposons que vous vouliez conditionnellement désactiver le bouton, vous ajoutez ici la logique
												// disabled={conditionPourDesactiver}
												style={{ // Assurez-vous que le style du span permet les interactions
													display: 'inline-block', // Le span agit comme un conteneur inline
													cursor: 'not-allowed' // Change le curseur si nécessaire
												}}
											>
												<DeleteIcon />
											</IconButton>
										</span>
									</Tooltip>

								</Grid>
							</Grid>
						))}
					</CardContent>
					<CardActions
						className="u-d-block"
					>
						<Button
							onClick={onClose}
						>
							Fermer
						</Button>
						<LoadingButton
							style={{ float: 'right' }}
							type="submit"
							loading={form.loading}
							variant="contained"
						>
							Enregistrer
						</LoadingButton>
					</CardActions>
				</Card>
			</Form>
		</div>
	)
}
