import './Index.scss';
import { Button, Container, Dialog, IconButton, Tooltip } from '@mui/material';
import { useConnectedPage, useEffectOnChange, usePageable, useSnackMessage } from '@/libs/hooks';
import { useDispatch, useSelector } from '@/libs/redux';
import { useCallback, useState } from 'react';
import { deleteDocumentTypeById, findDocumentTypes } from '@/stores/documentType';
import { DocumentType } from '@/models';
import { model } from '@/libs/candy';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconModeEdit from '@mui/icons-material/ModeEdit';
import IconDelete from '@mui/icons-material/Delete';
import Confirm from '@/components/dialog/Confirm/Confirm';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import CrudDocumentTypeEdit from '@/components/crud/documentType/Edit/Edit';
import { useTranslation } from 'react-i18next';
import IconCheck from '@mui/icons-material/Check';


export interface PageBaseDocumentTypesIndexDocumentProps {
	documentType: DocumentType
	onDelete?: () => any,
	onUpdate?: () => any,
}

export function PageBaseDocumentTypesIndexDocument(
	{
		documentType,
		onDelete = () => {},
		onUpdate = () => {},
	}: PageBaseDocumentTypesIndexDocumentProps
) {

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [ deleteOpen, setDeleteOpen ] = useState(false);
	const [ edit, setEdit ] = useState(false);

	return (
		<TableRow>
			<TableCell>
				{documentType.id}
			</TableCell>
			<TableCell>
				{documentType.name}
			</TableCell>
			<TableCell>
				{documentType.code}
			</TableCell>
			<TableCell>
				{t(`models.document_type_type.values.${documentType.type}`)}
			</TableCell>
			<TableCell>
				{t(`models.document_type_mode.values.${documentType.mode}`)}
			</TableCell>
			<TableCell>
				{documentType.public && <IconCheck color="success" />}
			</TableCell>
			<TableCell>
				{documentType.modeLabel}
			</TableCell>
			<TableCell>
				{documentType.formats.map(f => t(`models.file_slot_format.values.${f}`)).join(', ')}
			</TableCell>
			<TableCell className="u-cell-sticky-right u-d-flex">
				<Tooltip title="Editer">
					<span>
						<IconButton
							color="primary"
							onClick={() => setEdit(true)}
						>
							<IconModeEdit />
						</IconButton>
					</span>
				</Tooltip>
				<Tooltip title="Supprimer">
					<span>
						<IconButton
							color="error"
							onClick={() => setDeleteOpen(true)}
							disabled={documentType.used}
							style={{
								// Si le bouton est désactivé, vous pouvez ajuster le style du span ici
								display: 'inline-block',  // Nécessaire pour que le span agisse comme un conteneur
							}}
						>
							<IconDelete />
						</IconButton>
					</span>
				</Tooltip>
				<Confirm
					{...model(deleteOpen, setDeleteOpen)}
					title="Suppression d'un type de document"
					message={`Êtes-vous sûr de vouloir supprimer le type de document ${documentType.name} ?`}
					onConfirm={async () => {
						await dispatch(deleteDocumentTypeById(documentType.id));
						onDelete();
					}}
				/>
				<Dialog
					open={edit}
					onClose={() => setEdit(false)}
					maxWidth={'md'}
				>
					<CrudDocumentTypeEdit
						value={documentType}
						onClose={() => setEdit(false)}
						onUpdate={onUpdate}
					/>
				</Dialog>
			</TableCell>
		</TableRow>
	);
}

export default function PageBaseDocumentTypesIndex() {

	useConnectedPage();

	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const documentTypes = useSelector(state => state.documentType.documentTypes);
	const pageable = usePageable();
	const [ create, setCreate ] = useState(false);

	const refresh = useCallback(() => {
		(async () => {
			try {
				await dispatch(findDocumentTypes(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage));
			} catch (e) {
				console.error(e);
				pushMessage('Une erreur est survenue.', 'error');
			}
		})();
	}, [ pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage ])
	
	useEffectOnChange(() => {
		refresh();
	}, [ refresh ]);

	return (
		<Container className="p-base-documentTypes-Index">

			<h2>
				Gestion des types de document
				<Button
					style={{float: 'right'}}
					variant="contained"
					onClick={() => setCreate(true)}
				>
					Créer un type de document
				</Button>
				<Dialog
					open={create}
					onClose={() => setCreate(false)}
					maxWidth={'md'}
				>
					<CrudDocumentTypeEdit
						onClose={() => setCreate(false)}
						onUpdate={refresh}
					/>
				</Dialog>
			</h2>

			<SortableTable
				className="u-clearfix"
				heads={[
					{ sort: 'id', name: 'Id' },
					{ sort: 'name', name: 'Nom' },
					{ sort: 'code', name: 'Code' },
					{ sort: 'type', name: 'Type' },
					{ sort: 'public', name: 'Public' },
					{ sort: 'mode', name: 'Mode' },
					{ sort: 'modeLabel', name: 'Label du mode' },
					{ name: 'Formats', sort: 'formats' },
					{ name: 'Actions', stickyRight: true },
				]}
				result={documentTypes}
				renderItem={(item) => (
					<PageBaseDocumentTypesIndexDocument
						documentType={item}
						onDelete={refresh}
						onUpdate={refresh}
					/>
				)}
				pageable={pageable}
			/>
		</Container>
	);
}

PageBaseDocumentTypesIndex.getMetadata = () => {
	return {
		rights: [ 'MANAGE_DOCUMENT_TYPE' ]
	}
}
