import './Personnes.scss';
import { Fragment, useCallback, useState } from 'react';
import { useDispatch, useSelector } from '@/libs/redux';
import { useEffectOnChange, usePageable, useSnackMessage } from '@/libs/hooks';
import { AnnuaireProprietaire } from '@/models';
import { findAnnuaireProprietaires, setAnnuaireProprietaire, setAnnuaireProprietaires } from '@/stores/annuaireProprietaire';
import { Container, Dialog, IconButton } from '@mui/material';
import { model } from '@/libs/candy';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CrudAnnuaireProprietaireFilters from '@/components/crud/annuaireProprietaire/Filters/Filters';
import { PhoneHelper } from '@/libs/utils/PhoneHelper';
import TooltipElm from '@/components/Tooltip/TooltipElm';
import EditIcon from '@mui/icons-material/Edit';
import CrudAnnuaireProprietaireEdit from '@/components/crud/annuaireProprietaire/Edit/Edit';
import { setPage } from '@/stores/page';

export interface PageAnnuairePersonnesItemProps {
	item: AnnuaireProprietaire,
	onPrev: () => void,
	onNext: () => void,
}

export function PageAnnuairePersonnesItem(
	{
		item,
		onPrev,
		onNext,
	}: PageAnnuairePersonnesItemProps
) {
	const [openEditDialog, setOpenEditDialog] = useState(false);

	return (
		<TableRow>
			<TableCell>
				<TooltipElm message={item.civilite} />
			</TableCell>
			<TableCell style={{ maxWidth: '250px' }}>
				<TooltipElm message={item.nom} />
			</TableCell>
			<TableCell style={{ width: '130px' }}>
				<TooltipElm message={item.type} />
			</TableCell>
			<TableCell>
				{item.adresse?.map((line, i) => (
					<TooltipElm key={i} message={line} />
				))}
			</TableCell>
			<TableCell>
				{item.email ? <a href={`mailto:${item.email}`}><TooltipElm message={item.email} /></a> : ''}
			</TableCell>
			<TableCell style={{ width: '140px' }}>
				{item.telephone ? (
					<TooltipElm message={PhoneHelper.format(PhoneHelper.fix(item.telephone))} />
				) : (
					''
				)}
			</TableCell>
			<TableCell>
				<TooltipElm message={item.presenceSyndicats?.join(', ') || ''} />
			</TableCell>
			<TableCell className="center-content">
				<IconButton onClick={() => setOpenEditDialog(true)}>
					<EditIcon />
				</IconButton>
				<Dialog
					open={openEditDialog}
					onClose={() => setOpenEditDialog(false)}
					maxWidth="md"
					fullWidth
					PaperProps={{ style: { width: '100%', maxWidth: '100%' } }}  // Ajoutez cette ligne pour définir la largeur
				>
					<CrudAnnuaireProprietaireEdit
						item={item}
						onClose={() => setOpenEditDialog(false)}
						onUpdate={() => {
							setOpenEditDialog(false);
							// refresh data
						}}
						onPrev={() => Promise.resolve()} // Placeholder function for onPrev
						onNext={() => Promise.resolve()} // Placeholder function for onNext
					/>
				</Dialog>

			</TableCell>
		</TableRow>
	);
}


export default function PageAnnuairePersonnes() {
	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const annuaireProprietaires = useSelector(state => state.annuaireProprietaire.annuaireProprietaires);
	const pageable = usePageable();
	const [edit, setEdit] = useState<Nullable<AnnuaireProprietaire>>(null);

	const [filters, setFilters] = useState({
		id: '',
		nom: '',
		type: [],
		presenceSyndicats: [],
	});

	const refresh = useCallback(() => {
		(async () => {
			try {
				await dispatch(findAnnuaireProprietaires(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, filters));
			} catch (e) {
				console.error(e);
				pushMessage('Une erreur est survenue.', 'error');
			}
		})();
	}, [filters, pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage]);

	useEffectOnChange(() => {
		refresh();
	}, [refresh]);

	const searchPrevPage = async (cPage: Nullable<number> = null) => {
		cPage = cPage !== null ? cPage : pageable.page;
		if (cPage - 1 < 0) {
			pushMessage('Vous avez atteint le premier élément modifiable', 'warning');
		} else {
			dispatch(setAnnuaireProprietaires(null));
			pageable.setPage(cPage - 1);
			const annuaireProprietaires = await dispatch(findAnnuaireProprietaires(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, filters));
			let newAnnuaireProprietaire: Nullable<AnnuaireProprietaire> = null;
			for (let i = annuaireProprietaires.data.length - 1; i >= 0; i--) {
				const item = annuaireProprietaires.data[i];
				newAnnuaireProprietaire = item;
				break;
			}
			if (newAnnuaireProprietaire) {
				setEdit(null);
				await new Promise(r => setTimeout(r, 10));
				setEdit(newAnnuaireProprietaire);
			} else {
				await searchPrevPage(cPage - 1);
			}
		}
	};

	const searchNextPage = async (cPage: Nullable<number> = null) => {
		cPage = cPage !== null ? cPage : pageable.page;
		dispatch(setAnnuaireProprietaires(null));
		pageable.setPage(cPage + 1);
		const annuaireProprietaires = await dispatch(findAnnuaireProprietaires(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, filters));
		if (!annuaireProprietaires?.data?.length) {
			dispatch(setAnnuaireProprietaires(null));
			pageable.setPage(cPage);
			pushMessage('Vous avez atteint le dernier élément modifiable', 'warning');
		} else {
			let newAnnuaireProprietaire: Nullable<AnnuaireProprietaire> = null;
			for (let i = 0; i < annuaireProprietaires.data.length; i++) {
				const item = annuaireProprietaires.data[i];
				newAnnuaireProprietaire = item;
				break;
			}
			if (newAnnuaireProprietaire) {
				setEdit(null);
				await new Promise(r => setTimeout(r, 10));
				setEdit(newAnnuaireProprietaire);
			} else {
				await searchNextPage(cPage + 1);
			}
		}
	};

	const handlePrev = async () => {
		if (annuaireProprietaires?.data && edit) {
			const currentIndex = annuaireProprietaires.data.map(r => r.id).indexOf(edit.id);
			let newAnnuaireProprietaire: Nullable<AnnuaireProprietaire> = null;
			for (let i = currentIndex - 1; i >= 0; i--) {
				const item = annuaireProprietaires.data[i];
				newAnnuaireProprietaire = item;
				break;
			}

			if (newAnnuaireProprietaire) {
				setEdit(null);
				await new Promise(r => setTimeout(r, 10));
				setEdit(newAnnuaireProprietaire);
			} else {
				await searchPrevPage();
			}
		}
	};

	const handleNext = async () => {
		if (annuaireProprietaires?.data && edit) {
			const currentIndex = annuaireProprietaires.data.map(r => r.id).indexOf(edit.id);
			let newAnnuaireProprietaire: Nullable<AnnuaireProprietaire> = null;
			for (let i = currentIndex + 1; i < annuaireProprietaires.data.length; i++) {
				const item = annuaireProprietaires.data[i];
				newAnnuaireProprietaire = item;
				break;
			}

			if (newAnnuaireProprietaire) {
				setEdit(null);
				await new Promise(r => setTimeout(r, 10));
				setEdit(newAnnuaireProprietaire);
			} else {
				await searchNextPage();
			}
		}
	};

	return (
		<Container className="p-annuaire-Personne">
			<CrudAnnuaireProprietaireFilters
				{...model(filters, setFilters)}
			/>
			<SortableTable
				heads={[
					{ name: 'Civilité', sort: 'civilite' },
					{ name: 'Nom', sort: 'nom' },
					{ name: 'Type', sort: 'type', style: { width: '130px' } },
					{ name: 'Adresse', sort: 'adresse' },
					{ name: 'Email', sort: 'email' },
					{ name: 'Téléphone', sort: 'telephone', style: { width: '140px' } },
					{ name: 'Présence', sort: 'presenceSyndicats' },
					{ name: 'Coordonnées', sort: '', style: { width: '100px' } },
				]}
				result={annuaireProprietaires}
				renderItem={(item) => (
					<PageAnnuairePersonnesItem
						item={item}
						onPrev={handlePrev}
						onNext={handleNext}
					/>
				)}
				pageable={pageable}
			/>
		</Container>
	);
}

PageAnnuairePersonnes.getMetadata = () => {
	return {
		rights: ['ANNUAIRE_PERSONNE'],
		title: 'Annuaire - Personnes'
	}
}
