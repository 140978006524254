import { HEmail, HIndividu, HSuiviSocial, Referent, TriEvalSociale, HAdresse, HTelephone, TriAcqui, hTriAcqui, AnnuaireProprietaire } from '@/models';
import type { AccompagnementSocial, HAccompagnementSocial } from '@/models/AccompagnementSocial';
import { hReferent } from '@/models/Referent';
import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Dialog, IconButton, Typography, Paper, Box, Table, TableBody, TableRow, TableCell, InputAdornment, TableHead, Checkbox, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from '@/libs/redux';
import { useEffectOnChange, useSnackMessage } from '@/libs/hooks';
import { findReferentById, setReferent } from '@/stores/referent';
import IconDelete from '@mui/icons-material/Delete';
import IconModeEdit from '@mui/icons-material/ModeEdit';
import Confirm from '@/components/dialog/Confirm/Confirm';
import CrudAdresseList from '@/components/crud/adresse/List/List';
import CrudTelephoneList from '@/components/crud/telephone/List/List';
import { deleteIndividu } from '@/stores/individu';
import { dateToDash, formatNumber, formatDate } from '@/libs/utils';
import { CrudIndividuEdit } from '@/components/crud/individut/Edit/Edit';
import CrudEmailList from '@/components/crud/email/List/List';
import { CrudChargesMenageEdit } from '@/components/crud/chargesMenage/Edit/Edit';
import { deleteSuiviSocial } from '@/stores/suiviSocial';
import { CrudSuiviSocialEdit } from '@/components/crud/suiviSocial/Edit/Edit';
import Form from '@/components/forms/Form/Form';
import { useForm } from '@/libs/form';
import { model, number } from '@/libs/candy';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import accompagnementSocial, { findAccompagnementSocials, findAccompagnementSocialById, deleteAccompagnementSocial, postAccompagnementSocial, putAccompagnementSocial } from '@/stores/accompagnementSocial';
import Select from '@/components/inputs/Select/Select';
import DatePicker from '@/components/inputs/DatePicker/DatePicker';
import TextField from '@/components/inputs/TextField/TextField';
import { grey } from '@mui/material/colors';

import { CrudAdresseEdit } from '../../adresse/Edit/Edit';
import { CrudEmailEdit } from '../../email/Edit/Edit';
import { CrudTelephoneEdit } from '../../telephone/Edit/Edit';

import recouvrement from '@/stores/recouvrement';
import { Form as FormObject } from '@/libs/form';
import { User } from '@/models';
import { getMe } from '@/stores/user';
import { findAnnuaireProprietaires, findAnnuaireProprietaireById } from '@/stores/annuaireProprietaire';
import { CrudAnnuaireProprietaireFiltersProps } from '../Filters/Filters';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { deleteAdresse, postAdresse, putAdresse } from '@/stores/adresse';
import { deleteTelephone, postTelephone, putTelephone } from '@/stores/telephone';
import { deleteEmail, postEmail, putEmail } from '@/stores/email';
import TooltipElm from '@/components/Tooltip/TooltipElm'; // Assurez-vous que ce composant est importé correctement

export interface CrudAnnuaireProprietaireProps {
	onClose: () => any;
	onUpdate: () => any;
	onPrev?: () => Promise<any>;
	onNext?: () => Promise<any>;
	item: Nullable<AnnuaireProprietaire>;
}

function useReferentById(referentId: string) {
	const dispatch = useDispatch();
	const [referent, setReferent] = useState<Nullable<Referent>>(null);
	const [loading, setLoading] = useState(false);
	const fetchReferent = useCallback(async () => {
		if (referentId) {
			setLoading(true);
			try {
				const result = await dispatch(findReferentById(referentId));
				setReferent(result);
			} catch (e) {
				console.error(e);
			}
			setLoading(false);
		}
	}, [dispatch, referentId]);
	useEffect(() => {
		fetchReferent();
	}, [fetchReferent]);
	return { referent, loading, refresh: fetchReferent };
}


export interface InformationsProps {
	onRefresh: () => any;
	onClose: () => any;
	onUpdate: () => any;
	onPrev?: () => Promise<any>;
	onNext?: () => Promise<any>;
	item: Nullable<AnnuaireProprietaire>;
}

function Part9({ onRefresh, item }: InformationsProps) {
	const dispatch = useDispatch();
	const filteredReferenta = item?.id || '';
	const { referent, loading, refresh } = useReferentById(filteredReferenta);
	const [currentUser, setCurrentUser] = useState<Nullable<User>>(null);

	const [editAdresse, setEditAdresse] = useState<Nullable<HAdresse>>(null);
	const [editTelephone, setEditTelephone] = useState<Nullable<HTelephone>>(null);
	const [editEmail, setEditEmail] = useState<Nullable<HEmail>>(null);
	const [removeAdresse, setRemoveAdresse] = useState<Nullable<HAdresse>>(null);
	const [removeTelephone, setRemoveTelephone] = useState<Nullable<HTelephone>>(null);
	const [removeEmail, setRemoveEmail] = useState<Nullable<HEmail>>(null);

	const [showContent, setShowContent] = useState(true);
	const [addresses, setAddresses] = useState<HAdresse[]>([]);
	const [emails, setEmails] = useState<HEmail[]>([]);
	const [telephones, setTelephones] = useState<HTelephone[]>([]);

	const toggleContent = () => {
		setShowContent(!showContent);
	};

	useEffect(() => {
		refresh();
		dispatch(getMe()).then((user: Nullable<User>) => {
			setCurrentUser(user);
		});
	}, [refresh, dispatch]);

	const getTypeLabel = (type: any) => {
		switch (type) {
			case 1:
				return "Donnée syndic";
			case 2:
				return "Saisie manuelle";
			default:
				return "";
		}
	};

	const concatAddresses = (adresse1: string | null, adresse2: string | null, adresse3: string | null) => {
		return [adresse1, adresse2, adresse3].filter(Boolean).join(' | ');
	};

	const getSource = (type: number, source: string | null) => {
		return source || '';
	};

	const filterAndSortItems = <T extends { type: number; dateMaj: string; pertinence: number; coche: boolean }>(items: T[], key: keyof T) => {
		return items.sort((a, b) => {
			if (a.coche !== b.coche) {
				return (b.coche ? 1 : 0) - (a.coche ? 1 : 0);
			} else if (a.type !== b.type) {
				return b.type - a.type;
			} else if (new Date(a.dateMaj).getTime() !== new Date(b.dateMaj).getTime()) {
				return new Date(b.dateMaj).getTime() - new Date(a.dateMaj).getTime();
			} else {
				return b.pertinence - a.pertinence;
			}
		});
	};

	const submitPertinenceChange = async (newItem: any, previousItem: any, putFunction: any) => {
		try {
			if (previousItem) {
				const updatedPreviousItem = { ...previousItem, pertinence: null };
				await dispatch(putFunction(updatedPreviousItem));
			}

			if (newItem) {
				await dispatch(putFunction(newItem));
			}
		} catch (error) {
			console.error('Error updating item:', error);
		}
	};

	// handleCheckboxChangeForAddress
	const handleCheckboxChangeForAddress = async (index: number) => {
		const previousRelevantItem = addresses.find(item => item.pertinence === 1);

		const updatedList = addresses.map((item, i) => ({
			...item,
			coche: i === index,
			pertinence: i === index ? 1 : (item.pertinence === 1 ? 0 : item.pertinence),
		}));

		const relevantItem = updatedList.find(item => item.pertinence === 1);

		await submitPertinenceChange(relevantItem, previousRelevantItem, putAdresse);

		setAddresses(filterAndSortItems(updatedList, 'dateMaj'));
	};

	// handleCheckboxChangeForEmail
	const handleCheckboxChangeForEmail = async (index: number) => {
		const previousRelevantItem = emails.find(item => item.pertinence === 1);

		const updatedList = emails.map((item, i) => ({
			...item,
			coche: i === index,
			pertinence: i === index ? 1 : (item.pertinence === 1 ? 0 : item.pertinence),
		}));

		const relevantItem = updatedList.find(item => item.pertinence === 1);

		await submitPertinenceChange(relevantItem, previousRelevantItem, putEmail);

		setEmails(filterAndSortItems(updatedList, 'dateMaj'));
	};

	// handleCheckboxChangeForTelephone
	const handleCheckboxChangeForTelephone = async (index: number) => {
		const previousRelevantItem = telephones.find(item => item.pertinence === 1);

		const updatedList = telephones.map((item, i) => ({
			...item,
			coche: i === index,
			pertinence: i === index ? 1 : (item.pertinence === 1 ? null : item.pertinence),
		}));

		const relevantItem = updatedList.find(item => item.pertinence === 1);

		await submitPertinenceChange(relevantItem, previousRelevantItem, putTelephone);

		setTelephones(filterAndSortItems(updatedList, 'dateMaj'));
	};


	useEffect(() => {
		if (referent) {
			setAddresses(filterAndSortItems(referent.adresses?.map(adresse => ({ ...adresse, coche: adresse.pertinence === 1, pertinence: adresse.pertinence ?? null })) || [], 'adresse1'));
			setEmails(filterAndSortItems(referent.emails?.map(email => ({ ...email, coche: email.pertinence === 1, pertinence: email.pertinence ?? null })) || [], 'email1'));
			setTelephones(filterAndSortItems(referent.telephones?.map(telephone => ({ ...telephone, coche: telephone.pertinence === 1, pertinence: telephone.pertinence ?? null })) || [], 'telephone1'));
		}
	}, [referent]);

	const columnWidths = {
		type: '10%',
		address: '35%',
		comment: '20%',
		source: '10%',
		dateMaj: '10%',
		actions: '10%',
		reference: '5%'
	};

	return (
		<>
			<Card elevation={4} className="mb-1">
				<CardHeader
					className="py-1 mb-1"
					title="Coordonnées"
					titleTypographyProps={{ className: 'u-t-size-19' }}
					action={
						<IconButton onClick={toggleContent} style={{ color: 'white' }}>
							{showContent ? <AddIcon /> : <AddIcon />}
						</IconButton>
					}
				/>
				{showContent && (
					<CardContent className="pt-0">
						{/* ------------------------------ ADRESSES --------------------------------------------- */}
						<Grid container spacing={1} className="customGridContainer">
							<Grid xs={12}>
								<div style={{ overflow: 'auto' }}>
									<strong>Adresse(s) :</strong> <br />
									<table className="c-Crud-Referent-Edit-suiviSocial">
										<thead>
											<tr>
												<th style={{ width: columnWidths.address }}>Adresse</th>
												<th style={{ width: columnWidths.comment }}>Commentaire</th>
												<th style={{ width: columnWidths.source, textAlign: 'center' }}>Source</th>
												<th style={{ width: columnWidths.type, textAlign: 'center' }}>Type</th>
												<th style={{ width: columnWidths.dateMaj }}>Date maj</th>
												<th style={{ width: columnWidths.reference, textAlign: 'center' }}>
													<Tooltip title="Marquer comme adresse de référence">
														<span>Réf</span>
													</Tooltip>
												</th>
												<th style={{ width: columnWidths.actions, textAlign: 'center' }}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{addresses.length ? (
												addresses.map((adresse: HAdresse, index) => (
													<Tooltip
														key={adresse.id}
														title="Adresse de référence"
														disableHoverListener={adresse.pertinence !== 1}
													>
														<tr style={index === 0 ? { backgroundColor: '#f0f0f0' } : {}}>
															<td style={{ width: columnWidths.address }}>
																{concatAddresses(adresse.adresse1, adresse.adresse2, adresse.adresse3)}
															</td>
															<td style={{ width: columnWidths.comment }}>{adresse.commentaire}</td>
															<td style={{ width: columnWidths.source, textAlign: 'center' }}>
																{getSource(adresse.type, adresse.source)}
															</td>
															<td style={{ width: columnWidths.type, textAlign: 'center' }}>
																{getTypeLabel(adresse.type)}
															</td>
															<td style={{ width: columnWidths.dateMaj }}>{formatDate(adresse.dateMaj)}</td>
															<td style={{ width: columnWidths.reference, textAlign: 'center' }}>
																<Checkbox
																	checked={adresse.coche}
																	onChange={() => handleCheckboxChangeForAddress(index)}
																	inputProps={{ 'aria-label': 'Adresse de référence' }}
																/>
															</td>
															<td style={{ width: columnWidths.actions, textAlign: 'center' }}>
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
																	{adresse.type !== 1 && (
																		<>
																			<IconButton onClick={() => setEditAdresse(adresse)}>
																				<IconModeEdit />
																			</IconButton>
																			<IconButton onClick={() => setRemoveAdresse(adresse)}>
																				<IconDelete />
																			</IconButton>
																		</>
																	)}
																</div>
															</td>

														</tr>
													</Tooltip>
												))
											) : (
												<tr>
													<td colSpan={7} className="u-t-center">
														<i>Aucune adresse</i>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</Grid>
							<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
								<Button
									variant="contained"
									size="small"
									onClick={() =>
										setEditAdresse({
											id: 0,
											referent: referent?.id,
											adresse1: '',
											adresse2: '',
											adresse3: '',
											commentaire: '',
											source: '',
											pertinence: 0, // Ajout de pertinence par défaut pour une nouvelle adresse
											dateMaj: new Date().toISOString(), // Ajout de la date de mise à jour pour une nouvelle adresse
										})
									}
								>
									+
								</Button>
							</Grid>
							<Dialog open={editAdresse !== null} onClose={() => setEditAdresse(null)} maxWidth={'md'}>
								{editAdresse && (
									<CrudAdresseEdit
										referent={referent as Referent}
										value={editAdresse}
										onSave={() => {
											setEditAdresse(null);
											refresh();
										}}
										onClose={() => setEditAdresse(null)}
										currentUser={currentUser}
									/>
								)}
							</Dialog>
							<Confirm
								value={!!removeAdresse}
								onChange={() => setRemoveAdresse(null)}
								title="Suppression d'une adresse"
								message={`Êtes-vous sûr de vouloir supprimer cette adresse ?`}
								onConfirm={async () => {
									if (removeAdresse) {
										await dispatch(deleteAdresse(removeAdresse.id));
										setRemoveAdresse(null);
										refresh();
									}
								}}
							/>
						</Grid>
						{/* ------------------------------ EMAILS ----------------------------------------------- */}
						<Grid container spacing={1} className="customGridContainer">
							<Grid xs={12}>
								<div style={{ overflow: 'auto' }}>
									<strong>Email(s) :</strong> <br />
									<table className="c-Crud-Referent-Edit-suiviSocial">
										<thead>
											<tr>
												<th style={{ width: columnWidths.type, textAlign: 'center' }}>Type</th>
												<th style={{ width: columnWidths.address }}>Email</th>
												<th style={{ width: columnWidths.comment }}>Commentaire</th>
												<th style={{ width: columnWidths.source, textAlign: 'center' }}>Source</th>
												<th style={{ width: columnWidths.dateMaj }}>Date maj</th>
												<th style={{ width: columnWidths.reference, textAlign: 'center' }}>
													<Tooltip title="Marquer comme email de référence">
														<span>Réf</span>
													</Tooltip>
												</th>
												<th style={{ width: columnWidths.actions, textAlign: 'center' }}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{emails.length ? (
												emails.map((email: HEmail, index) => (
													<Tooltip
														key={email.id}
														title="Email de référence"
														disableHoverListener={email.pertinence !== 1}
													>
														<tr key={email.id} style={index === 0 ? { backgroundColor: '#f0f0f0' } : {}}>
															<td style={{ width: columnWidths.type, textAlign: 'center' }}>
																{getTypeLabel(email.type)}
															</td>
															<td style={{ width: columnWidths.address }}>{email.email1}</td>
															<td style={{ width: columnWidths.comment }}>{email.commentaire}</td>
															<td style={{ width: columnWidths.source, textAlign: 'center' }}>
																{getSource(email.type, email.source)}
															</td>
															<td style={{ width: columnWidths.dateMaj }}>{formatDate(email.dateMaj)}</td>
															<td style={{ width: columnWidths.reference, textAlign: 'center' }}>
																<Checkbox
																	checked={email.coche}
																	onChange={() => handleCheckboxChangeForEmail(index)}
																	inputProps={{ 'aria-label': 'Email de référence' }}
																/>
															</td>
															<td style={{ width: columnWidths.actions, textAlign: 'center' }}>
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
																	{email.type !== 1 && (
																		<>
																			<IconButton onClick={() => setEditEmail(email)}>
																				<IconModeEdit />
																			</IconButton>
																			<IconButton onClick={() => setRemoveEmail(email)}>
																				<IconDelete />
																			</IconButton>
																		</>
																	)}
																</div>
															</td>
														</tr>
													</Tooltip>
												))
											) : (
												<tr>
													<td colSpan={7} className="u-t-center">
														<i>Aucun email</i>
													</td>
												</tr>
											)}
										</tbody>

									</table>
								</div>
							</Grid>
							<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
								<Button
									variant="contained"
									size="small"
									onClick={() =>
										setEditEmail({
											id: 0,
											referent: referent?.id,
											email1: '',
											commentaire: '',
											source: '',
											pertinence: 0, // Ajout de pertinence par défaut pour un nouvel email
											dateMaj: new Date().toISOString(), // Ajout de la date de mise à jour pour un nouvel email
										})
									}
								>
									+
								</Button>
							</Grid>
							<Dialog open={editEmail !== null} onClose={() => setEditEmail(null)} maxWidth={'md'}>
								{editEmail && (
									<CrudEmailEdit
										referent={referent as Referent}
										value={editEmail}
										onSave={() => {
											setEditEmail(null);
											refresh();
										}}
										onClose={() => setEditEmail(null)}
										currentUser={currentUser}
									/>
								)}
							</Dialog>
							<Confirm
								value={!!removeEmail}
								onChange={() => setRemoveEmail(null)}
								title="Suppression d'un email"
								message={`Êtes-vous sûr de vouloir supprimer cet email ?`}
								onConfirm={async () => {
									if (removeEmail) {
										await dispatch(deleteEmail(removeEmail.id));
										setRemoveEmail(null);
										refresh();
									}
								}}
							/>
						</Grid>
						{/* ------------------------------ TELEPHONES ------------------------------------------- */}
						<Grid container spacing={1} className="customGridContainer">
							<Grid xs={12}>
								<div style={{ overflow: 'auto' }}>
									<strong>Numéro(s) de téléphone :</strong> <br />
									<table className="c-Crud-Referent-Edit-suiviSocial">
										<thead>
											<tr>
												<th style={{ width: columnWidths.type, textAlign: 'center' }}>Type</th>
												<th style={{ width: columnWidths.address }}>Numéro</th>
												<th style={{ width: columnWidths.comment }}>Commentaire</th>
												<th style={{ width: columnWidths.source, textAlign: 'center' }}>Source</th>
												<th style={{ width: columnWidths.dateMaj }}>Date maj</th>
												<th style={{ width: columnWidths.reference, textAlign: 'center' }}>
													<Tooltip title="Marquer comme téléphone de référence">
														<span>Réf</span>
													</Tooltip>
												</th>
												<th style={{ width: columnWidths.actions, textAlign: 'center' }}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{telephones.length ? (
												telephones.map((telephone: HTelephone, index) => (
													<Tooltip
														key={telephone.id}
														title="Téléphone de référence"
														disableHoverListener={telephone.pertinence !== 1}
													>
														<tr key={telephone.id} style={index === 0 ? { backgroundColor: '#f0f0f0' } : {}}>
															<td style={{ width: columnWidths.type, textAlign: 'center' }}>
																{getTypeLabel(telephone.type)}
															</td>
															<td style={{ width: columnWidths.address }}>{telephone.telephone1}</td>
															<td style={{ width: columnWidths.comment }}>{telephone.commentaire}</td>
															<td style={{ width: columnWidths.source, textAlign: 'center' }}>
																{getSource(telephone.type, telephone.source)}
															</td>
															<td style={{ width: columnWidths.dateMaj }}>{formatDate(telephone.dateMaj)}</td>
															<td style={{ width: columnWidths.reference, textAlign: 'center' }}>
																<Checkbox
																	checked={telephone.coche}
																	onChange={() => handleCheckboxChangeForTelephone(index)}
																	inputProps={{ 'aria-label': 'Téléphone de référence' }}
																/>
															</td>
															<td style={{ width: columnWidths.actions, textAlign: 'center' }}>
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
																	{telephone.type !== 1 && (
																		<>
																			<IconButton onClick={() => setEditTelephone(telephone)}>
																				<IconModeEdit />
																			</IconButton>
																			<IconButton onClick={() => setRemoveTelephone(telephone)}>
																				<IconDelete />
																			</IconButton>
																		</>
																	)}
																</div>
															</td>
														</tr>
													</Tooltip>
												))
											) : (
												<tr>
													<td colSpan={7} className="u-t-center">
														<i>Aucun téléphone</i>
													</td>
												</tr>
											)}
										</tbody>

									</table>
								</div>
							</Grid>
							<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
								<Button
									variant="contained"
									size="small"
									onClick={() =>
										setEditTelephone({
											id: 0,
											referent: referent?.id,
											telephone1: '',
											commentaire: '',
											source: '',
											pertinence: 0, // Ajout de pertinence par défaut pour un nouveau téléphone
											dateMaj: new Date().toISOString(), // Ajout de la date de mise à jour pour un nouveau téléphone
										})
									}
								>
									+
								</Button>
							</Grid>
							<Dialog open={editTelephone !== null} onClose={() => setEditTelephone(null)} maxWidth={'md'}>
								{editTelephone && (
									<CrudTelephoneEdit
										referent={referent as Referent}
										value={editTelephone}
										onSave={() => {
											setEditTelephone(null);
											refresh();
										}}
										onClose={() => setEditTelephone(null)}
										currentUser={currentUser}
									/>
								)}
							</Dialog>
							<Confirm
								value={!!removeTelephone}
								onChange={() => setRemoveTelephone(null)}
								title="Suppression d'un téléphone"
								message={`Êtes-vous sûr de vouloir supprimer ce téléphone ?`}
								onConfirm={async () => {
									if (removeTelephone) {
										await dispatch(deleteTelephone(removeTelephone.id));
										setRemoveTelephone(null);
										refresh();
									}
								}}
							/>
						</Grid>
					</CardContent>
				)}
			</Card>
		</>
	);
}

export default function CrudAnnuaireProprietaire({ onClose, onUpdate, onPrev, onNext, item }: CrudAnnuaireProprietaireProps) {
	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const [loading, setLoading] = useState(false);
	const [currentUser, setCurrentUser] = useState<Nullable<User>>(null);

	const refresh = useCallback(async () => {
		setLoading(true);
		try {
			if (item) {
				await dispatch(findAnnuaireProprietaireById(item.id));
			}
		} catch (e: unknown) {
			if (e instanceof Error) {
				console.error(e);
			} else {
				console.error('An unknown error occurred:', e);
			}
		}
		setLoading(false);
	}, [dispatch, item, pushMessage]);

	useEffect(() => {
		refresh();
		dispatch(getMe()).then((user: Nullable<User>) => {
			setCurrentUser(user);
		});
	}, [refresh, dispatch]);

	const handlePrev = async () => {
		if (onPrev) {
			setLoading(true);
			try {
				await onPrev();
			} catch (e) {
				console.error(e);
				pushMessage('Erreur lors du passage au formulaire précédent.', 'error');
			}
			setLoading(false);
		}
	};

	const handleNext = async () => {
		if (onNext) {
			setLoading(true);
			try {
				await onNext();
			} catch (e) {
				console.error(e);
				pushMessage('Erreur lors du passage au formulaire suivant.', 'error');
			}
			setLoading(false);
		}
	};

	const handleUpdate = async () => {
		if (onUpdate) await onUpdate();
	};

	const handleCancel = () => {
		onClose();
	};

	return (
		<div className="c-Crud-Annuaire-Proprietaire">
			{item ? (
				<Card>
					<CardContent
						style={{
							maxHeight: 'calc(100vh - 200px)',
							overflow: 'auto',
						}}
					>
						<Card sx={{ p: 2, mb: 2, overflow: 'hidden', boxShadow: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Grid container spacing={1}>
								<Typography variant="h6" sx={{ mb: 2 }}>
									{`${item.id} - ${item.nom || ''}`}
								</Typography>
							</Grid>
						</Card>
						<Part9 onRefresh={refresh} item={item} onClose={onClose} onUpdate={handleUpdate} />
					</CardContent>
					<CardActions className="u-d-block">
						<Button onClick={onClose}>Fermer</Button>
					</CardActions>
				</Card>
			) : (
				<div className="p-4 u-t-center">
					<CircularProgress size={150} thickness={2} />
				</div>
			)}
		</div>
	);
}
