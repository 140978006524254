import Select, { SelectProps } from '@/components/inputs/Select/Select';
import { useDispatch, useSelector } from '@/libs/redux';
import { findTypeLots } from '@/stores/typeLot';
import { useEffectOnce } from 'react-use';
import { Direction } from '@/models';

export interface CrudTypeLotSelectProp extends Omit<SelectProps, 'items'|'itemText'|'itemValue'> {
}

export default function CrudTypeLotSelect(
	{
		...props
	}: CrudTypeLotSelectProp
) {
	const distpatch = useDispatch();
	const all = useSelector(state => state.typeLot.typeLots);
	
	useEffectOnce(() => {
		distpatch(findTypeLots(0, '', Direction.ASC, 200, 0));
	});
	
	return all ? (
		<Select
			{...props}
			items={all.data}
			itemValue="id"
			itemText="nom"
		/>
	) : <></>;
}
