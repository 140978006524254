import { Button, Card, CardContent, CardHeader, CircularProgress, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { model } from '@/libs/candy';
import { useForm } from '@/libs/form';
import { useValidators } from '@/libs/validators';
import { useDispatch } from '@/libs/redux';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackMessage } from '@/libs/hooks';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import Form from '@/components/forms/Form/Form';
import TextField from '@/components/inputs/TextField/TextField';
import './ResetPasswordConfirm.scss';
import IconVisibilityOff from '@mui/icons-material/VisibilityOff';
import IconVisibility from '@mui/icons-material/Visibility';
import { useEffectOnce } from 'react-use';
import { resetPasswordCheckToken, resetPasswordConfirm } from '@/stores/user';
import { Redirect } from '@/models';

export default function PageResetPasswordConfirm() {

	const dispatch = useDispatch();
	const { email } = useParams();
	const navigate = useNavigate();
	const validators = useValidators();
	const [ password, setPassword ] = useState('');
	const [ repeat, setRepeat ] = useState('');
	const pushMessage = useSnackMessage();
	const [ showPassword, setShowPassword ] = useState(false);
	const [ urlChange, setUrlChange ] = useState<Nullable<Redirect>>(null);
	const [ invalidToken, setInvalidToken ] = useState<boolean>(false);
	const [ readOnly, setReadOnly ] = useState<boolean>(true);


	const form = useForm({
		handle: async () => {
			await form.call(async () => {
				await dispatch(resetPasswordConfirm(urlChange?.href as string, password));
				pushMessage('Changement de mot de passe réussie');
				navigate('/');
			});
		},
		fields: {
			password: {
				...model(password, setPassword),
				rules: [
					validators.required(),
					validators.minlength({ min: 6 }),
				],
			},
			repeat: {
				...model(repeat, setRepeat),
				rules: [
					validators.required(),
					validators.repeatField({ repeat: password }),
				],
			},
		}
	});
	
	useEffectOnce(() => {
		(async () => {
			try {
				setReadOnly(true);
				setInvalidToken(false);
				setUrlChange(null);
				const redirect = await dispatch(resetPasswordCheckToken(email as string, window.location.search));
				setUrlChange(redirect);
				setTimeout(() => {
					setReadOnly(false);
				}, 1000);
			} catch (e) {
				console.error(e);
				setInvalidToken(true);
			}
		})();
	});

	return (
		<div className="p-PageResetPasswordConfirm">
			<Card className="p-PageResetPassword-popup">

				<CardHeader
					title="Changement de mot de passe"
					className="CardHeader--primary"
				/>

				<CardContent>
					{!invalidToken && !urlChange && (
						<div className="p-4 u-t-center">
							<CircularProgress size={150} thickness={2} />
						</div>
					)}
					{invalidToken && (
						<>
						<p className="u-t-center" style={{color: '#F22'}}>
							Le lien de changement de mot de passe à expiré.
						</p>
							<div className="c-login-LoginForm-action u-t-right">
								<Button
									variant="contained"
									href="/"
								>
									Revenir a la connection
								</Button>
							</div>
						</>
					)}
					{!!urlChange && (
						<Form form={form}>

							<FormMainErrors form={form} />

							<div className="mb-1">
								<TextField
									{...form.fields.password}
									clearable
									label="Mot de passe"
									variant="standard"
									type={showPassword ? 'text' : 'password'}
									autoComplete="false"
									readOnly={readOnly}
									appendIcon={showPassword ? <IconVisibilityOff /> : <IconVisibility />}
									appendIconProps={{
										'aria-label': "toggle password visibility",
										onClick: () => setShowPassword(!showPassword)
									}}
								/>
							</div>

							<div className="mb-1">
								<TextField
									{...form.fields.repeat}
									clearable
									label="Répétez le mot de passe"
									variant="standard"
									type={showPassword ? 'text' : 'password'}
									autoComplete="false"
									readOnly={readOnly}
									appendIcon={showPassword ? <IconVisibilityOff /> : <IconVisibility />}
									appendIconProps={{
										'aria-label': "toggle password visibility",
										onClick: () => setShowPassword(!showPassword)
									}}
								/>
							</div>

							<div className="c-login-LoginForm-action">
								<LoadingButton
									variant="contained"
									type="submit"
									loading={form.loading}
								>
									Envoyer
								</LoadingButton>
							</div>
						</Form>
					)}
				</CardContent>
			</Card>
		</div>
	)
}

PageResetPasswordConfirm.getMetadata = () => {
	return {
		layout: 'empty'
	};
};
