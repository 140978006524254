import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import './AppBarPortal.scss';


export interface AppBarPortalProps {
	children: ReactNode;
}

export default function AppBarPortal(
	{
		children,
	}: AppBarPortalProps
) {

	return (
		<>
			{createPortal(<>{children}</>, document.getElementById('c-AppBar-portal') as any)}
		</>
	);
}
