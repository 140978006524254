import { Lot } from '@/models/Lot';
import { buildModelHelper } from '@/libs/modelHelper';
import { Proprietaire } from '@/models/Proprietaire';
import { TriLot } from './TriLot';
export interface Propriete {
	date: string;
	proprietaire?: Proprietaire;
	lot?: Lot;
	triLot?: TriLot;
}


export const hPropriete = buildModelHelper<Propriete>({
});

export type HPropriete = ReturnType<typeof hPropriete>;
