import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import { Referent, Telephone, User } from '@/models';
import { useState } from 'react';
import { useForm } from '@/libs/form';
import { useDispatch } from '@/libs/redux';
import { LoadingButton } from '@mui/lab';
import Form from '@/components/forms/Form/Form';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import { useValidators } from '@/libs/validators';
import { postTelephone, putTelephone } from '@/stores/telephone';
import TextField from '@/components/inputs/TextField/TextField';

export interface CrudTelephoneEditProps {
	referent: Referent,
	value: Telephone;
	onSave: (telephone: Telephone) => any;
	onClose: () => any;
	currentUser: Nullable<User>; // Ajouter currentUser comme prop
}

export function CrudTelephoneEdit(
	{
		referent,
		value,
		onSave,
		onClose,
		currentUser, // Recevoir currentUser
	}: CrudTelephoneEditProps
) {
	const dispatch = useDispatch();
	const validators = useValidators();
	const [telephone1, setTelephone1] = useState(value.telephone1);
	const [commentaire, setCommentaire] = useState(value.commentaire);
	const [source, setSource] = useState(value.source || '');
	const hiddenStyle = { display: 'none' };

	const form = useForm({
		handle: async () => {
			await form.call(async () => {
				const newSource = `${currentUser?.firstName} ${currentUser?.lastName}`;
				const newTelephone = {
					...value,
					telephone1,
					commentaire,
					source: newSource,
				};

				if (newTelephone.id) {
					const result = await dispatch(putTelephone(newTelephone));
					onSave(result);
				} else {
					const result = await dispatch(postTelephone(referent, newTelephone));
					console.log(result);
					onSave(result);
				}
			});
		},
		fields: {
			telephone1: {
				label: 'Numéro de téléphone',
				...model(telephone1, setTelephone1),
				rules: [
					validators.required(),
				],
			},
			commentaire: {
				label: 'Commentaire',
				...model(commentaire, setCommentaire),
				multiline: true,
				rows: 5,
			},
			source: {
				label: 'Source',
				...model(source, setSource),
				disabled: true, // Désactiver l'édition du champ source
				hidden: true,
			}
		}
	});

	return (
		<Form form={form}>
			<Card>
				<CardHeader
					title={value.id ? "Édition du numéro de téléphone" : "Création d'un numéro de téléphone"}
					className="CardHeader--primary"
				/>
				<CardContent>
					<FormMainErrors form={form} />
					<Grid container spacing={1}>
						<Grid xs={12}>
							<TextField {...form.fields.telephone1} />
						</Grid>
						<Grid xs={12}>
							<TextField
								{...form.fields.commentaire}
								multiline
								rows={7}
							/>
						</Grid>
						<Grid xs={12} style={hiddenStyle}>
							<TextField
								{...form.fields.source}
							/>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions className="u-d-block">
					<LoadingButton
						loading={form.loading}
						onClick={onClose}
					>
						Fermer
					</LoadingButton>
					<LoadingButton
						type="submit"
						color="primary"
						variant="contained"
						loading={form.loading}
						style={{ float: 'right' }}
						onClick={(event) => {
							event.preventDefault();
							form.handle(event);
						}}
					>
						Enregistrer
					</LoadingButton>
				</CardActions>
			</Card>
		</Form>
	);
}
