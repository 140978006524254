export function CharteText() {
	return (
		<>
			<p>
				L’Etablissement Public Foncier d’Ile-de-France (EPFIF) a mis en place à l’usage de l’équipe projet de
				l’ORCOD-IN de Grigny 2 ainsi qu’à ses prestataires un portail web, intitulé « BIG 2 », ayant vocation à
				structurer et consolider les données issues des différents process de l’ORCOD-IN (habitat privé,
				gestion, acquisitions, accompagnement social et relogement), facilitant ainsi le pilotage aux différentes
				échelles de l’ORCOD-IN.
			</p>
			<p>
				Ce portail est accessible en ligne à l’adresse https://grigny2.big-coproplus.fr/ et est administré par
				COPRO+, qui est chargé de la maintenance du système d’information et gère les accès des
				utilisateurs, sous validation des chefs de projet référents de l’EPFIF. La base de données est
				hébergée sur les serveurs de l’EPFIF.
			</p>
			<p>
				Donnant accès notamment à des données personnelles, l’emploi de cet outil nécessite l’application et
				l’observation de certaines règles issues du RGPD. C’est pourquoi, les collaborateurs et prestataires
				de l’EPFIF doivent prendre connaissance de la présente charte et la mettre en application.
			</p>
			<p>
				Les collaborateurs de l’EPFIF sont informés que le non-respect des prescriptions de la présente
				Charte peut exposer aux sanctions disciplinaires prévues à l’article 13 du Règlement intérieur, sur le
				fondement de ses articles 6 et 7.
			</p>

			<p>
				<strong>Article 1 : Périmètre d’utilisation du « BIG 2 »</strong>
			</p>

			<p>
				Le portail web « BIG 2 » est accessible depuis internet à partir de l’adresse suivante :
				https://grigny2.big-coproplus.fr/
			</p>
			<p>
				L’utilisation du portail « BIG 2 » et de ses contenus est circonscrite à l’exercice des missions du
				collaborateur ou du prestataire pour l’Etablissement. Cette utilisation ne peut être effectuée pour un
				autre usage, privé ou professionnel, ni pour un tiers.
			</p>

			<p>
				<strong>Article 2 : Connexion au portail « BIG 2 »</strong>
			</p>
			<p>
				Le collaborateur ou le prestataire se connecte au portail « BIG 2 » au moyen d’identifiants de
				connexion personnels. Ces identifiants doivent être gardés confidentiels, et ne doivent être
				communiqués à un tiers collaborateur de l’Etablissement ou personne extérieure.
			</p>
			<p>
				Le portail « BIG 2 » est connecté à l’annuaire d’entreprise de l’Etablissement. Les identifiants de
				connexion personnels du collaborateur EPFIF sont ceux de sa session Windows courante. Les
				services informatiques n’ont pas connaissance de son mot de passe personnel.
			</p>
			<p>
				Pour des raisons de sécurité, une déconnexion automatique est mise en place, après une période
				d’inactivité de 15 minutes.
			</p>
			<p>
				Un compte utilisateur temporaire peut, de manière exceptionnelle, être créé pour un collaborateur ou
				un prestataire par Copro+, sous validation des chefs de projet EPFIF. Le mot de passe de ce compte,
				à modifier régulièrement selon les contraintes de sécurité en vigueur, est personnel et peut être
				modifié par l’utilisateur à tout instant depuis son profil.<br />
				Ces comptes temporaires ne peuvent être créés que dans les cas suivants :
				<ul>
					<li>
						Pour les collaborateurs ayant l’usage du « BIG 2 » pour une mission spécifique, différente de
						leur mission courante.
					</li>
					<li>
						Pour les prestataires ayant l’usage du « BIG 2 » pour une mission spécifique, différente de
						leur mission courante.
					</li>
				</ul>
			</p>

			<p>
				<strong>Article 3 : Contenus et données disponibles sur « BIG 2 »</strong>
			</p>
			<p>
				Le portail web « BIG 2 » structure et consolide un large spectre de données, afin d’apporter aux
				collaborateurs et prestataires la fine connaissance des copropriétés et copropriétaires de Grigny 2,
				nécessaire à l’exercice de leurs missions.
			</p>
			<p>
				Ces données proviennent de différentes origines : EPFIF, données comptables des syndics de
				copropriété, prestataires, services de l’Etat, ville de Grigny, fournisseurs, etc.<br />
				Leur actualisation est assurée par COPRO+, laquelle fluctue en fonction de la politique de mise à jour
				des producteurs et du mode de diffusion de ces données.
			</p>
			<p>
				Au regard des fonctionnalités de l’outil, les données consultables sont restreintes en fonction du
				périmètre d’intervention des collaborateurs et prestataires (cf. tableau récapitulatif annexé)
			</p>
			<p>
				Par ailleurs, les utilisateurs ayant des questions quant au contenu, à la disponibilité ou à l’actualité
				d’une donnée, sont invités à les adresser à COPRO+ avec les chefs de projet EPFIF référents en
				copie.
			</p>
			<p>
				<strong>Article 4 : Fonctionnalités du « BIG 2 »</strong>
			</p>
			<p>
				Le « BIG 2 » se présente comme une plateforme proposant plusieurs fonctionnalités, parmi lesquelles
				on distingue
				<ul>
					<li>
						Les fonctionnalités accessibles à l’ensemble des collaborateurs (exemples : annuaire,
						observatoire habitat…)
					</li>
					<li>
						Les fonctionnalités accessibles à un groupe de collaborateurs (exemple : fiches de
						consultation, formulaire de saisie)
					</li>
				</ul>
			</p>
			<p>
				La disponibilité des fonctionnalités présentes dans ces applications et modules métiers varie selon les
				droits attribués à l’utilisateur, en particulier les fonctionnalités ayant trait à la consultation et/ou
				l’exploitation des données personnelles.
			</p>
			<p>
				Les droits d’accès aux applications du « BIG 2 » sont gérés par COPRO+, sous validation des chefs
				de projet EPFIF, selon les missions des collaborateurs et prestataires et conformément à la note
				définissant les modalités d’accès.
			</p>
			<p>
				Les utilisateurs s’interrogeant sur une fonctionnalité non accessible depuis leur profil sont invités à
				contacter les chefs de projet EPFIF référents.
			</p>
			<p>
				<strong>Article 5 : Production de documents à partir du « BIG 2 »</strong>
			</p>
			<p>
				Les contenus diffusés sur le portail « BIG 2 » ont vocation à apporter aux collaborateurs et aux
				prestataires de l’Etablissement une aide à la décision. Les tableaux ou graphiques produits à partir de
				« BIG 2 » peuvent ainsi illustrer leurs documents de travail.
			</p>
			<p>
				En cas de réutilisation, les collaborateurs et prestataires s’engagent à faire figurer sur les documents
				produits les sources des données utilisées.
			</p>
			<p>
				<strong>Article 6 : Transmission de données extraites du « BIG 2 »</strong>
			</p>
			<p>
				Les utilisateurs du « BIG 2 » ont la possibilité d’extraire, de manière autonome et pour leur usage, des
				données sous différentes formes : rapports, tableurs ou graphiques.
			</p>
			<p>
				Toute transmission de données personnelles ne peut être effectuée qu’en respect du RGPD. Dès lors,
				les utilisateurs intégreront à tout envoi la clause suivante, relative à la transmission de données
				personnelles à des tierces personnes :
			</p>
			<p>
				« Toute donnée personnelle transmise par l’EPFIF ou recueillie grâce au
				portail BIG 2 doit être traitée par son destinataire conformément au RGPD, ce qui implique notamment
				l’absence de transmission à des tiers étrangers à la finalité poursuivie, une sécurisation de son accès
				et sa suppression une fois la finalité remplie »
			</p>
			<p>
				Article 7 : Obligations liées aux données personnelles
			</p>
			<p>
				Les utilisateurs du « BIG 2 » utilisant les fonctionnalités de consultation de fiches (rapports et tableurs)
				comportant des données personnelles (propriétaires physiques) s’engagent à n’extraire, traiter et
				conserver ces éléments que pour le seul exercice de leur mission (cf. §3.2).
			</p>
			<p>
				Les informations personnelles contenues dans ces extraits ne doivent être conservées que si elles
				sont utiles au traitement réalisé, et durant la période nécessaire pour réaliser la finalité poursuivie.
				Lorsque leur conservation n’est plus légitime, elles doivent être supprimées par l’auteur de l’extrait.
			</p>
			<p>
				Afin de sécuriser la transmission de ces extraits, en interne comme en externe, les collaborateurs
				doivent éviter les envois par messagerie électronique (pièces jointes) ou outil collaboratif (Microsoft
				Teams). Ces consignes s’appliquent également aux prestataires de l’EPFIF.<br/>
				Seront privilégiés les liens hypertextes en interne (emplacement du document sur le réseau de
				l’EPFIF), et l’outil de transfert sécurisé FileSender en externe.
				Il est conseillé de chiffrer les documents.
			</p>
			<p>
				Enfin, tout témoin de la violation d’un fichier contenant des données personnelles doit en informer
				immédiatement la Déléguée à la Protection des Données (dpd@epfif.fr).
			</p>
			<p>
				Article 8 : Suspension et/ou suppression des comptes utilisateurs
			</p>
			<p>
				COPRO+ et/ou les chefs de projet référents procèdent à la suspension du compte utilisateur d’un
				collaborateur :
				<ul>
					<li>
						En cas de départ temporaire (congé sabbatique) ou définitif de l’établissement : cette
						suspension prend effet dans la semaine suivant la date de départ communiquée par les
						Ressources Humaines.
					</li>
					<li>
						En cas de suspicion de piratage : cette suspension intervient dès prise de connaissance du
						Service Informatique de cet évènement ;
					</li>
					<li>
						Dans le cas d’un compte utilisateur temporaire, en cas de demande du collaborateur lui-même
						(fin d’usage de cet accès ou perte de mot de passe) : cette suspension prend effet dès
						demande du collaborateur concerné.
					</li>
				</ul>
			</p>
			<p>
				<strong>Annexe : tableau des habilitations</strong>
			</p>
			<p>
				<table className="table table-bordered">
					<thead>               
					<tr>
						<th></th>
						<th colSpan={3}>Consultation</th>
						<th colSpan={3}>Saisie (formulaires)</th>
						<th rowSpan={2}>Observatoire habitat</th>
						<th rowSpan={2}>
							Pour plus
							d&#39;accès :
							Motivation
						</th>
					</tr>
					<tr>
						<th></th>
						<th>Fiche syndicat</th>
						<th>Fiche copro-priétaire</th>
						<th>Fiche logement</th>
						<th>Recouvre-ment</th>
						<th>AS</th>
						<th>Acquisitions</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td colSpan={9} style={{ background: '#DDD' }}>Pôle ORCOD-IN</td>
					</tr>
					<tr>
						<td>DGA en charge du pôle ORCOD-IN</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>X</td>
						<td>X</td>
					</tr>
					<tr>
						<td>Directeur adjoint</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>X</td>
						<td>X</td>
					</tr>
					<tr>
						<td>Centre de ressources</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>X</td>
						<td>X</td>
					</tr>
					<tr>
						<td>Direction de projet (OG)</td>
						<td>X</td>
						<td></td>
						<td>X</td>
						<td></td>
						<td></td>
						<td></td>
						<td>X</td>
						<td>X</td>
					</tr>
					<tr>
						<td>Chef.fe de projet habitat privé (OG)</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td></td>
						<td>X</td>
						<td>X</td>
					</tr>
					<tr>
						<td>Chef.fe de projet foncier (OG)</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td></td>
						<td></td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
					</tr>
					<tr>
						<td>Chef.fe de projet gestion/relogement (OG)</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td></td>
						<td></td>
						<td></td>
						<td>X</td>
						<td>X</td>
					</tr>
					<tr>
						<td>Chef.fe de projet projet urbain (OG)</td>
						<td>X</td>
						<td></td>
						<td>X</td>
						<td></td>
						<td></td>
						<td></td>
						<td>X</td>
						<td>X</td>
					</tr>
					<tr>
						<td>Assistant.e (OG)</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td></td>
						<td></td>
						<td></td>
						<td>X</td>
						<td>X</td>
					</tr>
					<tr>
						<td>Autres collaborateurs EPFIF</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>X</td>
					</tr>
					<tr>
						<td colSpan={9} style={{ background: '#DDD' }}>Prestataires</td>
					</tr>
					<tr>
						<td colSpan={9} style={{ background: '#EAEAEA' }}>Opérateurs PDS</td>
					</tr>
					<tr>
						<td>Direction de projet</td>
						<td>X</td>
						<td></td>
						<td>X</td>
						<td></td>
						<td></td>
						<td></td>
						<td>X</td>
						<td>X</td>
					</tr>
					<tr>
						<td>Chargée de mission</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td></td>
						<td></td>
						<td>X</td>
						<td>X</td>
					</tr>
					<tr>
						<td>Travailleur social</td>
						<td>X</td>
						<td>X</td>
						<td>X</td>
						<td></td>
						<td>X</td>
						<td></td>
						<td>X</td>
						<td>X</td>
					</tr>
					</tbody>
				</table>
			</p>
		</>
	);
}
