import { Tooltip } from '@mui/material';
import { ReactNode } from 'react';

export interface tooltipProps {
	message: ReactNode;
}

export default function TooltipElm(
	{
		message
	}: tooltipProps
) {

	return message ? (
		<Tooltip
				title={(
					<div>
						{message}
					</div>
				)}
			>
			<span>{message}</span>
		</Tooltip>
	) : <></>;
}
