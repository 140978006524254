import { useEffect, useRef } from 'react';

export function useEffectTimeout(callback: () => any = () => {}, timeout: number = 500, deps: any[] = []) {
	
	const to = useRef<any>(null);
	// Mise à jour de la référence si le callback ou les dépendances changent
	useEffect(() => {
		clearTimeout(to.current);
		to.current = setTimeout(() => {
			callback();
		}, timeout);
	}, [ timeout, ...deps ]); // eslint-disable-line react-hooks-static-deps/exhaustive-deps
}
