import { FieldAroundProps, FieldFormProps, useFieldAround, useFieldForm } from '@/components/inputs/generic';
import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input';

export interface PhoneFieldSpecific {
	readOnly?: boolean;
	inputProps?: Partial<MuiTelInputProps['InputProps']>;
	simpleValue?: boolean;
}

export type PhoneFieldProps = PhoneFieldSpecific & FieldFormProps & FieldAroundProps & Omit<MuiTelInputProps, 'onChange'|'InputProps'|'inputProps'>;

export default function PhoneField(
	{
		rules = [],
		clearable = false,
		appendIcon = null,
		prependIcon = null,
		appendIconProps = {},
		prependIconProps = {},
		appendSlot = null,
		prependSlot = null,
		value,
		onChange,
		ref,
		className = '',
		onBlur = () => {},
		inputProps = {},
		error = false,
		helperText = null,
		readOnly = false,
		disabled = false,
		simpleValue = false,
		...rest
	}: PhoneFieldProps
) {

	if (simpleValue && value?.indexOf('0') === 0) {
		value = '+33' + value?.substring(1);
	}

	const fieldForm = useFieldForm({
		className: `c-inputs-PhoneField ${className}`,
		rules,
		ref,
		value,
		onChange,
		defaultValue: '',
		error,
		helperText,
	});
	

	const fielAround = useFieldAround({
		clearable,
		appendIcon,
		prependIcon,
		appendIconProps,
		prependIconProps,
		appendSlot,
		prependSlot,
		markClear: fieldForm.markClear,
	});
	
	const handleBlur = (e: any) => {
		onBlur(e);
		fieldForm.markTouched();
	};

	const {
		startAdornment,
		...fielAroundFinal
	} = fielAround;
	
	return (
		<MuiTelInput
			defaultCountry={'FR'}
			preferredCountries={['FR']}
			onlyCountries={simpleValue ? ['FR'] : undefined}
			{...rest}
			{...fieldForm.props}
			InputProps={{
				readOnly,
				...fielAroundFinal,
				...inputProps
			}}
			onChange={(v) => {
				if (simpleValue) {
					if(v.indexOf('+33') === 0) {
						v = '0' + v?.substring(3).trim();
					}
				} else {
					v = v?.replace(/[\- \.]/g, '');
				}
				fieldForm.onChange(v);
			}}
			className={fieldForm.className}
			ref={fieldForm.ref}
			onBlur={handleBlur}
			error={fieldForm.error}
			helperText={fieldForm.helperText}
			disabled={disabled}
		/>
	);
}
