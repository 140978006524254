import { number as toNumber } from '@/libs/candy/number';
import { prevent as toPrevent } from '@/libs/candy/prevent';
import { stop as toStop } from '@/libs/candy/stop';
import { immediate as toImmediate } from '@/libs/candy/immediate';

export type ModelOnchange<T extends any = any> = (value: T, ...args: any[]) => any;

export function model<T extends any = any>(value: T|undefined, onChange: ModelOnchange<T>|undefined): { value: any, onChange: any } {
	return {
		value,
		onChange
	};
}
const number = function model<T extends any = any>(value: T|undefined, onChange: ModelOnchange<T>|undefined): { value: any, onChange: any } {
	return {
		value,
		onChange: toNumber(onChange)
	};
}
const prevent = function model<T extends any = any>(value: T|undefined, onChange: ModelOnchange<T>|undefined): { value: any, onChange: any } {
	return {
		value,
		onChange: toPrevent(onChange)
	};
}
const immediate = function model<T extends any = any>(value: T|undefined, onChange: ModelOnchange<T>|undefined): { value: any, onChange: any } {
	return {
		value,
		onChange: toImmediate(onChange as any)
	};
}
const stop = function model<T extends any = any>(value: T|undefined, onChange: ModelOnchange<T>|undefined): { value: any, onChange: any } {
	return {
		value,
		onChange: toStop(onChange as any)
	};
}

model.number = number;
model.stop = stop;
model.immediate = immediate;
model.prevent = prevent;


number.prevent = prevent;
number.stop = stop;
number.immediate = immediate;

prevent.number = number;
prevent.stop = stop;
prevent.immediate = immediate;

stop.number = number;
stop.prevent = prevent;
stop.immediate = immediate;

immediate.number = number;
immediate.stop = stop;
immediate.prevent = prevent;
