import { findIframes } from '@/stores/iframe';
import { Iframe, ResultType } from '@/models';
import { useSelector } from '@/libs/redux';
import MainLoader from '@/components/MainLoader/MainLoader';

export default function PageIframe() {

	const metadata = useSelector(state => state.page.metadata);
	const iframe: Iframe = metadata.iframe;

	return iframe ? (
		<div className="p-Iframe page-iframe">
			<iframe
				title={iframe.title}
				src={iframe.url}
				frameBorder={0}
				allowFullScreen
			></iframe>
		</div>
	) : <MainLoader/>;
}

PageIframe.onFirstRender = async ({ store }: any) => {

	const dispatch: DispatchApp = store.dispatch;
	let iframe: Nullable<Iframe> = null;
	try {
		const iframes: ResultType<Iframe> = await dispatch(findIframes())
		iframe = iframes?.data?.find(iframe => iframe.page === window.location.pathname) || null;
	} catch (e) {
		console.error(e);
	}

	return {
		iframe,
		rights: iframe?.rights || true,
		title: iframe?.title || ''
	}
}
