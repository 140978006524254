import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { ColorScheme, ResultType } from '@/models';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { Direction } from '@/models/Direction';
import { UriHelper } from '@/libs/utils';

export class ColorSchemeState {
    public colorSchemes: Nullable<ResultType<ColorScheme[]>> = null;
}

export default {
    state: ColorSchemeState,
    reducer: mapReducer([
        'colorSchemes',
    ]),
} as SubStore;

// MUTATIONS

export const setColorSchemes = (colorSchemes: Nullable<ResultType<ColorScheme[]>>) => (dispatch: DispatchApp) => {
    dispatch({ state: ColorSchemeState, type: MapReducerAction.MAP, colorSchemes });
};

// ACTIONS

export const findAllColorSchemes = () => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<ColorScheme[]>> => {
    const page: number = 0;  // Ou 1, selon ce qui est approprié
    const order: Nullable<string> = 'id';
    const direction: Direction = Direction.ASC;
    const limit: number = 200;

    const colorSchemes = await caller.get<ResultType<ColorScheme[]>>(`/api/color-schemes${UriHelper.queries({
        page,
        order,
        direction,
        limit,
    })}`);

    await dispatch(setColorSchemes(colorSchemes));

    return colorSchemes;
});
