import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { RecouvrementRecord, UserSyncicat } from '@/models';
import { loadingCall } from '@/libs/stores/candy/loadingCall';

export class RecouvrementRecordState {
	public recouvrementRecord: Nullable<RecouvrementRecord> = null;
}

export default {
	state: RecouvrementRecordState,
	reducer: mapReducer([
		'recouvrementRecord',
	]),
} as SubStore;

// MUTATIONS

export const setRecouvrementRecord = (recouvrementRecord: Nullable<RecouvrementRecord>) => (dispatch: DispatchApp) => {
	dispatch({ state: RecouvrementRecordState, type: MapReducerAction.MAP, recouvrementRecord });
};
// ACTIONS

export const findRecouvrementRecord = (
	syndicat: UserSyncicat,
	proprietaire: string,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<RecouvrementRecord> => {

	dispatch(setRecouvrementRecord(null));
	const recouvrementRecord = await caller.get<RecouvrementRecord>(`/api/recouvrement-records/${encodeURIComponent(proprietaire)}/${encodeURIComponent(syndicat)}`);
	dispatch(setRecouvrementRecord(recouvrementRecord));

	return recouvrementRecord;
});

export const putRecouvrementRecordById = (
	id: number,
	record: Partial<RecouvrementRecord>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<RecouvrementRecord> => {
	
	const recouvrementRecord = await caller.put<RecouvrementRecord>(`/api/recouvrement-records/${id}`, record);
	dispatch(setRecouvrementRecord(recouvrementRecord));

	return recouvrementRecord;
});
