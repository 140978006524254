import { buildModelHelper } from '@/libs/modelHelper';

export interface ChargesMenage {
	id: number;
	mensualiteEmpruntImmo: Nullable<number>;
	detteEmpruntImmo: Nullable<number>;
	mensualiteTaxeFonciere: Nullable<number>;
	detteTaxeFonciere: Nullable<number>;
	mensualiteTransport: Nullable<number>;
	mensualiteEnergie: Nullable<number>;
	detteEnergie: Nullable<number>;
	mesualiteAssuranceHabitation: Nullable<number>;
	detteAssuranceHabitation: Nullable<number>;
	mensualiteImpotRevenu: Nullable<number>;
	detteImpotRevenu: Nullable<number>;
	mensualiteMutuelle: Nullable<number>;
	detteMutuelle: Nullable<number>;
	mensualiteAutresCredits: Nullable<number>;
	detteAutresCredits: Nullable<number>;
	mensualiteAutresAssurances: Nullable<number>;
	detteAutresAssurances: Nullable<number>;
	mensualiteTelecom: Nullable<number>;
	detteTelecom: Nullable<number>;
	detteTransport: Nullable<number>;
	mensualiteEnfants: Nullable<number>;
	detteEnfants: Nullable<number>;
	mensualiteAutres: Nullable<number>;
	detteAutres: Nullable<number>;
	nomBanque: Nullable<string>;
}

export const hChargesMenage = buildModelHelper<ChargesMenage>({
});
export type HChargesMenage = ReturnType<typeof hChargesMenage>;
