import { useSelector } from '@/libs/redux';
import { LinearProgress } from '@mui/material';
import './LoadingBar.scss';

export default function LoadingBar() {

	const loading = useSelector(state => state.loader.loading);

	return (
		<LinearProgress className={[
			'c-LoadingBar',
			loading ? 'c-LoadingBar--loading' : '',
		 ].join(' ')} />
	);
}
