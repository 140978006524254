import Select, { SelectProps } from '@/components/inputs/Select/Select';
import { UserSyncicat } from '@/models';
import { useTranslation } from 'react-i18next';
import { AutoCompleteProps } from '@/components/inputs/AutoComplete/AutoComplete';
import { useEffectOnChange, usePageable, useSnackMessage } from '@/libs/hooks';
import { useDispatch, useSelector } from '@/libs/redux';
import { useCallback } from 'react';
import { findUsers } from '@/stores/user';

export interface CrudUserSyncicatSelectProp extends Omit<SelectProps, 'items'|'itemText'|'itemValue'> {
	filtered?: boolean;
	customFilter?: (item: UserSyncicat) => boolean;
}

export default function CrudUserSyncicatSelect(
	{
		filtered = false,
		customFilter = () => true,
		...props
	}: CrudUserSyncicatSelectProp
) {
	const { t } = useTranslation();
	const me = useSelector(state => state.user.me);
	
	const items = Object.values(UserSyncicat)
		.filter(value => !filtered || (me?.syndicats && me.syndicats.indexOf(value) !== -1))
		.filter(customFilter)
		.map(value => ({
			text: t(`models.user_syndicat.values.${value}`),
			value,
		}))
	;
	
	return (
		<Select
			items={items}
			{...props}
		/>
	);
}
