import { Native } from '@/libs/utils';
import { FormRule } from '@/libs/form';
import { Validators } from '@/libs/validators/useValidators';

export default function onlyLetters(
	this: Validators,
	{
		message
	}: {
		message?: string
	} = {}): FormRule {
	return (v: any) => (/^[a-zA-ZÀ-ÿ\- ]*$/gm.test(v) || Native.empty(v)) || message || this.t('validators.only_letters');
}
