import { FileSlot, FileSlotPeriodicity } from '@/models';
import { Button, Card, CardActions, CardContent, CardHeader, Chip, Typography } from '@mui/material';
import TextField from '@/components/inputs/TextField/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { useForm } from '@/libs/form';
import Form from '@/components/forms/Form/Form';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from '@/libs/redux';
import { useValidators } from '@/libs/validators';
import { postFileSlot, putFileSlotById } from '@/stores/fileSlot';
import './Edit.scss';
import { useEffect, useState } from 'react';
import { model } from '@/libs/candy';
import CrudDocumentTypeSelect from '@/components/crud/documentType/Select/Select';
import CrudFileSlotPeriodicitySelect from '@/components/crud/fileSlotPeriodicity/Select/Select';
import CrudUserSyncicatSelect from '@/components/crud/userSyncicat/Select/Select';
import CrudUserSelect from '@/components/crud/users/Select/Select';

export interface CrudFileSlotEditProps {
	value?: Nullable<FileSlot>;
	onClose: () => any,
	onUpdate: () => any,
}

const calcMaxDay = (month: Nullable<number>): number => {
	let maxDay = 31;
	if ([4,6,9,11].indexOf(month as number) !== -1) {
		maxDay = 30;
	}
	if (month === 2) {
		maxDay = 28;
	}
	return maxDay;
};

export default function CrudFileSlotEdit(
	{
		value = null,
		onClose,
		onUpdate,
	}: CrudFileSlotEditProps
) {

	const dispatch = useDispatch();
	const validators = useValidators();

	const [ syndicat, setSyndicat ] = useState(value?.syndicat || null);
	const [ documentType, setDocumentType ] = useState(value?.documentType || null);
	const [ periodicity, setPeriodicity ] = useState(value?.periodicity || null);
	const [ relanceUser, setRelanceUser ] = useState(value?.relanceUser || null);
	const [ day, setDay ] = useState(value?.day || null);
	const [ month, setMonth ] = useState(value?.month || null);
	const [ maxDay, setMaxDay ] = useState(calcMaxDay(month));
	const [ tags, setTags				] = useState<string[]>(value?.tags || []);
	const [ currentTag, setCurrentTag	] = useState<string>('');
	const addTag = () => {
		if (currentTag.trim() !== '') {
		  setTags([...tags, currentTag]);
		  setCurrentTag('');
		}
	};
	const removeTag = (index: number) => {
		const newTags = [...tags];
		newTags.splice(index, 1);
		setTags(newTags);
	  };

	useEffect(() => {
		setMaxDay(calcMaxDay(month));
	}, [ month ]);

	useEffect(() => {
		if (periodicity === FileSlotPeriodicity.QUARTERLY) {
			setMonth(null);
			setDay(null);
		}
	}, [ periodicity ]);
	
	const form = useForm({
		handle: async () => {
			await form.call(async () => {

				const fileSlot: any = {
					syndicat,
					documentType,
					periodicity,
					day,
					month,
					relanceUser,
					tags,
				};

				if (value) {
					await dispatch(putFileSlotById(value.id, fileSlot));
				} else {
					await dispatch(postFileSlot(fileSlot));
				}
				onUpdate();
				onClose();
			});
		},
		fields: {
			syndicat: {
				label: 'Syndicat',
				...model(syndicat, setSyndicat),
				rules: [
					validators.required(),
				],
				disabled: !!value?.used
			},
			documentType: {
				label: 'Document',
				...model(documentType, setDocumentType),
				rules: [
					validators.required(),
				],
				disabled: !!value?.used
			},
			periodicity: {
				label: 'Périodicité',
				...model(periodicity, setPeriodicity),
				rules: [
					validators.required(),
				],
				disabled: !!value?.used
			},
			relanceUser: {
				label: 'Relance User',
				...model(relanceUser, setRelanceUser),
				clearable: true,
			},
			day: {
				label: 'Jour',
				...model.number(day, setDay),
				rules: [
					validators.required(),
					validators.range({ min: 1, max: maxDay }),
				]
			},
			month: {
				label: 'Mois',
				...model.number(month, setMonth),
				rules: [
					validators.required(),
					validators.range({ min: 1, max: 12 }),
				]
			},
			// tags: {
			// 	label: 'Tags',
			// 	...model(tags, setTags)
			// },
		}
	});

	return (
		<div className="c-Crud-FileSlot-Edit" >
			<Form form={form}>
				<Card>
					<CardHeader
						title={value ? (
							<>Édition de l'emplacement {value.id}</>
						) : (
							<>Création d'un emplacement de document</>
						)}
					/>
					<CardContent
						style={{
							maxHeight: 'calc(100vh - 200px)',
							overflow: 'auto',
						}}
					>
						<FormMainErrors form={form} />
	
						<Grid container spacing={2}>
							<Grid
								xs={12} md={6}
							>
								<CrudUserSyncicatSelect {...form.fields.syndicat} />
							</Grid>
							<Grid
								xs={12} md={6}
							>
								<CrudDocumentTypeSelect {...form.fields.documentType} returnObject />
							</Grid>
							<Grid
								xs={12} md={6}
							>
								<CrudFileSlotPeriodicitySelect {...form.fields.periodicity} />
							</Grid>
							<Grid xs={12} md={6}>
								<CrudUserSelect {...form.fields.relanceUser} syndicats={syndicat} returnObject />
							</Grid>

							{periodicity === FileSlotPeriodicity.ANNUAL && (
								<Grid
									xs={12} md={6}
								>
									<Grid container spacing={2}>
										<Grid xs={6} >
											<TextField
												{...form.fields.day}
												type="number"
												min={1}
												max={maxDay}
											/>
										</Grid>
										<Grid xs={6} >
											<TextField
												{...form.fields.month}
												type="number"
												min={1}
												max={12}
											/>
										</Grid>
									</Grid>
								</Grid>
							)}
						</Grid>
						<Card className="mt-2">
							<CardContent>
								<Typography variant="h6">Tags</Typography>
								<Grid className="mt-1" container spacing={2}>
									{tags.map((tag, index) => (
										<Grid key={index}>
											<Chip label={tag} onDelete={() => removeTag(index)} />
										</Grid>
									))}
								</Grid>
								<Grid xs={12} className="mt-2">
									<TextField
										label="Entrez un nouveau tag"
										placeholder="Appuyez sur la virgule pour ajouter un nouveau tag"
										value={currentTag}
										onChange={(value) => setCurrentTag(value)}
										onKeyDown={(e) => {
										if ((e.key === ','  || e.key === 'Enter') && currentTag.trim() !== '') {
											e.preventDefault();
											addTag();
										}
										}}
									/>
								</Grid>
							</CardContent>
						</Card>
					</CardContent>
					<CardActions
						className="u-d-block"
					>
						<Button
							onClick={onClose}
						>
							Fermer
						</Button>
						<LoadingButton
							style={{ float: 'right' }}
							type="submit"
							loading={form.loading}
							variant="contained"
						>
							Enregistrer
						</LoadingButton>
					</CardActions>
				</Card>
			</Form>
		</div>
	)
}
