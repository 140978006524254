import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { Direction, GraphFiche, ResultType } from '@/models';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class GraphFicheState {
	graphFiches: Nullable<ResultType<GraphFiche>> = null;
}

export default {
	state: GraphFicheState,
	reducer: mapReducer([
		'graphFiches'
	]),
} as SubStore;

// MUTATIONS

export const setGraphFiches = (graphFiches: Nullable<ResultType<GraphFiche>>) => (dispatch: DispatchApp) => {
	dispatch({ state: GraphFicheState, type: MapReducerAction.MAP, graphFiches });
};

// ACTIONS

export const findGraphFiches = (
	page: number,
	order: string,
	direction: Direction,
	limit: number,
	filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<GraphFiche>> => {
	dispatch(setGraphFiches(null));
	const graphFiches = await caller.get<ResultType<GraphFiche>>(`/api/graph-fiches${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);

	dispatch(setGraphFiches(graphFiches));

	return graphFiches;
});
