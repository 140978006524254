import './Acteurs.scss';
import { Container } from '@mui/material';
import { useConnectedPage, useEffectOnChange, usePageable, useSnackMessage } from '@/libs/hooks';
import { useDispatch, useSelector } from '@/libs/redux';
import { useCallback, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { model } from '@/libs/candy';
import { User } from '@/models';
import { findUsers } from '@/stores/user';
import CrudUsersFilters from '@/components/crud/users/FIlters/Filters';
import { PhoneHelper } from '@/libs/utils/PhoneHelper';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import TooltipElm from '@/components/Tooltip/TooltipElm';

export interface PageAnnuaireActeurProps {
	user: User
}

export function PageAnnuaireActeur(
	{
		user,
	}: PageAnnuaireActeurProps
) {
	return (
		<TableRow>
			<TableCell>
				<TooltipElm message={user.lastName}/>
			</TableCell>
			<TableCell>
				<TooltipElm message={user.firstName}/>
			</TableCell>
			<TableCell>
				<TooltipElm message={user.structure}/>
			</TableCell>
			<TableCell>
				{user.email ? <a href={`mailto:${user.email}`}><TooltipElm message={user.email}/></a> : user.email}
			</TableCell>
			<TableCell>
				<a className="u-t-nowrap" href={`tel:${user.phone}`}><TooltipElm message={PhoneHelper.format(user.phone)}/></a>
			</TableCell>
			<TableCell>
				<a className="u-t-nowrap" href={`tel:${user.phone2}`}><TooltipElm message={PhoneHelper.format(user.phone2)}/></a>
			</TableCell>
			<TableCell>
				<TooltipElm message={user.mission}/>
			</TableCell>
			<TableCell>
				<TooltipElm message={user.fonction}/>
			</TableCell>
		</TableRow>
	);
}

export default function PageAnnuaireActeurs() {

	useConnectedPage();

	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const users = useSelector(state => state.user.users);
	const pageable = usePageable();

	const [ filters, setFilters ] = useState({
		structure: '',
		lastName: '',
		firstName: '',
	});

	const refresh = useCallback(() => {
		(async () => {
			try {
				await dispatch(findUsers(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, filters));
			} catch (e) {
				console.error(e);
				pushMessage('Une erreur est survenue.', 'error');
			}
		})();
	}, [ filters, pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage ])

	useEffectOnChange(() => {
		refresh();
	}, [ refresh ]);


	return (
		<Container className="p-annuaire-acteurs">

			<CrudUsersFilters
				{...model(filters, setFilters)}
			/>


			<SortableTable
				heads={[
					{ sort: 'lastName', name: 'Nom' },
					{ sort: 'firstName', name: 'Prénom' },
					{ sort: 'structure', name: 'Structure' },
					{ sort: 'email', name: 'Mail' },
					{ sort: 'phone', name: 'Téléphone 1' },
					{ sort: 'phone2', name: 'Téléphone 2' },
					{ sort: 'mission', name: 'Mission' },
					{ sort: 'fonction', name: 'Fonction' },
				]}
				result={users}
				renderItem={(item) => (
					<PageAnnuaireActeur
						user={item}
					/>
				)}
				pageable={pageable}
			/>
			
		</Container>
	);
}



PageAnnuaireActeurs.getMetadata = () => {
	return {
		rights: [ 'ANNUAIRE_ACTEUR' ], 
		title: 'Annuaire - Acteurs'
	}
}
