import { Native } from '@/libs/utils';
import { FormRule } from '@/libs/form';
import { Validators } from '@/libs/validators/useValidators';

export default function fileSize(
	this: Validators,
	{
		size,
		message
	}: {
		size: number,
		message?: string,
	}): FormRule {
	return (v: any) => {
		if (!Native.empty(v)) {
			v = Array.isArray(v) ? v : [ v ];
			for (const file of v) {
				if (file.size > size) {
					return message || this.t('validators.file_size', {size: (size / 1024 / 1024)});
				}
			}
		}
		return true;
	};
}
