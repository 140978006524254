import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { hToken, RefreshToken, Token } from '@/models';
import { setMe } from '@/stores/user';
import { GetState, Services } from '@/stores/index';

export class TokenState {
	token: Nullable<Token> = null;
}

export default {
	state: TokenState,
	reducer: mapReducer([
		'token',
	], null, (state, value, key) => {
		switch (key) {
			case 'token':
				try {
					if (state.token) {
						// Store in localstorage
						window.localStorage.setItem('token', JSON.stringify(state.token));
					} else {
						window.localStorage.removeItem('token');
					}
				} catch (e) {
					console.error(e);
					state.token = null;
				}
				break;
		}
		return state;
	}),
	onFirstLoad(dispatch) {
		let token = null;
		try {
			token = JSON.parse(window.localStorage.getItem('token') as string);
		} catch (e) {
		}
		dispatch(setToken(token));
	}
} as SubStore;

// MUTATIONS

export const setToken = (token: Nullable<Token>) => (dispatch: DispatchApp) => {
	dispatch({ state: TokenState, type: MapReducerAction.MAP, token });
};

// ACTIONS

export const login = (
	email: string,
	password: string,
) => async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Token> => {

	const token = await caller.post<Token>('/api/tokens/login', {
		email,
		password
	}, {}, { unloggedCall: true });

	dispatch(setToken(token));
	dispatch(setMe(token.user));

	return token;
};


export const logout = () => async (dispatch: DispatchApp): Promise<void> => {
	dispatch(setToken(null));
	dispatch(setMe(null));
};


export const checkToken = () => async (dispatch: DispatchApp, getState: GetState): Promise<Nullable<Token>> => {
	const token = hToken(getState().token.token);
	if (!token) {
		return null;
	}
	if (token.isValid) {
		return token;
	}
	if (!token.refreshToken.isValid) {
		await dispatch(logout());
		return null;
	}
	console.debug('Token invalid:', token);
	return await dispatch(refresh(token.refreshToken));
};


let refreshPromise: Nullable<Promise<Nullable<Token>>> = null;
export const refresh = (refreshToken: RefreshToken) => async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Nullable<Token>> => {
	
	if (refreshPromise) {
		return await refreshPromise;
	}

	refreshPromise = (async () => {
		try {
			const token = await caller.post<Token>('/api/tokens/refresh', {
				id: refreshToken.id
			}, {}, { unloggedCall: true });
			dispatch(setToken(token));
			dispatch(setMe(token.user));
			refreshPromise = null;
			return token;
		} catch (e) {
			console.error(e);
			await dispatch(logout());
			refreshPromise = null;
			return null;
		}
	})();

	return await refreshPromise;
}
