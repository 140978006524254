import { Provider } from 'react-redux';
import { Store } from '@reduxjs/toolkit';

export function wrapperStore (App: any, store: Store): any {
	return (props: any) => (
		<Provider store={store}>
			<App {...props} />
		</Provider>
	);
}
