import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { TypeProprietaire, ResultType } from '@/models';
import { Direction } from '@/models/Direction';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class TypeProprietaireState {
	public typeProprietaires: Nullable<ResultType<TypeProprietaire>> = null;
	public all: Nullable<TypeProprietaire[]> = null;
}

export default {
	state: TypeProprietaireState,
	reducer: mapReducer([
		'typeProprietaires',
		'all',
	]),
} as SubStore;

// MUTATIONS

export const setTypeProprietaires = (typeProprietaires: Nullable<ResultType<TypeProprietaire>>) => (dispatch: DispatchApp) => {
	dispatch({ state: TypeProprietaireState, type: MapReducerAction.MAP, typeProprietaires });
};
export const setAll = (all: Nullable<TypeProprietaire[]>) => (dispatch: DispatchApp) => {
	dispatch({ state: TypeProprietaireState, type: MapReducerAction.MAP, all });
};

// ACTIONS

export const findTypeProprietaires = (
	page: number,
	order: string,
	direction: Direction,
	limit: number,
	filters: any = {}
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<TypeProprietaire>> => {
	const typeProprietaires = await caller.get<ResultType<TypeProprietaire>>(`/api/type-proprietaires${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);
	
	dispatch(setTypeProprietaires(typeProprietaires));
	
	return typeProprietaires;
});

let allPromise: Nullable<Promise<TypeProprietaire[]>> = null;
export const findTypeProprietaireAll = () => loadingCall(async (dispatch: DispatchApp): Promise<TypeProprietaire[]> => {
	if (allPromise) {
		return await allPromise;
	}
	allPromise = dispatch(findTypeProprietaires(0, '', Direction.ASC, 200)).then((result: ResultType<TypeProprietaire>) => result.data);
	const all = (await allPromise) as TypeProprietaire[];
	dispatch(setAll(all));

	return all;
});
