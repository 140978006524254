import './Logements.scss';
import { Fragment, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from '@/libs/redux';
import { useEffectOnChange, usePageable, useSnackMessage } from '@/libs/hooks';
import { AnnuaireLogement } from '@/models';
import { findAnnuaireLogements } from '@/stores/annuaireLogement';
import { Container, Tooltip } from '@mui/material';
import { model } from '@/libs/candy';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CrudAnnuaireLogementFilters from '@/components/crud/annuaireLogement/Filters/Filters';
import TooltipElm from '@/components/Tooltip/TooltipElm';

export interface PageAnnuairePersonnesItemProps {
	item: AnnuaireLogement
}

export function PageAnnuairePersonnesItem(
	{
		item,
	}: PageAnnuairePersonnesItemProps
) {
	return (
		<TableRow>
			<TableCell>
				<TooltipElm message={item.syndicat} />
			</TableCell>
			<TableCell>
				<TooltipElm message={item.numero} />
			</TableCell>
			<TableCell>
				<TooltipElm message={item.batiment} />
			</TableCell>
			<TableCell>
				<TooltipElm message={item.adresse} />
			</TableCell>
			<TableCell>
				<TooltipElm message={item.escalier} />
			</TableCell>
			<TableCell>
				<TooltipElm message={item.etage} />
			</TableCell>
			<TableCell>
				<TooltipElm message={item.position} />
			</TableCell>
			<TableCell>
				<TooltipElm message={item.type} />
			</TableCell>
			<TableCell>
				{item.surface}
			</TableCell>
			<TableCell>
				{item.tantiemes}
			</TableCell>
			<TableCell>
				<Tooltip
					title={(
						<div>
							<div>{item.proprietaire.id} - {item.proprietaire.nom}</div>
							<div>{item.proprietaire.type}</div>
							<div>{item.proprietaire.adresse?.map((line, i) => (<Fragment key={i}>{line}<br /></Fragment>))}</div>
							<div>{item.proprietaire.email}</div>
							<div>{item.proprietaire.telephone}</div>
						</div>
					)}
				>
					<span>{item.proprietaireName}</span>
				</Tooltip>
			</TableCell>
		</TableRow>
	);
}

export default function PageAnnuairePersonnes() {

	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const annuaireLogements = useSelector(state => state.annuaireLogement.annuaireLogements);
	const pageable = usePageable();

	const [filters, setFilters] = useState({
		numero: '',
		batiment: '',
		proprietaireName: '',
		syndicat: [],
	});

	const refresh = useCallback(() => {
		(async () => {
			try {
				await dispatch(findAnnuaireLogements(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, filters));
			} catch (e) {
				console.error(e);
				pushMessage('Une erreur est survenue.', 'error');
			}
		})();
	}, [filters, pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage])

	useEffectOnChange(() => {
		refresh();
	}, [refresh]);

	useEffect(() => {
		// Rafraîchit la page une seule fois lorsque le composant est monté
		const hasRefreshed = sessionStorage.getItem('hasRefreshed');
		if (!hasRefreshed) {
			sessionStorage.setItem('hasRefreshed', 'true');
			window.location.reload();
		}
	}, []);

	return (
		<Container className="p-annuaire-Logements">

			<CrudAnnuaireLogementFilters
				{...model(filters, setFilters)}
			/>

			<SortableTable
				heads={[
					{ name: 'Syndicat', sort: 'syndicat' },
					{ name: 'Numéro', sort: 'numero' },
					{ name: 'Batiment', sort: 'batiment' },
					{ name: 'Adresse', sort: 'adresse' },
					{ name: 'Escalier', sort: 'escalier' },
					{ name: 'Étage', sort: 'etage' },
					{ name: 'Position', sort: 'position' },
					{ name: 'Type', sort: 'type' },
					{ name: 'Surface', sort: 'surface' },
					{ name: 'Tantiemes', sort: 'tantiemes' },
					{ name: 'Proprietaire', sort: 'proprietaireName' },
				]}
				result={annuaireLogements}
				renderItem={(item) => (
					<PageAnnuairePersonnesItem
						item={item}
					/>
				)}
				pageable={pageable}
			/>
		</Container>
	);
}

PageAnnuairePersonnes.getMetadata = () => {
	return {
		rights: ['ANNUAIRE_LOGEMENT'],
		title: 'Annuaire - Logements'
	}
}
