import { buildModelHelper } from '@/libs/modelHelper';
import { Token } from '@/models/Token';

export interface Media {
	id: number;
	fileName: string;
	size: number;
	type: string;
	public: boolean;
	hrefs: { [ name: string ]: string };
	mainContainer: any;
}

export const hMedia = buildModelHelper<Media>({
	getHref(
		{
			version = 'original',
			token = null
		}: {
			version?: string,
			token?: Nullable<Token>,
		} = {}
	): string {
		const url = this.hrefs[version] || this.hrefs['original'];
		const sep = url.indexOf('?') === -1 ? '?' : '&';
		return url + (token ? `${sep}_token=${token.id}` : '');
	}
});

export type HMedia = ReturnType<typeof hMedia>;
