import { AnyAction } from 'redux';

export enum MapReducerAction {
	MAP = 'MAP_REDUCER_MAP',
	CLEAR = 'MAP_REDUCER_CLEAR',
}

export function mapReducer(
	keys: string [],
	othersAction: Nullable<(state?: any, action?: any) => any> = null,
	onMatch: Nullable<(state?: any, value?: any, key?: string) => any> = null,
): (
	state: any,
	action: AnyAction,
) => any {
	return (state: any, action: AnyAction): any => {
		for (const key of keys) {
			if (action.type === MapReducerAction.CLEAR) {
				return new action.state();
			} else if (action.type === MapReducerAction.MAP && typeof action[key] !== 'undefined') {
				state = {...state, [key]: action[key]};
				if (onMatch) {
					state = onMatch(state, action[key], key)
				}
				return state;
			}
		}
		if (othersAction) {
			return othersAction(state, action);
		}
		console.error(new Error(`No reducer found: ${action.type} ${action.state.name}`));
		return state;
	};
}
