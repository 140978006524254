import { createRef, FormHTMLAttributes, useEffect } from 'react';
import { Form as FormValue } from '@/libs/form';
import { prevent } from '@/libs/candy';
import './Form.scss';

export interface FormProps extends FormHTMLAttributes<HTMLFormElement>{
	/**
	 * Le formulaire
	 */
	form: Pick<FormValue, 'rootElement'|'handle'>,
}

export default function Form(
	{
		children,
		noValidate = true,
		className = '',
		form,
		...args
	}: FormProps
) {
	const root = createRef<HTMLFormElement>();
	useEffect(() => {
		form.rootElement = root.current;
	}, [root, form])

	return (
		<form
			ref={root}
			className={`c-forms-Form ${className}`}
			noValidate={noValidate}
			{...args}
			onSubmit={prevent(form.handle.bind(form))}
		>
			{children}
		</form>
	)
}
