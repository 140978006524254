import { forwardRef } from 'react';
import {
	Link as RouterLink,
	LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LinkProps } from '@mui/material/Link';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/fr';
import { lighten, darken } from 'polished';

const LinkBehavior = forwardRef<
	HTMLAnchorElement,
	Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
	const { href, ...other } = props;
	// Map href (MUI) -> to (react-router)
	return <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />;
});

const mainColor = '#00A19A';

const theme = createTheme({
	palette: {
		primary: {
			main: mainColor,
			light: lighten(0.2, mainColor),
			dark: darken(0.2, mainColor),
			contrastText: '#ffffff'
		}
	},
	components: {
		MuiLink: {
			defaultProps: {
				component: LinkBehavior,
			} as LinkProps,
		},
		MuiButtonBase: {
			defaultProps: {
				LinkComponent: LinkBehavior,
			},
		},
		MuiCardHeader: {
			styleOverrides: {
				// Appliquez les styles que vous voulez surcharger
				root: {
					backgroundColor: mainColor,
					color: '#fff', // Assurez-vous que cette couleur offre un bon contraste
				},
			},
		},
	},
});



export function wrapperMui (App: any): any {
	return (props: any) => (
		<ThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
				<App {...props} />
			</LocalizationProvider>
		</ThemeProvider>
	);
}
