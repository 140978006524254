import { useLocation } from "react-router-dom";
import { useEffect } from 'react';

declare const _paq: any;

export function usePageView() {
	let location = useLocation();
	useEffect(() => {
		_paq.push(['trackPageView']);
	}, [ location ]);
}
