import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { Dispatch } from 'redux';
import { GetState } from '@/stores/index';

export class PageState {
	menuOpen: boolean = false;
	titles: Record<string, string> = {};
	metadata: any = null;
}

export default {
	state: PageState,
	reducer: mapReducer([
		'menuOpen',
		'titles',
		'metadata',
	]),
} as SubStore;


export const setMenuOpen = (menuOpen: boolean) => (dispatch: Dispatch) => {
	dispatch({ state: PageState, type: MapReducerAction.MAP, menuOpen });
};
export const setPage = (title: string) => (dispatch: Dispatch, getState: GetState) => {
	const titles = {
		...getState().page.titles,
		[window.location.pathname]: title
	};
	dispatch({ state: PageState, type: MapReducerAction.MAP, titles });
};

export const setMetadata = (metadata: Nullable<Record<string, any>>, append = false) => (dispatch: Dispatch, getState: GetState) => {
	if (metadata !== null) {
		metadata = {
			...(append ? (getState().page.metadata || {}) : {}),
			...(metadata || {})
		};
	}
	dispatch({ state: PageState, type: MapReducerAction.MAP, metadata });
};
