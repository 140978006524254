import { User } from '@/models/User';
import { buildModelHelper } from '@/libs/modelHelper';
import { hRefreshToken, HRefreshToken, RefreshToken } from '@/models/RefreshToken';

export interface Token {
	id: string;
	name: Nullable<string>;
	lifetime: Nullable<number>;
	createTime: number;
	rights: string[];
	user: User;
	refreshToken: RefreshToken
}

export const hToken = buildModelHelper<Token>({
	
	get isValid(): boolean {
		return (new Date()).getTime() <= (this.lifetime + this.createTime) * 1000;
	},
	
	get refreshToken(): HRefreshToken {
		return hRefreshToken(this._self.refreshToken);
	}
})

export type HToken = ReturnType<typeof hToken>;
