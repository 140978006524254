export enum RecouvrementRecordTypeExecution {
	NOTHING = '',
	SAISIE_LOYER = 'Saisie loyer',
	SAISIE_SALAIRE = 'Saisie salaire',
	SAISIE_MOBILIERE = 'Saisie mobilière',
	SAISIE_SUR_COMPTE = 'Saisie sur compte',
	SAISIE_VEHICULE = 'Saisie véhicule',
	SAISIE_IMMO = 'Saisie Immo.',
	AUTRE = 'Autre',
}
