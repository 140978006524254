import parsePhoneNumber from 'libphonenumber-js';

export class PhoneHelper {
	public static format(phone: any): string {
		if (typeof phone === 'string') {
			const phoneNumber = parsePhoneNumber(phone);
			if (phoneNumber) {
				phone = `(+${phoneNumber.countryCallingCode}) ${phoneNumber.formatNational()}`;
			}
		}
		return phone;
	}
	public static fix(phone: any): string {
		if (typeof phone === 'string') {
			phone = phone.replace(/[^\d]/g, '');
			if (phone[0] === '0') {
				phone = '+33'+phone.substring(1);
			}
		}
		return phone
	}
}
