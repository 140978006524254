import { UserSyncicat } from '@/models/UserSyncicat';
import { FileSlotPeriodicity } from '@/models/FileSlotPeriodicity';
import { DocumentType } from '@/models/DocumentType';
import { User } from '@/models/User';
import { buildModelHelper } from '@/libs/modelHelper';

export interface FileSlot {
	quarter: Nullable<number>;
	year: Nullable<number>;
	id: number;
	syndicat: UserSyncicat;
	documentType: DocumentType;
	periodicity: FileSlotPeriodicity;
	day: Nullable<number>;
	month: Nullable<number>;
	used: boolean;
	relanceUser: Nullable<User>
	tags: Nullable<string[]>;
}

export const hFileSlot = buildModelHelper<FileSlot>({
	toSend() {
		return {
			syndicat: this.syndicat,
			documentType: this.documentType ? this.documentType.id : null,
			periodicity: this.periodicity,
			day: this.day,
			month: this.month,
			relanceUser: this.relanceUser ? this.relanceUser.id : null,
			tags: this.tags
		}
	}
});

export type HFileSlot = ReturnType<typeof hFileSlot>;
