import { Native } from '@/libs/utils';
import { Validators } from '@/libs/validators/useValidators';
import { FormRule } from '@/libs/form';

export default function email(
	this: Validators,
	{message}: {
		message?: string,
	} = {}): FormRule {
	return (v: string) => Native.empty(v) || /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(v) || message || this.t('validators.email')as string;
}
