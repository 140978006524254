import './Evenements.scss';
import { Container } from '@mui/material';

export default function Evenements() {
	return (
		<Container className="p-base-Evenements">
			Evenements
		</Container>
	);
}
