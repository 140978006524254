import { buildModelHelper } from '@/libs/modelHelper';
import { AnnuaireProprietaire } from '@/models/AnnuaireProprietaire';

export interface AnnuaireLogement {
	id: string;
	syndicat: string;
	numero: string;
	batiment: string;
	adresse: string;
	escalier: string;
	etage: string;
	position: string;
	type: string;
	surface: number;
	tantiemes: number;
	proprietaireName: string;
	proprietaire: AnnuaireProprietaire;
	proprietaireId: string;

	adresseRef: string;
	emailRef: string;
	telephoneRef: string;
	soldeSp: number;
	soldeS2: number;

	occupation: string;
	categorieOccupation: number;
	nomCategorieOccupation: string;

	impaye: number;
	categorieImpaye: number;
	nomCategorieImpaye: boolean;

	retard: number;
	categorieRetard: boolean;
	nomCategorieRetard: boolean;

	defaillance: number;

	categorieRecouvrement: string;
	nomCategorieRecouvrement: string;
	commentaireRecouvrement: string;

	planBdf: string;
	typeAccompagnementSocial: string;
	dateVisiteRef: string;
	etatVisiteRef: string;
	situationLhi: string;
}

export const hAnnuaireLogement = buildModelHelper<AnnuaireLogement>({

	get formattedImpaye(): Nullable<string> {
		return this._self.impaye ? `Impayé : ${this._self.impaye} €` : null;
	},

	get formattedSoldeSp(): Nullable<string> {
		return this._self.soldeSp ? `${this._self.soldeSp} €` : null;
	},

	get formattedSoldeS2(): Nullable<string> {
		return this._self.soldeS2 ? `${this._self.soldeS2} €` : null;
	},

	get formattedRetard(): Nullable<string> {
		return this._self.retard ? `${this._self.retard} Trim.` : null;
	},

	isUniquePosition(Data: AnnuaireLogement[]): boolean {
		return Data.filter((dataItem) =>
			dataItem.position === this._self.position &&
			dataItem.batiment === this._self.batiment &&
			dataItem.syndicat === this._self.syndicat
		).length === 1;
	},

	correctPosition(Data: AnnuaireLogement[]): void {
		if (this._self.position !== null && !/\d$/.test(this._self.position)) {
			this._self.position += "1";
		}
		if (this._self.position !== null && this._self.id !== 'TR46-0340' && this._self.isUniquePosition(Data)) {
			this._self.position = this._self.position.replace(/\d+$/, (match: any) => String(Number(match) - 1));
		}
		if (this._self.position === null) {
			const index = Data.findIndex((value) => value.id === this._self.id);
			Data.splice(index, 1);
		}
	}
});

export type hAnnuaireLogement = ReturnType<typeof hAnnuaireLogement>;