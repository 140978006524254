import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { reverseDate } from './utils/DateHelper';

class PdfGenerator {
    generatePDF(content: any, setButtonVisible: any, selectedEtatMajs: any) {
        setButtonVisible(false);

        if (content) {
            // Utiliser html2canvas pour convertir le contenu HTML en une image
            setTimeout(() => {
                html2canvas(content, {
                    scale: 2, // Augmente la résolution de l'image
                    height: content.scrollHeight // Utiliser la hauteur totale de l'élément pour la capture
                }).then(canvas => {
                    const imgData = canvas.toDataURL('image/png');

                    const imgProps = {
                        width: canvas.width,
                        height: canvas.height
                    };

                    const a4Width = 210; // Largeur de la page A4 en mm
                    const a4Height = 297; // Hauteur de la page A4 en mm

                    // Calcul du ratio pour redimensionner l'image
                    const ratio = Math.min(a4Width / imgProps.width, a4Height / imgProps.height);

                    const imgWidth = imgProps.width * ratio;
                    const imgHeight = imgProps.height * ratio;

                    // Détecter l'orientation de l'image capturée
                    const orientation = imgHeight > imgWidth ? 'portrait' : 'landscape';

                    // Utiliser l'orientation de l'image pour définir l'orientation du PDF
                    const pdfWidth = orientation === 'portrait' ? a4Width : a4Height;
                    const pdfHeight = orientation === 'portrait' ? a4Height : a4Width;

                    const doc = new jsPDF({
                        orientation: orientation,
                        unit: 'mm',
                        format: [pdfWidth, pdfHeight] // Définir les dimensions du PDF
                    });

                    // Calcul des offsets pour centrer l'image
                    const xOffset = (pdfWidth - imgWidth) / 2;
                    const yOffset = (pdfHeight - imgHeight) / 2;

                    // Ajouter l'image au PDF
                    doc.addImage(imgData, 'PNG', xOffset, yOffset, imgWidth, imgHeight);
                    const fileName = `${reverseDate(new Date())}_Synoptique_${selectedEtatMajs?.syndicat || ''}_${selectedEtatMajs?.nomSyndicat || ''}.pdf`;
                    // Sauvegarder le fichier PDF
                    doc.save(fileName);
                    setButtonVisible(true);
                }).catch(error => {
                    console.error('Erreur lors de la conversion du HTML en image :', error);
                    setButtonVisible(true);
                });
            }, 100); // Délai de 0.1 seconde
        }
    };

}
const pdfGenerator = new PdfGenerator()
export default pdfGenerator;