import { Card, CardContent } from '@mui/material';
import { useState } from 'react';
import TextField from '@/components/inputs/TextField/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import { useEffectTimeout, usePropState } from '@/libs/hooks';
import { CardProps } from '@mui/material/Card/Card';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import CrudLogModerationTypeSelect from '../../logModerationType/Select/Select';
import DatePicker from '@/components/inputs/DatePicker/DatePicker';
import { dateToDash } from '@/libs/utils';

export interface CrudLogModerationFiltersProps extends CardProps {
	value?: Nullable<any>;
	onChange?: (value: Nullable<any>) => any,
}

export default function CrudLogModerationFilters(
	{
		value,
		onChange,
		className = '',
		...rest
	}: CrudLogModerationFiltersProps
) {

	const [ filters, setFilters ] = usePropState<any>(value, onChange, {
		type: [],
		user: '',
		createdAt: {
			start: '',
			end: ''
		}
	});

	const [ type, setType ] = useState<string>(filters.type);
	const [ user, setUser ] = useState<string>(filters.user);
	const [ open, setOpen] = useState<boolean>(false);

	const startInitial = new Date();
	const endInitial = new Date();
	startInitial.setMonth(endInitial.getMonth() - 1);
	endInitial.setDate(startInitial.getDate());

	const [ start, setStart ] = useState<Nullable<Date>>(startInitial);
	const [ end, setEnd ] = useState<Nullable<Date>>(endInitial);

	const formattedStart = start ? dateToDash(start) : null;
	const formattedEnd = end ? dateToDash(end) : null;
	
	useEffectTimeout(() => {
		if (
			filters.type 	!== type 	||
			filters.user 	!== user 	||
			filters.createdAt.start !== formattedStart ||
			filters.createdAt.end !== formattedEnd	  

		) {
			setFilters({
				type,
				user,
				createdAt: {
					start: formattedStart,
					end: formattedEnd
				}
			})
		}
	}, 500, [
		type,
		user,
		formattedStart,
		formattedEnd
	])
	
	
	return (
		<Card
			{...rest}
			className={[
				className,
				'mt-1',
				'mb-1',
				'c-Crud-Log-Filters'
			].join(' ')}
		>
			<CardContent className="p-1">
				<div onClick={() => setOpen(!open)}>
					<h5 className="m-0" style={{cursor: 'pointer'}}>  {open ? <KeyboardArrowUp style={{ verticalAlign: '-7px' }} /> : <KeyboardArrowDown style={{ verticalAlign: '-7px' }} />}<span>Filtres :</span></h5>
				</div>
				{open && (
					<Grid  container spacing={2} className="mt-1" >
						<Grid xs={12} md={4}>
							<CrudLogModerationTypeSelect
								{...model(type, setType)}
								multiple
								clearable
								label="Types"
								placeholder="Sélectionnez le type de log"
							/>
						</Grid>
						<Grid xs={12} md={2} >
							<TextField
								{...model(user, setUser)}
								clearable
								size="medium"
								label="Utilisateur"
								variant="outlined"
								placeholder="Rechercher par nom d'utilisateur"
							/>
						</Grid>
						<Grid
							xs={12} md={3}
						>
							<DatePicker
								{...model(start, setStart)}
								label="Du"
							/>
						</Grid>
						<Grid
							xs={12} md={3}
						>
							<DatePicker
								{...model(end, setEnd)}
								label="Au"
							/>
						</Grid>
					</Grid>
				)}
			</CardContent>
		</Card>
	)
}
