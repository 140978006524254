import { LayoutProps } from './index';
import SnackMessages from '@/components/SnackMessages/SnackMessages';
import AppBar from '@/components/AppBar/AppBar';
import LoadingBar from '@/components/LoadingBar/LoadingBar';
import Main from '@/components/Main/Main';
import Navigation from '@/components/Navigation/Navigation';
import { Charte } from '@/components/Charte/Charte';
import { PasswordExpired } from '@/components/Charte/PasswordExpired';

export default function MainLayout(
	{
		children,
		metadata,
	}: LayoutProps
) {
	return (
		<>
			<Charte />
			<PasswordExpired />
			<SnackMessages/>
			<LoadingBar />
			<AppBar title={metadata.title} />
			<Navigation/>
			<Main>
				{children}
			</Main>
		</>
	);
}
