import { Card, CardContent } from '@mui/material';
import { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import { useEffectTimeout, usePropState } from '@/libs/hooks';
import { CardProps } from '@mui/material/Card/Card';
import TextField from '@/components/inputs/TextField/TextField';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Select from '@/components/inputs/Select/Select';
import CrudUserSyncicatSelect from '@/components/crud/userSyncicat/Select/Select';

export interface CrudTriAcquiFiltersProps extends CardProps {
	value?: Nullable<any>;
	onChange?: (value: Nullable<any>) => any,
}

export default function CrudTriAcquiFilters(
	{
		value,
		onChange,
		className = '',
		...rest
	}: CrudTriAcquiFiltersProps
) {

	const [filters, setFilters] = usePropState<any>(value, onChange, {
		syndicat: [],
		lotReference: '',
		adresse: '',
		typeVente: '',
		statutDossier: [],
		secteurSyndicat: '',
		structure: '',
		proprietaire: '',
		nomProprietaire: '',
		responsable: '',
	});

	const [open, setOpen] = useState<boolean>(false);
	const [syndicat, setSyndicat] = useState<string[]>(filters.syndicat);
	const [lotReference, setLotReference] = useState<string>(filters.lotReference);
	const [typeVente, setTypeVente] = useState<string>(filters.typeVente);
	const [statutDossier, setStatutDossier] = useState<string[]>(filters.statutDossier);
	const [secteurSyndicat, setSecteurSyndicat] = useState<string>(filters.secteurSyndicat);
	const [structure, setStructure] = useState<string>(filters.structure);
	const [proprietaire, setProprietaire] = useState<string>(filters.proprietaire);
	const [nomProprietaire, setNomProprietaire] = useState<string>(filters.nomProprietaire);
	const [responsable, setResponsable] = useState<string>(filters.responsable);

	useEffectTimeout(() => {
		if (
			filters.syndicat !== syndicat ||
			filters.lotReference !== lotReference ||
			filters.typeVente !== typeVente ||
			filters.statutDossier !== statutDossier ||
			filters.secteurSyndicat !== secteurSyndicat ||
			filters.structure !== structure ||
			filters.proprietaire !== proprietaire ||
			filters.nomProprietaire !== nomProprietaire ||
			filters.responsable !== responsable 

		) {
			setFilters({
				syndicat,
				lotReference,
				typeVente,
				statutDossier,
				secteurSyndicat,
				structure,
				proprietaire,
				nomProprietaire,
				responsable,
			})
		}
	}, 500, [
		syndicat,
		lotReference,
		typeVente,
		statutDossier,
		secteurSyndicat,
		structure,
		proprietaire,
		nomProprietaire,
		responsable,
	]);

	return (
		<Card
			{...rest}
			className={[
				className,
				'mt-2',
				'mb-1',
				'c-Crud-Lot-Filters',
				open ? 'c-Crud-Lot-Filters--open' : ''
			].join(' ')}
		>
			<CardContent className="p-1">
				<div onClick={() => setOpen(!open)}>
					<h5 className="mt-0 mb-1" style={{ cursor: 'pointer' }}>
						{open ? <KeyboardArrowUp style={{ verticalAlign: '-7px' }} /> : <KeyboardArrowDown style={{ verticalAlign: '-7px' }} />}
						<span>Filtres :</span>
					</h5>
				</div>
				{open && (
					<Grid container spacing={1}>
						<Grid xs={12} md={4}>
							<CrudUserSyncicatSelect {...model(syndicat, setSyndicat)} clearable size="small" variant="standard" label="Syndicat" multiple />
						</Grid>
						<Grid xs={12} md={4}>
							<Select {...model(statutDossier, setStatutDossier)} items={
								['Acquis', 'Notaire saisi', 'Accord prix', 'Offre orale', 'Visite réalisée', 'Visite à programmer']}
								clearable size="small" variant="standard" label="Statut du dossier" multiple />
						</Grid>
						<Grid xs={12} md={4}>
							<TextField {...model(lotReference, setLotReference)} clearable size="small" variant="standard" label="Lot référence" />
						</Grid>
						<Grid xs={12} md={4}>
							<TextField {...model(typeVente, setTypeVente)} clearable size="small" variant="standard" label="Type vente" />
						</Grid>
						<Grid xs={12} md={4}>
							<TextField {...model(secteurSyndicat, setSecteurSyndicat)} clearable size="small" variant="standard" label="Secteur" />
						</Grid>
						<Grid xs={12} md={4}>
							<TextField {...model(structure, setStructure)} clearable size="small" variant="standard" label="Structure en charge" />
						</Grid>
						<Grid xs={12} md={4}>
							<TextField {...model(proprietaire, setProprietaire)} clearable size="small" variant="standard" label="Propriétaire" />
						</Grid>
						<Grid xs={12} md={4}>
							<TextField {...model(nomProprietaire, setNomProprietaire)} clearable size="small" variant="standard" label="Nom Propriétaire" />
						</Grid>
						<Grid xs={12} md={4}>
							<TextField {...model(responsable, setResponsable)} clearable size="small" variant="standard" label="Responsable" />
						</Grid>
					</Grid>
				)}
			</CardContent>
		</Card>
	)
}
