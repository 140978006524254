export enum RecouvrementRecordPlanBdf {
	NOTHING = '',
	INFORMATION_DEMANDEE_EN_ATTENTE = 'Information demandée en attente',
	DOSSIER_DEPOSE = 'Dossier déposé',
	DOSSIER_RECEVABLE = 'Dossier recevable',
	DOSSIER_REJETE = 'Dossier rejeté',
	MORATOIRE = 'Moratoire',
	PLAN_CONVENTIONNEL_DE_REDRESSEMENT = 'Plan conventionnel de redressement',
	MESURES_IMPOSEES = 'Mesures imposées',
	RETABLISSEMENT_PERSONNEL = 'Rétablissement personnel',
	PLAN_CADUC  = 'Plan Caduc ',
}
