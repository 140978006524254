import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import { Email, Referent, User } from '@/models';
import { useState } from 'react';
import { useForm } from '@/libs/form';
import { useDispatch } from '@/libs/redux';
import { LoadingButton } from '@mui/lab';
import Form from '@/components/forms/Form/Form';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import { useValidators } from '@/libs/validators';
import TextField from '@/components/inputs/TextField/TextField';
import { postEmail, putEmail } from '@/stores/email';

export interface CrudEmailEditProps {
	referent: Referent,
	value: Email;
	onSave: (email: Email) => any;
	onClose: () => any;
	currentUser: Nullable<User>; // Ajouter currentUser comme prop
}

export function CrudEmailEdit(
	{
		referent,
		value,
		onSave,
		onClose,
		currentUser, // Recevoir currentUser
	}: CrudEmailEditProps
) {
	const dispatch = useDispatch();
	const validators = useValidators();
	const [email1, setEmail1] = useState(value.email1);
	const [commentaire, setCommentaire] = useState(value.commentaire);
	const [source, setSource] = useState(value.source || '');
	const hiddenStyle = { display: 'none' };

	const form = useForm({
		handle: async () => {
			const newSource = `${currentUser?.firstName} ${currentUser?.lastName}`;
			await form.call(async () => {
				if (value.id) {
					const result = await dispatch(putEmail({
						...value,
						email1,
						commentaire,
						source: newSource,
					}));
					onSave(result);
				} else {
					const result = await dispatch(postEmail(referent, {
						email1,
						commentaire,
						source: newSource,
					}));
					onSave(result);
				}
			});
		},
		fields: {
			email1: {
				label: 'Email',
				...model(email1, setEmail1),
				rules: [
					// validators.required(),
					// validators.email()
				],
				type: 'email'
			},
			commentaire: {
				label: 'Commentaire',
				...model(commentaire, setCommentaire),
				multiline: true,
				rows: 5,
			},
			source: {
				label: 'Source',
				...model(source, setSource),
				disabled: true, // Désactiver l'édition du champ source
				hidden: true,
			}
		}
	});

	return (
		<Form form={form}>
			<Card>
				<CardHeader
					title={value.id ? "Édition de l'email" : "Création d'un email"}
					className="CardHeader--primary"
				/>
				<CardContent>
					<FormMainErrors form={form} />
					<Grid container spacing={1}>
						<Grid xs={12}>
							<TextField {...form.fields.email1} />
						</Grid>
						<Grid xs={12}>
							<TextField
								{...form.fields.commentaire}
								multiline
								rows={7}
							/>
						</Grid>
						<Grid xs={12} style={hiddenStyle}>
							<TextField
								{...form.fields.source}
							/>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions className="u-d-block">
					<LoadingButton
						loading={form.loading}
						onClick={onClose}
					>
						Fermer
					</LoadingButton>
					<LoadingButton
						type="submit"
						color="primary"
						variant="contained"
						loading={form.loading}
						style={{ float: 'right' }}
						onClick={(event) => {
							event.preventDefault();
							form.handle(event);
						}}
					>
						Enregistrer
					</LoadingButton>
				</CardActions>
			</Card>
		</Form>
	);
}
