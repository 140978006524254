import { buildModelHelper } from '@/libs/modelHelper';

export interface SuiviSocial {
	id: number;
	date: string;
	typeContact: string;
	demarches: string;
	aFaire: string;
}

export const hSuiviSocial = buildModelHelper<SuiviSocial>({
	get date(): Nullable<Date> { return this._self.date ? new Date(this._self.date) : null; },
	set date(value: Nullable<Date>) { this._self.date = value ? value.toISOString() : null; },
});
export type HSuiviSocial = ReturnType<typeof hSuiviSocial>;
