export enum RecouvrementRecordStatutPortage {
	NOTHING = '',
	INJONCTION_DE_PAYER = 'Contact EPF établi',
	VISITE_EFFECTUEE = 'Visite effectuée',
	OFFRE_ORALE = 'Offre orale',
	OFFRE_ECRITE = 'Offre écrite',
	OFFRE_REFUSEE = 'Offre refusée',
	SAISIE_DU_NOTAIRE = 'Saisie du notaire',
	PAS_DE_REPONSE = 'Pas de réponse',
}
