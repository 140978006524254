import { useEffect, useState } from 'react';

export function usePropState<T = any>(prop: (T|undefined), onChange?: (value: T) => any, defaultValue: any = null, a?: string): [ T, (value: T) => any ] {
	const [ stateV, setStateV ] = useState(typeof prop !== 'undefined' ? prop : defaultValue);
	useEffect(() => {
		setStateV(prop);
	}, [ prop ]);
	if (typeof prop !== 'undefined' && typeof onChange !== 'undefined') {
		return [ prop, onChange ] as any;
	}
	return [ stateV, setStateV ];
}
