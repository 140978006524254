import { Lot, Proprietaire, TriLot } from '@/models';
import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress } from '@mui/material';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import { useEffectOnChange, usePageable, useSnackMessage } from '@/libs/hooks';
import { useDispatch, useSelector } from '@/libs/redux';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useCallback, useEffect, useState } from 'react';
import { model } from '@/libs/candy';
import CrudTriLotFilters from '@/components/crud/triLot/Filters/Filters';
import { findTriLots, findTriLotsByProprietaireId, findTriLotsLogement, findTriLotsByProprietaireNom } from '@/stores/triLot';
import './Select.scss';
import TooltipElm from '@/components/Tooltip/TooltipElm';
import { rgb } from 'polished';
import { Propriete } from '@/models/Propriete';

export interface CrudLotSelectItemProps {
	item: TriLot;
	value: Lot[];
	onChange: (v: Lot) => void;
	onClose: () => any;
}

export function CrudTriLotSelectItem({
	item,
	value,
	onChange,
}: CrudLotSelectItemProps) {
	const [canSelect, setCanSelect] = useState(false);
	const selected = value.some(l => l.id === item.lot?.id);

	const anyLotSelected = value.length > 0;

	useEffect(() => {
		if (!anyLotSelected) {
			const timer = setTimeout(() => {
				setCanSelect(true);
			}, 2000);

			return () => clearTimeout(timer);
		} else {
			setCanSelect(true);
		}
	}, [anyLotSelected]);

	return (
		<TableRow style={{ height: '24px' }}>
			<TableCell><TooltipElm message={item.syndicat.toString() || '-'} /></TableCell>
			<TableCell style={{ textAlign: 'center' }}><TooltipElm message={item.numero || '-'} /></TableCell>
			<TableCell><TooltipElm message={item.type || '-'} /></TableCell>
			<TableCell style={{ width: '16%' }}><TooltipElm message={item.adresse || '-'} /></TableCell>
			<TableCell style={{ textAlign: 'center' }}><TooltipElm message={item.escalier || '-'} /></TableCell>
			<TableCell style={{ textAlign: 'center' }}><TooltipElm message={item.etage || '-'} /></TableCell>
			<TableCell style={{ textAlign: 'center' }}><TooltipElm message={item.position || '-'} /></TableCell>
			<TableCell style={{ textAlign: 'center' }}><TooltipElm message={item.proprietaire || '-'} /></TableCell>
			<TableCell style={{ width: '30%' }}><TooltipElm message={item.nomProprietaire || '-'} /></TableCell>
			<TableCell style={{ textAlign: 'center' }}><TooltipElm message={item.surface || '-'} /></TableCell>
			<TableCell style={{ textAlign: 'center' }}><TooltipElm message={item.nombrePieces || '-'} /></TableCell>
			<TableCell>
				{canSelect ? (
					<Button
						onClick={() => {
							if (!selected && item.lot) {
								onChange(item.lot);
							}
						}}
						disabled={selected}
					>
						<span style={{ fontSize: '22px', color: selected ? rgb(0, 161, 154) : rgb(0, 161, 154), display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>{selected ? '☑' : '☐'}</span>
					</Button>
				) : (
					!anyLotSelected && <CircularProgress size={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} />
				)}
			</TableCell>
		</TableRow>
	);
}

export interface CrudTriLotSelectProps {
	proprietaire: Nullable<Proprietaire>;
	value: Lot[];
	onChange: (v: Lot[]) => any;
	onClose: () => any;
}

export function CrudTriLotSelect({
	proprietaire,
	value,
	onChange,
	onClose,
}: CrudTriLotSelectProps) {
	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const pageable = usePageable();
	const triLots = useSelector(state => state.triLot.triLots);

	const [filters, setFilters] = useState({
		syndicat: [],
		numero: '',
		adresse: '',
		escalier: '',
		etage: '',
		position: '',
		typeId: [],
		proprietaire: '',
		nomProprietaire: '',
		nombrePieces: '',
	});
	const [selectedLots, setSelectedLots] = useState<Lot[]>(value || []);

	const refresh = useCallback(() => {
		(async () => {
			try {
				if (selectedLots.length > 0) {
					const syndicateName = selectedLots?.[0]?.syndicat?.nom ?? null;
					const ownerName = selectedLots?.[0]?.currentProprietaire?.nom ?? null;
					const ownerId = selectedLots?.[0]?.proprietes?.[0]?.proprietaire?.id ?? null;

					if (ownerId) {
						await dispatch(findTriLotsByProprietaireId(ownerId, pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, filters));
					}
					else if (ownerName) {
						await dispatch(findTriLotsByProprietaireNom(ownerName, pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, filters));
					}
				} else {
					await dispatch(findTriLotsLogement(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, filters));
				}
			} catch (e) {
				console.error(e);
				pushMessage('Une erreur est survenue.', 'error');
			}
		})();
	}, [selectedLots, filters, pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, dispatch, pushMessage]);

	useEffectOnChange(() => {
		refresh();
	}, [refresh]);


	const handleLotSelection = (selectedLot: Lot) => {
		if (!selectedLots.some(lot => lot.id === selectedLot.id)) {
			setSelectedLots([...selectedLots, selectedLot]);
		}
	};

	return (
		<Card className="c-Crud-TriLot-Select" style={{ height: '100vh' }}>
			<CardHeader className="py-1 mb-1" title="Sélectionner des lots" titleTypographyProps={{ className: 'u-t-size-19' }} />
			<CardContent style={{ height: '70vh', overflow: 'auto' }}>
				<CrudTriLotFilters
					{...model(filters, setFilters)}
				/>
				<SortableTable
					style={{ maxHeight: '100%', width: '100%' }}
					heads={[
						{ name: 'Syndicat', sort: 'syndicat' },
						{ name: 'Numéro', sort: 'numero' },
						{ name: 'Type', sort: 'type' },
						{ name: 'Adresse', sort: 'adresse' },
						{ name: 'Escalier', sort: 'escalier' },
						{ name: 'Etage', sort: 'etage' },
						{ name: 'Position', sort: 'position' },
						{ name: 'Propriétaire', sort: 'proprietaire' },
						{ name: 'Nom propriétaire', sort: 'nomProprietaire' },
						{ name: 'Surface', sort: 'surface' },
						{ name: 'Nombre pièces', sort: 'nombrePieces' },
						{ name: 'Actions', stickyRight: true },
					]}
					result={triLots}
					renderItem={(item => (
						<CrudTriLotSelectItem
							value={selectedLots}
							onChange={handleLotSelection}
							item={item}
							onClose={onClose}
						/>
					))}
					pageable={pageable}
				/>
			</CardContent>
			<CardActions style={{ justifyContent: 'space-between' }}>
				<Button onClick={onClose}>Fermer</Button>
				<Button onClick={() => {
					onChange(selectedLots);
					onClose(); // Ferme le sélecteur après la validation
				}}>
					Valider la sélection
				</Button>
			</CardActions>
		</Card>
	);
}
