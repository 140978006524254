import repeatField from './repeatField';
import onlyInteger from './onlyInteger';
import onlyLetters from './onlyLetters';
import email from './email';
import range from './range';
import maxlength from './maxlength';
import fileSize from './fileSize';
import required from './required';
import notNull from './notNull';
import password from './password';
import passwordClass from './passwordClass';
import phone from './phone';
import fileFormats from './fileFormats';
import minlength from '@/libs/validators/minlength';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

const list = {
	repeatField,
	onlyNumber: onlyInteger,
	onlyLetters,
	email,
	range,
	minlength,
	maxlength,
	fileSize,
	required,
	notNull,
	password,
	passwordClass,
	fileFormats,
	phone,
}

export interface ValidatorsTrans {
	t: (key: string, options?: any) => string
}

export type Validators = ValidatorsTrans & typeof list;

export function useValidators(): Validators {

	const { t } = useTranslation();

	const validators: Validators = useMemo(() => ({
		t,
		...Object.fromEntries(
			Object.entries(list).map(([ name, func ]) => [ name, (...args: any[]) => func.bind(validators)(...args) ])
		)
	}) as any, [ t ]);
	return validators;
}
