import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Collapse, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import IconChevronLeft from '@mui/icons-material/ChevronLeft';
import IconExpandLess from '@mui/icons-material/ExpandLess';
import IconExpandMore from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from '@/libs/redux';
import { setMenuOpen } from '@/stores/page';
import navigations, { NavigationItem } from '@/navigations';
import './Navigation.scss';
import { useIsGranted } from '@/libs/hooks';
import { hUser } from '@/models';


export interface NavigationListItemProps {
	item: NavigationItem;
}

const isOpen = (item: NavigationItem, pathname: string): boolean => {
	return item.children
		? item.children.reduce((a, v) => a || isOpen(v, pathname), false) as boolean
		: pathname.indexOf(item.to as string) === 0
	;
}

export function NavigationListItem(
	{
		item
	}: NavigationListItemProps
) {

	const location = useLocation();
	const navigate = useNavigate();
	const isGranted = useIsGranted();
	const [ open, setOpen ] = useState(isOpen(item, location.pathname));

	const handleClick = () => {
		setOpen(!open);
		if (item.to) {
			navigate(item.to);
		}
	};

	return (
		isGranted(item.granted) ? <>
			<ListItemButton
				className={[
					'c-NavigationListItem',
					location.pathname.indexOf(item.to as string) === 0 ? 'c-NavigationListItem--selected' : ''
				].join(' ')}
				onClick={handleClick}
			>
				{!!item.icon && (
					<ListItemIcon>
						{item.icon}
					</ListItemIcon>
				)}
				<ListItemText primary={item.title} />
				{!!item.children && (
					open ? <IconExpandLess /> : <IconExpandMore />
				)}
			</ListItemButton>
			{!!item.children && (
				<Collapse in={open} timeout="auto" unmountOnExit>
					<List>
						{item.children.map((sub, i) => (
							<NavigationListItem
								key={i}
								item={sub}
							/>
						))}
					</List>
				</Collapse>
			)}
		</> : <></>
	);
}


export default function Navigation() {

	const dispatch = useDispatch();
	const menuOpen = useSelector(state => state.page.menuOpen);
	const me = hUser(useSelector(state => state.user.me));

	return (
		<Drawer
			className={[
				'c-Navigation',
				menuOpen ? 'c-Navigation--open' : '',
			].join(' ')}
			variant="persistent"
			open={menuOpen}
		>
			<div
				className="c-Navigation-header"
			>
				<div className="c-Navigation-header-name">
					<Avatar sx={{ bgcolor: '#673AB7' }}>{me?.sigle}</Avatar>
					<span>{me?.fullName}</span>
				</div>

				<IconButton onClick={() => dispatch(setMenuOpen(!menuOpen))}>
					<IconChevronLeft />
				</IconButton>
			</div>
			<div
				className="c-Navigation-content"
			>
				<List>
					{navigations.map((item, i) => (
						<NavigationListItem
							key={i}
							item={item}
						/>
					))}
				</List>
			</div>
		</Drawer>
	);
}
