import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { TypeLot, ResultType } from '@/models';
import { Direction } from '@/models/Direction';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class TypeLotState {
	public typeLots: Nullable<ResultType<TypeLot>> = null;
}

export default {
	state: TypeLotState,
	reducer: mapReducer([
		'typeLots',
	]),
} as SubStore;

// MUTATIONS

export const setTypeLots = (typeLots: Nullable<ResultType<TypeLot>>) => (dispatch: DispatchApp) => {
	dispatch({ state: TypeLotState, type: MapReducerAction.MAP, typeLots });
};

// ACTIONS

export const findTypeLots = (
	page: number,
	order: string,
	direction: Direction,
	limit: number,
	filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<TypeLot>> => {
	const typeLots = await caller.get<ResultType<TypeLot>>(`/api/type-lots${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);
	
	dispatch(setTypeLots(typeLots));
	
	return typeLots;
});
