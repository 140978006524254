import { Alert, Card, CircularProgress, Grid, IconButton, LinearProgress, Switch, Tooltip } from '@mui/material';
import { useSelector } from '@/libs/redux';
import { CustomRowType, DocumentTypeMode, DocumentUpload, hDocumentType } from '@/models';
import IconDelete from '@mui/icons-material/Delete';
import IconAdd from '@mui/icons-material/Add';
import { model } from '@/libs/candy';
import { useValidators } from '@/libs/validators';
import { Form } from '@/libs/form';
import CrudDocumentTypeSelect from '@/components/crud/documentType/Select/Select';
import DatePicker from '@/components/inputs/DatePicker/DatePicker';
import TextField from '@/components/inputs/TextField/TextField';
import FileField from '@/components/inputs/FileField/FileField';
import { dateToDash, toDate } from '@/libs/utils';
import { DocumentCustomField } from '@/models/DocumentCustomField';
import { DocumentType } from '@/models/DocumentType';
import { DocumentTypeType } from '@/models/DocumentTypeType';
import {
	Document,
	DocumentStatus,
	FileSlotPeriodicity,
	hDocument,
	HDocument,
	hSlot,
	Slot,
	SlotStatus,
	UserSyncicat,
	hUser
} from '@/models';
import slotList from '@/stores/slotList';
import { useEffect, useState } from 'react';

export interface CrudDocumentUploadListItemProps {
	value: DocumentUpload,
	onChange: (value: DocumentUpload) => any,

	item: DocumentUpload,
	canBeDelete: boolean,
	onAdd: () => any,
	onRemove: () => any,
	form: Form,
}

function getTrimesterFromDate(date: Date) {
	const month = date.getMonth() + 1; // Obtenez le mois actuel de la date
	if (month <= 3) return 1; // Janvier à Mars
	if (month <= 6) return 2; // Avril à Juin
	if (month <= 9) return 3; // Juillet à Septembre
	return 4; // Octobre à Décembre
}

// Fonction pour obtenir le premier jour de l'année et le dernier jour du trimestre en cours
function getStartEndDates(year: number) {
	const startDate = new Date(year, 0, 1); // Premier janvier de l'année donnée
	let endDate;

	const currentMonth = new Date().getMonth();
	if (currentMonth <= 2) { // Premier trimestre
		endDate = new Date(year, 2, 31); // 31 mars
	} else if (currentMonth <= 5) { // Deuxième trimestre
		endDate = new Date(year, 5, 30); // 30 juin
	} else if (currentMonth <= 8) { // Troisième trimestre
		endDate = new Date(year, 8, 30); // 30 septembre
	} else { // Quatrième trimestre
		endDate = new Date(year, 11, 31); // 31 décembre
	}

	return { startDate, endDate };
}

function renderCustomRow(customRow: DocumentCustomField, indexRow: any, value: any, onChange: (value: any) => void, form: any) {
	// if(customRow.value !== '' && customRow.value !== null) {
	// 	return;
	// }
	const customField = value.documentType.customRows[indexRow].value;
	const updateCustomField = (newValue: any) => {
		const updatedValue = { ...value };
		updatedValue.documentType.customRows[indexRow].value = newValue;
		onChange(updatedValue);
	};

	if (customRow.field.customType === CustomRowType.TEXT) {
		return (
			<TextField
				{...model(customField, updateCustomField)}
				label={customRow.field.customLabel}
				readOnly={form.loading}
				disabled={value.success}
			/>
		);
	} else if (customRow.field.customType === CustomRowType.DATE) {
		return (
			<DatePicker
				{...model(dateToDash(customField), updateCustomField)}
				label={customRow.field.customLabel}
				readOnly={form.loading}
				disabled={value.success}
			/>
		);
	} else if (customRow.field.customType === CustomRowType.NUMBER) {
		return (
			<TextField
				type="number"
				{...model.number(customField, updateCustomField)}
				label={customRow.field.customLabel}
				readOnly={form.loading}
				disabled={value.success}
			/>
		);
	}

}

function CrudDocumentUploadListItem(
	{
		item,
		canBeDelete,
		value,
		onChange,
		onAdd,
		onRemove,
		form,
	}: CrudDocumentUploadListItemProps
) {
	const validators = useValidators();
	const documentTypes = useSelector(state => state.documentType.bySyndicatAndPeriodicity);

	const documentType = hDocumentType(value.documentType);
	const [lockStartDate, setLockStartDate] = useState(true);
	const [lockSimpleDate, setLockSimpleDate] = useState(false);

	const year = value.year || new Date().getFullYear();

	useEffect(() => {
		if (lockSimpleDate) {
			const endDate = new Date(year, 11, 31);
			onChange({ ...value, date2: endDate });
		}
	}, [lockSimpleDate, year]);

	useEffect(() => {
		if (lockStartDate) {
			const { startDate } = getStartEndDates(year);
			onChange({ ...value, date: startDate });
		}
	}, [lockStartDate, year]);

	const [lockQuarterEndDate, setLockQuarterEndDate] = useState(true); // État pour le verrouillage de date2

	useEffect(() => {
		if (!lockQuarterEndDate) return;

		const currentDate = new Date(); // Utilisez la date actuelle pour déterminer le trimestre
		const startTrimester = getTrimesterFromDate(currentDate);
		let endOfMonth = 31; // Par défaut pour Mars, Juin, Septembre, Décembre
		const year = currentDate.getFullYear(); // L'année courante

		switch (startTrimester) {
			case 1:
				endOfMonth = 31; // Fin Mars
				break;
			case 2:
				endOfMonth = 30; // Fin Juin
				break;
			case 3:
				endOfMonth = 30; // Fin Septembre
				break;
			case 4:
				endOfMonth = 31; // Fin Décembre
				break;
		}

		const endQuarterDate = new Date(year, startTrimester * 3 - 1, endOfMonth);
		onChange({ ...value, date2: endQuarterDate }); // Mettez à jour date2 en conséquence
	}, [lockQuarterEndDate]);


	return (
		<Card className="p-2 mb-1" elevation={5}>
			<div className="mb-2">Document:</div>

			<Grid container spacing={2}>
				<Grid item xs={11}>
					<Grid container spacing={2}>
						{value.error && (
							<Grid item xs={12}>
								<Alert severity="error">{value.error}</Alert>
							</Grid>
						)}
						<Grid item xs={12} md={6}>
							<CrudDocumentTypeSelect
								{...model(value.documentType, documentType => onChange({ ...value, documentType }))}
								rules={[
									validators.required()
								]}
								label="Document"
								list={documentTypes}
								returnObject
								readOnly={form.loading}
								disabled={value.success}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							{value.documentType?.mode === DocumentTypeMode.START_END && (
								<Grid container spacing={1}>
									<Grid item xs={12} md={6}>
										<DatePicker
											{...model(value.date, date => onChange({ ...value, date }))}
											rules={[validators.required()]}
											label={value.documentType.modeLabel?.split(/|/)?.[0] || 'Date de début'}
											readOnly={form.loading}
											disabled={value.success || lockStartDate} // Désactivez la sélection manuelle si lockStartDate est true
										/>
										<Tooltip title="Verrouiller/Déverrouiller la date de début au 1er janvier">
											<Switch
												checked={lockStartDate}
												onChange={(e) => setLockStartDate(e.target.checked)}
												name="lockStartDate"
												color="primary"
											/>
										</Tooltip>
									</Grid>
									<Grid item xs={12} md={6}>
										<DatePicker
											{...model(value.date2, date2 => onChange({ ...value, date2 }))}
											rules={[validators.required()]}
											label={value.documentType.modeLabel?.split(/|/)?.[1] || 'Date de fin'}
											readOnly={form.loading}
											disabled={value.success || lockQuarterEndDate} // Désactivez la sélection manuelle si lockEndDate est true
										/>
										<Tooltip title="Verrouiller/Déverrouiller la date de fin au dernier jour du trimestre actuel">
											<Switch
												checked={lockQuarterEndDate}
												onChange={(e) => setLockQuarterEndDate(e.target.checked)}
												name="lockQuarterEndDate"
												color="primary"
											/>
										</Tooltip>
									</Grid>
									
								</Grid>
							)}
							{
								value.documentType?.mode === DocumentTypeMode.DATE && (
									<>
										<DatePicker
											{...model(value.date2, date2 => onChange({ ...value, date2 }))}
											rules={[validators.required()]}
											label={value.documentType.modeLabel || 'Date'}
											readOnly={form.loading}
											disabled={value.success || lockQuarterEndDate} // Désactivez la sélection manuelle si lockSimpleDate est true
										/>
										{/* Commutateur pour la date simple */}
										<Tooltip title="Verrouiller/Déverrouiller la date de fin au dernier jour du trimestre actuel">
											<Switch
												checked={lockQuarterEndDate}
												onChange={(e) => setLockQuarterEndDate(e.target.checked)}
												name="lockQuarterEndDate"
												color="primary"
											/>
										</Tooltip>
									</>
								)
							}
							{value.documentType?.mode === DocumentTypeMode.YEAR && (
								<TextField
									{...model.number(value.year, year => onChange({ ...value, year }))}
									type="number"
									rules={[
										validators.onlyNumber(),
										validators.range({ min: 1900, max: 2100 }),
										validators.required()
									]}
									label={value.documentType.modeLabel || 'Année'}
									readOnly={form.loading}
									disabled={value.success}
								/>
							)}
						</Grid>
						{value.documentType?.customRows.map((customRow, indexRow) => (
							<Grid item xs={12} key={customRow.field.uuid}>
								{renderCustomRow(customRow, indexRow, value, onChange, form)}
							</Grid>
						))}
						<Grid item xs={12}>
							{value.documentType && (
								<FileField
									label="Fichier"
									{...model(value.file, file => onChange({ ...value, file }))}
									rules={[
										validators.fileFormats({
											accept: documentType.acceptFormat
										}),
										validators.required(),
									]}
									inputProps={{
										inputProps: {
											accept: documentType.acceptFormat.join(', ')
										}
									}}
									readOnly={form.loading}
									disabled={value.success}
								/>
							)}
						</Grid>
						{value.success ? (
							<Grid item xs={12}>
								<Alert severity="success">Document envoyé avec succès</Alert>
							</Grid>
						) : !value.error && (
							value.progress !== null && (
								<Grid item xs={12}>
									<LinearProgress variant="determinate" value={value.progress * 100} />
								</Grid>
							)
						)}
					</Grid>
				</Grid>
				<Grid item xs={1}>
					<Tooltip title="Supprimer le fichier">
						<span>
							<IconButton
								onClick={onRemove}
								disabled={!canBeDelete} // Ce bouton peut être désactivé
							>
								<IconDelete />
							</IconButton>
						</span>
					</Tooltip>
					<Tooltip title="Ajouter un fichier">
						<span>
							<IconButton onClick={onAdd}>
								<IconAdd />
							</IconButton>
						</span>
					</Tooltip>
				</Grid>
			</Grid>
		</Card>
	);
}

export interface CrudDocumentUploadListProps {
	value: DocumentUpload[];
	onChange: (value: DocumentUpload[]) => any;
	form: Form;
}


export default function CrudDocumentUploadList(
	{
		value,
		onChange,
		form,
	}: CrudDocumentUploadListProps
) {
	const documentTypes = useSelector(state => state.documentType.bySyndicatAndPeriodicity);

	const handleAdd = () => {
		onChange([
			...value,
			{
				documentType: null,
				file: null,
				date: null,
				date2: null,
				year: null,
				progress: null,
				error: null,
				success: false,
			}
		]);
	};
	const handleRemove = (index: number) => {
		value.splice(index, 1);
		onChange([...value]);
	}


	return (
		<Card elevation={3} className="p-2">
			{documentTypes ? (
				value.map((item, i) => (
					<CrudDocumentUploadListItem
						key={i}
						item={item} // Ajoutez ceci pour passer explicitement `item`
						{...model(item, item => {
							value[i] = item;
							onChange([...value]);
						})}
						canBeDelete={value.length > 1}
						onAdd={handleAdd}
						onRemove={() => handleRemove(i)}
						form={form}
					/>
				))
			) : (
				<div className="p-4 u-t-center">
					<CircularProgress size={150} thickness={2} />
				</div>
			)}
		</Card>
	);
}
