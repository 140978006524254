import './View.scss';
import { Card, CardContent, Container, IconButton, Skeleton, Grid, TableRow } from '@mui/material';
import { useConnectedPage, useEffectFirstRender } from '@/libs/hooks';
import { useDispatch, useSelector } from '@/libs/redux';
import { findUserById } from '@/stores/user';
import IconArrowBack from '@mui/icons-material/ArrowBack';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import IconCheck from '@mui/icons-material/Check';
import { hUser } from '@/models';
import { PhoneHelper } from '@/libs/utils/PhoneHelper';

export default function PageUsersView() {

	useConnectedPage();

	const dispatch = useDispatch();
	const user = hUser(useSelector(state => state.user.user));
	const { id } = useParams();
	const [ notFound, setNotFound ] = useState(false);

	useEffectFirstRender(() => {
		(async () => {
			setNotFound(false);
			setNotFound(!(await dispatch(findUserById(+(id as any)))));
		})();
	}, [ id ]);

	return notFound ? (
			<div className="u-t-center p-4">
				Utilisateur non trouvé
			</div>
		) :
		(
			<Container className="p-habilitation-users-View">

				<h2>
					<IconButton color="default" href="/habilitation/users">
						<IconArrowBack/>
					</IconButton>
					&nbsp;Utilisateur {user?.fullName}
				</h2>


				<Grid container spacing={2}>

					<Grid
						item
						xs={12} md={6}
					>
						<Card className="mb-2">
							<CardContent>
								{user ? (
									<Table>
										<TableRow><TableCell><strong>Nom         </strong></TableCell><TableCell>{user?.firstName}</TableCell></TableRow>
										<TableRow><TableCell><strong>Prénom      </strong></TableCell><TableCell>{user?.lastName}</TableCell></TableRow>
										<TableRow><TableCell><strong>Structure   </strong></TableCell><TableCell>{user?.structure}</TableCell></TableRow>
										<TableRow><TableCell><strong>Mail        </strong></TableCell><TableCell>{user?.email}</TableCell></TableRow>
										<TableRow><TableCell><strong>Téléphone 1 </strong></TableCell><TableCell><span className="u-t-nowrap">{PhoneHelper.format(user.phone)}</span></TableCell></TableRow>
										<TableRow><TableCell><strong>Téléphone 2 </strong></TableCell><TableCell><span className="u-t-nowrap">{PhoneHelper.format(user.phon2)}</span></TableCell></TableRow>
										<TableRow><TableCell><strong>Mission   </strong></TableCell><TableCell>{user?.mission}</TableCell></TableRow>
										<TableRow><TableCell><strong>Fonction  </strong></TableCell><TableCell>{user?.fonction}</TableCell></TableRow>
									</Table>
								) : (
									<Skeleton variant="rounded" height={350} />
								)}
							</CardContent>
						</Card>

						<Card>
							<CardContent>
								{user ? (
									<Table>
										<TableRow><TableCell><strong>Admin </strong></TableCell><TableCell>{user?.hasRole('ROLE_ADMIN')  && <IconCheck color="success" />}</TableCell></TableRow>
										<TableRow><TableCell><strong>Fourn </strong></TableCell><TableCell>{user?.hasRole('ROLE_FOURN')  && <IconCheck color="success" />}</TableCell></TableRow>
										<TableRow><TableCell><strong>Syndic</strong></TableCell><TableCell>{user?.hasRole('ROLE_SYNDIC') && <IconCheck color="success" />}</TableCell></TableRow>
										<TableRow><TableCell><strong>SDC   </strong></TableCell><TableCell>{user?.hasRole('ROLE_SDC')    && <IconCheck color="success" />}</TableCell></TableRow>
										<TableRow><TableCell><strong>Immo  </strong></TableCell><TableCell>{user?.hasRole('ROLE_IMMO')   && <IconCheck color="success" />}</TableCell></TableRow>
										<TableRow><TableCell><strong>AS    </strong></TableCell><TableCell>{user?.hasRole('ROLE_AS')     && <IconCheck color="success" />}</TableCell></TableRow>
										<TableRow><TableCell><strong>Obs   </strong></TableCell><TableCell>{user?.hasRole('ROLE_OBS')    && <IconCheck color="success" />}</TableCell></TableRow>
										<TableRow><TableCell><strong>Liq   </strong></TableCell><TableCell>{user?.hasRole('ROLE_LIQ')    && <IconCheck color="success" />}</TableCell></TableRow>
									</Table>
								) : (
									<Skeleton variant="rounded" height={350} />
								)}
							</CardContent>
						</Card>
					</Grid>

					<Grid
						item
						xs={12} md={6}
					>
						<Card>
							<CardContent>
								<CardContent>
									{user ? (
										<Table>
											<TableRow>
												<TableCell><strong>TR11 </strong></TableCell><TableCell>{user.hasSyndicat('SP') && <IconCheck color="success" />}</TableCell>
												<TableCell></TableCell>
											</TableRow>
											<TableRow>
												<TableCell><strong>TR11 </strong></TableCell><TableCell>{user.hasSyndicat('TR11') && <IconCheck color="success" />}</TableCell>
												<TableCell><strong>TR12 </strong></TableCell><TableCell>{user.hasSyndicat('TR12') && <IconCheck color="success" />}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><strong>TR13 </strong></TableCell><TableCell>{user.hasSyndicat('TR13') && <IconCheck color="success" />}</TableCell>
												<TableCell><strong>TR14 </strong></TableCell><TableCell>{user.hasSyndicat('TR14') && <IconCheck color="success" />}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><strong>TR15 </strong></TableCell><TableCell>{user.hasSyndicat('TR15') && <IconCheck color="success" />}</TableCell>
												<TableCell><strong>TR16 </strong></TableCell><TableCell>{user.hasSyndicat('TR16') && <IconCheck color="success" />}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><strong>TR17 </strong></TableCell><TableCell>{user.hasSyndicat('TR17') && <IconCheck color="success" />}</TableCell>
												<TableCell><strong>TR18 </strong></TableCell><TableCell>{user.hasSyndicat('TR18') && <IconCheck color="success" />}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><strong>TR21 </strong></TableCell><TableCell>{user.hasSyndicat('TR21') && <IconCheck color="success" />}</TableCell>
												<TableCell><strong>TR24 </strong></TableCell><TableCell>{user.hasSyndicat('TR24') && <IconCheck color="success" />}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><strong>TR25 </strong></TableCell><TableCell>{user.hasSyndicat('TR25') && <IconCheck color="success" />}</TableCell>
												<TableCell><strong>TR26 </strong></TableCell><TableCell>{user.hasSyndicat('TR26') && <IconCheck color="success" />}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><strong>TR27 </strong></TableCell><TableCell>{user.hasSyndicat('TR27') && <IconCheck color="success" />}</TableCell>
												<TableCell><strong>TR28 </strong></TableCell><TableCell>{user.hasSyndicat('TR28') && <IconCheck color="success" />}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><strong>TR29 </strong></TableCell><TableCell>{user.hasSyndicat('TR29') && <IconCheck color="success" />}</TableCell>
												<TableCell><strong>TR30 </strong></TableCell><TableCell>{user.hasSyndicat('TR30') && <IconCheck color="success" />}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><strong>TR31 </strong></TableCell><TableCell>{user.hasSyndicat('TR31') && <IconCheck color="success" />}</TableCell>
												<TableCell><strong>TR33 </strong></TableCell><TableCell>{user.hasSyndicat('TR33') && <IconCheck color="success" />}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><strong>TR41 </strong></TableCell><TableCell>{user.hasSyndicat('TR41') && <IconCheck color="success" />}</TableCell>
												<TableCell><strong>TR42 </strong></TableCell><TableCell>{user.hasSyndicat('TR42') && <IconCheck color="success" />}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><strong>TR43 </strong></TableCell><TableCell>{user.hasSyndicat('TR43') && <IconCheck color="success" />}</TableCell>
												<TableCell><strong>TR44 </strong></TableCell><TableCell>{user.hasSyndicat('TR44') && <IconCheck color="success" />}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><strong>TR45 </strong></TableCell><TableCell>{user.hasSyndicat('TR45') && <IconCheck color="success" />}</TableCell>
												<TableCell><strong>TR46 </strong></TableCell><TableCell>{user.hasSyndicat('TR46') && <IconCheck color="success" />}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><strong>TR47 </strong></TableCell><TableCell>{user.hasSyndicat('TR47') && <IconCheck color="success" />}</TableCell>
												<TableCell><strong>TR48 </strong></TableCell><TableCell>{user.hasSyndicat('TR48') && <IconCheck color="success" />}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><strong>TR49 </strong></TableCell><TableCell>{user.hasSyndicat('TR49') && <IconCheck color="success" />}</TableCell>
												<TableCell><strong>TR79 </strong></TableCell><TableCell>{user.hasSyndicat('TR79') && <IconCheck color="success" />}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><strong>TR80 </strong></TableCell><TableCell>{user.hasSyndicat('TR80') && <IconCheck color="success" />}</TableCell>
												<TableCell><strong>TR8X </strong></TableCell><TableCell>{user.hasSyndicat('TR8X') && <IconCheck color="success" />}</TableCell>
											</TableRow>
										</Table>
									) : (
										<Skeleton variant="rounded" height={350} />
									)}
								</CardContent>
							</CardContent>
						</Card>
					</Grid>

				</Grid>

			</Container>
		)
	;
}


PageUsersView.getMetadata = () => {
	return {
		rights: [ 'HABILITATION' ]
	}
}
