import {
	FormControl,
	Select as MUISelect,
	SelectProps as MUISelectProps,
	FormControlProps, InputLabel, FormHelperText, MenuItem
} from '@mui/material';
import {
	FieldAroundProps,
	FieldFormProps,
	useFieldAround,
	useFormSelect
} from '@/components/inputs/generic';
import { ReactNode } from 'react';
import './Select.scss';

export interface SelectPropsSpecific<T = any> {
	label?: ReactNode;
	value?: any;
	onChange?: (v: any) => any,
	items: T[],
	itemText?: string;
	itemValue?: string;
	returnObject?: boolean;
	multiple?: boolean;
	slotOption?: (item: T, isSelected: boolean, index: number) =>  ReactNode;
	error?: boolean;
	helperText?: Nullable<string>;
	inputProps?: Partial<MUISelectProps>;
	readOnly?: boolean;
}

export type SelectProps<T = any> = SelectPropsSpecific<T> & FieldFormProps & FieldAroundProps & FormControlProps;

export default function Select<T = any>(
	{
		items,
		itemText = 'text',
		itemValue = 'value',
		returnObject = false,
		multiple = false,
		label,
		rules = [],
		clearable = false,
		appendIcon = null,
		prependIcon = null,
		appendIconProps = {},
		prependIconProps = {},
		appendSlot = null,
		prependSlot = null,
		value,
		onChange,
		ref,
		className = '',
		onBlur = () => {},
		error = false,
		helperText = null,
		inputProps,
		slotOption,
		readOnly = false,
		disabled = false,
		variant,
		...rest
	}: SelectProps<T>
) {

	const generic = useFormSelect({
		items,
		itemText,
		itemValue,
		returnObject,
		multiple,
		value,
		onChange,
		defaultValue: multiple ? [] : null,
		className: `c-inputs-Select ${className}`,
		rules,
		ref,
		error,
		helperText,
	});

	const fielAround = useFieldAround({
		clearable,
		appendIcon,
		prependIcon,
		appendIconProps,
		prependIconProps,
		appendSlot,
		prependSlot,
		markClear: generic.markClear,
	});

	const handleBlur = (e: any) => {
		onBlur(e);
		generic.markTouched();
	};
	
	const handleChange = (event: any) => {
		const value = event.target?.value || '';
		if (multiple) {
			for (const item of generic.value) {
				if (value.indexOf(generic.getFlatValueOnItem(item)) === -1) {
					generic.select(item);
				}
			}
			for (const v of value) {
				const item = generic.findItemFromValue(v);
				if (item && !generic.isSelected(item)) {
					generic.select(item);
				}
			}
		} else {
			generic.select(generic.findItemFromValue(value));
		}
	};
	
	return (
		<FormControl
			fullWidth {...rest}
			className={generic.className}
			error={generic.error}
			ref={generic.ref}
			disabled={disabled}
			variant={variant}
		>
			{!!label && (
				<InputLabel
					variant={variant}
				>
					{label}
				</InputLabel>
			)}
			<MUISelect
				{...generic.props}
				{...fielAround}
				{...inputProps}
				onBlur={handleBlur}
				multiple={multiple}
				value={generic.getFlatValue('')}
				onChange={handleChange}
				disabled={disabled}
				readOnly={readOnly}
				variant={variant}
			>
				{items.map((item, i) => (
					<MenuItem
						key={i}
						value={generic.getFlatValueOnItem(item)}
					>
						{slotOption ? (
							slotOption(item, generic.isSelected(item), i)
						): (
							multiple ? (
								<span>{generic.getFlatTextOnItem(item)}</span>							
							) : (
								<span>{generic.getFlatTextOnItem(item)}</span>	
							)
						)}
					</MenuItem>
				))}
			</MUISelect>
			{!!generic.helperText && <FormHelperText>{generic.helperText}</FormHelperText>}
		</FormControl>
	);
}
