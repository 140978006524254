import { Form } from '@/libs/form/Form';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function useForm(
	{
		fields = {},
		handle,
		genericErrorLabel = null,
		mainErrorField = Form.ROOT_FIELD,
		valid = true,
	}: {
		fields?: Record<string, any>
		handle: (event?: FormEvent) => any,
		genericErrorLabel?: Nullable<string>,
		mainErrorField?: string,
		valid?: boolean,
	}
): Form {

	const { t } = useTranslation();
	const stateIsValid = useState<boolean>(false);
	const stateNoError = useState<boolean>(false);
	const stateLoading = useState<boolean>(false);
	const stateErrors = useState<Record<string, string[]>>({});

	if (!genericErrorLabel) {
		genericErrorLabel = t('validators.main_error');
	}

	const form = useMemo(() => new Form(
		stateIsValid,
		stateNoError,
		stateLoading,
		stateErrors,
		fields,
		genericErrorLabel as string,
		mainErrorField,
		handle,
		valid
	), [
		stateIsValid,
		stateNoError,
		stateLoading,
		stateErrors,
		fields,
		genericErrorLabel,
		mainErrorField,
		handle,
		valid
	]);

	useEffect(() => {
		form.updateState();
	}, [ form ]);

	return form;
}
