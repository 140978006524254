import { buildModelHelper } from '@/libs/modelHelper';
import { RessourcesIndividuelles } from '@/models/RessourcesIndividuelles';

export interface Individu {
	id: number;
	typeIndividuId: number;
	civilite: string;
	nom: string;
	dateNaissance: Nullable<string>;
	lieuNaissance: string;
	nationalite: string;
	activiteStatut: string;
	activiteCommentaire: string;
	ressources: RessourcesIndividuelles;
}

export const hIndividu = buildModelHelper<Individu>({
	get dateNaissance(): Nullable<Date> { return this._self.dateNaissance ? new Date(this._self.dateNaissance) : null; },
	set dateNaissance(value: Nullable<Date>) { this._self.dateNaissance = value ? value.toISOString() : null; },
});
export type HIndividu = ReturnType<typeof hIndividu>;
