import { RouteObject } from 'react-router';
import SaisieRecouvrement from '@/pages/saisie/Recouvrement/Recouvrement';
import SaisieAcquisitions from '@/pages/saisie/Acquisitions/Acquisitions';
import SaisieAccompagnementSocial from '@/pages/saisie/AccompagnementSocial/AccompagnementSocial';
import Iframe from '@/pages/Iframe/Iframe';

export const saisie: RouteObject[]&any = [
	{
		path: '/saisie/recouvrement',
		element: <SaisieRecouvrement/>
	},
	{
		path: '/saisie/acquisitions',
		element: <SaisieAcquisitions/>
	},
	{
		path: '/saisie/accompagnement-social',
		element: <SaisieAccompagnementSocial/>
	},
	{
		path: '/saisie/synthese-recouvrement',
		element: <Iframe/>
	},
];
