import { buildModelHelper } from '@/libs/modelHelper';

export interface AcquisitionDia {
    id: string;
    acquisitionId: Nullable<number>;
    date: Nullable<string>;
    montant: Nullable<number>;
    prixm2: Nullable<number>;
    surfaceCarrez: Nullable<number>;
    montantCommissionAgence: Nullable<number>;
    chargeCommission: Nullable<string>;
    nomAcquereur: Nullable<string>;
    dateDemandeUniqueVisite: Nullable<string>;
    dateDemandeDocs: Nullable<string>;
    dateReceptionDocs: Nullable<string>;
    decisionPreemptionEpf: Nullable<string>;
    decisionPreemptionEpfAdju: Nullable<string>;
    dateCourrierPreemption: Nullable<string>;
    retourProprietaire: Nullable<string>;
    commentaire: Nullable<string>;
}

export const hAcquisitionDia = buildModelHelper<AcquisitionDia>({
    get date(): Nullable<Date> { return this._self.date ? new Date(this._self.date) : null; },
    set date(value: Nullable<Date>) { this._self.date = value ? value.toISOString() : null; },
    get dateDemandeUniqueVisite(): Nullable<Date> { return this._self.dateDemandeUniqueVisite ? new Date(this._self.dateDemandeUniqueVisite) : null; },
    set dateDemandeUniqueVisite(value: Nullable<Date>) { this._self.dateDemandeUniqueVisite = value ? value.toISOString() : null; },
    get dateDemandeDocs(): Nullable<Date> { return this._self.dateDemandeDocs ? new Date(this._self.dateDemandeDocs) : null; },
    set dateDemandeDocs(value: Nullable<Date>) { this._self.dateDemandeDocs = value ? value.toISOString() : null; },
    get dateReceptionDocs(): Nullable<Date> { return this._self.dateReceptionDocs ? new Date(this._self.dateReceptionDocs) : null; },
    set dateReceptionDocs(value: Nullable<Date>) { this._self.dateReceptionDocs = value ? value.toISOString() : null; },
    get dateCourrierPreemption(): Nullable<Date> { return this._self.dateCourrierPreemption ? new Date(this._self.dateCourrierPreemption) : null; },
    set dateCourrierPreemption(value: Nullable<Date>) { this._self.dateCourrierPreemption = value ? value.toISOString() : null; },
});

export type HAcquisitionDia = ReturnType<typeof hAcquisitionDia>;
