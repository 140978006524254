import axios, { AxiosProgressEvent, AxiosResponse } from 'axios';
import { Store } from '@reduxjs/toolkit';
import { hToken } from '@/models';
import { checkToken, logout } from '@/stores/token';

export interface CallOptions {
	unloggedCall?: boolean,
	fullUrl?: boolean
}

export class Caller {

	private _store: Store;
	
	public constructor(store: Store) {
		this._store = store;
	}

	public get baseUri(): string {
		return process.env.REACT_APP_CLIENT_API_URI as string;
	}

	private assertResponse(result: AxiosResponse) {
		if (!(result.status >= 200 && result.status < 300)) {
			throw result;
		}
	}

	private async buildHeaders(headers: any, options: CallOptions): Promise<any> {
		const token = options.unloggedCall ? null : hToken(await this._store.dispatch(checkToken() as any));
		return {
			...(token?.isValid ? { Authorization: `BEARER ${token.id}` } : {}),
			...headers
		};
	}

	private async parseError(e: any): Promise<any> {
		if (e.status === 401 || (e.response && e.response.status === 401)) {
			await this._store.dispatch(logout() as any);
		}
	}

	/**
	 * caller.get(`/api/user/{id}`, User);
	 * caller.get(`/api/user/{id}`);
	 */
	public async get<T = any>(path: string, headers: any = {}, options: CallOptions = {}): Promise<T> {
		try {
			const result = await axios.get((options.fullUrl ?  '' : this.baseUri) + path, {
				headers: await this.buildHeaders({
					accept: 'application/json',
					...headers
				}, options)
			});
			this.assertResponse(result);
			return result.data;
		} catch (e) {
			await this.parseError(e);
			throw e;
		}
	}


	public async post<T = any>(path: string, data: any = null, headers: any = {}, options: CallOptions = {}): Promise<T> {
		try {
			const result = await axios.post((options.fullUrl ?  '' : this.baseUri) + path, data, {
				headers: await this.buildHeaders({
					accept: 'application/json',
					...headers
				}, options)
			});
			this.assertResponse(result);
			return result.data;
		} catch (e) {
			await this.parseError(e);
			throw e;
		}
	}

	public async postMultipart<T = any>(
		path: string,
		data: any,
		onUploadProgress: (percent: number) => any = () => {},
		headers: any = {},
		options: CallOptions = {}
	): Promise<T> {
		try {
			const formData = new FormData();
			for (const [name, value] of Object.entries(data)) {
				formData.append(name, value as any);
			}
			const result = await axios.post((options.fullUrl ?  '' : this.baseUri) + path, formData, {
				headers: await this.buildHeaders({
					'Content-Type': 'multipart/form-data',
					accept: 'application/json',
					...headers
				}, options),
				onUploadProgress: (event: AxiosProgressEvent) => onUploadProgress(event.total ? event.loaded / event.total : 0),
			});
			this.assertResponse(result);
			return result.data;
		} catch (e) {
			await this.parseError(e);
			throw e;
		}
	}

	public async put<T = any>(path: string, data: any, headers: any = {}, options: CallOptions = {}): Promise<T> {
		try {
			if (data && typeof data.toPost === 'function') {
				data = data.toPost();
			}
			const result = await axios.put((options.fullUrl ?  '' : this.baseUri) + path, data, {
				headers: await this.buildHeaders({
					accept: 'application/json',
					...headers
				}, options)
			});
			this.assertResponse(result);
			return result.data;
		} catch (e) {
			await this.parseError(e);
			throw e;
		}
	}

	public async patch<T = any>(path: string, data: any = {}, headers: any = {}, options: CallOptions = {}): Promise<T> {
		try {
			const result = await axios.patch((options.fullUrl ?  '' : this.baseUri) + path, data, {
				headers: await this.buildHeaders({
					accept: 'application/json',
					...headers
				}, options)
			});
			this.assertResponse(result);
			return result.data;
		} catch (e) {
			await this.parseError(e);
			throw e;
		}
	}

	public async delete<T = any>(path: string, headers: any = {}, options: CallOptions = {}): Promise<T> {
		try {
			const result = await axios.delete((options.fullUrl ?  '' : this.baseUri) + path, {
				headers: await this.buildHeaders({
					accept: 'application/json',
					...headers
				}, options)
			});
			this.assertResponse(result);
			return result.data;
		} catch (e) {
			await this.parseError(e);
			throw e;
		}
	}
}
