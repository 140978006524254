import { Card, CircularProgress, Table, TableContainer } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Direction, ResultType } from '@/models';
import TableBody from '@mui/material/TableBody';
import { Fragment, ReactNode } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { CardProps } from '@mui/material/Card/Card';
import './SortableTable.scss';

export interface SortablePageable {
	order: string;
	setOrder: (v: string) => any;
	direction: Direction,
	setDirection: (v: Direction) => any,
	rowsPerPage: number;
	setRowsPerPage: (v:number) => any,
	page: number;
	setPage: (v:number) => any,
}

export interface SortableTableHead {
	name: ReactNode;
	sort?: string;
	stickyLeft?: boolean;
	stickyRight?: boolean;
	style?: any;
	align?: string
}

export interface SortableTableProps<T = any> extends CardProps {
	heads: SortableTableHead[];
	result?: Nullable<ResultType<T>|T[]>;
	maxHeight?: string;
	renderItem: (item: T, i: number, list: T[]) => ReactNode,

	pageable?: Nullable<SortablePageable>;
}

export default function SortableTable<T = any>(
	{
		heads,
		result = null,
		renderItem,
		className = '',
		pageable = null,
		...rest
	}: SortableTableProps<T>
) {

	const resultList = result ? (
		Array.isArray(result as any) ? {
			data: (result as T[]),
			total: (result as T[]).length
		} : (result as ResultType<T>)
	) : null;
	
	const changeOrder = (value: string) => {
		if (pageable) {
			if (value === pageable.order) {
				if (pageable.direction === Direction.ASC) {
					pageable.setDirection(Direction.DESC);
				} else {
					pageable.setDirection(Direction.ASC);
				}
			} else {
				pageable.setOrder(value);
				pageable.setDirection(Direction.ASC);
			}
		}
	}
	
	return (
		<Card
			{...rest}
			className={[
				'c-tables-SortableTable',
				className,
			].join(' ')}
		>
			{resultList?.data ? (
				<>
					<TableContainer>
						<Table stickyHeader size="small">
	
							<TableHead>
								<TableRow>
									{heads.map((h, i) => (
										<TableCell
											key={i}
											className={[
												h.stickyLeft ? 'u-cell-sticky-left' : '',
												h.stickyRight ? 'u-cell-sticky-right' : '',
												h.align ? `u-t-${h.align}` : '',
											].join(' ')}
											style={{
												...(h.style || {})
											}}
										>
											{
												pageable && h.sort ? (
													<TableSortLabel
														style={{
															whiteSpace: 'nowrap',
														}}
														active={pageable.order === h.sort}
														hideSortIcon={pageable.order !== h.sort}
														direction={pageable.direction}
														onClick={() => changeOrder(h.sort as string)}
													>
														{h.name}
													</TableSortLabel>
												) : (
													<>{h.name}</>
												)
											}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{resultList?.data.map((item, i, list) => (
									<Fragment key={i}>
										{renderItem(item, i, list)}
									</Fragment>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					{pageable && (
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, 100]}
							component="div"
							count={resultList?.total || 0}
							rowsPerPage={pageable.rowsPerPage}
							page={pageable.page}
							onPageChange={(event, newPage) => pageable.setPage(newPage)}
							onRowsPerPageChange={event => {
								pageable.setRowsPerPage(+event.target.value);
								pageable.setPage(0);
							}}
						/>
					)}
				</>
			) : (
				<div className="p-4 u-t-center">
					<CircularProgress size={150} thickness={2} />
				</div>
			)}
		</Card>
	);
}
