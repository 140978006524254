import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { Acquisition, Direction, ResultType } from '@/models';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';
import { AcquisitionVisite } from '@/models/AcquisitionVisite';
import { AcquisitionDia } from '@/models/AcquisitionDia'; // Importer le modèle AcquisitionDia
import { AnyAction } from '@reduxjs/toolkit';

export class AcquisitionState {
    public acquisitions: Nullable<ResultType<Acquisition>> = null;
    public acquisition: Nullable<Acquisition> = null;
}

export enum ActionTypes {
    ADD_VISITE = 'ADD_VISITE',
    UPDATE_VISITE = 'UPDATE_VISITE',
    REMOVE_VISITE = 'REMOVE_VISITE',
    ADD_DIA = 'ADD_DIA',
    UPDATE_DIA = 'UPDATE_DIA',
    REMOVE_DIA = 'REMOVE_DIA',
}

export default {
    state: AcquisitionState,
    reducer: mapReducer([
        'acquisitions',
        'acquisition',
    ], (state: AcquisitionState, action: AnyAction) => {
        switch (action.type) {
            case ActionTypes.ADD_VISITE:
                if (state.acquisition) {
                    state.acquisition.acquisitionVisites = [
                        ...(state.acquisition.acquisitionVisites || []),
                        action.visite
                    ];
                    state.acquisition = { ...state.acquisition };
                }
                break;
            case ActionTypes.UPDATE_VISITE:
                if (state.acquisition) {
                    const index = state.acquisition.acquisitionVisites?.findIndex((v: AcquisitionVisite) => v.id === action.visite.id);
                    if (index !== undefined && index !== -1) {
                        state.acquisition.acquisitionVisites[index] = action.visite;
                    }
                    state.acquisition = { ...state.acquisition };
                }
                break;
            case ActionTypes.REMOVE_VISITE:
                if (state.acquisition) {
                    const index = state.acquisition.acquisitionVisites?.findIndex((v: AcquisitionVisite) => v.id === action.visite.id);
                    if (index !== undefined && index !== -1) {
                        state.acquisition.acquisitionVisites.splice(index, 1);
                    }
                    state.acquisition = { ...state.acquisition };
                }
                break;
            case ActionTypes.ADD_DIA:
                if (state.acquisition) {
                    state.acquisition.acquisitionDias = [
                        ...(state.acquisition.acquisitionDias || []),
                        action.dia
                    ];
                    state.acquisition = { ...state.acquisition };
                }
                break;
            case ActionTypes.UPDATE_DIA:
                if (state.acquisition) {
                    const index = state.acquisition.acquisitionDias?.findIndex((d: AcquisitionDia) => d.id === action.dia.id);
                    if (index !== undefined && index !== -1) {
                        state.acquisition.acquisitionDias[index] = action.dia;
                    }
                    state.acquisition = { ...state.acquisition };
                }
                break;
            case ActionTypes.REMOVE_DIA:
                if (state.acquisition) {
                    const index = state.acquisition.acquisitionDias?.findIndex((d: AcquisitionDia) => d.id === action.dia.id);
                    if (index !== undefined && index !== -1) {
                        state.acquisition.acquisitionDias.splice(index, 1);
                    }
                    state.acquisition = { ...state.acquisition };
                }
                break;
        }
        return state;
    }),
} as SubStore;

// MUTATIONS

export const setAcquisitions = (acquisitions: Nullable<ResultType<Acquisition>>) => (dispatch: DispatchApp) => {
    dispatch({ state: AcquisitionState, type: MapReducerAction.MAP, acquisitions });
};
export const setAcquisition = (acquisition: Nullable<Acquisition>) => (dispatch: DispatchApp) => {
    dispatch({ state: AcquisitionState, type: MapReducerAction.MAP, acquisition });
};

export const addVisite = (visite: AcquisitionVisite) => (dispatch: DispatchApp) => {
    dispatch({ state: AcquisitionState, type: ActionTypes.ADD_VISITE, visite });
};

export const updateVisite = (visite: AcquisitionVisite) => (dispatch: DispatchApp) => {
    dispatch({ state: AcquisitionState, type: ActionTypes.UPDATE_VISITE, visite });
}

export const removeVisite = (visite: AcquisitionVisite) => (dispatch: DispatchApp) => {
    dispatch({ state: AcquisitionState, type: ActionTypes.REMOVE_VISITE, visite });
}

export const addDia = (dia: AcquisitionDia) => (dispatch: DispatchApp) => {
    dispatch({ state: AcquisitionState, type: ActionTypes.ADD_DIA, dia });
};

export const updateDia = (dia: AcquisitionDia) => (dispatch: DispatchApp) => {
    dispatch({ state: AcquisitionState, type: ActionTypes.UPDATE_DIA, dia });
};

export const removeDia = (dia: AcquisitionDia) => (dispatch: DispatchApp) => {
    dispatch({ state: AcquisitionState, type: ActionTypes.REMOVE_DIA, dia });
};

// ACTIONS

export const findAcquisitions = (
    page: number,
    order: string,
    direction: Direction,
    limit: number,
    filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<Acquisition>> => {
    const acquisitions = await caller.get<ResultType<Acquisition>>(`/api/acquisitions${UriHelper.queries({
        page,
        order,
        direction,
        limit,
        filters: JSON.stringify(filters)
    })}`);

    dispatch(setAcquisitions(acquisitions));

    return acquisitions;
});

export const findAcquisition = (
    id: number,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Acquisition> => {
    dispatch(setAcquisition(null));
    const acquisition = await caller.get<Acquisition>(`/api/acquisitions/${id}`);
    dispatch(setAcquisition(acquisition));
    return acquisition;
});

export const findAcquisitionById = (
    id: string,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Acquisition> => {
    if (getState().acquisition.acquisition?.id) {
        dispatch(setAcquisition(null));
    }
    const acquisition = await caller.get<Acquisition>(`/api/acquisitions/${encodeURIComponent(id)}`);
    dispatch(setAcquisition(acquisition));
    return acquisition;
});

export const postAcquisition = (
    acquisition: Partial<Acquisition>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Acquisition> => {
    acquisition = await caller.post<Acquisition>(`/api/acquisitions`, acquisition);
    dispatch(setAcquisition(acquisition as Acquisition));
    return acquisition as Acquisition;
});

export const putAcquisition = (
    acquisition: Partial<Acquisition>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Acquisition> => {
    acquisition = await caller.put<Acquisition>(`/api/acquisitions/${acquisition.id}`, acquisition);
    dispatch(setAcquisition(acquisition as Acquisition));
    return acquisition as Acquisition;
});

export const deleteAcquisitionById = (
    acquisitionId: number,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<void> => {
    await caller.delete<Acquisition>(`/api/acquisitions/${acquisitionId}`);
    return;
});

export const deleteAllAcquisitions = () => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<void> => {
    await caller.delete<Acquisition>(`/api/acquisitions`);
    return;
});

// Actions pour AcquisitionDia

export const putAcquisitionDia = (
    acquisitionDia: Partial<AcquisitionDia>
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<AcquisitionDia> => {
    const dia = await caller.put<AcquisitionDia>(`/api/acquisition-dia/${acquisitionDia.id}`, {
        date: acquisitionDia.date,
        commentaire: acquisitionDia.commentaire,
        montant: acquisitionDia.montant,
        prixm2: acquisitionDia.prixm2,
        surfaceCarrez: acquisitionDia.surfaceCarrez,
        montantCommissionAgence: acquisitionDia.montantCommissionAgence,
        chargeCommission: acquisitionDia.chargeCommission,
        nomAcquereur: acquisitionDia.nomAcquereur,
        dateDemandeUniqueVisite: acquisitionDia.dateDemandeUniqueVisite,
        dateDemandeDocs: acquisitionDia.dateDemandeDocs,
        dateReceptionDocs: acquisitionDia.dateReceptionDocs,
        decisionPreemptionEpf: acquisitionDia.decisionPreemptionEpf,
        decisionPreemptionEpfAdju: acquisitionDia.decisionPreemptionEpfAdju,
        dateCourrierPreemption: acquisitionDia.dateCourrierPreemption,
        retourProprietaire: acquisitionDia.retourProprietaire,
        acquisitionId: acquisitionDia.acquisitionId,
    });
    dispatch(updateDia(dia));
    return dia;
});

export const postAcquisitionDia = (acquisition: Acquisition, acquisitionDia: Partial<AcquisitionDia>) =>
    loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<AcquisitionDia> => {
        const dia = await caller.post<AcquisitionDia>(`/api/acquisition-dia/${encodeURIComponent(acquisition.id)}`, {
            date: acquisitionDia.date,
            commentaire: acquisitionDia.commentaire,
            montant: acquisitionDia.montant,
            prixm2: acquisitionDia.prixm2,
            surfaceCarrez: acquisitionDia.surfaceCarrez,
            montantCommissionAgence: acquisitionDia.montantCommissionAgence,
            chargeCommission: acquisitionDia.chargeCommission,
            nomAcquereur: acquisitionDia.nomAcquereur,
            dateDemandeUniqueVisite: acquisitionDia.dateDemandeUniqueVisite,
            dateDemandeDocs: acquisitionDia.dateDemandeDocs,
            dateReceptionDocs: acquisitionDia.dateReceptionDocs,
            decisionPreemptionEpf: acquisitionDia.decisionPreemptionEpf,
            decisionPreemptionEpfAdju: acquisitionDia.decisionPreemptionEpfAdju,
            dateCourrierPreemption: acquisitionDia.dateCourrierPreemption,
            retourProprietaire: acquisitionDia.retourProprietaire,
            acquisitionId: acquisitionDia.acquisitionId,
        });
        if (getState().acquisition.acquisition?.id === acquisition.id) {
            dispatch(addDia(dia));
        }
        return dia;
    });

export const deleteAcquisitionDia = (
    acquisitionDia: AcquisitionDia,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<AcquisitionDia> => {
    const dia = await caller.delete<AcquisitionDia>(`/api/acquisition-dia/${acquisitionDia.id}`);
    dispatch(removeDia(acquisitionDia));
    return dia;
});

