import { RouterProvider, createBrowserRouter, useOutlet, useNavigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useDispatch, useSelector } from '@/libs/redux';
import { useEffectFirstRender } from '@/libs/hooks';
import { setMetadata } from '@/stores/page';

const WrapperApp = (
	{
		App
	}: {
		App: any
	}
) => {
	const outlet = useOutlet();
	const store = useStore();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const getMetadata = outlet?.props?.children?.props?.children?.type?.getMetadata || (() => ({}));
	const onAfterLoad = outlet?.props?.children?.props?.children?.type?.onFirstRender || (() => ({}));
	const firstRender = useSelector(state => state.firstRender);
	const metadata = useSelector(state => state.page.metadata);

	let ctx = {
		store,
		navigate
	};
	const  handlerFirstRender = async () => {
		if (onAfterLoad) {
			const metadata = await onAfterLoad(ctx);
			dispatch(setMetadata(metadata, true));
		}
	}
	
	useEffect(() => {
		(async () => {
			setMetadata(null);
			dispatch(setMetadata(await getMetadata(ctx)));
			if (firstRender) {
				await handlerFirstRender();
			}
		})();
	}, [ window.location.href ])

	useEffectFirstRender(async () => {
		await handlerFirstRender()
	});
	
	return metadata ? <App metadata={metadata} /> : <></>;
}

export function wrapperRouter (App: any, routes: RouteObject[]): any {
	return () => {
		const router = createBrowserRouter([
			{
				path: '/',
				element: <WrapperApp App={App} />,
				children: routes
			},
		]);
		return <RouterProvider router={router} />;
	}
}
