import { Button, Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import IconFeedback from '@mui/icons-material/Feedback'
import './Feedback.scss';
import { useState } from 'react';
import TextField from '@/components/inputs/TextField/TextField';
import { useForm } from '@/libs/form';
import { model } from '@/libs/candy';
import Form from '@/components/forms/Form/Form';
import { LoadingButton } from '@mui/lab';
import { useValidators } from '@/libs/validators';
import { useDispatch } from '@/libs/redux';
import { createFeedback } from '@/stores/feedback';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';

export function Feedback()  {
	
	const validators = useValidators();
	const dispatch = useDispatch();
	const [ open, setOpen ] = useState(false);
	const [ message, setMessage ] = useState('');
	
	const form = useForm({
		async handle() {
			await form.call(async () => {
				await dispatch(createFeedback({
					message
				}));
				form.clear();
				setOpen(false);
			});
		},
		fields: {
			message: {
				...model(message, setMessage),
				label: 'Saisissez votre retour',
				rules: [
					validators.required()
				]
			}
		}
	});
	
	return (
		<>
			<div className="c-Feedback">
				<Tooltip arrow title="Retour Utilisateur">
					<IconButton size="large" onClick={() => setOpen(true)}>
						<IconFeedback />
					</IconButton>
				</Tooltip>
			</div>
			<Dialog open={open} onClose={() => setOpen(false)} maxWidth={'md'}>
				<DialogTitle>
					Faites nous un retour.
				</DialogTitle>
				<DialogContent>
					<Form form={form} className="py-2">
						<FormMainErrors form={form} /> 
						<div>
							<TextField
								{...form.fields.message}
								multiline
								rows={5}
							/>
						</div>
						<div className="mt-2 u-d-flex u-justify-space-between">
							<Button onClick={() => setOpen(false)}>
								Fermer
							</Button>
							<LoadingButton
								type="submit"
								variant="contained"
								loading={form.loading}
							>
								Envoyer
							</LoadingButton>
						</div>
					</Form>
				</DialogContent>
			</Dialog>
		</>
	);
}
