import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { Direction, Proprietaire, ResultType } from '@/models';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class ProprietaireState {
	public proprietaires: Nullable<ResultType<Proprietaire>> = null;
}

export default {
	state: ProprietaireState,
	reducer: mapReducer([
		'proprietaires',
	]),
} as SubStore;

// MUTATIONS

export const setProprietaires = (proprietaires: Nullable<ResultType<Proprietaire>>) => (dispatch: DispatchApp) => {
	dispatch({ state: ProprietaireState, type: MapReducerAction.MAP, proprietaires });
};

// ACTIONS

export const findProprietaires = (
	page: number,
	order: string,
	direction: Direction,
	limit: number,
	filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<Proprietaire>> => {
	const proprietaires = await caller.get<ResultType<Proprietaire>>(`/api/proprietaires${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);

	dispatch(setProprietaires(proprietaires));

	return proprietaires;
});
