import Select, { SelectProps } from '@/components/inputs/Select/Select';
import { useSelector } from '@/libs/redux';
import { useDispatch } from '@/libs/redux';
import { findDocumentTypeAll } from '@/stores/documentType';
import { useEffectOnce } from 'react-use';
import { Native } from '@/libs/utils';
import { DocumentType } from '@/models';

export interface CrudDocumentTypeSelectProp extends Omit<SelectProps, 'items'|'itemText'|'itemValue'> {
	list?: Nullable<DocumentType[]>;
}

export default function CrudDocumentTypeSelect(
	{
		list,
		...props
	}: CrudDocumentTypeSelectProp
) {
	const distpatch = useDispatch();
	const all = useSelector(state => state.documentType.all);
	const items = Native.defined(list) ? list : all;
	
	useEffectOnce(() => {
		distpatch(findDocumentTypeAll());
	});
	
	return items ? (
		<Select
			{...props}
			items={items}
			itemValue="id"
			itemText="name"
		/>
	) : <></>;
}
