import { buildModelHelper } from '@/libs/modelHelper';

// src/models/accompagnementSocial.ts

export interface AccompagnementSocial {
	id: string;
	referentId: string;
	structureEnCharge: string;
	date: string | null;
	originePriseContact: string;
	planBdf: string;
	nombreDossiersDeposes: string;
	typeAccompagnement: string;
	protocole: string;
	fsl: string;
	fslVerse: number | null;
	actionLogement: string;
	ouvertureDroits: string;
	aidesExceptionnelles: string;
	insertionProfessionnelle: string;
	synthese: string;
	numeroSecu: string;
	numeroCaf: string;
	anneeAcquisition: string | null;
	prixAchat: number | null;
	montantCreditImmo: number | null;
	dureeCreditImmo: string | null;
	banqueCreditImmo: string;
	montantCreditConso: number | null;
	montantDetteFiscale: number | null;
	montantAutresDettes: number | null;
  }

export const hAccompagnementSocial = buildModelHelper<AccompagnementSocial>({
	get date(): Nullable<Date> { return this._self.date ? new Date(this._self.date) : null; },
    get dateEvalSociale(): Nullable<Date> { return this._self.dateEvalSociale ? new Date(this._self.dateEvalSociale) : null; },
    set dateEvalSociale(value: Nullable<Date>) { this._self.dateEvalSociale = value ? value.toISOString() : null; },
    get dateDernierBdf(): Nullable<Date> { return this._self.dateDernierBdf ? new Date(this._self.dateDernierBdf) : null; },
    set dateDernierBdf(value: Nullable<Date>) { this._self.dateDernierBdf = value ? value.toISOString() : null; },
});

export type HAccompagnementSocial = ReturnType<typeof hAccompagnementSocial>;
