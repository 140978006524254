import { HTMLAttributes, ReactNode } from 'react';
import './FormError.scss';

export interface FormErrorProps extends HTMLAttributes<HTMLSpanElement> {
	/**
	 * Contenu de l'erreur
	 */
	children: ReactNode
}

export default function FormError(
	{
		children,
		className = '',
		...args
	}: FormErrorProps
) {
	return (
		<span
			{...args}
			className={'c-forms-FormError ' + className}
		>
			{children}
		</span>
	);
}
