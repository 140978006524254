import { FieldAroundProps, FieldFormProps, useFormSelect } from '@/components/inputs/generic';
import {
	Radio as MUIRadio,
	RadioProps as MUIRadioProps,
	FormControl,
	FormControlLabel,
	FormControlProps,
	FormHelperText
} from '@mui/material';
import { ChangeEvent, ReactNode } from 'react';
import './Radio.scss';

export interface RadioPropsSpecific {
	label?: ReactNode;
	value: any;
	onChange: (v: any) => any,
	item: any,
	itemText?: string;
	itemValue?: string;
	returnObject?: boolean;
	error?: boolean;
	helperText?: Nullable<string>;
	inputProps?: Partial<MUIRadioProps>;
	onChangeOrigin?: (event: any) => any,
	readOnly?: boolean;
}

export type RadioProps = RadioPropsSpecific & FieldFormProps & FieldAroundProps & FormControlProps;

export default function Radio(
	{
		item = null,
		itemText = 'text',
		itemValue = 'value',
		returnObject = false,
		rules = [],
		clearable = false,
		appendIcon = null,
		prependIcon = null,
		appendIconProps = {},
		prependIconProps = {},
		appendSlot = null,
		prependSlot = null,
		value,
		onChange,
		onChangeOrigin = () => {},
		defaultValue = '',
		ref,
		label = '',
		className = '',
		onBlur = () => {},
		error = false,
		helperText = null,
		inputProps = {},
		readOnly = false,
		disabled = false,
		...rest
	}: RadioProps
) {
	const generic = useFormSelect({
		items: [ item ],
		itemText,
		itemValue,
		returnObject,
		multiple: false,
		value,
		onChange,
		defaultValue: null,
		className: `c-inputs-Radio ${className}`,
		rules,
		ref,
		error,
		helperText,
	});

	const isSelected = generic.isSelected(item);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (readOnly || disabled) {
			return;
		}
		if (onChangeOrigin) {
			onChangeOrigin(e);
		}
		const checked = e.target.checked;
		if (checked) {
			generic.select(item);
		}
	};
	
	return (
		<>
			<FormControl
				{...rest}
				className={generic.className}
				error={generic.error}
				ref={generic.ref}
				disabled={disabled}
			>
				<FormControlLabel
					control={(
						<MUIRadio
							checked={isSelected}
							{...generic.props}
							{...inputProps}
							onChange={handleChange}
							readOnly={readOnly}
							disabled={disabled}
						/>
					)}
					label={label}
				/>
				{!!generic.helperText && <FormHelperText>{generic.helperText}</FormHelperText>}
			</FormControl>
		</>
	);
}
