import { FormControl, InputLabel, MenuItem, Select, Button, Tooltip, Typography } from '@mui/material';
import { JSXElementConstructor, ReactElement, ReactFragment, ReactPortal } from 'react';
import { toDate } from '@/libs/utils';
import { formatNumber } from '@/libs/utils/NumberHelper';
import React from 'react';
import { getBackgroundColor, getFilteredLogements, filterLogements, groupLogements, getOrderedPositions, getMaxEtage, getOrderEtage, sortData } from './Filtre/Filtre';

// ====================================== Information lier Au Data du Synoptique ======================================
export function checkData(filtre: any, Data: any, Colors: any) {
    if (filtre === 'null') {
        return { null: '' };
    }

    // Mettre en majuscule la première lettre de filtre
    filtre = filtre.charAt(0).toUpperCase() + filtre.slice(1);

    // Vérifier si le filtre commence par 'categorie' et ajuster la majuscule
    if (filtre.toLowerCase().startsWith('categorie')) {
        filtre = 'Categorie' + filtre.slice(9);
    }

    // Vérifier si le filtre est une catégorie
    if (Data.some((logement: { hasOwnProperty: (arg0: string) => any; }) => logement.hasOwnProperty(`categorie${filtre}`))) {
        filtre = `Categorie${filtre}`;
    }

    // Vérifier si la valeur du filtre contient un nombre
    const filterNumber = filtre.replace('Categorie' || 'categorie', '');
    const values = Data.map((logement: { [x: string]: any; }) => logement[filterNumber?.toLowerCase()]);
    const containsNumber = values.some((value: string) => /[0-9]/.test(value));

    // Vérifie si le filtre est une catégorie et ajoute le préfixe 'nom' si nécessaire
    const filtre2 = filtre.startsWith('Categorie') ? `nom${filtre}` : filtre;

    // Obtenir les valeurs uniques
    const filteredValues = Data.flatMap((logement: any) => logement[filtre]);
    const uniqueValues: any = Array.from(new Set(filteredValues).values());

    // Filtrer les couleurs une seule fois
    const degraderColors = Colors.filter((color: { id: string; }) => color.id.startsWith('E'));
    const regularColors = Colors.filter((color: { id: string; }) => color.id.startsWith('B'));
    const selectedColors = filtre.startsWith('Categorie') && containsNumber ? degraderColors : regularColors;

    // Créer un mappage unique des couleurs
    const colorByValue: Record<any, any> = {};
    const uniqueColors = Array.from(new Set(selectedColors.map((color: { hex: any; }) => color.hex)).values());

    if (filtre2.startsWith('nomCategorie')) {
        const customSort = (a: any, b: any) => {
            if (a.charAt(0) === '⓪' || b.charAt(0) === '⓪') {
                return a.charAt(0).localeCompare(b.charAt(0));
            }
            return a.localeCompare(b);
        };

        const values: any = Array.from(new Set(Data.map((logement: { [x: string]: any; }) => logement[`nom${filtre}`]))).sort(customSort);
        values.forEach((value: string, index: number) => {
            colorByValue[value] = uniqueColors[index % uniqueColors.length];
        });
        return colorByValue;
    }

    uniqueValues.forEach((value: string, index: number) => {
        colorByValue[value] = uniqueColors[index % uniqueColors.length];
    });

    return colorByValue;
};

export function processData(Data: any) {
    let test = false;
    for (let i = 0; i <= 1; i++) {
        Data.data.forEach((item: any) => {
            const isUniquePosition = Data.data.filter((dataItem: any) =>
                dataItem.position === item.position &&
                dataItem.batiment === item.batiment &&
                dataItem.syndicat === item.syndicat
            ).length === 1;
            if (item.hasOwnProperty('impaye') && test) {
                // item.impaye = `Impayé : ${formatNumber(item.impaye, { decimal: 0 })} €`;
                const test = 'Impayé : ';
                item.impaye = `${test}${formatNumber(item.impaye, { decimal: 0 })} €`;
            }
            if (item.hasOwnProperty('soldeSp') && item.soldeSp !== null && test) {
                item.soldeSp = `${formatNumber(item.soldeSp)} €`;
            }
            if (item.hasOwnProperty('soldeS2') && item.soldeS2 !== null && test) {
                item.soldeS2 = `${formatNumber(item.soldeS2)} €`;
            }
            if (item.hasOwnProperty('tantieme') && test) {
                item.tantieme = formatNumber(item.tantieme)
            }
            if (item.hasOwnProperty('retard') && test) {
                item.retard = `${formatNumber(item.retard, { decimal: 0 })} Trim.`;
            }
            if (item.hasOwnProperty('position') && item.position !== null) {
                if (!/\d$/.test(item.position)) {
                    item.position = item.position + "1";
                }
            }
            if (item.hasOwnProperty('position') && item.position !== null && item.id !== 'TR46-0340' && test && isUniquePosition) {
                if (/\d$/.test(item.position)) {
                    if (isUniquePosition) {
                        item.position = item.position.replace(/\d+$/, (match: any) => String(Number(match) - 1));
                    }
                }
            }
            if (item.hasOwnProperty('position') && item.position === null) {
                const index = Data.data.findIndex((value: any) => value.id === item.id);
                Data.data.splice(index, 1);
            }
        });
        test = true;
    }
}

// ====================================== Générer l'entete du Synoptique ======================================
export function TitleDate(props: any) {
    return (
        <h1 className='Margin' style={{ display: props.hide ? 'none' : '' }}>
            {props.selectedEtatMajs ? `${props.selectedEtatMajs.syndicat} - ${props.selectedEtatMajs.nomSyndicat} ${new Date().toLocaleDateString('fr-FR')}` : ''}
        </h1>
    );
}

export function Indication() {
    return (
        <div className='Margin Indication'>
            <p>Les indications de position sont relatives à la sortie d'ascenseur,<br /> lorsque l'escalier (<b>E</b>) est en face de soi. <b>G</b> : Gauche - <b>FG</b> : Face Gauche - <b>FD</b> : Face Droite - <b>D</b> : Droite</p>
            <img src='/images/SynoptiquePosition.png' className='image' />
        </div>
    );
}

export function DateMaJ(props: any) {
    const { selectedEtatMajs } = props;

    // Préparation des données avant le return
    const hasDateMajPresence = selectedEtatMajs?.dateMajPresence;
    const hasDateMajBalance = selectedEtatMajs?.dateMajBalance;

    const dateMajPresenceText = hasDateMajPresence ? (
        <>MàJ Présence : <b>{toDate(selectedEtatMajs.dateMajPresence)}</b></>
    ) : null;
    const dateMajBalanceText = hasDateMajBalance ? (
        <>MàJ Compta : <b>{toDate(selectedEtatMajs.dateMajBalance)}</b></>
    ) : null;

    return (
        <>
            {selectedEtatMajs && (
                <div className='Margin Aligner'>
                    {dateMajPresenceText && <p>{dateMajPresenceText}</p>}
                    {dateMajBalanceText && <p>{dateMajBalanceText}</p>}
                </div>
            )}
        </>
    );
}

// ====================================== Générer les cellules du tableau ======================================
export function CellEtage(index: number, Value: { etage: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined; }, Valuee: { etage: any; }[]) {
    if (index > 0 && Value.etage === Valuee[index - 1].etage) {
        return null;
    } else {
        return (
            <td className='border etage'>
                <tr className='borderInternes etageInt'>{Value.etage}</tr>
            </td>
        );
    }
}

// Fonction pour créer le contenu du tooltip
function createTooltipContent(logement: any) {
    const tooltipItems = [
        logement.tantiemes && <><br /><b>Type :</b> <span>{logement.type}</span></>,
        logement.tantiemes && <><br /><b>Tant. CCG :</b> {logement.tantiemes}</>,
        logement.tantiemes && <><br /><b>Surface :</b> {logement.surface} m²</>,
        logement.proprietaireName && <><br />{logement.proprietaireName}</>,
        logement.occupation && <><br />{logement.occupation}</>,
        logement.adresseRef && <><br />{logement.adresseRef}</>,
        logement.impaye && (
            <><br /><br /><b>Impayé {logement.syndicat} :</b> {typeof logement.impaye === 'string'
                ? logement.impaye.replace('Impayé :', '')
                : logement.impaye.toString()}
            </>
        ),
        logement.retard && <><br /><b>Retard {logement.syndicat} :</b> {logement.retard}</>,
        logement.soldeSp && <><br /><b>Solde SP :</b> {logement.soldeSp}</>,
        logement.soldeS2 && <><br /><b>Solde tous SDC :</b> {logement.soldeS2}</>
    ];

    return (
        <div className='Tooltip-logement'>
            <Typography color="inherit">{logement.id} : </Typography>
            <p>Propriétaire :</p>
            {tooltipItems.map((item, index) => item && <React.Fragment key={index}>{item}</React.Fragment>)}
        </div>
    );
}

export function CellInformationLogements(
    order: { [x: string]: number; },
    Halfsize: string,
    Fullsize: string,
    logementsSansE: any[],
    logementsEtage: any[],
    filtre: string,
    filtre2: string | number | null,
    filtre3: string | number | null,
    Bg_color: { [x: string]: any; }
) {
    const filteredLogements = getFilteredLogements(logementsSansE, order);

    return (
        <>
            {filteredLogements.map((logement, i) => {
                const occurrences = logementsEtage.filter((item) => item.position === logement.position);
                const estDouble = occurrences.length > 1;
                const backgroundColor = getBackgroundColor(logement, filtre, Bg_color);

                // Préparer les filtres affichés
                const filtresAffiches = [filtre2, filtre, filtre3].map(f => f !== null && logement[f]);

                return (
                    <React.Fragment key={i}>
                        {logement.isEmpty !== true ? (
                            <Tooltip
                                disableInteractive
                                arrow
                                title={createTooltipContent(logement)}
                            >
                                <td className='border' key={i} style={{ width: estDouble ? Halfsize : Fullsize, backgroundColor }}>
                                    <div className='borderInternesBot bold Titlesmall'>{logement.numero} - {logement.type} ({logement.surface}m²)</div>
                                    <div className='NewInfo' style={{ width: estDouble ? Halfsize : Fullsize }}>
                                        {filtresAffiches.map((filtreVal, index) => filtreVal && <div key={index} className='bold'>{filtreVal}</div>)}
                                    </div>
                                </td>
                            </Tooltip>
                        ) : (
                            <td className='border'>
                                <tr className='bold' />
                            </td>
                        )}
                    </React.Fragment>
                );
            })}
        </>
    );
}

// LOGEMENTS
function renderLogementsTranche(
    logementsGrouped: any[],
    orderedItems: any,
    orderEtage: any,
    Bg_color: any,
    Halfsize: string,
    Fullsize: string,
    filtre: string,
    filtre2: string,
    filtre3: string,
    orderG: any,
    orderD: any
) {
    return logementsGrouped.map((Valuee: any) => {
        const orderedItems: { [key: string]: number } = {
            "G1": 0,
            "G2": 1,
            "G3": 2,
            "FG3": 3,
            "FG2": 4,
            "FG1": 5,
            "E": 6,
            "FD1": 7,
            "FD2": 8,
            "FD3": 9,
            "D3": 10,
            "D2": 11,
            "D1": 12,
        };
        const filteredAndOrderedData = getOrderedPositions(Valuee, orderedItems);
        return (
            <div key={Valuee[0].adresse}>
                <tr>
                    <th>
                        <div>{Valuee[0].adresse}</div>
                    </th>
                </tr>
                <tr>
                    {filteredAndOrderedData.map((Value: any, index: number) => (
                        <td key={index} className={`border bold etageTitle ${Value === 'E' ? "etage" : ""}`}>{Value}</td>
                    ))}
                </tr>
                {Valuee
                    .sort((a: { etage: string }, b: { etage: string }) => orderEtage[b.etage] - orderEtage[a.etage] || a.etage.localeCompare(b.etage))
                    .map((Value: any, index: number) => {
                        const logementsEtage = Valuee.filter((logement: { etage: string }) => logement.etage === Value.etage);
                        const logementsSansE = [
                            ...logementsEtage,
                            ...filteredAndOrderedData
                                .filter(position => !logementsEtage.some((logement: { position: any }) => logement.position === position))
                                .map(position => ({ position, isEmpty: true }))
                        ].filter(logement => logement.position !== "E");

                        return (
                            <tr key={index}>
                                {index > 0 && Value.etage === Valuee[index - 1].etage ? null : (
                                    <>
                                        {CellInformationLogements(orderG, Halfsize, Fullsize, logementsSansE, logementsEtage, filtre, filtre2, filtre3, Bg_color)}
                                    </>
                                )}
                                {CellEtage(index, Value, Valuee)}
                                {index > 0 && Value.etage === Valuee[index - 1].etage ? null : (
                                    <>
                                        {CellInformationLogements(orderD, Halfsize, Fullsize, logementsSansE, logementsEtage, filtre, filtre2, filtre3, Bg_color)}
                                    </>
                                )}
                            </tr>
                        );
                    })}
            </div>
        );
    });
}

function processLogement(
    logement: { syndicat: string; batiment: string; position: string },
    Data: any[],
    props: any,
    orderG: any,
    orderD: any,
    Halfsize: string,
    Fullsize: string,
    Bg_color: any,
    filtre: string,
    filtre2: string | null,
    filtre3: string | null
) {
    if (logement.position === null) return null;

    const logementsBatiment = Data.filter((item: { syndicat: string; batiment: string }) => item.batiment === logement.batiment && item.syndicat === logement.syndicat);
    const filteredLogements = filterLogements(logementsBatiment, props.selectedTranche, props.selectedAdresse);

    if (filteredLogements.length === 0) return null;

    const logementsGroupedArray = groupLogements(filteredLogements, props.selectedAdresse);
    const maxEtage = getMaxEtage(logementsBatiment);
    const orderEtage = getOrderEtage(maxEtage);

    const selectedSyndicat = props.selectedTranche;
    const selectedMaj = props.Majs.data.find((item: { syndicat: string }) => item.syndicat === selectedSyndicat);
    return (
        <table className='TableauForm' key={logement.batiment + logement.syndicat}>
            <thead>
                <tr>
                    <th>
                        <div>{selectedMaj ? selectedMaj.syndicat + " - " + selectedMaj.nomSyndicat : ''}</div>
                        <div>{logement.batiment}</div>
                    </th>
                </tr>
            </thead>
            <tbody className='StreetStyle'>
                {renderLogementsTranche(
                    logementsGroupedArray,
                    props.orderedItems || {},  // Ensure orderedItems is not null
                    orderEtage || {},          // Ensure orderEtage is not null
                    Bg_color || {},            // Ensure Bg_color is not null
                    Halfsize || '',            // Provide a default value if null
                    Fullsize || '',           // Provide a default value if null
                    filtre || '',             // Provide a default value if null
                    filtre2 || '',            // Provide a default value if null
                    filtre3 || '',            // Provide a default value if null
                    orderG || {},             // Ensure orderG is not null
                    orderD || {}              // Ensure orderD is not null
                )}
            </tbody>
        </table>
    );
}

// Main RenderTable function
export function RenderTable({
    Data,
    props,
    orderG,
    orderD,
    Halfsize,
    Fullsize,
    Bg_color,
    filtre,
    filtre2,
    filtre3
}: any) {
    const sortedData = sortData(Data, { syndicat: '', batiment: '' });

    // Prepare all processed data
    const processedTables = sortedData.map((logement: { syndicat: string; batiment: string, position: string }, index: number) => {
        if (index === 0 || logement.batiment !== sortedData[index - 1].batiment || logement.syndicat !== sortedData[index - 1].syndicat) {
            return processLogement(logement, Data, props, orderG, orderD, Halfsize, Fullsize, Bg_color, filtre, filtre2, filtre3);
        }
        return null;
    }).filter(Boolean);

    return <>{processedTables}</>;
}