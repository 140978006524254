import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { ResultType } from '@/models';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';
import { LogModeration } from '@/models/LogModeration';
import { Direction } from '@/models/Direction';

export class LogModerationState {
	public logModerations: Nullable<ResultType<LogModeration>> = null;
}

export default {
	state: LogModerationState,
	reducer: mapReducer([
		'logModerations',
	]),
} as SubStore;


export const setLogModerations = (logModerations: Nullable<ResultType<LogModeration>>) => (dispatch: DispatchApp) => {
	dispatch({ state: LogModerationState, type: MapReducerAction.MAP, logModerations });
};

export const findLogModerations = (
	page: number,
	order: string,
	direction: Direction,
	limit: number,
	filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<LogModeration>> => {
	const logs = await caller.get<ResultType<LogModeration>>(`/api/log-moderations${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);
	
	dispatch(setLogModerations(logs));
	
	return logs;
});
