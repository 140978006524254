import { buildModelHelper } from '@/libs/modelHelper';
import { Lot } from '@/models/Lot';
import { Syndicat } from '@/models/Syndicat';
import { TypeLot } from '@/models/TypeLot';
import { Acquisition } from '@/models/Acquisition';
import { Propriete } from '@/models/Propriete';
import { Proprietaire } from '@/models/Proprietaire';

export interface TriLot {
	espace: any;
	id: string;
	syndicat: Syndicat;
	numero: any;
	batiment: any;
	adresse: any;
	escalier: any;
	etage: Nullable<string>;
	position: string;
	type: Nullable<string>;
	typeId: Nullable<string>;
	surface: Nullable<number>;
	tantiemes: Nullable<number>;
	proprietaire: Nullable<string>;
	nomProprietaire: Nullable<string>;
	nombrePieces: Nullable<number>;
	lot: Nullable<Lot>;	
	triLot: Nullable<TriLot>;
	proprietes: Propriete[]
	currentProprietaire: Nullable<Proprietaire>;
	// critereLogement: any;
}

export const hTriLot = buildModelHelper<TriLot>({
});

export type HTriLot = ReturnType<typeof hTriLot>;
