import { LayoutProps } from './index';
import SnackMessages from '@/components/SnackMessages/SnackMessages';

export default function EmptyLayout(
	{
		children
	}: LayoutProps
) {
	return (
		<>
			<SnackMessages/>
			<main>
				{children}
			</main>
		</>
	);
}
