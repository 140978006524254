import { Container, Link } from '@mui/material';
import './NotFound.scss';

export default function PageNotFound() {
	return (
		<Container className="p-NotFound">
			<h2>Page non trouvée</h2>
			<p>
				<Link href="/home">Retourner à l'accueil</Link>
			</p>
		</Container>
	)
}
