import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { Direction, Feedback, ResultType } from '@/models';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class FeedbackState {
	public feedbacks: Nullable<ResultType<Feedback>> = null;
}

export default {
	state: FeedbackState,
	reducer: mapReducer([
		'feedbacks',
	]),
} as SubStore;

// MUTATIONS

export const setFeedbacks = (feedbacks: Nullable<ResultType<Feedback>>) => (dispatch: DispatchApp) => {
	dispatch({ state: FeedbackState, type: MapReducerAction.MAP, feedbacks });
};

// ACTIONS

export const findFeedbacks = (
	page: number = 0,
	order: string = '',
	direction: Direction = Direction.ASC,
	limit: number = 200,
	filters: any
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<Feedback>> => {
	const feedbacks = await caller.get<ResultType<Feedback>>(`/api/feedbacks${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);
	
	dispatch(setFeedbacks(feedbacks));
	
	return feedbacks;
});

export const createFeedback = (
	feedback: Partial<Feedback>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<Feedback>> => {
	return await caller.post<ResultType<Feedback>>(`/api/feedbacks`, feedback);
});

export const patchFeedbackStatus = (
	feedback: Partial<Feedback>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<Feedback>> => {
	return await caller.patch<ResultType<Feedback>>(`/api/feedbacks/status/${feedback.id}`, feedback);
});
