import { RouteObject } from 'react-router';
import Iframe from '@/pages/Iframe/Iframe';

export const consultation: RouteObject[]&any = [
	{
		path: '/consultation/syndicats',
		element: <Iframe/>
	},
	{
		path: '/consultation/coproprietaires',
		element: <Iframe/>
	},
	{
		path: '/consultation/immobilier',
		element: <Iframe/>
	},
];
