import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { Telephone, Referent, ResultType } from '@/models';
import { Direction } from '@/models/Direction';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class TelephoneState {
}

export default {
    state: TelephoneState,
    reducer: mapReducer([]),
} as SubStore;

// ACTIONS
export const putTelephone = (
    telephone: Partial<Telephone>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Telephone> => {
    return await caller.put<Telephone>(`/api/telephones/${telephone.id}`, {
        telephone1: telephone.telephone1,
        commentaire: telephone.commentaire,
		source: telephone.source,
		pertinence: telephone.pertinence,
    });
});

export const postTelephone = (
    referent: Referent | string, // Accepter soit un objet soit un id
    telephone: Partial<Telephone>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Telephone> => {
    let referentId;

    if (typeof referent === 'string') {
        referentId = referent;
    } else if (typeof referent === 'object' && referent !== null) {
        // Vérifiez que referent a un id
        if (!referent.id) {
            console.error('Referent missing id:', referent);
            throw new Error('Referent object is missing id property.');
        }

        // Utilisez directement l'ID du référent
        referentId = referent.id;
    } else {
        console.error('Invalid referent:', referent);
        throw new Error('Invalid referent. Expected an object or a string but received a non-valid type.');
    }

    // Vérifiez si referentId est défini
    if (!referentId) {
        console.error('referent object:', referent);
        throw new Error('referentId is undefined. Please check the referent object.');
    }

    return await caller.post<Telephone>(`/api/telephones/${encodeURIComponent(referentId)}`, {
        telephone1: telephone.telephone1,
        commentaire: telephone.commentaire,
        source: telephone.source,
		pertinence: telephone.pertinence,
    });
});


export const deleteTelephone = (
    telephone: Telephone,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Telephone> => {
    return await caller.delete<Telephone>(`/api/telephones/${telephone}`);
});
