import Select, { SelectProps } from '@/components/inputs/Select/Select';
import { enumToValueText } from '@/libs/utils/EnumHelper';
import { DocumentTypeType } from '@/models';
import { useTranslation } from 'react-i18next';

export interface CrudDocumentTypeTypeTypeSelectProp extends Omit<SelectProps, 'items'|'itemText'|'itemValue'> {
}

export default function CrudDocumentTypeTypeTypeSelect(
	props: CrudDocumentTypeTypeTypeSelectProp
) {
	const { t } = useTranslation();

	return (
		<Select
			items={enumToValueText(t, 'models.document_type_type.values', DocumentTypeType)}
			{...props}
		/>
	);
}
