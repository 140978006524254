import { ReactNode } from 'react';
import { useSelector } from '@/libs/redux';
import { Container } from '@mui/material';
import { useEffectFirstRender } from '@/libs/hooks';
import { useNavigate } from 'react-router-dom';
import MainLoader from '@/components/MainLoader/MainLoader';

export interface CheckRightOutletProps {
	metadata?: any;
	children: ReactNode;
}


export default function CheckRightsOutlet(
	{
		metadata = {},
		children,
	}: CheckRightOutletProps
) {
	const navigate = useNavigate();
	const connected = useSelector(state => state.user.connected);
	const token = useSelector(state => state.token.token);
	const firstRender = useSelector(state => state.firstRender);

	
	useEffectFirstRender(() => {
		if (
			(
				metadata.rights?.length ||
				metadata.rights === true
			) && 
			!connected
		) {
			navigate('/');
		}
	}, [ metadata.rights, connected ]);
	
	if (!metadata) {
		return (
			<MainLoader/>
		);
	}
	
	if (metadata.rights && metadata.rights?.length) {
		if (
			token &&
			connected
		) {
			if (metadata.rights === true || token.rights.filter(r => metadata.rights.includes(r)).length) {
				return <>{children}</>;
			} 
			return (
				<Container className="u-t-center">
					<p>
						Page inaccessible
					</p>
				</Container>
			);
		}
		return (
			<MainLoader/>
		);
	}

	return <>{children}</>;
}
