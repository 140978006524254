import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { EtatMaj, ResultType } from '@/models';
import { Direction } from '@/models/Direction';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class EtatMajState {
	public etatMajs: Nullable<ResultType<EtatMaj>> = null;
}

export default {
	state: EtatMajState,
	reducer: mapReducer([
		'etatMajs',
	]),
} as SubStore;

// MUTATIONS

export const setEtatMajs = (etatMajs: Nullable<ResultType<EtatMaj>>) => (dispatch: DispatchApp) => {
	dispatch({ state: EtatMajState, type: MapReducerAction.MAP, etatMajs });
};

// ACTIONS

export const findEtatMajs = (
	page: number,
	order: string,
	direction: Direction,
	limit: number,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<EtatMaj>> => {
	const etatMajs = await caller.get<ResultType<EtatMaj>>(`/api/etat-majs${UriHelper.queries({
		page,
		order,
		direction,
		limit,
	})}`);
	
	dispatch(setEtatMajs(etatMajs));
	
	return etatMajs;
});
