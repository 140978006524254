import { useCallback } from 'react';
import { useSelector } from '@/libs/redux';

export function useIsGranted() {

	const connected = useSelector(state => state.user.connected);
	const token = useSelector(state => state.token.token);

	return useCallback((rights?: Nullable<string[]>|boolean) => {

		if (rights === null || typeof rights === 'undefined') {
			return true;
		}

		if (connected && token) {
			if (rights === true || (Array.isArray(rights) && rights.length === 0)) {
				return true;
			}
			if (Array.isArray(rights) && rights.filter(r => token.rights.includes(r)).length) {
				return true;
			}
		}

		return rights === false;
	}, [ connected, token ]);
}
