import { useEffect } from 'react';
import { useSelector } from '@/libs/redux';

export function useEffectFirstRender(callback: () => any, effectParam: any[] = []) {
	const firstRender = useSelector(state => state.firstRender);
	useEffect(() => {
		if (firstRender) {
			callback();
		}
	}, [ firstRender, ...effectParam ]); // eslint-disable-line react-hooks-static-deps/exhaustive-deps
}
