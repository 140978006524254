import { Container } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export default function MainLoader() {
	return (
		<Container>
			<div className="p-4 u-t-center">
				<CircularProgress size={150} thickness={2} />
			</div>
		</Container>
	);
}
