import { DocumentStatus } from '@/models';
import { SlotStatus } from '@/models/SlotStatus';
import { useTranslation } from 'react-i18next';
import './Show.scss';
import { Chip, ChipProps } from '@mui/material';

export interface CrudSlotsShowProps extends ChipProps {
	value?: Nullable<DocumentStatus|SlotStatus>;
}

export default function CrudSlotStatusShow(
	{
		value,
		className = '',
		...rest
	}: CrudSlotsShowProps
) {
	const { t } = useTranslation();
	
	let color: ChipProps['color'] = 'primary';
	if (value === SlotStatus.WAITING) {
		color = 'warning';
	}
	if (value === SlotStatus.IN_PROGRESS) {
		color = 'info';
	}
	if (value === SlotStatus.REFUSED) {
		color = 'error';
	}
	if (value === SlotStatus.VALIDATED) {
		color = 'success';
	}
	
	return value ? (
		<Chip
			label={t(`models.slot_status.values.${value}`)}
			color={color}
			{...rest}
			className={[
				'c-crud-slotStatus-Show',
				className,
			].join(' ')}
		/>
	) : <></>;
}
