import './Upload.scss';
import { Button, Card, Container, Dialog, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { useCallback, useState } from 'react';
import { model } from '@/libs/candy';
import Grid from '@mui/material/Unstable_Grid2';
import CrudUserSyncicatSelect from '@/components/crud/userSyncicat/Select/Select';
import { DocumentTypeMode, FileSlotPeriodicity, HDocument, hSlot, Slot, SlotStatus, UserSyncicat } from '@/models';
import { useConnectedPage, useEffectOnChange, useIsGranted, useSnackMessage } from '@/libs/hooks';
import { useDispatch, useSelector } from '@/libs/redux';
import { findSlotListByBetweenDate } from '@/stores/slotList';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import { toDate } from '@/libs/utils';
import CrudDocumentUpload from '@/components/crud/document/Upload/Upload';
import CrudSlotStatusShow from '@/components/crud/slotStatus/Show/Show';
import IconVisibility from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
import { findRelanceSlotListByBetweenDate } from '@/stores/relanceSlotList';

export interface PageBaseUploadItemProps {
	item: Slot,
	onUploaded: (syndicat: Nullable<UserSyncicat>) => any,
	disable?: Nullable<boolean>,
}

export function PageBaseUploadItem(
	{
		item,
		onUploaded,
	}: PageBaseUploadItemProps
) {

	const isGranted = useIsGranted();
	const slot = hSlot(item);
	const token = useSelector(state => state.token.token);
	const [dialogSendFile, setDialogSendFile] = useState(false);
	return (
		<TableRow>
			<TableCell style={{ maxWidth: '250px' }}>
				{slot.fileSlot.documentType.name}
			</TableCell>
			<TableCell>
				{slot.getPeriodicity() || <div className="u-t-center">-</div>}
			</TableCell>
			<TableCell>
				{slot.fileSlot.periodicity !== FileSlotPeriodicity.NONE ? toDate(slot.date) : <div className="u-t-center">-</div>}
			</TableCell>
			<TableCell>
				{slot.documents.map((document: HDocument) => (
					<div key={document.id}>
						{(() => {
							//Solution temporaire pour télécharger les fichiers TESTER EN DEV // PROD A DETERMINER
							const baseHref = document.file.media.getHref({ token });
							const parts = baseHref.split('/');
							parts.pop();
							const newId = document.id;

							const newUrl = token
								? `${parts.join('/')}/${newId}?_token=${token.id}`
								: null;
							return (
								<div>
									{newUrl && (
										<>
											{isGranted() ?
												<>
													<a target="_blank" href={newUrl}>{document.fileName}</a>
													{document.isPDF && (
														<span className="pl-1">
															<IconButton color="primary" target="_blank" href={`${newUrl}&view=1`}>
																<IconVisibility />
															</IconButton>
														</span>
													)}
												</>
												:
												<Tooltip title="Vous n'avez pas les droits nécessaires pour télécharger ce document.">
													<span>{document.fileName}</span>
												</Tooltip>
											}
										</>
									)}
								</div>
							);
						})()}
					</div>
				))}
			</TableCell>
			<TableCell>
				<CrudSlotStatusShow value={slot.status} />
			</TableCell>
			<TableCell className="u-cell-sticky-right u-d-flex u-align-start">
				<Button
					size="small"
					variant="contained"
					color="secondary"
					onClick={() => setDialogSendFile(true)}
				>
					Déposer un fichier
				</Button>
				<Dialog
					open={dialogSendFile}
					onClose={() => setDialogSendFile(false)}
					maxWidth={'md'}
				>
					<CrudDocumentUpload
						syndicat={slot.fileSlot.syndicat}
						periodicity={slot.fileSlot.periodicity}
						documentType={slot.fileSlot.documentType}
						date={slot.fileSlot.periodicity === FileSlotPeriodicity.QUARTERLY && slot.fileSlot.documentType.mode === DocumentTypeMode.DATE ? slot.dateLess15 : slot.start}
						date2={slot.end}
						year={slot.year}
						onClose={() => setDialogSendFile(false)}
						onUploaded={onUploaded}
					/>
				</Dialog>
			</TableCell>
		</TableRow>
	);
}


export interface PageBaseStatusItemItemHeadProps {
	item: Slot;
	rowSpan?: number;
}

export function PageBaseStatusItemItemHead(
	{
		item,
		rowSpan = 1
	}: PageBaseStatusItemItemHeadProps
) {
	const { t } = useTranslation();
	const slot = hSlot(item);

	return (
		<>
			<TableCell style={{ maxWidth: '250px' }} rowSpan={rowSpan}>
				{slot.fileSlot.documentType.name}
			</TableCell>
			<TableCell rowSpan={rowSpan}>
				{slot.getPeriodicity()}
			</TableCell>
			<TableCell rowSpan={rowSpan}>
				{toDate(slot.date)}
			</TableCell>
		</>
	);
}

export default function PageBaseUpload() {

	useConnectedPage();

	const startInitial = new Date();
	const endInitial = new Date();
	startInitial.setMonth(endInitial.getMonth() - 3);
	endInitial.setDate(startInitial.getDate() + 14);

	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const [dialogSendFile, setDialogSendFile] = useState(false);
	const [syndicat, setSyndicat] = useState<Nullable<UserSyncicat>>(null);
	const [start, setStart] = useState<Nullable<Date>>(startInitial);
	const [end, setEnd] = useState<Nullable<Date>>(endInitial);
	const slotList = useSelector(state => 
		state.slotList.slotList?.slots?.filter(slot => slot.status !== SlotStatus.VALIDATED) || []
	  );
	  const relanceSlotList = useSelector(state => 
		state.relanceSlotList.relanceSlotList?.slots?.filter(slot => slot.status !== SlotStatus.VALIDATED) || []
	  );
	  
	

	const isDisplay = syndicat && start && end;
	const isDisplayRelance = isDisplay && !!relanceSlotList?.length;

	const refresh = useCallback(async () => {
		try {
			if (syndicat && start && end) {
				await dispatch(findSlotListByBetweenDate(start, end, { syndicat: [syndicat] }));
				await dispatch(findRelanceSlotListByBetweenDate(start, end, { syndicat: [syndicat] }));
			}
		} catch (e) {
			console.error(e);
			pushMessage('Une erreur est survenue', 'error');
		}
	}, [syndicat, start, end]);

	useEffectOnChange(() => {
		refresh();
	}, [refresh]);

	const relanceSlotsFiltered = relanceSlotList
	.filter(slot => !syndicat?.length || syndicat.indexOf(slot.fileSlot.syndicat) !== -1);
  
	const handleUpload = (newSyndicat: Nullable<UserSyncicat>) => {
		if (!newSyndicat || newSyndicat === syndicat) {
			refresh();
		} else {
			setSyndicat(newSyndicat);
		}
	};

	return (
		<Container className="p-base-Upload">
			<h2>
				Dépôt de fichiers
				<Button
					style={{ float: 'right' }}
					variant="contained"
					onClick={() => setDialogSendFile(true)}
				>
					Déposer un fichier
				</Button>
				<Dialog
					open={dialogSendFile}
					onClose={() => setDialogSendFile(false)}
					maxWidth={'md'}
				>
					<CrudDocumentUpload
						syndicat={syndicat}
						onClose={() => setDialogSendFile(false)}
						onUploaded={handleUpload}
					/>
				</Dialog>
			</h2>

			<Card className="u-clearfix p-2 mb-2" elevation={4}>
				<Grid container spacing={1}>
					<Grid
						xs={12} md={4}
					>
						<CrudUserSyncicatSelect
							{...model(syndicat, setSyndicat)}
							label="Sélectionnez un syndicat"
							filtered
						/>
					</Grid>
					{/*<Grid*/}
					{/*	xs={12} md={4}*/}
					{/*>*/}
					{/*	<DatePicker*/}
					{/*		{...model(start, setStart)}*/}
					{/*		label="Du"*/}
					{/*	/>*/}
					{/*</Grid>*/}
					{/*<Grid*/}
					{/*	xs={12} md={4}*/}
					{/*>*/}
					{/*	<DatePicker*/}
					{/*		{...model(end, setEnd)}*/}
					{/*		label="Au"*/}
					{/*	/>*/}
					{/*</Grid>*/}

				</Grid>
			</Card>

			{isDisplayRelance && (
				<>
					<h4 className="mt-5">Fichier relancé</h4>
					<SortableTable
						heads={[
							{ name: 'Document', style: { maxWidth: '250px' } },
							{ name: 'Période' },
							{ name: 'Échéance d\'envoi' },
							{ name: 'Fichiers' },
							{ name: 'Statut' },
							{ name: 'Actions', stickyRight: true },
						]}
						result={relanceSlotsFiltered}
						renderItem={(item) => (
							<PageBaseUploadItem
								item={item}
								onUploaded={handleUpload}
							/>
						)}
					/>
				</>
			)}
			<h4 className="mt-5">Fichier à envoyer</h4>
			{isDisplay && (
				<SortableTable
					heads={[
						{ name: 'Document', style: { maxWidth: '250px' } },
						{ name: 'Période' },
						{ name: 'Échéance d\'envoi' },
						{ name: 'Fichiers' },
						{ name: 'Statut' },
						{ name: 'Actions', stickyRight: true },
					]}
					result={slotList}
					renderItem={item => (
						<PageBaseUploadItem
							item={item}
							onUploaded={handleUpload}
						/>
					)}
				/>
			)}

		</Container>
	);
}

PageBaseUpload.getMetadata = () => {
	return {
		title: 'Dépôt de données',
		rights: ['BASE_UPLOAD', 'BASE_DOWNLOAD'],
	}
}
