import { Native } from '@/libs/utils';
import { FormRule } from '@/libs/form';
import { Validators } from '@/libs/validators/useValidators';

function validate(value: string, numClasses: number): boolean {
	let classes = 0;
	if (/[A-Z]/.test(value)) {
		classes++;
	}
	if (/[a-z]/.test(value)) {
		classes++;
	}
	if (/[0-9]/.test(value)) {
		classes++;
	}
	if (/[^A-Za-z0-9]/.test(value)) {
		classes++;
	}
	return classes >= numClasses;
}

export default function passwordClass(
	this: Validators,
	{
		message,
		numClasses = 1
	}: {
		message?: string,
		numClasses?: number
	} = {}): FormRule {

	return (v: string) =>
		Native.empty(v) ||
		validate(v as string, numClasses) ||
		this.t('validators.passwordClass', { numClasses })
	;
}
