import { FormControl, FormControlProps, FormHelperText, InputLabel } from '@mui/material';
import { FieldAroundProps, FieldFormProps, useFieldAround, useFieldForm } from '@/components/inputs/generic';
import { HTMLAttributes, ReactNode } from 'react';
import './GroupField.scss';

export interface GroupFieldSpecific<T = any> {
	label?: ReactNode;
	value?: any;
	error?: boolean;
	helperText?: Nullable<string>;
	inputProps?: HTMLAttributes<HTMLDivElement>;
}

export type SelectProps<T = any> = GroupFieldSpecific<T> & FieldFormProps & FieldAroundProps & FormControlProps;

export default function GroupField<T = any>(
	{
		label,
		rules = [],
		clearable = false,
		appendIcon = null,
		prependIcon = null,
		appendIconProps = {},
		prependIconProps = {},
		appendSlot = null,
		prependSlot = null,
		value,
		onChange,
		ref,
		className = '',
		onBlur = () => {},
		error = false,
		helperText = null,
		disabled = false,
		variant,
		inputProps = {},
		children,
		...rest
	}: SelectProps<T>
) {

	const generic = useFieldForm({
		className: `c-inputs-GroupField ${className}`,
		rules,
		ref,
		value,
		onChange,
		error,
		helperText,
	});

	const fielAround = useFieldAround({
		clearable,
		appendIcon,
		prependIcon,
		appendIconProps,
		prependIconProps,
		appendSlot,
		prependSlot,
		markClear: generic.markClear,
	});

	const handleBlur = (e: any) => {
		onBlur(e);
		generic.markTouched();
	};
	
	return (
		<FormControl
			fullWidth {...rest}
			className={generic.className}
			error={generic.error}
			ref={generic.ref}
			disabled={disabled}
			variant={variant}
		>
			{!!label && (
				<InputLabel
					variant={variant}
					className="c-inputs-GroupField-label"
				>
					{label}
				</InputLabel>
			)}
			<div
				{...inputProps}
				className={[
					'c-inputs-GroupField-content',
					inputProps.className || ''
				].join(' ')}
			>
				{children}
			</div>
			{!!generic.helperText && <FormHelperText>{generic.helperText}</FormHelperText>}
		</FormControl>
	);
}
