import { buildModelHelper } from '@/libs/modelHelper';
import { RecouvrementRecordSuccession } from '@/models/RecouvrementRecordSuccession';
import { RecouvrementRecordTypeProcedure } from '@/models/RecouvrementRecordTypeProcedure';
import { RecouvrementRecordTypeExecution } from '@/models/RecouvrementRecordTypeExecution';
import { RecouvrementRecordEtatSi } from '@/models/RecouvrementRecordEtatSi';
import { RecouvrementRecordProcedureDordre } from '@/models/RecouvrementRecordProcedureDordre';
import { RecouvrementRecordStatutPortage } from '@/models/RecouvrementRecordStatutPortage';
import { UserSyncicat } from '@/models/UserSyncicat';
import { RecouvrementRecordPlanBdf } from '@/models/RecouvrementRecordPlanBdf';
import { RecouvrementRecordHypotheque } from '@/models/RecouvrementRecordHypotheque';

export interface RecouvrementRecord {
	id: number;
	proprietaireId: string;
	syndicatId: UserSyncicat;
	dateMaj: Nullable<string>;
	relance: Nullable<boolean>;
	med: Nullable<boolean>;
	relanceAmed: Nullable<boolean>;
	echeancier: Nullable<string>;
	succession: Nullable<RecouvrementRecordSuccession>;
	typeProcedure: Nullable<RecouvrementRecordTypeProcedure>;
	hypotheque: Nullable<RecouvrementRecordHypotheque>;
	dateAssignation: Nullable<string>;
	dateAudience: Nullable<string>;
	dateJugement: Nullable<string>;
	dateExecution: Nullable<string>;
	typeExecution: Nullable<RecouvrementRecordTypeExecution>;
	dateVoteSi: Nullable<string>;
	etatSi: Nullable<RecouvrementRecordEtatSi>;
	dateAudienceOrientation: Nullable<string>;
	dateAudienceAdjudication: Nullable<string>;
	dateCpvsi: Nullable<string>;
	procedureDordre: Nullable<RecouvrementRecordProcedureDordre>;
	precoAmiable: Nullable<boolean>;
	precoProcedure: Nullable<boolean>;
	precoAccompSocial: Nullable<boolean>;
	precoPortageAdj: Nullable<boolean>;
	precoPortageAmia: Nullable<boolean>;
	precoSi: Nullable<boolean>;
	precoRelogement: Nullable<boolean>;
	statutPortage: Nullable<RecouvrementRecordStatutPortage>;
	conclusionChop: Nullable<string>;
	commentaire: Nullable<string>;
	planBdf: Nullable<RecouvrementRecordPlanBdf>;
	respectDossierSurend: Nullable<string>;
	organismeSuiviSocial: Nullable<string>;
	etatSuiviSocial: Nullable<string>;
	procedureAvancement: Nullable<string>;
	executionAvancement: Nullable<string>;
	origineSi: Nullable<string>;
	protocole: Nullable<boolean>;
	commandementDePayer: Nullable<boolean>;
}

export const hRecouvrementRecord = buildModelHelper<RecouvrementRecord>({
	get dateMaj                 (): Nullable<Date> { return this._self.dateMaj                  ? new Date(this._self.dateMaj                 ) : null; },
	get dateAssignation         (): Nullable<Date> { return this._self.dateAssignation          ? new Date(this._self.dateAssignation         ) : null; },
	get dateAudience            (): Nullable<Date> { return this._self.dateAudience             ? new Date(this._self.dateAudience            ) : null; },
	get dateJugement            (): Nullable<Date> { return this._self.dateJugement             ? new Date(this._self.dateJugement            ) : null; },
	get dateExecution           (): Nullable<Date> { return this._self.dateExecution            ? new Date(this._self.dateExecution           ) : null; },
	get dateVoteSi              (): Nullable<Date> { return this._self.dateVoteSi               ? new Date(this._self.dateVoteSi              ) : null; },
	get dateAudienceOrientation (): Nullable<Date> { return this._self.dateAudienceOrientation  ? new Date(this._self.dateAudienceOrientation ) : null; },
	get dateAudienceAdjudication(): Nullable<Date> { return this._self.dateAudienceAdjudication ? new Date(this._self.dateAudienceAdjudication) : null; },
	get dateCpvsi(): Nullable<Date> { return this._self.dateCpvsi ? new Date(this._self.dateCpvsi) : null; },

	set dateMaj                 (value: Nullable<Date>) { this._self.dateMaj                  = value ? value.toISOString() : null; },
	set dateAssignation         (value: Nullable<Date>) { this._self.dateAssignation          = value ? value.toISOString() : null; },
	set dateAudience            (value: Nullable<Date>) { this._self.dateAudience             = value ? value.toISOString() : null; },
	set dateJugement            (value: Nullable<Date>) { this._self.dateJugement             = value ? value.toISOString() : null; },
	set dateExecution           (value: Nullable<Date>) { this._self.dateExecution            = value ? value.toISOString() : null; },
	set dateVoteSi              (value: Nullable<Date>) { this._self.dateVoteSi               = value ? value.toISOString() : null; },
	set dateAudienceOrientation (value: Nullable<Date>) { this._self.dateAudienceOrientation  = value ? value.toISOString() : null; },
	set dateAudienceAdjudication(value: Nullable<Date>) { this._self.dateAudienceAdjudication = value ? value.toISOString() : null; },
	set dateCpvsi               (value: Nullable<Date>) { this._self.dateCpvsi                = value ? value.toISOString() : null; },
});

export type HRecouvrementRecord = ReturnType<typeof hRecouvrementRecord>;
