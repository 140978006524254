import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { SuiviSocial, Referent } from '@/models';
import { loadingCall } from '@/libs/stores/candy/loadingCall';

export class SuiviSocialState {
}

export default {
    state: SuiviSocialState,
    reducer: mapReducer([]),
} as SubStore;

// ACTIONS

export const putSuiviSocial = (
    suiviSocial: Partial<SuiviSocial>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<SuiviSocial> => {
    return await caller.put<SuiviSocial>(`/api/suivi-socials/${suiviSocial.id}`, {
        date: suiviSocial.date,
        typeContact: suiviSocial.typeContact,
        aFaire: suiviSocial.aFaire,
        demarches: suiviSocial.demarches,
    });
});

export const postSuiviSocial = (
    referent: Referent | string, // Accepter soit un objet soit un id
    suiviSocial: Partial<SuiviSocial>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<SuiviSocial> => {
    let referentId;

    if (typeof referent === 'string') {
        referentId = referent;
    } else if (typeof referent === 'object' && referent !== null) {
        // Vérifiez que referent a un id et des suivi sociaux
        if (!referent.id) {
            console.error('Referent missing id:', referent);
            throw new Error('Referent object is missing id property.');
        }

        if (!Array.isArray(referent.suiviSocials)) {
            console.warn('Referent suivi sociaux is not an array. Defaulting to empty array.');
            referent.suiviSocials = [];
        }

        // Définir referentId
        if (referent.suiviSocials.length > 0) {
            referentId = referent.suiviSocials.map((a: any) => a.referent).join(',');
        } else {
            referentId = referent.id;
        }
    } else {
        console.error('Invalid referent:', referent);
        throw new Error('Invalid referent. Expected an object or a string but received a non-valid type.');
    }

    // Vérifiez si referentId est défini
    if (!referentId) {
        console.error('referent object:', referent);
        throw new Error('referentId is undefined. Please check the referent object.');
    }

    return await caller.post<SuiviSocial>(`/api/suivi-socials/${encodeURIComponent(referentId)}`, {
        date: suiviSocial.date,
        typeContact: suiviSocial.typeContact,
        aFaire: suiviSocial.aFaire,
        demarches: suiviSocial.demarches,
    });
});

export const deleteSuiviSocial = (
    suiviSocial: SuiviSocial,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<SuiviSocial> => {
    return await caller.delete<SuiviSocial>(`/api/suivi-socials/${suiviSocial.id}`);
});
