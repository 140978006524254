import { FieldAroundProps, FieldFormProps, useFieldForm } from '@/components/inputs/generic';
import { PhoneFieldSpecific } from '@/components/inputs/PhoneField/PhoneField';
import { MuiFileInput, MuiFileInputProps } from 'mui-file-input';
import './FileField.scss';

export type FileFieldProps = PhoneFieldSpecific & FieldFormProps & FieldAroundProps & Omit<MuiFileInputProps, 'InputProps'|'inputProps'>; 

export default function FileField(
	{
		rules = [],
		clearable = false,
		appendIcon = null,
		prependIcon = null,
		appendIconProps = {},
		prependIconProps = {},
		appendSlot = null,
		prependSlot = null,
		value,
		onChange,
		ref,
		className = '',
		onBlur = () => {},
		inputProps = {},
		error = false,
		helperText = null,
		readOnly = false,
		disabled = false,
		...rest
	}: FileFieldProps
) {

	const fieldForm = useFieldForm({
		className: `c-inputs-FileField ${className}`,
		rules,
		ref,
		value,
		onChange,
		defaultValue: '',
		error,
		helperText,
	});

	const handleBlur = (e: any) => {
		onBlur(e);
		fieldForm.markTouched();
	};

	return (
		<MuiFileInput
			{...rest}
			{...fieldForm.props}
			InputProps={{
				...inputProps,
				inputProps: {
					readOnly,
					...inputProps.inputProps || {}
				}
			}}
			inputRef={fieldForm.ref}
			className={fieldForm.className}
			onBlur={handleBlur}
			error={fieldForm.error}
			helperText={fieldForm.helperText}
			disabled={disabled}
		/>
	);
}
