import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { ResultType, TriLot } from '@/models';
import { Direction } from '@/models/Direction';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class TriLotState {
	public triLots: Nullable<ResultType<TriLot>> = null;
	public triLot: Nullable<TriLot> = null;
}

export default {
	state: TriLotState,
	reducer: mapReducer([
		'triLots',
		'triLot'
	]),
} as SubStore;

// MUTATIONS

export const setTriLots = (triLots: Nullable<ResultType<TriLot>>) => (dispatch: DispatchApp) => {
	dispatch({ state: TriLotState, type: MapReducerAction.MAP, triLots });
};
export const setTriLot = (triLot: Nullable<TriLot>) => (dispatch: DispatchApp) => {
	dispatch({ state: TriLotState, type: MapReducerAction.MAP, triLot });
};

// ACTIONS

export const findTriLotsByProprietaireId = (
	proprietaireId: number,
	page: number,
	order: string,
	direction: Direction,
	limit: number,
	filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<TriLot>> => {
	const triLots = await caller.get<ResultType<TriLot>>(`/api/tri-lots/proprietaire/${encodeURIComponent(proprietaireId)}${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);

	dispatch(setTriLots(triLots));

	return triLots;
});

export const findTriLotsByProprietaireNom = (
	nomProprietaire: string,
	page: number,
	order: string,
	direction: Direction,
	limit: number,
	filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<TriLot>> => {
	const triLots = await caller.get<ResultType<TriLot>>(`/api/tri-lots/proprietaire-nom/${encodeURIComponent(nomProprietaire)}${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);

	dispatch(setTriLots(triLots));

	return triLots;
});

export const findTriLotsLogement = (
	page: number,
	order: string,
	direction: Direction,
	limit: number,
	filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<TriLot>> => {
	const triLots = await caller.get<ResultType<TriLot>>(`/api/tri-lots/logement${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);
	
	dispatch(setTriLots(triLots));
	
	return triLots;
});

export const findTriLots = (
	page: number,
	order: string,
	direction: Direction,
	limit: number,
	filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<TriLot>> => {
	const triLots = await caller.get<ResultType<TriLot>>(`/api/tri-lots${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);
	
	dispatch(setTriLots(triLots));
	
	return triLots;
});
