import { Native } from '@/libs/utils';
import { FormRule } from '@/libs/form';
import { Validators } from '@/libs/validators/useValidators';
import { matchIsValidTel } from 'mui-tel-input';

export default function phone(
	this: Validators,
	{
		message,
	}: {
		message?: string,
	} = {}): FormRule {
	return (v: any) => {
		return Native.empty(v) || matchIsValidTel(v) || message || this.t('validators.phone');
	};
}
