import {
	TextField as MUITextField,
	TextFieldProps as MUITextFieldProps
} from '@mui/material';
import { FieldAroundProps, FieldFormProps, useFieldAround, useFieldForm } from '@/components/inputs/generic';
import { useCallback } from 'react';

export interface TextFieldSpecific {
	onChangeOrigin?: (event: any) => any,
	inputProps?: Partial<MUITextFieldProps['InputProps']>;
	readOnly?: boolean;
}

export type TextFieldProps = TextFieldSpecific & FieldFormProps & FieldAroundProps & Omit<MUITextFieldProps, 'onChange'|'InputProps'>;

export default function TextField(
	{
		rules = [],
		clearable = false,
		appendIcon = null,
		prependIcon = null,
		appendIconProps = {},
		prependIconProps = {},
		appendSlot = null,
		prependSlot = null,
		value,
		onChange,
		onChangeOrigin = () => {},
		ref,
		className = '',
		onBlur = () => {},
		inputProps = {},
		error = false,
		helperText = null,
		readOnly = false,
		...rest
	}: TextFieldProps
) {

	const fieldForm = useFieldForm({
		className: `c-inputs-TextField ${className}`,
		rules,
		ref,
		value,
		onChange,
		defaultValue: '',
		error,
		helperText,
	});

	const fielAround = useFieldAround({
		clearable,
		appendIcon,
		prependIcon,
		appendIconProps,
		prependIconProps,
		appendSlot,
		prependSlot,
		markClear: fieldForm.markClear,
	});

	const handleBlur = (e: any) => {
		onBlur(e);
		fieldForm.markTouched();
	};

	const handleChange = useCallback((event: any) => {
		onChangeOrigin(event);
		fieldForm.onChange(event.target.value);
	}, [ onChangeOrigin, fieldForm.onChange ]); // eslint-disable-line react-hooks-static-deps/exhaustive-deps


	return (
		<MUITextField
			{...rest}
			{...fieldForm.props}
			onChange={handleChange}
			value={fieldForm.props.value || ''}
			className={fieldForm.className}
			InputProps={{
				...fielAround,
				...inputProps,
				readOnly: readOnly
			}}
			error={fieldForm.error}
			helperText={fieldForm.helperText}
			ref={fieldForm.ref}
			onBlur={handleBlur}
		/>
	);
}
