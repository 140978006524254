import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '@/libs/redux';
import { CharteText } from './CharteText';
import Checkbox from '@/components/inputs/Checkbox/Checkbox';
import { model } from '@/libs/candy';
import { LoadingButton } from '@mui/lab';
import { useForm } from '@/libs/form';
import Form from '@/components/forms/Form/Form';
import { useValidators } from '@/libs/validators';
import { patchCharte, patchPassword } from '@/stores/user';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import { hUser } from '@/models';
import TextField from '@/components/inputs/TextField/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import IconVisibilityOff from '@mui/icons-material/VisibilityOff';
import IconVisibility from '@mui/icons-material/Visibility';

export function PasswordExpired() {

	const validators = useValidators();
	const dispatch = useDispatch();
	const [ open, setOpen ] = useState(false);
	const [ password, setPassword ] = useState('');
	const [ confirm, setConfirm ] = useState('');
	const [ showPassword, setShowPassword ] = useState(false);
	const me = useSelector(state => state.user.me);
	
	const form = useForm({
		async handle() {
			await form.call(async () => {
				await dispatch(patchPassword(password));
			});
		},
		fields: {
			password: {
				...model(password, setPassword),
				label: 'Mot de passe',
				rules: [
					validators.required(),
					validators.password(),
					validators.repeatField({
						repeat: confirm
					}),
				]
			},
			confirm: {
				...model(confirm, setConfirm),
				label: 'Confirmez votre mot de passe',
			}
		}
	})
	
	useEffect(() => {
		const meH = hUser(me);
		const now = new Date(); 
		if (
			me && (
				!meH.lastPasswordUpdated ||
				(now.getTime() - meH.lastPasswordUpdated.getTime()) > (3600 * 24 * 90 * 1000)
			)
		) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	}, [ me ]);
	
	return (
		<Dialog open={open} maxWidth={'lg'}>
			<Form form={form}>
				<DialogTitle>
					Votre mot de passe à expiré. Veuillez en saisir un nouveau.
				</DialogTitle>
				<DialogContent>

					<Grid container spacing={2}>
						<Grid xs={0} md={3} />
						<Grid xs={12} md={6}>
							<FormMainErrors form={form} />
						</Grid>
						<Grid xs={0} md={3} />
						<Grid xs={0} md={3} />
						<Grid xs={12} md={6}>
							<div>
								<TextField
									{...form.fields.password}
									type={showPassword ? 'text' : 'password'}
									autoComplete="new-password"
									appendIcon={showPassword ? <IconVisibilityOff /> : <IconVisibility />}
									appendIconProps={{
										'aria-label': "toggle password visibility",
										onClick: () => setShowPassword(!showPassword)
									}}
								/>
							</div>
						</Grid>
						<Grid xs={0} md={3} />
						<Grid xs={0} md={3} />
						<Grid xs={12} md={6}>
							<div>
								<TextField
									{...form.fields.confirm}
									type={showPassword ? 'text' : 'password'}
									autoComplete="new-password"
									appendIcon={showPassword ? <IconVisibilityOff /> : <IconVisibility />}
									appendIconProps={{
										'aria-label': "toggle password visibility",
										onClick: () => setShowPassword(!showPassword)
									}}
								/>
							</div>
						</Grid>
						<Grid xs={0} md={3} />
					</Grid>
					
				</DialogContent>
				<DialogActions>
					<div className="u-t-center" style={{ width: '100%' }}>
						<LoadingButton
							type="submit"
							variant="contained"
							loading={form.loading}
						>
							Valider
						</LoadingButton>
					</div>
				</DialogActions>
			</Form>
		</Dialog>
	);
}
