import { Native } from '@/libs/utils';
import { FormRule } from '@/libs/form';
import { Validators } from '@/libs/validators/useValidators';

export default function required(
	this: Validators,
	{
		message
	}: {
		message?: string
	} = {}): FormRule {
	return (v: any) => ((!Native.empty(v) && v !== false) && !(Array.isArray(v) && v.length === 0)) || message || this.t('validators.required');
}
