import type { ReactNode } from 'react';
import EmptyLayout from './Empty';
import MainLayout from './Main';

export interface LayoutProps {
	children: ReactNode;
	[name: string]: any;
}

export default function Layout(
	{
		metadata = {},
		...rest
	}: LayoutProps
) {
	if (metadata.layout === 'empty') {
		return <EmptyLayout
			metadata={metadata}
			{...rest}
		/>;
	}
	return <MainLayout
		metadata={metadata}
		{...rest}
	/>;
}
