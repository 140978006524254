import type { EventHandler } from 'react';

export function number(callback?: EventHandler<any>): EventHandler<any> {
	return function(this: any, ...args: any) {
		args[0] = parseFloat(args[0]);
		if (isNaN(args[0])) {
			args[0] = null;
		}
		if (callback) callback.apply(this, args);
	}
}
