import { Lot } from '@/models/Lot';
import { buildModelHelper } from '@/libs/modelHelper';
import { AcquisitionLot } from '@/models/AcquisitionLot';
import { hProprietaire, HProprietaire, Proprietaire } from '@/models/Proprietaire';
import { AcquisitionVisite, hAcquisitionVisite, HAcquisitionVisite } from './AcquisitionVisite';
import { AcquisitionDia, hAcquisitionDia, HAcquisitionDia } from './AcquisitionDia'; // Importer le modèle AcquisitionDia

export interface Acquisition {
	syndicat: string;
	proprietaire: string;
	id: number;
	referentId: string;
	aRecontacter?: string;
	avocatDernierEncherisseur: string;
	dateEnvoiFicheAcqui: Nullable<string>;
	dateJugementAdju: Nullable<string>;
	commentaireAdju: Nullable<string>;
	commentaireAmiable: Nullable<string>;
	commentairePreemption: Nullable<string>;
	contexteAcquisition: Nullable<string>;
	dateAcquisition: Nullable<string>;
	dateAudienceAdju: Nullable<string>;
	dateDemandeDs: Nullable<string>;
	dateOffreEcrite: Nullable<string>;
	dateOffreEpf: Nullable<string>;
	dateOffreOrale: Nullable<string>;
	dateRapportVille: Nullable<string>;
	dateSaisineNotaire: Nullable<string>;
	dateSignature: Nullable<string>;
	decisionPreemptionEpfAdju: Nullable<string>;
	dossierBloque?: string;
	montantMap?: number | null;
	montantDerniereEnchere?: number | null;
	nomAvocatDernierEncherisseur?: string;
	nomAvocatPoursuivant?: string;
	nomDernierEncherisseur?: string;
	nomPoursuivant?: string;
	prixm2Acquisition: Nullable<number>;
	prixm2Map?: number | null;
	recuperationCcvPvd?: string;
	refusPrix?: string;
	responsable?: string;
	situationLhi: string;
	surfaceCarrezAcquisition: Nullable<number>;
	surfaceDeclaree: Nullable<number>;
	structure?: string;
	type: string;
	typeVente: string;
	dateMaj: Nullable<string>;
	acquisitionLots: AcquisitionLot[];
	acquisitionVisites: AcquisitionVisite[];
	acquisitionDias: AcquisitionDia[]; // Définir le type correctement
}

export const hAcquisition = buildModelHelper<Acquisition>({
	get proprietaire(): HProprietaire {
		return hProprietaire(this.acquisitionLots?.[0]?.lot?.proprietes?.[0]?.proprietaire || null);
	},

	get lots(): Lot[] {
		return this.acquisitionLots?.map((al: AcquisitionLot) => al.lot) || [];
	},

	get visites(): AcquisitionVisite[] {
		return this.acquisitionVisites?.map((al: AcquisitionVisite) => al.id).flat() || [];
	},

	get acquisitionVisitesl(): HAcquisitionVisite[] {
		return this._self.acquisitionVisites?.map((e: AcquisitionVisite) => hAcquisitionVisite(e));
	},

	get dia(): AcquisitionDia[] {
		return this.acquisitionDias?.map((al: AcquisitionDia) => al.id).flat() || [];
	},

	get acquisitionDias(): HAcquisitionDia[] {
		return this._self.acquisitionDias?.map((e: AcquisitionDia) => hAcquisitionDia(e));
	},

	get dateAcquisition(): Nullable<Date> {
		return this._self.dateAcquisition ? new Date(this._self.dateAcquisition) : null;
	},

	get dateOffreEcrite(): Nullable<Date> {
		return this._self.dateOffreEcrite ? new Date(this._self.dateOffreEcrite) : null;
	},

	get dateOffreOrale(): Nullable<Date> {
		return this._self.dateOffreOrale ? new Date(this._self.dateOffreOrale) : null;
	},

	get dateOffreEpf(): Nullable<Date> {
		return this._self.dateOffreEpf ? new Date(this._self.dateOffreEpf) : null;
	},

	get dateSaisineNotaire(): Nullable<Date> {
		return this._self.dateSaisineNotaire ? new Date(this._self.dateSaisineNotaire) : null;
	},

	get dateSignature(): Nullable<Date> {
		return this._self.dateSignature ? new Date(this._self.dateSignature) : null;
	},

	get dateRapportVille(): Nullable<Date> {
		return this._self.dateRapportVille ? new Date(this._self.dateRapportVille) : null;
	},

	get dateAudienceAdju(): Nullable<Date> {
		return this._self.dateAudienceAdju ? new Date(this._self.dateAudienceAdju) : null;
	},

	get dateDemandeDs(): Nullable<Date> {
		return this._self.dateDemandeDs ? new Date(this._self.dateDemandeDs) : null;
	},

	get dateEnvoiFicheAcqui(): Nullable<Date> {
		return this._self.dateEnvoiFicheAcqui ? new Date(this._self.dateEnvoiFicheAcqui) : null;
	},

	get dateJugementAdju(): Nullable<Date> {
		return this._self.dateJugementAdju ? new Date(this._self.dateJugementAdju) : null;
	},

	get dateMaj(): Nullable<Date> {
		return this._self.dateMaj ? new Date(this._self.dateMaj) : null;
	},

	set dateAcquisition(value: Nullable<Date>) {
		this._self.dateAcquisition = value ? value.toISOString() : null;
	},

	set dateRapportVille(value: Nullable<Date>) {
		this._self.dateRapportVille = value ? value.toISOString() : null;
	},

	set dateAudienceAdju(value: Nullable<Date>) {
		this._self.dateAudienceAdju = value ? value.toISOString() : null;
	},

	set dateDemandeDs(value: Nullable<Date>) {
		this._self.dateDemandeDs = value ? value.toISOString() : null;
	},

	set dateOffreEcrite(value: Nullable<Date>) {
		this._self.dateOffreEcrite = value ? value.toISOString() : null;
	},

	set dateOffreOrale(value: Nullable<Date>) {
		this._self.dateOffreOrale = value ? value.toISOString() : null;
	},

	set dateOffreEpf(value: Nullable<Date>) {
		this._self.dateOffreEpf = value ? value.toISOString() : null;
	},

	set dateSaisineNotaire(value: Nullable<Date>) {
		this._self.dateSaisineNotaire = value ? value.toISOString() : null;
	},

	set dateSignature(value: Nullable<Date>) {
		this._self.dateSignature = value ? value.toISOString() : null;
	},

	set dateMaj(value: Nullable<Date>) {
		this._self.dateMaj = value ? value.toISOString() : null;
	},

	set dateEnvoiFicheAcqui(value: Nullable<Date>) {
		this._self.dateEnvoiFicheAcqui = value ? value.toISOString() : null;
	},

	set dateJugementAdju(value: Nullable<Date>) {
		this._self.dateJugementAdju = value ? value.toISOString() : null;
	},
});

export type HAcquisition = ReturnType<typeof hAcquisition>;
