import { useCallback } from 'react';
import { useSelector } from '@/libs/redux';
import { UserSyncicat } from '@/models';

export function useIsSyndicat() {

	const me = useSelector(state => state.user.me);

	return useCallback((rights: UserSyncicat[]) => {
		if (me) {
			if (rights.filter(r => me.syndicats.includes(r)).length) {
				return true;
			}
		}

		return false;
	}, [ me ]);
}
