import './Edit.scss';
import {
	hRecouvrementRecord,
	RecouvrementRecord,
	RecouvrementRecordEcheancier,
	RecouvrementRecordEtatSi,
	RecouvrementRecordHypotheque,
	RecouvrementRecordPlanBdf,
	RecouvrementRecordProcedureDordre,
	RecouvrementRecordStatutPortage,
	RecouvrementRecordSuccession,
	RecouvrementRecordTypeExecution,
	RecouvrementRecordTypeProcedure,
} from '@/models';
import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Dialog, IconButton, Tooltip } from '@mui/material';
import IconDelete from '@mui/icons-material/Delete';
import Confirm from '@/components/dialog/Confirm/Confirm';
import AddIcon from '@mui/icons-material/Add';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useCallback, useEffect, useState } from 'react';
import TextField from '@/components/inputs/TextField/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { Form as FormObject, useForm } from '@/libs/form';
import Form from '@/components/forms/Form/Form';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import { model } from '@/libs/candy';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from '@/libs/redux';
import { findRecouvrementRecord, putRecouvrementRecordById } from '@/stores/recouvrementRecord';
import { useEffectOnChange, useSnackMessage } from '@/libs/hooks';
import Select from '@/components/inputs/Select/Select';
import Checkbox from '@/components/inputs/Checkbox/Checkbox';
import Radio from '@/components/inputs/Radio/Radio';
import GroupField from '@/components/inputs/GroupField/GroupField';
import DatePicker from '@/components/inputs/DatePicker/DatePicker';
import CrudRecouvrementRecordEditHeadChart from '@/components/crud/recouvrementRecord/EditHeadChart/EditHeadChart';
import { dateToDash, formatNumber, formatDate } from '@/libs/utils';
import IconModeEdit from '@mui/icons-material/ModeEdit';
import { findReferentById, setReferent } from '@/stores/referent';
import { HAdresse, HEmail, HIndividu, HTelephone, Referent, HReferent, TriEvalSociale } from '@/models';
import { CrudChargesMenageEdit } from '@/components/crud/chargesMenage/Edit/Edit';
import { CrudAdresseEdit } from '../../adresse/Edit/Edit';
import { CrudEmailEdit } from '../../email/Edit/Edit';
import { CrudTelephoneEdit } from '../../telephone/Edit/Edit';
import { User } from '@/models';
import { getMe } from '@/stores/user';
import { deleteAdresse, postAdresse, putAdresse } from '@/stores/adresse';
import { deleteTelephone, postTelephone, putTelephone } from '@/stores/telephone';
import { deleteEmail, postEmail, putEmail } from '@/stores/email';
import { Checkbox as MuiCheckbox } from '@mui/material';

export interface CrudRecouvrementRecordEditProps {
	onClose: () => any;
	onUpdate: () => any;
	onPrev: () => Promise<any>;
	onNext: () => Promise<any>;
}

function Part1({ form }: { form: FormObject }) {
	return (
		<Card elevation={4} className="p-1 mb-1">
			<Grid container spacing={1}>
				<Grid
					xs={12} md={4}
				>
					<Select {...form.fields.succession} />
				</Grid>
				<Grid
					xs={12} md={4}
				>
					<Select {...form.fields.planBdf} />
				</Grid>
				<Grid
					xs={12} md={4}
				>
					<Select {...form.fields.respectDossierSurend} />
				</Grid>
				<Grid
					xs={12} md={4}
				>
					<Select {...form.fields.etatSuiviSocial} />
				</Grid>
				<Grid
					xs={12} md={4}
				>
					<TextField {...form.fields.organismeSuiviSocial} />
				</Grid>
				<Grid xs={12} md={4}>
					<Select {...form.fields.statutPortage} />
				</Grid>
			</Grid>
		</Card>
	);
}

function Part2(
	{
		form,
		echeancier,
		setEcheancier
	}: {
		form: FormObject,
		echeancier: RecouvrementRecordEcheancier,
		setEcheancier: (value: RecouvrementRecordEcheancier) => any
	}) {
	return (
		<Grid container spacing={2} className="mb-0">
			<Grid
				xs={12} md={4}
			>
				<Card elevation={4} className="p-1" style={{ height: '100%' }}>
					<Grid container spacing={1}>
						{/*<Grid xs={12}>*/}
						{/*	<Select {...form.fields.hypotheque} />*/}
						{/*</Grid>*/}
						<Grid xs={12}>
							<DatePicker {...form.fields.dateMaj} />
						</Grid>
					</Grid>
				</Card>
			</Grid>
			<Grid
				xs={12} md={4}
			>
				<Card elevation={4} className="p-1" style={{ height: '100%' }}>
					<GroupField label="Amiable / pré-contentieux">
						<Checkbox {...form.fields.relance} />
						<Checkbox {...form.fields.med} />
						<Checkbox {...form.fields.relanceAmed} />
						<Checkbox {...form.fields.commandementDePayer} />
						<Checkbox {...form.fields.protocole} />
					</GroupField>
				</Card>
			</Grid>
			<Grid
				xs={12} md={4}
			>
				<Card elevation={4} className="p-1" style={{ height: '100%' }}>
					<GroupField
						{...form.fields.echeancier}
					>
						<Grid container columnSpacing={1} rowSpacing={0}>
							{Object.values(RecouvrementRecordEcheancier).map(value => (
								<Grid
									key={value}
									xs={12} lg={6}
								>
									<Radio
										{...model(echeancier, setEcheancier)}
										item={value}
										label={value || <i>Aucun</i>}
									/>
								</Grid>
							))}
						</Grid>
					</GroupField>
				</Card>
			</Grid>
		</Grid>
	);
}

function Commentaire({ form }: { form: FormObject }) {
	const [showContent, setShowContent] = useState(false);
	const toggleContent = () => {
		setShowContent(!showContent);
	};
	return (
		<Card elevation={4} className="mb-1">
			<CardHeader
				className="py-1 mb-1"
				title="Commentaire"
				titleTypographyProps={{ className: 'u-t-size-19' }}
				action={
					<IconButton onClick={toggleContent} style={{ color: 'white' }}>
						{showContent ? <ExpandLessIcon /> : <AddIcon />}
					</IconButton>
				}
			/>
			{showContent && (
				<CardContent className="pt-1">
					<TextField
						multiline
						rows={5}
						{...form.fields.commentaire}
						placeholder="Vos données de commentaire seront traitées conformément à notre politique de confidentialité et aux réglementations de la RGPD."
						helperText="Cette zone de commentaires est visible sur les fiches copropriétaires et dans les compte-rendus, diffusés aux instances de copropriété : conformément au RGPD, les informations collectées et renseignées sur les personnes doivent être adéquates, pertinentes et non excessives au regard de la finalité du traitement envisagé. Les commentaires ne doivent donc pas être inappropriés, subjectifs et insultants." />
				</CardContent>
			)}
		</Card>
	);
}

function Orientation({ form }: { form: FormObject }) {
	return (
		<Card elevation={4} className="mb-1">
			<CardHeader title="Orientation" titleTypographyProps={{ className: 'u-t-size-19' }} />
			<CardContent className="pt-0">
				<Grid container spacing={1}>
					<Grid xs={12} md={4}>
						<Grid container spacing={1}>
							<Grid xs={12}><Checkbox {...form.fields.precoAmiable} /></Grid>
							<Grid xs={12}><Checkbox {...form.fields.precoProcedure} /></Grid>
							<Grid xs={12}><Checkbox {...form.fields.precoSi} /></Grid>
						</Grid>
					</Grid>
					<Grid xs={12} md={4}>
						<Grid container spacing={1}>
							<Grid xs={12}><Checkbox {...form.fields.precoAccompSocial} /></Grid>
							<Grid xs={12}><Checkbox {...form.fields.precoPortageAdj} /></Grid>
							{/*<Grid xs={12}><Checkbox {...form.fields.precoPortageAmia} /></Grid>*/}
							{/*<Grid xs={12}><Checkbox {...form.fields.precoRelogement} /></Grid>*/}
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

function SaisiesImmobilieres({ form }: { form: FormObject }) {
	const [showContent, setShowContent] = useState(false);
	const toggleContent = () => {
		setShowContent(!showContent);
	};
	return (
		<Card elevation={4} className="mb-1">
			<CardHeader
				className="py-1 mb-1"
				title="Saisies immobilières"
				titleTypographyProps={{ className: 'u-t-size-19' }}
				action={
					<IconButton onClick={toggleContent} style={{ color: 'white' }}>
						{showContent ? <ExpandLessIcon /> : <AddIcon />}
					</IconButton>
				}
			/>
			{showContent && (
				<CardContent>
					<Grid container spacing={1}>
						<Grid xs={12} md={6}><Select {...form.fields.origineSi} /></Grid>
						<Grid xs={12} md={6}><DatePicker {...form.fields.dateVoteSi} /></Grid>
						<Grid xs={12} md={6}><Select {...form.fields.etatSi} /></Grid>
						<Grid xs={12} md={6}><DatePicker {...form.fields.dateCpvsi} /></Grid>
						<Grid xs={12} md={6}><DatePicker {...form.fields.dateAudienceOrientation} /></Grid>
						{/*<Grid xs={12} md={6}><Select {...form.fields.procedureDordre} /></Grid>*/}
						<Grid xs={12} md={6}><DatePicker {...form.fields.dateAudienceAdjudication} /></Grid>
					</Grid>
				</CardContent>
			)}
		</Card>
	);
}

function Procedures({ form }: { form: FormObject }) {
	const [showContent, setShowContent] = useState(true);
	const toggleContent = () => {
		setShowContent(!showContent);
	};
	return (
		<Card elevation={4} className="mb-1">
			<CardHeader
				className="py-1 mb-1"
				title="Procédures"
				titleTypographyProps={{ className: 'u-t-size-19' }}
				action={
					<IconButton onClick={toggleContent} style={{ color: 'white' }}>
						{showContent ? <ExpandLessIcon /> : <AddIcon />}
					</IconButton>
				}
			/>
			{showContent && (
				<CardContent className="pt-0">
					<Grid container spacing={1}>
						<Grid xs={12}>
							<Grid container spacing={1}>
								<Grid xs={12} md={4}><Select {...form.fields.procedureAvancement} /></Grid>
								<Grid xs={12} md={4} ><Select {...form.fields.typeProcedure} /></Grid>
							</Grid>
						</Grid>
						<Grid xs={12}>
							<Grid container spacing={1}>
								<Grid xs={12} md={4}><DatePicker {...form.fields.dateAssignation} /></Grid>
								<Grid xs={12} md={4}><DatePicker {...form.fields.dateAudience} /></Grid>
								<Grid xs={12} md={4}><DatePicker {...form.fields.dateJugement} /></Grid>
							</Grid>
						</Grid>
						<Grid xs={12}>
							<Grid container spacing={1}>
								<Grid xs={12} md={4}><Select {...form.fields.typeExecution} /></Grid>
								<Grid xs={12} md={4}><Select {...form.fields.executionAvancement} /></Grid>
								<Grid xs={12} md={4}><DatePicker {...form.fields.dateExecution} /></Grid>
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
			)}
		</Card>
	);
}

export interface CrudReferentEditProps {
	onClose: () => any;
	onUpdate: () => any;
	onPrev: () => Promise<any>;
	onNext: () => Promise<any>;
	item: Nullable<TriEvalSociale>;
}

function useReferentById(referentId: string) {
	const dispatch = useDispatch();
	const [referent, setReferent] = useState<Nullable<Referent>>(null);
	const [loading, setLoading] = useState(false);
	const fetchReferent = useCallback(async () => {
		setLoading(true);
		try {
			const result = await dispatch(findReferentById(referentId));
			setReferent(result);
		} catch (e) {
			console.error(e);
		}
		setLoading(false);
	}, [dispatch, referentId]);
	useEffect(() => {
		fetchReferent();
	}, [fetchReferent]);
	return { referent, loading, refresh: fetchReferent };
}

export interface InformationsProps {
	onRefresh: () => any;
}

function Part9({ onRefresh }: InformationsProps) {
	const dispatch = useDispatch();
	const recouvrement = useSelector((state: any) => state.recouvrement.recouvrement);
	const filteredReferenta = recouvrement?.proprietaire;
	const { referent, loading, refresh } = useReferentById(filteredReferenta);
	const [currentUser, setCurrentUser] = useState<Nullable<User>>(null);

	const [editAdresse, setEditAdresse] = useState<Nullable<HAdresse>>(null);
	const [editTelephone, setEditTelephone] = useState<Nullable<HTelephone>>(null);
	const [editEmail, setEditEmail] = useState<Nullable<HEmail>>(null);
	const [removeAdresse, setRemoveAdresse] = useState<Nullable<HAdresse>>(null);
	const [removeTelephone, setRemoveTelephone] = useState<Nullable<HTelephone>>(null);
	const [removeEmail, setRemoveEmail] = useState<Nullable<HEmail>>(null);

	const [showContent, setShowContent] = useState(false);
	const [addresses, setAddresses] = useState<HAdresse[]>([]);
	const [emails, setEmails] = useState<HEmail[]>([]);
	const [telephones, setTelephones] = useState<HTelephone[]>([]);

	const toggleContent = () => {
		setShowContent(!showContent);
	};

	useEffect(() => {
		refresh();
		dispatch(getMe()).then((user: Nullable<User>) => {
			setCurrentUser(user);
		});
	}, [refresh, dispatch]);

	const getTypeLabel = (type: any) => {
		switch (type) {
			case 1:
				return "Donnée syndic";
			case 2:
				return "Saisie manuelle";
			default:
				return "";
		}
	};

	const concatAddresses = (adresse1: string | null, adresse2: string | null, adresse3: string | null) => {
		return [adresse1, adresse2, adresse3].filter(Boolean).join(' | ');
	};

	const getSource = (type: number, source: string | null) => {
		return source || '';
	};

	const filterAndSortItems = <T extends { type: number; dateMaj: string; pertinence: number; coche: boolean }>(items: T[], key: keyof T) => {
		return items.sort((a, b) => {
			if (a.coche !== b.coche) {
				return (b.coche ? 1 : 0) - (a.coche ? 1 : 0);
			} else if (a.type !== b.type) {
				return b.type - a.type;
			} else if (new Date(a.dateMaj).getTime() !== new Date(b.dateMaj).getTime()) {
				return new Date(b.dateMaj).getTime() - new Date(a.dateMaj).getTime();
			} else {
				return b.pertinence - a.pertinence;
			}
		});
	};

	const submitPertinenceChange = async (newItem: any, previousItem: any, putFunction: any) => {
		try {
			if (previousItem) {
				const updatedPreviousItem = { ...previousItem, pertinence: null };
				await dispatch(putFunction(updatedPreviousItem));
			}

			if (newItem) {
				await dispatch(putFunction(newItem));
			}
		} catch (error) {
			console.error('Error updating item:', error);
		}
	};

	const handleCheckboxChangeForAddress = async (index: number) => {
		const previousRelevantItem = addresses.find(item => item.pertinence === 1);

		const updatedList = addresses.map((item, i) => ({
			...item,
			coche: i === index,
			pertinence: i === index ? 1 : (item.pertinence === 1 ? 0 : item.pertinence),
		}));

		const relevantItem = updatedList.find(item => item.pertinence === 1);

		await submitPertinenceChange(relevantItem, previousRelevantItem, putAdresse);

		setAddresses(filterAndSortItems(updatedList, 'dateMaj'));
	};

	const handleCheckboxChangeForEmail = async (index: number) => {
		const previousRelevantItem = emails.find(item => item.pertinence === 1);

		const updatedList = emails.map((item, i) => ({
			...item,
			coche: i === index,
			pertinence: i === index ? 1 : (item.pertinence === 1 ? 0 : item.pertinence),
		}));

		const relevantItem = updatedList.find(item => item.pertinence === 1);

		await submitPertinenceChange(relevantItem, previousRelevantItem, putEmail);

		setEmails(filterAndSortItems(updatedList, 'dateMaj'));
	};

	const handleCheckboxChangeForTelephone = async (index: number) => {
		const previousRelevantItem = telephones.find(item => item.pertinence === 1);

		const updatedList = telephones.map((item, i) => ({
			...item,
			coche: i === index,
			pertinence: i === index ? 1 : (item.pertinence === 1 ? null : item.pertinence),
		}));

		const relevantItem = updatedList.find(item => item.pertinence === 1);

		await submitPertinenceChange(relevantItem, previousRelevantItem, putTelephone);
		
		setTelephones(filterAndSortItems(updatedList, 'dateMaj'));
	};

	useEffect(() => {
		if (referent) {
			setAddresses(filterAndSortItems(referent.adresses?.map(adresse => ({ ...adresse, coche: adresse.pertinence === 1, pertinence: adresse.pertinence ?? null })) || [], 'adresse1'));
			setEmails(filterAndSortItems(referent.emails?.map(email => ({ ...email, coche: email.pertinence === 1, pertinence: email.pertinence ?? null })) || [], 'email1'));
			setTelephones(filterAndSortItems(referent.telephones?.map(telephone => ({ ...telephone, coche: telephone.pertinence === 1, pertinence: telephone.pertinence ?? null })) || [], 'telephone1'));
		}
	}, [referent]);

	const columnWidths = {
		type: '10%',
		address: '35%',
		comment: '20%',
		source: '10%',
		dateMaj: '10%',
		actions: '10%',
		reference: '5%'
	};

	return (
		<>
			<Card elevation={4} className="mb-1">
				<CardHeader
					className="py-1 mb-1"
					title="Coordonnées"
					titleTypographyProps={{ className: 'u-t-size-19' }}
					action={
						<IconButton onClick={toggleContent} style={{ color: 'white' }}>
							{showContent ? <ExpandLessIcon /> : <AddIcon />}
						</IconButton>
					}
				/>
				{showContent && (
					<CardContent className="pt-0">
						{/* ------------------------------ ADRESSES --------------------------------------------- */}
						<Grid container spacing={1} className="customGridContainer">
							<Grid xs={12}>
								<div style={{ overflow: 'auto' }}>
									<strong>Adresse(s) :</strong> <br />
									<table className="c-Crud-Referent-Edit-suiviSocial">
										<thead>
											<tr>
												<th style={{ width: columnWidths.address }}>Adresse</th>
												<th style={{ width: columnWidths.comment }}>Commentaire</th>
												<th style={{ width: columnWidths.source, textAlign: 'center' }}>Source</th>
												<th style={{ width: columnWidths.type, textAlign: 'center' }}>Type</th>
												<th style={{ width: columnWidths.dateMaj }}>Date maj</th>
												<th style={{ width: columnWidths.reference, textAlign: 'center' }}>
													<Tooltip title="Marquer comme adresse de référence">
														<span>Réf</span>
													</Tooltip>
												</th>
												<th style={{ width: columnWidths.actions, textAlign: 'center' }}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{addresses.length ? (
												addresses.map((adresse: HAdresse, index) => (
													<Tooltip title={adresse.pertinence === 1 ? "Adresse de référence" : ""} key={adresse.id}>
														<tr style={index === 0 ? { backgroundColor: '#f0f0f0' } : {}}>
															<td style={{ width: columnWidths.address }}>
																{concatAddresses(adresse.adresse1, adresse.adresse2, adresse.adresse3)}
															</td>
															<td style={{ width: columnWidths.comment }}>{adresse.commentaire}</td>
															<td style={{ width: columnWidths.source, textAlign: 'center' }}>
																{getSource(adresse.type, adresse.source)}
															</td>
															<td style={{ width: columnWidths.type, textAlign: 'center' }}>
																{getTypeLabel(adresse.type)}
															</td>
															<td style={{ width: columnWidths.dateMaj }}>{formatDate(adresse.dateMaj)}</td>
															<td style={{ width: columnWidths.reference, textAlign: 'center' }}>
																<MuiCheckbox
																	checked={Boolean(adresse.coche)}
																	onChange={() => handleCheckboxChangeForAddress(index)}
																	inputProps={{ 'aria-label': 'Adresse de référence' }}
																/>
															</td>
															<td style={{ width: columnWidths.actions, textAlign: 'center' }}>
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
																	{adresse.type !== 1 && (
																		<>
																			<IconButton onClick={() => setEditAdresse(adresse)}>
																				<IconModeEdit />
																			</IconButton>
																			<IconButton onClick={() => setRemoveAdresse(adresse)}>
																				<IconDelete />
																			</IconButton>
																		</>
																	)}
																</div>
															</td>

														</tr>
													</Tooltip>
												))
											) : (
												<tr>
													<td colSpan={7} className="u-t-center">
														<i>Aucune adresse</i>
													</td>
												</tr>
											)}
										</tbody>

									</table>
								</div>
							</Grid>
							<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
								<Button
									variant="contained"
									size="small"
									onClick={() =>
										setEditAdresse({
											id: 0,
											referent: referent?.id,
											adresse1: '',
											adresse2: '',
											adresse3: '',
											commentaire: '',
											source: '',
											pertinence: 0,
											dateMaj: new Date().toISOString(),
										})
									}
								>
									+
								</Button>
							</Grid>
							<Dialog open={editAdresse !== null} onClose={() => setEditAdresse(null)} maxWidth={'md'}>
								{editAdresse && (
									<CrudAdresseEdit
										referent={referent as Referent}
										value={editAdresse}
										onSave={() => {
											setEditAdresse(null);
											refresh();
										}}
										onClose={() => setEditAdresse(null)}
										currentUser={currentUser}
									/>
								)}
							</Dialog>
							<Confirm
								value={!!removeAdresse}
								onChange={() => setRemoveAdresse(null)}
								title="Suppression d'une adresse"
								message={`Êtes-vous sûr de vouloir supprimer cette adresse ?`}
								onConfirm={async () => {
									if (removeAdresse) {
										await dispatch(deleteAdresse(removeAdresse.id));
										setRemoveAdresse(null);
										refresh();
									}
								}}
							/>
						</Grid>
						{/* ------------------------------ EMAILS ----------------------------------------------- */}
						<Grid container spacing={1} className="customGridContainer">
							<Grid xs={12}>
								<div style={{ overflow: 'auto' }}>
									<strong>Email(s) :</strong> <br />
									<table className="c-Crud-Referent-Edit-suiviSocial">
										<thead>
											<tr>
												<th style={{ width: columnWidths.type, textAlign: 'center' }}>Type</th>
												<th style={{ width: columnWidths.address }}>Email</th>
												<th style={{ width: columnWidths.comment }}>Commentaire</th>
												<th style={{ width: columnWidths.source, textAlign: 'center' }}>Source</th>
												<th style={{ width: columnWidths.dateMaj }}>Date maj</th>
												<th style={{ width: columnWidths.reference, textAlign: 'center' }}>
													<Tooltip title="Marquer comme email de référence">
														<span>Réf</span>
													</Tooltip>
												</th>
												<th style={{ width: columnWidths.actions, textAlign: 'center' }}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{emails.length ? (
												emails.map((email: HEmail, index) => (
													<Tooltip title={email.pertinence === 1 ? "Email de référence" : ""} key={email.id}>
														<tr style={index === 0 ? { backgroundColor: '#f0f0f0' } : {}}>
															<td style={{ width: columnWidths.type, textAlign: 'center' }}>
																{getTypeLabel(email.type)}
															</td>
															<td style={{ width: columnWidths.address }}>{email.email1}</td>
															<td style={{ width: columnWidths.comment }}>{email.commentaire}</td>
															<td style={{ width: columnWidths.source, textAlign: 'center' }}>
																{getSource(email.type, email.source)}
															</td>
															<td style={{ width: columnWidths.dateMaj }}>{formatDate(email.dateMaj)}</td>
															<td style={{ width: columnWidths.reference, textAlign: 'center' }}>
																<MuiCheckbox
																	checked={Boolean(email.coche)}
																	onChange={() => handleCheckboxChangeForEmail(index)}
																	inputProps={{ 'aria-label': 'Email de référence' }}
																/>
															</td>
															<td style={{ width: columnWidths.actions, textAlign: 'center' }}>
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
																	{email.type !== 1 && (
																		<>
																			<IconButton onClick={() => setEditEmail(email)}>
																				<IconModeEdit />
																			</IconButton>
																			<IconButton onClick={() => setRemoveEmail(email)}>
																				<IconDelete />
																			</IconButton>
																		</>
																	)}
																</div>
															</td>
														</tr>
													</Tooltip>
												))
											) : (
												<tr>
													<td colSpan={7} className="u-t-center">
														<i>Aucun email</i>
													</td>
												</tr>
											)}
										</tbody>

									</table>
								</div>
							</Grid>
							<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
								<Button
									variant="contained"
									size="small"
									onClick={() =>
										setEditEmail({
											id: 0,
											referent: referent?.id,
											email1: '',
											commentaire: '',
											source: '',
											pertinence: 0,
											dateMaj: new Date().toISOString(),
										})
									}
								>
									+
								</Button>
							</Grid>
							<Dialog open={editEmail !== null} onClose={() => setEditEmail(null)} maxWidth={'md'}>
								{editEmail && (
									<CrudEmailEdit
										referent={referent as Referent}
										value={editEmail}
										onSave={() => {
											setEditEmail(null);
											refresh();
										}}
										onClose={() => setEditEmail(null)}
										currentUser={currentUser}
									/>
								)}
							</Dialog>
							<Confirm
								value={!!removeEmail}
								onChange={() => setRemoveEmail(null)}
								title="Suppression d'un email"
								message={`Êtes-vous sûr de vouloir supprimer cet email ?`}
								onConfirm={async () => {
									if (removeEmail) {
										await dispatch(deleteEmail(removeEmail.id));
										setRemoveEmail(null);
										refresh();
									}
								}}
							/>
						</Grid>
						{/* ------------------------------ TELEPHONES ------------------------------------------ */}
						<Grid container spacing={1} className="customGridContainer">
							<Grid xs={12}>
								<div style={{ overflow: 'auto' }}>
									<strong>Téléphone(s) :</strong> <br />
									<table className="c-Crud-Referent-Edit-suiviSocial">
										<thead>
											<tr>
												<th style={{ width: columnWidths.type, textAlign: 'center' }}>Type</th>
												<th style={{ width: columnWidths.address }}>Téléphone</th>
												<th style={{ width: columnWidths.comment }}>Commentaire</th>
												<th style={{ width: columnWidths.source, textAlign: 'center' }}>Source</th>
												<th style={{ width: columnWidths.dateMaj }}>Date maj</th>
												<th style={{ width: columnWidths.reference, textAlign: 'center' }}>
													<Tooltip title="Marquer comme téléphone de référence">
														<span>Réf</span>
													</Tooltip>
												</th>
												<th style={{ width: columnWidths.actions, textAlign: 'center' }}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{telephones.length ? (
												telephones.map((telephone: HTelephone, index) => (
													<Tooltip title={telephone.pertinence === 1 ? "Téléphone de référence" : ""} key={telephone.id}>
														<tr style={index === 0 ? { backgroundColor: '#f0f0f0' } : {}}>
															<td style={{ width: columnWidths.type, textAlign: 'center' }}>
																{getTypeLabel(telephone.type)}
															</td>
															<td style={{ width: columnWidths.address }}>{telephone.telephone1}</td>
															<td style={{ width: columnWidths.comment }}>{telephone.commentaire}</td>
															<td style={{ width: columnWidths.source, textAlign: 'center' }}>
																{getSource(telephone.type, telephone.source)}
															</td>
															<td style={{ width: columnWidths.dateMaj }}>{formatDate(telephone.dateMaj)}</td>
															<td style={{ width: columnWidths.reference, textAlign: 'center' }}>
																<MuiCheckbox
																	checked={Boolean(telephone.coche)}
																	onChange={() => handleCheckboxChangeForTelephone(index)}
																	inputProps={{ 'aria-label': 'Téléphone de référence' }}
																/>
															</td>
															<td style={{ width: columnWidths.actions, textAlign: 'center' }}>
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
																	{telephone.type !== 1 && (
																		<>
																			<IconButton onClick={() => setEditTelephone(telephone)}>
																				<IconModeEdit />
																			</IconButton>
																			<IconButton onClick={() => setRemoveTelephone(telephone)}>
																				<IconDelete />
																			</IconButton>
																		</>
																	)}
																</div>
															</td>
														</tr>
													</Tooltip>
												))
											) : (
												<tr>
													<td colSpan={7} className="u-t-center">
														<i>Aucun téléphone</i>
													</td>
												</tr>
											)}
										</tbody>

									</table>
								</div>
							</Grid>
							<Grid xs={12} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
								<Button
									variant="contained"
									size="small"
									onClick={() =>
										setEditTelephone({
											id: 0,
											referent: referent?.id,
											telephone1: '',
											commentaire: '',
											source: '',
											pertinence: 0,
											dateMaj: new Date().toISOString(),
										})
									}
								>
									+
								</Button>
							</Grid>
							<Dialog open={editTelephone !== null} onClose={() => setEditTelephone(null)} maxWidth={'md'}>
								{editTelephone && (
									<CrudTelephoneEdit
										referent={referent as Referent}
										value={editTelephone}
										onSave={() => {
											setEditTelephone(null);
											refresh();
										}}
										onClose={() => setEditTelephone(null)}
										currentUser={currentUser}
									/>
								)}
							</Dialog>
							<Confirm
								value={!!removeTelephone}
								onChange={() => setRemoveTelephone(null)}
								title="Suppression d'un téléphone"
								message={`Êtes-vous sûr de vouloir supprimer ce téléphone ?`}
								onConfirm={async () => {
									if (removeTelephone) {
										await dispatch(deleteTelephone(removeTelephone.id));
										setRemoveTelephone(null);
										refresh();
									}
								}}
							/>
						</Grid>
					</CardContent>
				)}
			</Card>
		</>
	);
}


export default function CrudRecouvrementRecordEdit(
	{
		onClose,
		onUpdate,
		onPrev,
		onNext,
	}: CrudRecouvrementRecordEditProps
) {
	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const recouvrement = useSelector(state => state.recouvrement.recouvrement);
	const record = hRecouvrementRecord(useSelector(state => state.recouvrementRecord.recouvrementRecord));
	const [loading, setLoading] = useState(false);

	const [succession, setSuccession] = useState<RecouvrementRecordSuccession>(RecouvrementRecordSuccession.NOTHING);
	const [planBdf, setPlanBdf] = useState<RecouvrementRecordPlanBdf>(RecouvrementRecordPlanBdf.NOTHING);
	const [respectDossierSurend, setRespectDossierSurend] = useState<Nullable<string>>(null);
	const [hypotheque, setHypotheque] = useState<RecouvrementRecordHypotheque>(RecouvrementRecordHypotheque.NON);
	const [dateMaj, setDateMaj] = useState<Date>(new Date());
	const [relance, setRelance] = useState<boolean>(false);
	const [protocole, setProtocole] = useState<boolean>(false);
	const [med, setMed] = useState<boolean>(false);
	const [commandementDePayer, setCommandementDePayer] = useState<boolean>(false);
	const [relanceAmed, setRelanceAmed] = useState<boolean>(false);
	const [echeancier, setEcheancier] = useState<RecouvrementRecordEcheancier>(RecouvrementRecordEcheancier.NOTHING);
	const [procedureAvancement, setProcedureAvancement] = useState<Nullable<string>>(null);
	const [executionAvancement, setExecutionAvancement] = useState<Nullable<string>>(null);
	const [typeProcedure, setTypeProcedure] = useState<RecouvrementRecordTypeProcedure>(RecouvrementRecordTypeProcedure.NOTHING);
	const [typeExecution, setTypeExecution] = useState<RecouvrementRecordTypeExecution>(RecouvrementRecordTypeExecution.NOTHING);
	const [dateAssignation, setDateAssignation] = useState<Nullable<Date>>(null);
	const [dateAudience, setDateAudience] = useState<Nullable<Date>>(null);
	const [dateJugement, setDateJugement] = useState<Nullable<Date>>(null);
	const [dateExecution, setDateExecution] = useState<Nullable<Date>>(null);
	const [dateVoteSi, setDateVoteSi] = useState<Nullable<Date>>(null);
	const [dateAudienceOrientation, setDateAudienceOrientation] = useState<Nullable<Date>>(null);
	const [dateAudienceAdjudication, setDateAudienceAdjudication] = useState<Nullable<Date>>(null);
	const [dateCpvsi, setDateCpvsi] = useState<Nullable<Date>>(null);
	const [etatSi, setEtatSi] = useState<RecouvrementRecordEtatSi>(RecouvrementRecordEtatSi.NOTHING);
	const [procedureDordre, setProcedureDordre] = useState<RecouvrementRecordProcedureDordre>(RecouvrementRecordProcedureDordre.NOTHING);
	const [precoAmiable, setPrecoAmiable] = useState<boolean>(false);
	const [precoProcedure, setPrecoProcedure] = useState<boolean>(false);
	const [precoAccompSocial, setPrecoAccompSocial] = useState<boolean>(false);
	const [precoPortageAdj, setPrecoPortageAdj] = useState<boolean>(false);
	const [precoPortageAmia, setPrecoPortageAmia] = useState<boolean>(false);
	const [precoSi, setPrecoSi] = useState<boolean>(false);
	const [precoRelogement, setPrecoRelogement] = useState<boolean>(false);
	const [statutPortage, setStatutPortage] = useState<RecouvrementRecordStatutPortage>(RecouvrementRecordStatutPortage.NOTHING);
	const [origineSi, setOrigineSi] = useState<string>('');
	const [commentaire, setCommentaire] = useState<string>('');
	const [etatSuiviSocial, setEtatSuiviSocial] = useState<Nullable<string>>(null);
	const [organismeSuiviSocial, setOrganismeSuiviSocial] = useState<string>('');

	const refresh = useCallback(() => {
		(async () => {
			setLoading(true);
			try {
				if (recouvrement) {
					await dispatch(findRecouvrementRecord(recouvrement.syndicat, recouvrement.proprietaire));
				}
			} catch (e) {
				console.error(e);
				pushMessage('Une erreur est survenue', 'error');
			}
			setLoading(false);
		})();
	}, [recouvrement?.id])

	useEffectOnChange(() => {
		refresh();
	}, [refresh]);
	useEffectOnChange(() => {
		(async () => {
			setLoading(true);
			try {
				if (recouvrement) {
					await dispatch(findRecouvrementRecord(recouvrement.syndicat, recouvrement.proprietaire));
				}
			} catch (e) {
				console.error(e);
				pushMessage('Une erreur est survenue', 'error');
			}
			setLoading(false);
		})();
	}, [recouvrement?.syndicat, recouvrement?.proprietaire]);

	useEffectOnChange(() => {
		if (record) {
			setSuccession(record.succession || RecouvrementRecordSuccession.NOTHING);
			setPlanBdf(record.planBdf || RecouvrementRecordPlanBdf.NOTHING);
			setRespectDossierSurend(record.respectDossierSurend || null);
			setHypotheque(record.hypotheque || RecouvrementRecordHypotheque.NON);
			setDateMaj(record.dateMaj || new Date());
			setRelance(!!record.relance);
			setProtocole(!!record.protocole);
			setMed(!!record.med);
			setCommandementDePayer(!!record.commandementDePayer);
			setRelanceAmed(!!record.relanceAmed);
			setEcheancier(record.echeancier || RecouvrementRecordEcheancier.NOTHING);
			setProcedureAvancement(record.procedureAvancement || null);
			setExecutionAvancement(record.executionAvancement || null);
			setTypeProcedure(record.typeProcedure || RecouvrementRecordTypeProcedure.NOTHING);
			setTypeExecution(record.typeExecution || RecouvrementRecordTypeExecution.NOTHING);
			setDateAssignation(record.dateAssignation);
			setDateAudience(record.dateAudience);
			setDateJugement(record.dateJugement);
			setDateExecution(record.dateExecution);
			setDateVoteSi(record.dateVoteSi);
			setDateAudienceOrientation(record.dateAudienceOrientation);
			setDateAudienceAdjudication(record.dateAudienceAdjudication);
			setDateCpvsi(record.dateCpvsi);
			setEtatSi(record.etatSi || RecouvrementRecordEtatSi.NOTHING);
			setProcedureDordre(record.procedureDordre || RecouvrementRecordProcedureDordre.NOTHING);
			setPrecoAmiable(!!record.precoAmiable);
			setPrecoProcedure(!!record.precoProcedure);
			setPrecoAccompSocial(!!record.precoAccompSocial);
			setPrecoPortageAdj(!!record.precoPortageAdj);
			setPrecoPortageAmia(!!record.precoPortageAmia);
			setPrecoSi(!!record.precoSi);
			setPrecoRelogement(!!record.precoRelogement);
			setStatutPortage(record.statutPortage || RecouvrementRecordStatutPortage.NOTHING);
			setOrigineSi(record.origineSi || null);
			setCommentaire(record.commentaire || '');
			setEtatSuiviSocial(record.etatSuiviSocial || null)
			setOrganismeSuiviSocial(record.organismeSuiviSocial || '')
		}
	}, [record?.id]);

	const form = useForm({
		handle: async () => {
			await form.call(async () => {
				await save();
				onUpdate();
				onClose();
			});
		},
		fields: {
			succession: {
				...model(succession, setSuccession),
				label: 'Succession',
				items: Object.values(RecouvrementRecordSuccession).map(value => ({
					text: value || <i>Aucun</i>,
					value
				})),
			},
			planBdf: {
				...model(planBdf, setPlanBdf),
				label: 'Situation surendettement',
				items: Object.values(RecouvrementRecordPlanBdf).map(value => ({
					text: value || <i>Aucun</i>,
					value
				})),
			},
			respectDossierSurend: {
				...model(respectDossierSurend, setRespectDossierSurend),
				label: 'Respect du dossier de surendettement',
				items: [
					null,
					'Oui',
					'Non-respect : à dénoncer',
					'Non-respect : dénoncé'
				].map(value => ({
					text: value || <i>Aucun</i>,
					value
				})),
			},
			etatSuiviSocial: {
				...model(etatSuiviSocial, setEtatSuiviSocial),
				label: 'État suivi social',
				items: [
					null,
					'Repérage / Prise de contact en cours',
					'Pas de réponse',
					'Évalluation sociale',
					'Suivi',
					'Veille',
					'Refus'
				].map(value => ({
					text: value || <i>Aucun</i>,
					value
				})),
			},
			organismeSuiviSocial: {
				...model(organismeSuiviSocial, setOrganismeSuiviSocial),
				label: 'Organisme suivi social',
			},
			hypotheque: {
				...model(hypotheque, setHypotheque),
				label: 'Hypothèque',
				items: Object.values(RecouvrementRecordHypotheque),
			},
			dateMaj: {
				...model(dateMaj, setDateMaj),
				label: 'Date mise à jour',
			},
			relance: {
				...model(relance, setRelance),
				label: 'Relance',
			},
			protocole: {
				...model(protocole, setProtocole),
				label: 'Protocle d\'accord',
			},
			med: {
				...model(med, setMed),
				label: 'Relance avec MED\n',
			},
			commandementDePayer: {
				...model(commandementDePayer, setCommandementDePayer),
				label: 'Commandement de payer\n',
			},
			relanceAmed: {
				...model(relanceAmed, setRelanceAmed),
				label: 'Relance après mise en demeure',
			},
			echeancier: {
				...model(echeancier, setEcheancier),
				label: 'Echéancier',
			},
			procedureAvancement: {
				...model(procedureAvancement, setProcedureAvancement),
				label: 'Avancement procédure',
				items: [
					null,
					'Transmis à l\'avocat',
					'Assigné',
					'Audiencé',
					'Jugé',
					'Désisté',
					'Soldé'
				].map(value => ({
					text: value || <i>Aucune</i>,
					value
				})),
			},
			executionAvancement: {
				...model(executionAvancement, setExecutionAvancement),
				label: 'Avancement exécution',
				items: [
					null,
					'A lancer',
					'En cours',
					'Infructueuse',
					'Soldée'
				].map(value => ({
					text: value || <i>Aucune</i>,
					value
				})),
			},
			typeProcedure: {
				...model(typeProcedure, setTypeProcedure),
				label: 'Type procédure',
				items: Object.values(RecouvrementRecordTypeProcedure).map(value => ({
					text: value || <i>Aucune</i>,
					value
				})),
			},
			typeExecution: {
				...model(typeExecution, setTypeExecution),
				label: 'Type exécution',
				items: Object.values(RecouvrementRecordTypeExecution).map(value => ({
					text: value || <i>Aucune</i>,
					value
				})),
			},
			dateAssignation: {
				...model(dateAssignation, setDateAssignation),
				label: 'Date Assignation',
			},
			dateAudience: {
				...model(dateAudience, setDateAudience),
				label: 'Date Audience',
			},
			dateJugement: {
				...model(dateJugement, setDateJugement),
				label: 'Date Jugement',
			},
			dateExecution: {
				...model(dateExecution, setDateExecution),
				label: 'Date Execution',
			},
			dateVoteSi: {
				...model(dateVoteSi, setDateVoteSi),
				label: 'Date Vote',
			},
			dateAudienceOrientation: {
				...model(dateAudienceOrientation, setDateAudienceOrientation),
				label: 'Date audience orientation',
			},
			dateAudienceAdjudication: {
				...model(dateAudienceAdjudication, setDateAudienceAdjudication),
				label: 'Date audience adjudication',
			},
			dateCpvsi: {
				...model(dateCpvsi, setDateCpvsi),
				label: 'Date commandement de payer',
			},
			etatSi: {
				...model(etatSi, setEtatSi),
				label: 'État saisie',
				items: Object.values(RecouvrementRecordEtatSi).map(value => ({
					text: value || <i>Aucune</i>,
					value
				})),
			},
			procedureDordre: {
				...model(procedureDordre, setProcedureDordre),
				label: 'Procédure d\'ordre',
				items: Object.values(RecouvrementRecordProcedureDordre).map(value => ({
					text: value || <i>Aucun</i>,
					value
				})),
			},
			precoAmiable: {
				...model(precoAmiable, setPrecoAmiable),
				label: 'Amiable / pré-contentieux'
			},
			precoProcedure: {
				...model(precoProcedure, setPrecoProcedure),
				label: 'Procédure'
			},
			precoSi: {
				...model(precoSi, setPrecoSi),
				label: 'Saisi Immobilière'
			},
			precoAccompSocial: {
				...model(precoAccompSocial, setPrecoAccompSocial),
				label: 'AS maintien'
			},
			precoPortageAdj: {
				...model(precoPortageAdj, setPrecoPortageAdj),
				label: 'AS changement de statut'
			},
			precoPortageAmia: {
				...model(precoPortageAmia, setPrecoPortageAmia),
				label: 'Ori portage amia.'
			},
			precoRelogement: {
				...model(precoRelogement, setPrecoRelogement),
				label: 'Besoin relogement'
			},
			statutPortage: {
				...model(statutPortage, setStatutPortage),
				label: 'Statut portage',
				items: Object.values(RecouvrementRecordStatutPortage).map(value => ({
					text: value || <i>Aucun</i>,
					value
				})),
			},
			origineSi: {
				...model(origineSi, setOrigineSi),
				label: 'Origine SI',
				items: [
					null,
					'SDC',
					'SP',
					'Tiers',
				].map(value => ({
					text: value || <i>Aucune</i>,
					value
				})),
			},
			commentaire: {
				...model(commentaire, setCommentaire),
				multiline: true,
				rows: 5,
			},
		}

	});

	const save = async () => {
		const newRecord: Partial<RecouvrementRecord> = {
			succession,
			planBdf,
			respectDossierSurend,
			etatSuiviSocial,
			organismeSuiviSocial,
			hypotheque,
			dateMaj: dateMaj ? dateMaj.toISOString() : null,
			relance,
			protocole,
			med,
			commandementDePayer,
			relanceAmed,
			echeancier,
			typeProcedure,
			procedureAvancement,
			executionAvancement,
			typeExecution,
			dateAssignation: dateAssignation ? dateAssignation.toISOString() : null,
			dateAudience: dateAudience ? dateAudience.toISOString() : null,
			dateJugement: dateJugement ? dateJugement.toISOString() : null,
			dateExecution: dateExecution ? dateExecution.toISOString() : null,
			dateVoteSi: dateVoteSi ? dateVoteSi.toISOString() : null,
			dateAudienceOrientation: dateAudienceOrientation ? dateAudienceOrientation.toISOString() : null,
			dateAudienceAdjudication: dateAudienceAdjudication ? dateAudienceAdjudication.toISOString() : null,
			dateCpvsi: dateCpvsi ? dateCpvsi.toISOString() : null,
			etatSi,
			procedureDordre,
			precoAmiable,
			precoProcedure,
			precoAccompSocial,
			precoPortageAdj,
			precoPortageAmia,
			precoSi,
			precoRelogement,
			statutPortage,
			origineSi,
			commentaire
		};
		await dispatch(putRecouvrementRecordById(record.id, newRecord));

		pushMessage(`Fiche de "${recouvrement?.nom || 'Inconnu'}" mise à jour.`);
	};

	const handlePrev = async () => {
		setLoading(true);
		try {
			await save();
			await onPrev();
		} catch (e) {
			console.error(e);
			pushMessage('Une erreur est survenue.', 'error');
		}
		setLoading(false);
	}

	const handleNext = async () => {
		setLoading(true);
		try {
			await save();
			await onNext();
		} catch (e) {
			console.error(e);
			pushMessage('Une erreur est survenue.', 'error');
		}
		setLoading(false);
	}

	return (
		<div className="c-Crud-RecouvrementRecord-Edit" >
			{recouvrement && record ? (
				<Form form={form}>
					<Card>
						<CardContent
							style={{
								maxHeight: 'calc(100vh - 200px)',
								overflow: 'auto',
							}}
						>
							<FormMainErrors form={form} />


							<Card className="p-1 mb-1">
								<Grid container>
									<Grid xs={12} md={4} lg={5}>

										<Grid container spacing={1}>
											<Grid xs={12} lg={6}>
												<h3 style={{ marginTop: '-10px' }}>{`${recouvrement.nom || 'Inconnu'}`} - {recouvrement.syndicat}</h3>
												<table>
													<tr><th className="u-t-left">Occupation :</th><td>{recouvrement?.statutOccupation}</td></tr>
													<tr><th className="u-t-left">Lots :</th><td>{recouvrement?.nombreLots}</td></tr>
													<tr><th className="u-t-left">Retard :</th><td>{!isNaN(recouvrement?.retard as any) ? formatNumber(recouvrement.retard) : '-'}</td></tr>
													<tr><th className="u-t-left">Solde SP :</th><td>{!isNaN(recouvrement?.soldeSp as any) ? formatNumber(recouvrement.soldeSp) : '-'}</td></tr>
												</table>
											</Grid>
											<Grid xs={12} lg={6} className="p-2">
												<div style={{ border: '1px solid #AAA', borderRadius: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
													<h2 className="my-2 u-d-flex u-a-center">Solde :</h2>
													<div className="u-t-size-24 mb-2">{!isNaN(recouvrement?.solde as any) ? `${formatNumber(recouvrement.solde, { decimal: 0 })}€` : '-'}</div>
												</div>
											</Grid>
										</Grid>
									</Grid>
									<Grid xs={12} md={8} lg={7}>
										<CrudRecouvrementRecordEditHeadChart />
									</Grid>
								</Grid>
							</Card>

							<Part2 form={form} echeancier={echeancier} setEcheancier={setEcheancier} />
							<Procedures form={form} />
							<SaisiesImmobilieres form={form} />
							<Part1 form={form} />
							{/*<Orientation form={form}/>*/}
							<Commentaire form={form} />
							<Part9 onRefresh={refresh} />
						</CardContent>
						<CardActions
							className="u-d-block"
						>
							<Button
								onClick={onClose}
							>
								Annuler et Fermer
							</Button>
							<LoadingButton
								style={{ float: 'right' }}
								type="button"
								loading={form.loading || loading}
								onClick={handleNext}
							>
								Enregistrer et suivant
							</LoadingButton>
							<LoadingButton
								style={{ float: 'right' }}
								type="submit"
								loading={form.loading || loading}
								variant="contained"
							>
								Enregistrer et fermer
							</LoadingButton>
							<LoadingButton
								style={{ float: 'right' }}
								type="button"
								loading={form.loading || loading}
								onClick={handlePrev}
							>
								Enregistrer et précédent
							</LoadingButton>
						</CardActions>
					</Card>
				</Form>
			) : (
				<div className="p-4 u-t-center">
					<CircularProgress size={150} thickness={2} />
				</div>
			)}
		</div>
	)
}
