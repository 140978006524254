import React, { useState, useCallback } from 'react';
import './AccompagnementSocial.scss';
import { useDispatch, useSelector } from '@/libs/redux';
import { useEffectOnChange, usePageable, useSnackMessage } from '@/libs/hooks';
import { hTriEvalSociale, ResultType, TriEvalSociale, Referent } from '@/models';
import TableRow from '@mui/material/TableRow';
import { Button, CircularProgress, Container, Dialog, IconButton, Tooltip } from '@mui/material';
import IconModeEdit from '@mui/icons-material/ModeEdit';
import { useStore } from 'react-redux';
import { State } from '@/stores';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import TableCell from '@mui/material/TableCell';
import { findTriEvalSociales, setTriEvalSociales } from '@/stores/triEvalSociale';
import ExcelJS from 'exceljs';
import { LoadingButton } from '@mui/lab';
import AppBarPortal from '@/components/AppBarPortal/AppBarPortal';
import { CrudReferentSelect } from '@/components/crud/referent/Select/Select';
import { postAccompagnementSocial } from '@/stores/accompagnementSocial';
import CrudReferentRecordEdit from '@/components/crud/referent/Edit/Edit';
import type { AccompagnementSocial, HAccompagnementSocial } from '@/models/AccompagnementSocial';
import { toDate } from '@/libs/utils';
import TooltipElm from '@/components/Tooltip/TooltipElm';

export interface PageUsersIndexUserProps {
	item: TriEvalSociale;
	onEdit: (item: TriEvalSociale) => any;
}

export function PageSaisieAccompagnementSocialItem({ item, onEdit }: PageUsersIndexUserProps) {
	const evalSocial = hTriEvalSociale(item);

	return (
		<TableRow>
			<TableCell>
				<TooltipElm message={evalSocial.id || <span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
					-
				</span>} />
			</TableCell>
			<TableCell>
				<TooltipElm message={evalSocial.nomProprietaire || <span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
					-
				</span>} />
			</TableCell>
			<TableCell>
				<TooltipElm message={evalSocial.typeProprietaire || <span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
					-
				</span>} />
			</TableCell>
			<TableCell>
				<div style={{ whiteSpace: 'initial' }}>{evalSocial.presenceSyndicats || <span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
					-
				</span>}
				</div>
			</TableCell>
			<TableCell>
				<div style={{ textAlign: 'center' }}>
					<TooltipElm message={evalSocial.soldeSdc || <span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						-
					</span>} />
				</div>
			</TableCell>
			<TableCell>
				<div style={{ textAlign: 'center' }}>
					<TooltipElm message={evalSocial.soldeSp || <span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						-
					</span>} />
				</div>
			</TableCell>
			<TableCell>
				<TooltipElm message={evalSocial.typeAccompagnement || <span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
					-
				</span>} />
			</TableCell>
			<TableCell>
				<TooltipElm message={evalSocial.dateEvalSociale ? toDate(evalSocial.dateEvalSociale) : <span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
					-
				</span>} />
			</TableCell>
			<TableCell className="u-cell-sticky-right u-d-flex">
				<Tooltip title="Editer">
					<IconButton color="primary" onClick={() => onEdit(item)}>
						<IconModeEdit />
					</IconButton>
				</Tooltip>
			</TableCell>

		</TableRow>
	);
}

export default function PageSaisieAccompagnementSocial() {
	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const store = useStore();
	const triEvalSociales = useSelector((state: State) => state.triEvalSociale.triEvalSociales);
	const pageable = usePageable();
	const [edit, setEdit] = useState<Nullable<TriEvalSociale>>(null);
	const [selectReferentOpen, setSelectReferentOpen] = useState(false);
	const [selectedReferent, setSelectedReferent] = useState<Nullable<Referent>>(null);
	const [loading, setLoading] = useState(false);

	const [filters, setFilters] = useState({
		id: '',
		nomProprietaire: '',
		typeProprietaire: '',
		presenceSyndicats: '',
		structure: '',
		typeAccompagnement: '',
		dateEvalSociale: null,
		critereEvalSociale: '',
	});

	const exportToExcelJS = async () => {
		if (!triEvalSociales || triEvalSociales.data.length === 0) {
			alert("Aucune donnée à exporter.");
			return;
		}

		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet('Liste des Ménages');

		worksheet.columns = [
			{ header: 'ID', key: 'id', width: 10 },
			{ header: 'Nom Propriétaire', key: 'nomProprietaire', width: 35 },
			{ header: 'Type Propriétaire', key: 'typeProprietaire', width: 25 },
			{ header: 'Présence Syndicats', key: 'presenceSyndicats', width: 65 },
			{ header: 'Solde SDC', key: 'soldeSdc', width: 15 },
			{ header: 'Solde SP', key: 'soldeSp', width: 15 },
			{ header: 'Type Accompagnement', key: 'typeAccompagnement', width: 25 },
			{ header: 'Date Eval Sociale', key: 'dateEvalSociale', width: 20 },
		];

		triEvalSociales.data.forEach(item => {
			worksheet.addRow(item);
		});

		worksheet.eachRow((row, rowNumber) => {
			row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
				if (rowNumber === 1) {
					cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
					cell.alignment = { horizontal: 'left' };
					cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '808080' } };
				} else {
					cell.fill = {
						type: 'pattern',
						pattern: 'solid',
						fgColor: { argb: rowNumber % 2 === 0 ? 'D8D8D8' : 'FFFFFFFF' },
					};
				}
				cell.border = {
					left: { style: 'thin', color: { argb: 'FFCCCCCC' } },
					right: { style: 'thin', color: { argb: 'FFCCCCCC' } },
				};
			});
		});

		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.href = url;
		anchor.download = 'AccompagnementSocial.xlsx';
		anchor.click();

		window.URL.revokeObjectURL(url);
		anchor.remove();
	};

	const refresh = useCallback(() => {
		(async () => {
			try {
				await dispatch(findTriEvalSociales(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, filters));
			} catch (e) {
				console.error(e);
				pushMessage('Une erreur est survenue.', 'error');
			}
		})();
	}, [filters, pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage]);

	useEffectOnChange(() => {
		refresh();
	}, [refresh]);

	const waitReferents = async (): Promise<ResultType<TriEvalSociale>> => {
		const state = store.getState() as State;
		const triEvalSociales = state.triEvalSociale.triEvalSociales;
		if (triEvalSociales) {
			return triEvalSociales;
		}

		await new Promise(r => setTimeout(r, 50));
		return waitReferents();
	};

	const searchPrevPage = async (cPage: Nullable<number> = null) => {
		cPage = cPage !== null ? cPage : pageable.page;
		if (cPage - 1 < 0) {
			pushMessage('Vous avez atteint le premier élément modifiable', 'warning');
		} else {
			dispatch(setTriEvalSociales(null));
			pageable.setPage(cPage - 1);
			const triEvalSociales = await waitReferents();
			let newTriEvalSociale: Nullable<TriEvalSociale> = null;
			for (let i = triEvalSociales.data.length - 1; i >= 0; i--) {
				const item = triEvalSociales.data[i];
				newTriEvalSociale = item;
				break;
			}
			if (newTriEvalSociale) {
				setEdit(null);
				await new Promise(r => setTimeout(r, 10));
				setEdit(newTriEvalSociale);
			} else {
				await searchPrevPage(cPage - 1);
			}
		}
	};

	const searchNextPage = async (cPage: Nullable<number> = null) => {
		cPage = cPage !== null ? cPage : pageable.page;
		dispatch(setTriEvalSociales(null));
		pageable.setPage(cPage + 1);
		const triEvalSociales = await waitReferents();
		if (!triEvalSociales?.data?.length) {
			dispatch(setTriEvalSociales(null));
			pageable.setPage(cPage);
			pushMessage('Vous avez atteint le dernier élément modifiable', 'warning');
		} else {
			let newTriEvalSociale: Nullable<TriEvalSociale> = null;
			for (let i = 0; i < triEvalSociales.data.length; i++) {
				const item = triEvalSociales.data[i];
				newTriEvalSociale = item;
				break;
			}
			if (newTriEvalSociale) {
				setEdit(null);
				await new Promise(r => setTimeout(r, 10));
				setEdit(newTriEvalSociale);
			} else {
				await searchNextPage(cPage + 1);
			}
		}
	};

	const handlePrev = async () => {
		if (triEvalSociales?.data && edit) {
			const currentIndex = triEvalSociales.data.map(r => r.id).indexOf(edit.id);
			let newTriEvalSociale: Nullable<TriEvalSociale> = null;
			for (let i = currentIndex - 1; i >= 0; i--) {
				const item = triEvalSociales.data[i];
				newTriEvalSociale = item;
				break;
			}

			if (newTriEvalSociale) {
				setEdit(null);
				await new Promise(r => setTimeout(r, 10));
				setEdit(newTriEvalSociale);
			} else {
				await searchPrevPage();
			}
		}
	};

	const handleNext = async () => {
		if (triEvalSociales?.data && edit) {
			const currentIndex = triEvalSociales.data.map(r => r.id).indexOf(edit.id);
			let newTriEvalSociale: Nullable<TriEvalSociale> = null;
			for (let i = currentIndex + 1; i < triEvalSociales.data.length; i++) {
				const item = triEvalSociales.data[i];
				newTriEvalSociale = item;
				break;
			}

			if (newTriEvalSociale) {
				setEdit(null);
				await new Promise(r => setTimeout(r, 10));
				setEdit(newTriEvalSociale);
			} else {
				await searchNextPage();
			}
		}
	};

	const handleOpenSelectReferent = () => {
		setSelectReferentOpen(true);
	};

	const handleCloseSelectReferent = () => {
		setSelectReferentOpen(false);
	};

	const handleReferentChange = (newReferents: Referent[]) => {
		if (newReferents.length > 0) {
			setSelectedReferent(newReferents[0]);
		}
	};

	const handleCreateAccompagnementSocial = async () => {
		if (selectedReferent) {
			setLoading(true);
			try {
				const accompagnementSocial: Partial<AccompagnementSocial> = {
					id: selectedReferent.id,
					// Ajoutez d'autres propriétés nécessaires pour créer un accompagnement social ici
					// Example:
					// nom: '',
					// age: 0,
				};
				await dispatch(postAccompagnementSocial(selectedReferent));
				pushMessage('Accompagnement social créé avec succès.', 'success');
			} catch (error) {
				console.error(error);
				pushMessage('Une erreur est survenue lors de la création de l\'accompagnement social.', 'error');
			} finally {
				setLoading(false);
				setSelectReferentOpen(false);
				setSelectedReferent(null);
				refresh();
			}
		}
	};

	return (
		<Container className="p-saisie-AccompagnementSocial">
			<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', marginTop: '10px' }}>
				<div style={{ marginLeft: 'auto' }}>
					<LoadingButton
						onClick={exportToExcelJS}
						loading={false}
						loadingIndicator={<CircularProgress color="inherit" />}
						variant="contained"
						color="primary"
						size="small"
					>
						Exporter sur Excel
					</LoadingButton>
				</div>
			</div>
			<SortableTable
				heads={[
					{ name: 'ID', sort: 'id' },
					{ name: 'Nom propriétaire', sort: 'nomProprietaire' },
					{ name: 'Type propriétaire', sort: 'typeProprietaire' },
					{ name: 'Présence syndicats', sort: 'presenceSyndicats' },
					{ name: 'Solde SDC', sort: 'soldeSdc' },
					{ name: 'Solde SP', sort: 'soldeSp' },
					{ name: 'Type accompagnement', sort: 'typeAccompagnement' },
					{ name: 'Date éval sociale', sort: 'dateEvalSociale' },
					{ name: 'Actions', stickyRight: true },
				]}
				result={triEvalSociales}
				renderItem={(item) => (
					<PageSaisieAccompagnementSocialItem
						item={item}
						onEdit={setEdit}
					/>
				)}
				pageable={pageable}
			/>

			<Dialog open={!!edit} onClose={() => setEdit(null)} maxWidth={'xl'}>
				<CrudReferentRecordEdit
					item={edit}
					onClose={() => setEdit(null)}
					onUpdate={() => refresh()}
					onPrev={handlePrev}
					onNext={handleNext}
				/>
			</Dialog>

			<AppBarPortal>
				<Button
					style={{ float: 'right' }}
					variant="contained"
					onClick={handleOpenSelectReferent}
				>
					Créer un accompagnement social
				</Button>
			</AppBarPortal>

			<Dialog open={selectReferentOpen} onClose={handleCloseSelectReferent} maxWidth="lg">
				<CrudReferentSelect
					value={selectedReferent ? [selectedReferent] : []}
					onChange={handleReferentChange}
					onClose={handleCloseSelectReferent}
				/>
				<Button
					variant="contained"
					onClick={handleCreateAccompagnementSocial}
					disabled={!selectedReferent || loading}
				>
					Sauvegarder
				</Button>
			</Dialog>
		</Container>
	);
}

PageSaisieAccompagnementSocial.getMetadata = () => {
	return {
		rights: ['SAISIE_ACCOMPAGNEMENT_SOCIAL'],
		title: 'Accompagnement social - Liste des Ménages',
	};
};
