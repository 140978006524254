import { AnyAction } from 'redux';
import { SubStore } from '@/libs/redux';

export type SnackMessageType = 'success'|'error'|'warning';

export interface SnackMessageItem {
	text: string,
	type: SnackMessageType,
	id: number,
}

export class SnackMessageState {
	public messages: SnackMessageItem[] = [];
}

export enum ActionTypes {
	PUSH = 'SNACK_MESSAGE_PUSH',
	POP = 'SNACK_MESSAGE_POP',
}

export default {
	state: SnackMessageState,
	reducer(state: SnackMessageState, action: AnyAction) {
		switch (action.type) {
			case ActionTypes.PUSH:
				state.messages = [ ...state.messages, action.message ];
				break;
			case ActionTypes.POP:
				const index = state.messages.map(m => m.id).indexOf(action.message.id);
				if (index !== -1) {
					state.messages.splice(index, 1);
					state.messages = [...state.messages];
				}
				break;
		}
		return state;
	},
} as SubStore;


// MUTATIONS

export const messagePush = (message: SnackMessageItem) => (dispatch: DispatchApp) => {
	return dispatch({ state: SnackMessageState, type: ActionTypes.PUSH, message });
}

export const messagePop = (message: SnackMessageItem) => (dispatch: DispatchApp) => {
	return dispatch({ state: SnackMessageState, type: ActionTypes.POP, message });
}

// ACTIONS
let id = 0;
export const snackMessagePush = (text: string, type: SnackMessageType) => async (dispatch: DispatchApp) => {
	const message = {
		id: ++id,
		text,
		type
	};
	dispatch(messagePush(message));
	await new Promise(r => setTimeout(r, 6000));
	dispatch(messagePop(message));
}
