import { FieldAroundProps, FieldFormProps, useFieldAround, useFieldForm } from '@/components/inputs/generic';
import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { TextFieldProps } from '@mui/material';


export interface DatePickerSpecific {
	onBlur?: (event: FocusEvent) => any;
	error?: boolean;
	helperText?: Nullable<string>;
	inputProps?: Partial<TextFieldProps['InputProps']>;
	textField?: Partial<TextFieldProps>;
}

export type DatePickerProps = DatePickerSpecific & FieldFormProps & FieldAroundProps & Omit<MuiDatePickerProps<any>, 'onChange'|'onChange'>;

export default function DatePicker(
	{
		rules = [],
		clearable = false,
		appendIcon = null,
		prependIcon = null,
		appendIconProps = {},
		prependIconProps = {},
		appendSlot = null,
		prependSlot = null,
		value,
		onChange,
		ref,
		className = '',
		onBlur = () => {},
		inputProps = {},
		error = false,
		helperText = null,
		textField = {},
		...rest
	}: DatePickerProps
) {

	const fieldForm = useFieldForm({
		className: `c-inputs-DatePicker ${className}`,
		rules,
		ref,
		value,
		onChange,
		defaultValue: '',
		error,
		helperText,
	});

	const fielAround = useFieldAround({
		clearable,
		appendIcon,
		prependIcon,
		appendIconProps,
		prependIconProps,
		appendSlot,
		prependSlot,
		markClear: fieldForm.markClear,
	});
	
	const handleBlur = (e: any) => {
		onBlur(e);
		fieldForm.markTouched();
	};
	
	const valueDJS = dayjs(fieldForm.value);
	const handleChange = (value: Dayjs) => {
		fieldForm.onChange(value && value.isValid() ? value.toDate() : null);
	}
	
	return (
		<MuiDatePicker
			{...rest}
			{...fieldForm.props}
			value={valueDJS}
			onChange={handleChange}
			slotProps={{
				textField: {
					onBlur: handleBlur,
					error: fieldForm.error,
					helperText: fieldForm.helperText,
					InputProps: {
						...fielAround,
						...inputProps
					},
					...textField
				},
			}}
			className={fieldForm.className}
			ref={fieldForm.ref}
		/>
	);
}
