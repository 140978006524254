// src/stores/accompagnementSocial.ts

import { mapReducer, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { AccompagnementSocial, ResultType } from '@/models';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class AccompagnementSocialState {
  public accompagnementSocials: Nullable<ResultType<AccompagnementSocial>> = null;
  public accompagnementSocial: Nullable<AccompagnementSocial> = null;
}

export default {
  state: AccompagnementSocialState,
  reducer: mapReducer(['accompagnementSocials', 'accompagnementSocial']),
} as SubStore;

// ACTIONS

export const putAccompagnementSocial = (
  accompagnementSocial: Partial<AccompagnementSocial>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<AccompagnementSocial> => {
  return await caller.put<AccompagnementSocial>(`/api/accompagnement-social/${accompagnementSocial.id}`, accompagnementSocial);
});

export const postAccompagnementSocial = (
  accompagnementSocial: Partial<AccompagnementSocial>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<AccompagnementSocial> => {
  return await caller.post<AccompagnementSocial>(`/api/accompagnement-social`, accompagnementSocial);
});

export const deleteAccompagnementSocial = (
  accompagnementSocial: AccompagnementSocial,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<void> => {
  await caller.delete(`/api/accompagnement-social/${accompagnementSocial.id}`);
});

export const findAccompagnementSocialById = (
  id: string,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<AccompagnementSocial> => {
  return await caller.get<AccompagnementSocial>(`/api/accompagnement-social/${encodeURIComponent(id)}`);
});

export const findAccompagnementSocials = (
  page: number,
  order: string,
  limit: number,
  filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<AccompagnementSocial>> => {
  return await caller.get<ResultType<AccompagnementSocial>>(`/api/accompagnement-social${UriHelper.queries({
    page,
    order,
    limit,
    filters: JSON.stringify(filters)
  })}`);
});
;
