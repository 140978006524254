import Select from '@/components/inputs/Select/Select';
import { Direction, hUser } from '@/models';
import { AutoCompleteProps } from '@/components/inputs/AutoComplete/AutoComplete';
import { useEffectOnChange, useSnackMessage } from '@/libs/hooks';
import { useDispatch, useSelector } from '@/libs/redux';
import { findUsers } from '@/stores/user';
import { CircularProgress } from '@mui/material';

export interface CrudUserSelectProp extends Omit<AutoCompleteProps, 'items'|'itemText'|'itemValue'> {
	syndicats?: string;
}

export default function CrudUserSelect(
	{
		syndicats,
		...props
	}: CrudUserSelectProp
) {
	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const users = useSelector(state => state.user.users);

	useEffectOnChange(() => {
		(async () => {
			try {
				await dispatch(findUsers(0, '', Direction.ASC, 200, {
					syndicats
				}));
			} catch (e) {
				pushMessage('Une erreur est survenue.', 'error');
			}
		})();
	}, [ syndicats ]);

	return users ? (
		<Select
			items={users.data.map(u => hUser(u))}
			itemText="fullNameDetail"
			itemValue="id"
			{...props}
		/>
	) : <CircularProgress />;

}
