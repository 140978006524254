import React, { useState, useCallback } from 'react';
import { Card, CardContent, CardActions, CardHeader, TableCell, TableRow, Button } from '@mui/material';
import { useDispatch, useSelector } from '@/libs/redux';
import { usePageable, useSnackMessage, useEffectOnChange, usePropState } from '@/libs/hooks';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import { model } from '@/libs/candy';
import { findReferents } from '@/stores/referent';
import { Referent } from '@/models';

export interface CrudReferentSelectProps {
    value: Referent[];
    onChange: (v: Referent[]) => any;
    onClose: () => any;
}

export function CrudReferentSelect({ value, onChange, onClose }: CrudReferentSelectProps) {
    const dispatch = useDispatch();
    const pushMessage = useSnackMessage();
    const pageable = usePageable();
    const referents = useSelector(state => state.referent.referents);

    const [filters, setFilters] = useState({
        id: '',
        nom: '',
        prenom: '',
        email: '',
    });

    const refresh = useCallback(() => {
        (async () => {
            try {
                await dispatch(findReferents(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, filters));
            } catch (e) {
                console.error(e);
                pushMessage('Une erreur est survenue.', 'error');
            }
        })();
    }, [filters, pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage]);

    useEffectOnChange(() => {
        refresh();
    }, [refresh]);

    return (
        <Card>
            <CardHeader className="py-1 mb-1" title="Sélectionnez un référent" titleTypographyProps={{ className: 'u-t-size-19' }}/>
            <CardContent>
                <SortableTable
                    style={{ maxHeight: 'calc(100vH - 400px)' }}
                    heads={[
                        { name: 'Nom', sort: 'nom' },
                        { name: 'Prénom', sort: 'prenom' },
                        { name: 'Email', sort: 'email' },
                        { name: 'Téléphone', sort: 'telephone' },
                        { name: 'Actions' },
                    ]}
                    result={referents}
                    renderItem={(item: Referent) => (
                        <CrudReferentSelectItem
                            value={value}
                            onChange={onChange}
                            item={item}
                        />
                    )}
                    pageable={pageable}
                />
            </CardContent>
            <CardActions>
                <Button onClick={onClose}>Fermer</Button>
            </CardActions>
        </Card>
    );
}

export interface CrudReferentSelectItemProps {
    item: Referent;
    value: Referent[];
    onChange: (v: Referent[]) => any;
}

export function CrudReferentSelectItem({ item, value, onChange }: CrudReferentSelectItemProps) {
    const selected = value.map(r => r.id).includes(item.id);

    return (
        <TableRow>
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.nom}</TableCell>
            <TableCell>{item.libre1}</TableCell>
            <TableCell>{item.libre2}</TableCell>
            <TableCell>
                <Button
                    onClick={() => {
                        if (!selected) {
                            onChange([item]); // Only allow single selection
                        }
                    }}
                    disabled={selected}
                >
                    {selected ? 'Sélectionné' : 'Sélectionner'}
                </Button>
            </TableCell>
        </TableRow>
    );
}
