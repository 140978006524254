import Select, { SelectProps } from '@/components/inputs/Select/Select';
import { RecouvrementCategorieImpayes, RecouvrementStatusOccupation } from '@/models';
import { useTranslation } from 'react-i18next';
import { enumToValueText } from '@/libs/utils/EnumHelper';

export interface CrudRecouvrementStatusOccupationSelectProp extends Omit<SelectProps, 'items'|'itemText'|'itemValue'> {
}

export default function CrudRecouvrementStatusOccupationSelect(
	props: CrudRecouvrementStatusOccupationSelectProp
) {
	const { t } = useTranslation();

	return (
		<Select
			items={enumToValueText(t, 'models.recouvrement_status_occupation.values', RecouvrementStatusOccupation)}
			{...props}
		/>
	);
}
