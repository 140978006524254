import { Lot } from '@/models/Lot';
import { buildModelHelper } from '@/libs/modelHelper';
import { Acquisition } from '@/models/Acquisition';

export interface AcquisitionLot {
	id: number;
	acquisition?: Acquisition;
	lot?: Lot;
}


export const hAcquisitionLot = buildModelHelper<AcquisitionLot>({
});

export type HAcquisitionLot = ReturnType<typeof hAcquisitionLot>;
