import { Card, CardContent } from '@mui/material';
import { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import { useEffectTimeout, usePropState } from '@/libs/hooks';
import { CardProps } from '@mui/material/Card/Card';
import CrudUserSyncicatSelect from '@/components/crud/userSyncicat/Select/Select';
import TextField from '@/components/inputs/TextField/TextField';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { UserSyncicat } from '@/models';
import CrudTypeLotSelect from '@/components/crud/typeLot/Select/Select';

export interface CrudTriLotFiltersProps extends CardProps {
	value?: Nullable<any>;
	onChange?: (value: Nullable<any>) => any,
}

export default function CrudTriLotFilters(
	{
		value,
		onChange,
		className = '',
		...rest
	}: CrudTriLotFiltersProps
) {

	const [ filters, setFilters ] = usePropState<any>(value, onChange, {
		syndicat: [],
		numero: '',
		adresse: '',
		escalier: '',
		etage: '',
		position: '',
		typeId: [],
		proprietaire: '',
		nomProprietaire: '',
	});

	const [ open, setOpen] = useState<boolean>(false);
	const [ syndicat, setSyndicat ] = useState<UserSyncicat>(filters.syndicat);
	const [ typeId, setTypeId ] = useState<string[]>(filters.typeId);
	const [ numero, setNumero ] = useState<string>(filters.numero);
	const [ adresse, setAdresse ] = useState<string>(filters.adresse);
	const [ escalier, setEscalier ] = useState<string>(filters.escalier);
	const [ etage, setEtage ] = useState<string>(filters.etage);
	const [ position, setPosition ] = useState<string>(filters.position);
	const [ proprietaire, setProprietaire ] = useState<string>(filters.typeId);
	const [ nomProprietaire, setNomProprietaire ] = useState<string>(filters.proprietaire);
	
	useEffectTimeout(() => {
		if (
			filters.syndicat !== syndicat ||
			filters.numero !== numero ||
			filters.adresse !== adresse ||
			filters.escalier !== escalier  ||
			filters.etage !== etage||
			filters.position !== position ||
			filters.typeId !== typeId ||
			filters.proprietaire !== proprietaire ||
			filters.nomProprietaire !== nomProprietaire
		) {
			setFilters({
				syndicat,
				numero,
				adresse,
				escalier,
				etage,
				position,
				typeId,
				proprietaire,
				nomProprietaire,
			})
		}
	}, 500, [
		syndicat,
		numero,
		adresse,
		escalier,
		etage,
		position,
		typeId,
		proprietaire,
		nomProprietaire,
	]);
	
	return (
		<Card
			{...rest}
			className={[
				className,
				'mt-2',
				'mb-1',
				'c-Crud-Lot-Filters',
				open ? 'c-Crud-Lot-Filters--open' : ''
			].join(' ')}
		>
			<CardContent className="p-1">
				<div onClick={() => setOpen(!open)}>
					<h5 className="m-0" style={{cursor: 'pointer'}}>  {open ? <KeyboardArrowUp style={{ verticalAlign: '-7px' }} /> : <KeyboardArrowDown style={{ verticalAlign: '-7px' }} />}<span>Filtres :</span></h5>
				</div>
				{open && (
					<Grid container spacing={1}>
						<Grid xs={12} md={4}>
							<CrudUserSyncicatSelect
								{...model(syndicat, setSyndicat)}
								multiple
								clearable
								size="small"
								variant="standard"
								label="Syndicats"
								customFilter={s => s !== 'SP'}
							/>
						</Grid>
						<Grid xs={12} md={4}>
							<CrudTypeLotSelect
								{...model(typeId, setTypeId)}
								multiple
								clearable
								size="small"
								variant="standard"
								label="Type"
							/>
						</Grid>
						<Grid xs={12} md={4}><TextField {...model(numero, setNumero)} clearable size="small" variant="standard" label="Numéro" /></Grid>
						<Grid xs={12} md={4}><TextField {...model(adresse, setAdresse)} clearable size="small" variant="standard" label="Adresse" /></Grid>
						<Grid xs={12} md={4}><TextField {...model(escalier, setEscalier)} clearable size="small" variant="standard" label="Escalier" /></Grid>
						<Grid xs={12} md={4}><TextField {...model(etage, setEtage)} clearable size="small" variant="standard" label="Étage" /></Grid>
						<Grid xs={12} md={4}><TextField {...model(position, setPosition)} clearable size="small" variant="standard" label="Position" /></Grid>
						<Grid xs={12} md={4}><TextField {...model(proprietaire, setProprietaire)} clearable size="small" variant="standard" label="Propriétaire" /></Grid>
						<Grid xs={12} md={4}><TextField {...model(nomProprietaire, setNomProprietaire)} clearable size="small" variant="standard" label="Nom Propriétaire" /></Grid>
					</Grid>
				)}
			</CardContent>
		</Card>
	)
}
