import { buildModelHelper } from '@/libs/modelHelper';
import { hUser } from '@/models/User';

export enum FeedbackStatus {
	NEW = 'NEW',
	COPRO_PLUS = 'COPRO_PLUS',
	CLOSE = 'CLOSE',
}
export interface Feedback {
	id: number;
	message: string;
	status: FeedbackStatus;
	createdAt: string;
	updatedAt: string;
}

export const hFeedback = buildModelHelper<Feedback>({
	get createdAt() {
		return this._self.createdAt ? new Date(this._self.createdAt) : null;
	},
	get updatedAt() {
		return this._self.updatedAt ? new Date(this._self.updatedAt) : null;
	},
	get user() {
		return hUser(this._self.user);
	},
})

export type HFeedback = ReturnType<typeof hFeedback>;
