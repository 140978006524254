import './Acquisitions.scss';
import { useDispatch, useSelector } from '@/libs/redux';
import { useEffectOnChange, usePageable, useSnackMessage } from '@/libs/hooks';
import { useCallback, useState } from 'react';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import { Direction, hTriAcqui, ResultType, TriAcqui } from '@/models';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Button, CircularProgress, Container, Dialog, IconButton, Tooltip } from '@mui/material';
import IconModeEdit from '@mui/icons-material/ModeEdit';
import CrudLotRecordEdit from '@/components/crud/acquisition/Edit/Edit';
import { useStore } from 'react-redux';
import { State } from '@/stores';
import IconDelete from '@mui/icons-material/Delete';
import { deleteAcquisitionById } from '@/stores/acquisition';
import Confirm from '@/components/dialog/Confirm/Confirm';
import AppBarPortal from '@/components/AppBarPortal/AppBarPortal';
import { findTriAcquis, setTriAcquis } from '@/stores/triAcqui';
import TooltipElm from '@/components/Tooltip/TooltipElm';
import CrudTriAcquiFilters from '@/components/crud/triAcqui/Filters/Filters';
import { model } from '@/libs/candy';
import { UriHelper } from '@/libs/utils';
import LoadingBar from '@/components/LoadingBar/LoadingBar';
import { LoadingButton } from '@mui/lab';
import { dateToDash, formatDate, formatNumber, formatDateTime } from '@/libs/utils';

export interface PageUsersIndexUserProps {
	item: TriAcqui
	onEdit: (item: TriAcqui) => any,
	onRemove: (item: TriAcqui) => any,
}

export function PageSaisieAcquisitionsItem(
	{
		item,
		onEdit,
		onRemove
	}: PageUsersIndexUserProps
) {
	// const { t } = useTranslation();
	const triAcqui = hTriAcqui(item)
	// console.log('triAcqui.dateMaj : ', triAcqui.dateMaj);
	return (

		<TableRow>
			<TableCell><TooltipElm message={triAcqui.syndicat || <i>Auncun syndicat</i>} /></TableCell>
			<TableCell><TooltipElm message={triAcqui.lotReference || <i>Auncun lot</i>} /></TableCell>
			<TableCell><TooltipElm message={triAcqui.nombreLots || 0} /></TableCell>
			<TableCell><TooltipElm message={triAcqui.adresse || <i>Auncune adresse</i>} /></TableCell>
			<TableCell><TooltipElm message={triAcqui.etage ||
				<span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>-</span>} />
			</TableCell>
			<TableCell><TooltipElm message={triAcqui.typeVente ||
				<span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>-</span>} />
			</TableCell>
			<TableCell><TooltipElm message={triAcqui.statutDossier ||
				<span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>-</span>} />
			</TableCell>
			<TableCell><TooltipElm message={triAcqui.secteurSyndicat ||
				<span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>-</span>} />
			</TableCell>
			<TableCell><TooltipElm message={triAcqui.proprietaire || <i>Auncun Propriétaire</i>} /></TableCell>
			<TableCell><TooltipElm message={triAcqui.nomProprietaire ||
				<span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>-</span>} />
			</TableCell>
			<TableCell><TooltipElm message={triAcqui.responsable ||
				<span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>-</span>} />
			</TableCell>
			<TableCell><TooltipElm message={formatDateTime(triAcqui.dateMaj) ||
				<span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>-</span>} />
			</TableCell>
			{/* <TableCell><TooltipElm message={triAcqui.presenceSdc || <span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
				-
			</span>} />/TableCell> */}
			<TableCell className="u-cell-sticky-right u-d-flex">
				<Tooltip title="Editer">
					<IconButton
						color="primary"
						onClick={() => onEdit(item)}
					>
						<IconModeEdit />
					</IconButton>
				</Tooltip>
				<Tooltip title="Supprimer le fichier">
					<IconButton
						onClick={() => onRemove(item)}
					>
						<IconDelete />
					</IconButton>
				</Tooltip>
			</TableCell>
		</TableRow>
	);
}

export default function PageSaisieAcquisitions() {
	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const store = useStore<State>();
	const triAcquis = useSelector(state => state.triAcqui.triAcquis);
	const [edit, setEdit] = useState<Nullable<TriAcqui>>(null);
	const [remove, setRemove] = useState<Nullable<TriAcqui>>(null);
	// Par défaut, trier par 'dateMaj' en desc
	const [order, setOrder] = useState<string>('dateMaj'); // Colonne de tri par défaut
	const [direction, setDirection] = useState<'asc' | 'desc'>('desc'); // Ordre par défaut en 'desc'
	const pageable = usePageable();
    const pageableFilters = usePageable({
        order: 'dateMaj', // Colonne de tri par défaut
        direction: Direction.DESC, // Direction par défaut
    });
	
	const [filters, setFilters] = useState({
		syndicat: [],
		lotReference: '',
		adresse: '',
		typeVente: '',
		statutDossier: [],
		secteurSyndicat: '',
		structure: '',
		proprietaire: '',
		nomProprietaire: '',
		responsable: '',
		dateMaj: '',
	});

	const refresh = useCallback(() => {
		(async () => {
			try {
				// Conversion de 'asc' et 'desc' vers les valeurs de l'énumération Direction
				// await dispatch(findTriAcquis(pageable.page, order, direction === 'asc' ? Direction.ASC : Direction.DESC, pageable.rowsPerPage, filters));
				await dispatch(findTriAcquis(pageableFilters.page, pageableFilters.order, pageableFilters.direction, pageableFilters.rowsPerPage, filters));

			} catch (e) {
				console.error(e);
			}
		})();
	}, [filters, pageableFilters.page, pageableFilters.order, pageableFilters.direction, pageableFilters.rowsPerPage])

	// }, [filters, pageable.page, order, direction, pageable.rowsPerPage]);

	useEffectOnChange(() => {
		refresh();
	}, [refresh]);

	const waitAcquisitions = async (): Promise<ResultType<TriAcqui>> => {
		const triAcquis = store.getState().triAcqui.triAcquis;
		if (triAcquis) {
			return triAcquis;
		}

		await new Promise(r => setTimeout(r, 50));
		return waitAcquisitions();
	};

	const searchPrevPage = async (cPage: Nullable<number> = null) => {
		cPage = cPage !== null ? cPage : pageableFilters.page;
		if (cPage - 1 < 0) {
			pushMessage('Vous avez atteint le premier element modifiable', 'warning')
		} else {
			dispatch(setTriAcquis(null));
			pageableFilters.setPage(cPage - 1);
			const triAcquis = await waitAcquisitions();
			let newTriAcqui: Nullable<TriAcqui> = null;
			for (let i = triAcquis.data.length - 1; i >= 0; i--) {
				const item = triAcquis.data[i];
				newTriAcqui = item;
				break;
			}
			if (newTriAcqui) {
				setEdit(null);
				await new Promise(r => setTimeout(r, 10));
				setEdit(newTriAcqui);
			} else {
				await searchPrevPage(cPage - 1);
			}
		}
		if (!triAcquis?.data?.length) {
			dispatch(setTriAcquis(null));
			pageableFilters.setPage(cPage);
			pushMessage('Vous avez atteint le dernier element modifiable', 'warning')
		} else {
			let newTriAcqui: Nullable<TriAcqui> = null;
			for (let i = 0; i < triAcquis.data.length; i++) {
				const item = triAcquis.data[i];
				newTriAcqui = item;
				break;
			}
			if (newTriAcqui) {
				setEdit(null);
				await new Promise(r => setTimeout(r, 10));
				setEdit(newTriAcqui);
			} else {
				await searchNextPage(cPage + 1);
			}
		}
	}

	const handlePrev = async () => {
		if (triAcquis?.data && edit) {
			const currentIndex = triAcquis.data.map(r => r.id).indexOf(edit.id);
			let newTriAcqui: Nullable<TriAcqui> = null;
			for (let i = currentIndex - 1; i >= 0; i--) {
				const item = triAcquis.data[i];
				newTriAcqui = item;
				break;
			}

			if (newTriAcqui) {
				setEdit(null);
				await new Promise(r => setTimeout(r, 10));
				setEdit(newTriAcqui);
			} else {
				await searchPrevPage();
			}
		}
	}

	const searchNextPage = async (cPage: Nullable<number> = null) => {
		cPage = cPage !== null ? cPage : pageableFilters.page;
		dispatch(setTriAcquis(null));
		pageableFilters.setPage(cPage + 1);
		const triAcquis = await waitAcquisitions();
		if (!triAcquis?.data?.length) {
			dispatch(setTriAcquis(null));
			pageableFilters.setPage(cPage);
			pushMessage('Vous avez atteint le dernier element modifiable', 'warning')
		} else {
			let newTriAcqui: Nullable<TriAcqui> = null;
			for (let i = 0; i < triAcquis.data.length; i++) {
				const item = triAcquis.data[i];
				newTriAcqui = item;
				break;
			}
			if (newTriAcqui) {
				setEdit(null);
				await new Promise(r => setTimeout(r, 10));
				setEdit(newTriAcqui);
			} else {
				await searchNextPage(cPage + 1);
			}
		}
	}

	const handleNext = async () => {
		if (triAcquis?.data && edit) {
			const currentIndex = triAcquis.data.map(r => r.id).indexOf(edit.id);
			let newTriAcqui: Nullable<TriAcqui> = null;
			for (let i = currentIndex + 1; i < triAcquis.data.length; i++) {
				const item = triAcquis.data[i];
				newTriAcqui = item;
				break;
			}

			if (newTriAcqui) {
				setEdit(null);
				await new Promise(r => setTimeout(r, 10));
				setEdit(newTriAcqui);
			} else {
				await searchNextPage();
			}
		}
	}

	const token = useSelector(state => state.token.token);

	const href = `${process.env.REACT_APP_CLIENT_API_URI as string}/download/tri-acquis/export?${UriHelper.queries({
		token: token?.id,
		order: pageable.order,
		direction: pageable.direction,
		filters: JSON.stringify(filters),
	})}`;


	return (
		<Container className="p-saisie-Acquisitions">

			<CrudTriAcquiFilters
				{...model(filters, setFilters)}
			/>
			<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
				<div style={{ marginLeft: 'auto' }}>
					{/* Bouton pour redirection */}
					<LoadingButton
						variant="contained"
						style={{
							color: 'white', // Ensure the text is white for contrast
							marginLeft: '10px'
						}}
						size="small"
						onClick={() => window.open(href, '_blank')} // Fonctionnalité de lien
					>
						Exporter sur Excel
					</LoadingButton>
				</div>
			</div>
			<SortableTable
				heads={[
					{ name: 'Syndicat', sort: 'syndicat' },
					{ name: 'Lot référence', sort: 'lotReference' },
					{ name: 'Nombre lots', sort: 'nombreLots' },
					{ name: 'Adresse', sort: 'adresse' },
					{ name: 'Etage', sort: 'etage' },
					{ name: 'Type vente', sort: 'typeVente' },
					{ name: 'Statut dossier', sort: 'statutDossier' },
					{ name: 'Secteur', sort: 'secteurSyndicat' },
					{ name: 'Propriétaire', sort: 'proprietaire' },
					{ name: 'Nom propriétaire', sort: 'nomProprietaire' },
					{ name: 'Responsable', sort: 'responsable' },
					{ name: 'Date de mise à jour', sort: 'dateMaj' }, // Nouveau champ pour la date
					{ name: 'Actions', stickyRight: true },
				]}
				result={triAcquis} // Appliquer le tri par date ici
				renderItem={(item) => (
					<PageSaisieAcquisitionsItem
						item={item}
						onEdit={setEdit}
						onRemove={setRemove}
					/>
				)}
				pageable={pageableFilters}
			/>

			<Dialog
				open={!!edit}
				onClose={() => setEdit(null)}
				maxWidth={'xl'}
			>
				<CrudLotRecordEdit
					onClose={() => setEdit(null)}
					onUpdate={() => refresh()}
					onPrev={handlePrev}
					onNext={handleNext}
					item={edit}
				/>
			</Dialog>

			<Confirm
				value={!!remove}
				onChange={() => setRemove(null)}
				title="Suppression d'une acquisition"
				message={`Êtes--vous sûr de vouloir supprimer l'acquisition ${remove?.nomProprietaire} (${remove?.syndicat}) ?`}
				onConfirm={async () => {
					if (remove) {
						await dispatch(deleteAcquisitionById(remove.id));
					}
					refresh();
				}}
			/>

			<AppBarPortal>
				<Button
					style={{ float: 'right', marginRight: '10px' }}
					variant="contained"
					onClick={() => setEdit({} as any)}
				>
					Créer une acquisition
				</Button>
			</AppBarPortal>
		</Container>
	);
}

PageSaisieAcquisitions.getMetadata = () => {
	return {
		rights: ['SAISIE_ACQUISITIONS'],
		title: 'Acquisitions'
	}
}
