import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { Email, Referent, ResultType } from '@/models';
import { Direction } from '@/models/Direction';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class EmailState {
}

export default {
    state: EmailState,
    reducer: mapReducer([]),
} as SubStore;

// ACTIONS

export const putEmail = (
    email: Partial<Email>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Email> => {
    return await caller.put<Email>(`/api/emails/${email.id}`, {
        email1: email.email1,
        commentaire: email.commentaire,
		source: email.source,
		pertinence: email.pertinence,
    });
});

export const postEmail = (
    referent: Referent | string, // Accepter soit un objet soit un id
    email: Partial<Email>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Email> => {
    let referentId;

    if (typeof referent === 'string') {
        referentId = referent;
    } else if (typeof referent === 'object' && referent !== null) {
        // Vérifiez que referent a un id
        if (!referent.id) {
            console.error('Referent missing id:', referent);
            throw new Error('Referent object is missing id property.');
        }

        // Utilisez directement l'ID du référent
        referentId = referent.id;
    } else {
        console.error('Invalid referent:', referent);
        throw new Error('Invalid referent. Expected an object or a string but received a non-valid type.');
    }

    // Vérifiez si referentId est défini
    if (!referentId) {
        console.error('referent object:', referent);
        throw new Error('referentId is undefined. Please check the referent object.');
    }

    return await caller.post<Email>(`/api/emails/${encodeURIComponent(referentId)}`, {
        email1: email.email1,
        commentaire: email.commentaire,
        source: email.source,
		pertinence: email.pertinence,
    });
});


export const deleteEmail = (
    email: Email,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Email> => {
    return await caller.delete<Email>(`/api/emails/${email}`);
});
