import { UserSyncicat } from '@/models/UserSyncicat';
import { FileSlotPeriodicity } from '@/models/FileSlotPeriodicity';
import { DocumentFile, hDocumentFile, HDocumentFile } from '@/models/DocumentFile';
import { buildModelHelper } from '@/libs/modelHelper';
import { DocumentStatus } from '@/models/DocumentStatus';
import { DocumentType } from '@/models/DocumentType';

export interface Document {
	syndicat: UserSyncicat;
	periodicity: FileSlotPeriodicity;
	documentType: DocumentType;
	date: string;
	date2: string;
	year: number;
	quarter: number;
	fileName: string;
	file: DocumentFile;
	status: DocumentStatus;
	createdAt: string;
}

export const hDocument = buildModelHelper<Document>({
	get file(): HDocumentFile {
		return hDocumentFile(this._self.file);
	},
	get createdAt(): Nullable<Date> {
		return this._self.createdAt ? new Date(this._self.createdAt) : null;
	},
	get isPDF(): boolean {
		return this.fileName.substring(this.fileName.length - 4).toLowerCase() === '.pdf';
	}
});

export type HDocument = ReturnType<typeof hDocument>;
