import './Annuaire.scss';
import { Container } from '@mui/material';

export default function Annuaire() {
	return (
		<Container className="p-documentaire-acteurs-Annuaire">
			Annuaire
		</Container>
	);
}
