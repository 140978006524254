import { useEffect, useState } from 'react';

function compareArrays(array1: any[], array2: any[]): boolean {
	if (array1 === array2) {
		return true;
	}
	if (!array1 || !array2) {
		return false;
	}
	if (array1.length !== array2.length) {
		return false;
	}

	for (let i = 0; i < array1.length; i++) {
		if (array1[i] !== array2[i]) {
			return false;
		}
	}

	return true;
}


export function useEffectOnChange(callback: () => any = () => {}, deps: any[] = []) {
	
	let [ prev, setPrev ] = useState<any>(null);

	// Mise à jour de la référence si le callback ou les dépendances changent
	useEffect(() => {
		if (!compareArrays(prev, deps)) {
			callback();
			prev = deps;
			setPrev(prev);
		}
	}, [ prev, ...deps ]); // eslint-disable-line react-hooks-static-deps/exhaustive-deps
}
