import { buildModelHelper } from '@/libs/modelHelper';

export interface TriEvalSociale {
	id: string;
	nomProprietaire: Nullable<string>;
	typeProprietaire: Nullable<string>;
	presenceSyndicats: Nullable<string>;
	soldeSdc: Nullable<number>;
	soldeSp: Nullable<number>;
	structure: Nullable<string>;
	typeAccompagnement: Nullable<string>;
	dateEvalSociale: Nullable<string>;
	critereEvalSociale: Nullable<boolean>;
}

export const hTriEvalSociale = buildModelHelper<TriEvalSociale>({
	get dateEvalSociale(): Nullable<Date> { 
		return this._self.dateEvalSociale ? new Date(this._self.dateEvalSociale) : null; 
	},
	set dateEvalSociale(value: Nullable<Date>) { 
		this._self.dateEvalSociale = value ? value.toISOString() : null; 
	},
});

export type HTriEvalSociale = ReturnType<typeof hTriEvalSociale>;
