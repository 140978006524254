import { FileSlot } from '@/models/FileSlot';
import { buildModelHelper } from '@/libs/modelHelper';
import { FileSlotPeriodicity } from '@/models/FileSlotPeriodicity';
import { Document, hDocument, HDocument } from '@/models/Document';
import { SlotStatus } from '@/models/SlotStatus';
import { DocumentTypeMode } from '@/models/DocumentTypeMode';

export interface Slot {
    fileSlot: FileSlot;
    date: string;
    quarter: number;
    year: number;
    documents: Document[];
    status: SlotStatus;
}

export const hSlot = buildModelHelper<Slot>({
    get date(): Date {
        return new Date(this._self.date);
    },
    get dateLess15(): Date {
        const date = new Date(this.date);
        date.setDate(date.getDate() - 15);
        return date;
    },
    get documents(): HDocument[] {
        return this._self.documents?.map((d: Document) => hDocument(d));
    },

    getPeriodicity(): string {
        const quarter = this.fileSlot.documentType.id === 1 || this.fileSlot.documentType.id === 3 ? this.calculateCurrentQuarter() : this.quarter;
        if (this.fileSlot.documentType.id === 1 || this.fileSlot.documentType.id === 3) {
            return `${this.year} - T${quarter}`;
        }
        if (this.fileSlot.documentType.id === 2) {
            if (quarter === 1) {
                return `31/03/${this.year}`;
            }
            if (quarter === 2) {
                return `30/06/${this.year}`;
            }
            if (quarter === 3) {
                return `30/09/${this.year}`;
            }
            if (quarter === 4) {
                return `31/12/${this.year}`;
            }
            return `${this.year} - T${quarter}`;
        }
        if (this.fileSlot.documentType.id === 7) {
            if (quarter === 1) {
                return `31/03/${this.year}`;
            }
            if (quarter === 2) {
                return `30/06/${this.year}`;
            }
            if (quarter === 3) {
                return `30/09/${this.year}`;
            }
            if (quarter === 4) {
                return `31/12/${this.year}`;
            }
            return `${this.year} - T${quarter}`;
        }
        if (this.fileSlot.documentType.id === 11) {
            if (quarter === 1) {
                return `31/03/${this.year}`;
            }
            if (quarter === 2) {
                return `30/06/${this.year}`;
            }
            if (quarter === 3) {
                return `30/09/${this.year}`;
            }
            if (quarter === 4) {
                return `31/12/${this.year}`;
            }
            return `${this.year} - T${quarter}`;
        }
        if (this.fileSlot.documentType.id === 8) {
            if (quarter === 1) {
                return `T1 ${this.year}`;
            }
            if (quarter === 2) {
                return `T2 ${this.year}`;
            }
            if (quarter === 3) {
                return `T3 ${this.year}`;
            }
            if (quarter === 4) {
                return `T4 ${this.year}`;
            }
            return `${this.year} - T${quarter}`;
        } else {
            if (this.fileSlot.periodicity === FileSlotPeriodicity.ANNUAL) {
                return `Exercice ${this.year}`;
            }
            if (this.fileSlot.periodicity === FileSlotPeriodicity.NONE) {
                return `Sans périodicité`;
            }
            return `T${quarter} ${this.year}`;
        }
    },

    calculateCurrentQuarter(): number {
        const now = new Date();
        const month = now.getMonth();
        if (month < 3) return 1;
        if (month < 6) return 2;
        if (month < 9) return 3;
        return 4;
    },

    get start(): Nullable<Date> {
        const date = new Date();
        if (this.fileSlot.documentType.mode === DocumentTypeMode.START_END) {
            if (this.fileSlot.documentType.id === 1 || this.fileSlot.documentType.id === 3) {
                date.setFullYear(this.year, 0, 1);
            } else if (this.fileSlot.periodicity === FileSlotPeriodicity.ANNUAL) {
                date.setFullYear(this.year, 0, 1);
            } else if (this.fileSlot.periodicity === FileSlotPeriodicity.QUARTERLY) {
                if (this.quarter === 1) date.setFullYear(this.year, 0, 1);
                if (this.quarter === 2) date.setFullYear(this.year, 3, 1);
                if (this.quarter === 3) date.setFullYear(this.year, 6, 1);
                if (this.quarter === 4) date.setFullYear(this.year, 9, 1);
            }
        } else if (this.fileSlot.documentType.mode === DocumentTypeMode.DATE) {
            return this.date;
        }
        return date;
    },

    get end(): Nullable<Date> {
        const date = new Date();
        if (this.fileSlot.documentType.mode === DocumentTypeMode.START_END) {
            if (this.fileSlot.periodicity === FileSlotPeriodicity.ANNUAL) {
                date.setFullYear(this.year, 11, 31);
            } else if (this.fileSlot.periodicity === FileSlotPeriodicity.QUARTERLY) {
                if (this.quarter === 1) date.setFullYear(this.year, 2, 31);
                if (this.quarter === 2) date.setFullYear(this.year, 5, 30);
                if (this.quarter === 3) date.setFullYear(this.year, 8, 30);
                if (this.quarter === 4) date.setFullYear(this.year, 11, 31);
            }
        }
        return date;
    },
});

export type hHSlot = ReturnType<typeof hSlot>;
