import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import { HSuiviSocial, Referent, SuiviSocial } from '@/models';
import { useState } from 'react';
import { useForm } from '@/libs/form';
import { useDispatch } from '@/libs/redux';
import { LoadingButton } from '@mui/lab';
import Form from '@/components/forms/Form/Form';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import TextField from '@/components/inputs/TextField/TextField';
import { postSuiviSocial, putSuiviSocial } from '@/stores/suiviSocial';
import { useValidators } from '@/libs/validators';
import DatePicker from '@/components/inputs/DatePicker/DatePicker';

export interface CrudSuiviSocialEditProps {
	referent: Referent,
	suivi: HSuiviSocial,
	onSave: (individu: SuiviSocial) => any;
	onClose: () => any;
}
export function CrudSuiviSocialEdit(
	{
		referent,
		suivi,
		onSave,
		onClose,
	}: CrudSuiviSocialEditProps
) {
	const dispatch = useDispatch();
	const validators = useValidators();
	const [ date, setDate ] = useState<Nullable<Date>>(suivi?.date || null);
	const [ typeContact, setTypeContact ] = useState<Nullable<Date>>(suivi?.typeContact || null);
	const [ aFaire, setAFaire ] = useState<Nullable<Date>>(suivi?.aFaire || null);
	const [ demarches, setDemarches ] = useState<Nullable<Date>>(suivi?.demarches || null);
	
	const form = useForm({
		handle: async () => {
			await form.call(async () => {
				const newSuivi = {
					...(suivi || {}),
					date: date ? date.toISOString() : null,
					typeContact,
					aFaire,
					demarches,
				};
				if (newSuivi.id) {
					const result = await dispatch(putSuiviSocial(newSuivi));
					onSave(result);
				} else {
					const result = await dispatch(postSuiviSocial(referent, newSuivi));
					onSave(result);
				}
			});
		},
		fields: {
			date: {
				...model(date, setDate),
				rules: [
					validators.required(),
				],
				label: 'Date'
			},
			typeContact: { ...model(typeContact, setTypeContact), label: 'Type de contact' },
			aFaire: { ...model(aFaire, setAFaire), label: 'À faire' },
			demarches: { ...model(demarches, setDemarches), label: 'Démarches' },
		}
	})
	
	return (
		<Form form={form}>
			<Card>
				<CardHeader
					title="Édition de la charge du ménage"
					className="CardHeader--primary"
				/>
				<CardContent>
					<FormMainErrors form={form} />

					<Grid container spacing={1}>
						<Grid xs={12} md={6}><DatePicker {...form.fields.date} /></Grid>
						<Grid xs={12} md={6}><TextField {...form.fields.typeContact} /></Grid>
						<Grid xs={12}><TextField multiline rows={7} {...form.fields.aFaire} /></Grid>
						<Grid xs={12}><TextField multiline rows={7} {...form.fields.demarches} /></Grid>
					</Grid>
					
				</CardContent>
				<CardActions className="u-d-block">
					<LoadingButton
						loading={form.loading}
						onClick={onClose}
					>
						Fermer
					</LoadingButton>
					<LoadingButton
						type="submit"
						color="primary"
						variant="contained"
						loading={form.loading}
						style={{float: 'right'}}
					>
						Valider
					</LoadingButton>
				</CardActions>
			</Card>
		</Form>
	)
}