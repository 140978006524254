import { buildModelHelper } from '@/libs/modelHelper';

export interface AnnuaireProprietaire {
	id: string;
	civilite: string;
	nom: string;
	type: string;
	adresse: string[];
	email: string;
	telephone: string;
	presenceSyndicats: string[];
}
export const hAnnuaireProprietaire = buildModelHelper<AnnuaireProprietaire>({
});
export type HAnnuaireProprietaire = ReturnType<typeof hAnnuaireProprietaire>;

