import { Card, CardContent, Container } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

export default function Tokens() {

	const columns: GridColDef[] = [
		{ field: 'id', headerName: 'ID', width: 90 },
		{
			field: 'firstName',
			headerName: 'First name',
			width: 150,
			editable: true,
		},
		{
			field: 'lastName',
			headerName: 'Last name',
			width: 150,
			editable: true,
		},
		{
			field: 'email',
			headerName: 'Email',
			width: 200,
			editable: true,
		},
		{
			field: 'role',
			headerName: 'Roles',
			width: 100,
		},
		// {
		// 	field: 'fullName',
		// 	headerName: 'Full name',
		// 	description: 'This column has a value getter and is not sortable.',
		// 	sortable: false,
		// 	width: 160,
		// 	valueGetter: (params: GridValueGetterParams) =>
		// 		`${params.row.firstName || ''} ${params.row.lastName || ''}`,
		// },
	];

	const rows = [
		{ id: 1, role: 'Admin', lastName: 'Snow', firstName: 'Jon', email: 'snow@bigcopro.com' },
		{ id: 2, role: 'Utilisateur', lastName: 'Lannister', firstName: 'Cersei', email: 'lannister@bigcopro.com' },
		{ id: 3, role: 'Utilisateur', lastName: 'Lannister', firstName: 'Jaime', email: 'lannister@bigcopro.com' },
		{ id: 4, role: 'Utilisateur', lastName: 'Stark', firstName: 'Arya', email: 'stark@bigcopro.com' },
		{ id: 5, role: 'Utilisateur', lastName: 'Targfaryen', firstName: 'Daenerys', email: 'targfaryen@bigcopro.com' },
		{ id: 6, role: 'Utilisateur', lastName: 'Melisandre', firstName: '', email:'melisandre@bigcopro.com' },
		{ id: 7, role: 'Utilisateur', lastName: 'Clifford', firstName: 'Ferrara', email: 'clifford@bigcopro.com' },
		{ id: 8, role: 'Utilisateur', lastName: 'Frances', firstName: 'Rossini', email: 'frances@bigcopro.com' },
		{ id: 9, role: 'Utilisateur', lastName: 'Roxie', firstName: 'Harvey', email: 'roxie@bigcopro.com' },
	];

	return (
		<Container className="p-habilitation-Tokens pt-3">

			<Card>
				<CardContent>
					<div style={{ height: '400px'}}>
						<DataGrid
							rows={rows}
							columns={columns}
							initialState={{
								pagination: {
									paginationModel: {
										pageSize: 5,
									},
								},
							}}
							pageSizeOptions={[5]}
							checkboxSelection
							disableRowSelectionOnClick
						/>
					</div>
				</CardContent>
			</Card>
		</Container>
	)
}
