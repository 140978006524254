import './Catalogue.scss';
import { Container } from '@mui/material';

export default function Catalogue() {
	return (
		<Container className="p-documentaire-Catalogue">
			Catalogue
		</Container>
	);
}
