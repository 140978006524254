import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { Recouvrement, ResultType } from '@/models';
import { Direction } from '@/models/Direction';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class RecouvrementState {
	public recouvrements: Nullable<ResultType<Recouvrement>> = null;
	public recouvrement: Nullable<Recouvrement> = null;
}

export default {
	state: RecouvrementState,
	reducer: mapReducer([
		'recouvrements',
		'recouvrement'
	]),
} as SubStore;

// MUTATIONS

export const setRecouvrements = (recouvrements: Nullable<ResultType<Recouvrement>>) => (dispatch: DispatchApp) => {
	dispatch({ state: RecouvrementState, type: MapReducerAction.MAP, recouvrements });
};
export const setRecouvrement = (recouvrement: Nullable<Recouvrement>) => (dispatch: DispatchApp) => {
	dispatch({ state: RecouvrementState, type: MapReducerAction.MAP, recouvrement });
};

// ACTIONS

export const findRecouvrements = (
	page: number,
	order: string,
	direction: Direction,
	limit: number,
	filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<Recouvrement>> => {
	const recouvrements = await caller.get<ResultType<Recouvrement>>(`/api/recouvrements${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);
	
	dispatch(setRecouvrements(recouvrements));
	
	return recouvrements;
});
