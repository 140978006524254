import { GetState, Services } from '@/stores';
import { loaderPop, loaderPush } from '@/stores/loader';

export function loadingCall<T>(cb: (dispatch: DispatchApp, getState: GetState, services: Services) => Promise<T>): (dispatch: DispatchApp, getState: GetState, services: Services) => Promise<T> {
	return async (dispatch: DispatchApp, getState: GetState, services: Services) => {
		try {
			dispatch(loaderPush());
			return await cb(dispatch, getState, services);
		} catch (e) {
			throw e;
		} finally {
			dispatch(loaderPop());
		}
	};
}
