import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { AnnuaireLogement, ResultType } from '@/models';
import { Direction } from '@/models/Direction';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class SynoptiqueState {
    public annuaireLogements: Nullable<ResultType<AnnuaireLogement>> = null;
}

export default {
    state: SynoptiqueState,
    reducer: mapReducer([
        'annuaireLogements',
    ]),
} as SubStore;

// MUTATIONS

export const setAnnuaireLogements = (annuaireLogements: Nullable<ResultType<AnnuaireLogement>>) => (dispatch: DispatchApp) => {
    dispatch({ state: SynoptiqueState, type: MapReducerAction.MAP, annuaireLogements });
};

// ACTIONS

export const findAnnuaireLogements = (
    page: number,
    order: string,
    direction: Direction,
    limit: number,
    filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<AnnuaireLogement>> => {
    const annuaireLogements = await caller.get<ResultType<AnnuaireLogement>>(`/api/annuaire-logements${UriHelper.queries({
        page,
        order,
        direction,
        limit,
        filters: JSON.stringify(filters)
    })}`);
    dispatch(setAnnuaireLogements(annuaireLogements));
    return annuaireLogements;
});

export const findAllAnnuaireLogements = () => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<AnnuaireLogement>> => {
    const response = await caller.get<ResultType<AnnuaireLogement>>('/api/annuaire-logements/all');

    const allAnnuaireLogements = response.data;

    dispatch(setAnnuaireLogements({
        data: allAnnuaireLogements,
        total: allAnnuaireLogements.length
    } as ResultType<AnnuaireLogement>));

    return {
        data: allAnnuaireLogements,
        total: allAnnuaireLogements.length
    } as ResultType<AnnuaireLogement>;
});
