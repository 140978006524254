import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { Direction, Iframe, ResultType } from '@/models';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class IframeState {
	public iframes: Nullable<ResultType<Iframe>> = null;
}

export default {
	state: IframeState,
	reducer: mapReducer([
		'iframes',
	]),
} as SubStore;

// MUTATIONS

export const setIframes = (iframes: Nullable<ResultType<Iframe>>) => (dispatch: DispatchApp) => {
	dispatch({ state: IframeState, type: MapReducerAction.MAP, iframes });
};

// ACTIONS

export const findIframes = (
	page: number = 0,
	order: string = '',
	direction: Direction = Direction.ASC,
	limit: number = 200,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<Iframe>> => {
	const iframes = await caller.get<ResultType<Iframe>>(`/api/iframes${UriHelper.queries({
		page,
		order,
		direction,
		limit,
	})}`);
	
	dispatch(setIframes(iframes));
	
	return iframes;
});
