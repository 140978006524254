import { Native } from '@/libs/utils';
import { FormRule } from '@/libs/form';
import { Validators } from '@/libs/validators/useValidators';

export default function password(
	this: Validators,
	{
		message,
		length = 12
	}: {
		message?: string,
		length?: number
	} = {}): FormRule {

	return (v: string) =>
		Native.empty(v) ||
		(
			v.length >= length &&
			/[A-Z]/.test(v) &&
			/[a-z]/.test(v) &&
			/[0-9]/.test(v) &&
			/[\W_]/.test(v)
		) ||
		message ||
		this.t('validators.password', { length })
	;
}
