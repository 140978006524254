import './Index.scss';
import { Button, Container, Dialog, IconButton, Tooltip } from '@mui/material';
import { useConnectedPage, useEffectOnChange, usePageable, useSnackMessage } from '@/libs/hooks';
import { useDispatch, useSelector } from '@/libs/redux';
import { useCallback, useState } from 'react';
import { deleteUserById, findUsers } from '@/stores/user';
import { hUser, User } from '@/models';
import { model } from '@/libs/candy';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import IconCheck from '@mui/icons-material/Check';
import IconModeEdit from '@mui/icons-material/ModeEdit';
import IconDelete from '@mui/icons-material/Delete';
import IconVisibility from '@mui/icons-material/Visibility';
import Confirm from '@/components/dialog/Confirm/Confirm';
import CrudUserEdit from '@/components/crud/users/Edit/Edit';
import CrudUsersFilters from '@/components/crud/users/FIlters/Filters';
import { PhoneHelper } from '@/libs/utils/PhoneHelper';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import AppBarPortal from '@/components/AppBarPortal/AppBarPortal';
import TooltipElm from '@/components/Tooltip/TooltipElm';
import { rgb } from 'polished';


export interface PageUsersIndexUserProps {
	user: User
	onDelete?: () => any,
	onUpdate?: () => any,
}

export function PageUsersIndexUser(
	{
		user,
		onDelete = () => { },
		onUpdate = () => { },
	}: PageUsersIndexUserProps
) {

	const userH = hUser(user);
	const dispatch = useDispatch();
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [edit, setEdit] = useState(false);

	return (
		<TableRow>
			<TableCell>
				<TooltipElm message={user.lastName} />
			</TableCell>
			<TableCell>
				<TooltipElm message={user.firstName} />
			</TableCell>
			<TableCell>
				<TooltipElm message={user.structure} />
			</TableCell>
			<TableCell>
				{!!user.email && <a href={`mailto:${user.email}`}><TooltipElm message={user.email} /></a>}
			</TableCell>
			<TableCell>
				{!!user.phone && <a className="u-t-nowrap" href={`tel:${user.phone}`}><TooltipElm message={PhoneHelper.format(user.phone)} /></a>}
			</TableCell>
			<TableCell>
				{!!user.phone2 && <a className="u-t-nowrap" href={`tel:${user.phone2}`}><TooltipElm message={PhoneHelper.format(user.phone2)} /></a>}
			</TableCell>
			<TableCell>
				<TooltipElm message={user.mission} />
			</TableCell>
			<TableCell>
				<TooltipElm message={user.fonction} />
			</TableCell>
			<TableCell>
				{userH.hasRole('ROLE_ADMIN') ? (
					<span style={{ fontSize: '2em', color: rgb(0, 161, 154), display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						●
					</span>
				) : (
					<span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						-
					</span>
				)}
			</TableCell>
			<TableCell>
				{userH.hasRole('ROLE_FOURN') ? (
					<span style={{ fontSize: '2em', color: rgb(0, 161, 154), display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						●
					</span>
				) : (
					<span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						-
					</span>
				)}
			</TableCell>
			<TableCell>
				{userH.hasRole('ROLE_SYNDIC') ? (
					<span style={{ fontSize: '2em', color: rgb(0, 161, 154), display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						●
					</span>
				) : (
					<span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						-
					</span>
				)}
			</TableCell>
			<TableCell>
				{userH.hasRole('ROLE_SDC') ? (
					<span style={{ fontSize: '2em', color: rgb(0, 161, 154), display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						●
					</span>
				) : (
					<span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						-
					</span>
				)}
			</TableCell>
			<TableCell>
				{userH.hasRole('ROLE_IMMO') ? (
					<span style={{ fontSize: '2em', color: rgb(0, 161, 154), display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						●
					</span>
				) : (
					<span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						-
					</span>
				)}
			</TableCell>
			<TableCell>
				{userH.hasRole('ROLE_AS') ? (
					<span style={{ fontSize: '2em', color: rgb(0, 161, 154), display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						●
					</span>
				) : (
					<span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						-
					</span>
				)}
			</TableCell>
			<TableCell>
				{userH.hasRole('ROLE_OBS') ? (
					<span style={{ fontSize: '2em', color: rgb(0, 161, 154), display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						●
					</span>
				) : (
					<span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						-
					</span>
				)}
			</TableCell>
			<TableCell>
				{userH.hasRole('ROLE_LIQ') ? (
					<span style={{ fontSize: '2em', color: rgb(0, 161, 154), display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						●
					</span>
				) : (
					<span style={{ fontSize: '1.5em', color: 'darkgray', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						-
					</span>
				)}
			</TableCell>
			<TableCell className="u-cell-sticky-right u-d-flex">
				<Tooltip title="Afficher">
					<IconButton color="default" href={`/habilitation/users/${user.id}`} >
						<IconVisibility />
					</IconButton>
				</Tooltip>
				<Tooltip title="Editer">
					<IconButton
						color="primary"
						onClick={() => setEdit(true)}
					>
						<IconModeEdit />
					</IconButton>
				</Tooltip>
				<Tooltip title="Supprimer">
					<IconButton color="error" onClick={() => setDeleteOpen(true)}>
						<IconDelete />
					</IconButton>
				</Tooltip>
				<Confirm
					{...model(deleteOpen, setDeleteOpen)}
					title="Suppression d'un utilisateur"
					message={`Êtes--vous sûr de vouloir supprimer l'utilisateur ${user.firstName} ${user.lastName} ?`}
					onConfirm={async () => {
						await dispatch(deleteUserById(user.id));
						onDelete();
					}}
				/>
				<Dialog
					open={edit}
					onClose={() => setEdit(false)}
					maxWidth={'md'}
				>
					<CrudUserEdit
						value={user}
						onClose={() => setEdit(false)}
						onUpdate={onUpdate}
					/>
				</Dialog>
			</TableCell>
		</TableRow>
	);
}

export default function PageHabilitationIndex() {

	useConnectedPage();

	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const users = useSelector(state => state.user.users);
	const pageable = usePageable();
	const [create, setCreate] = useState(false);

	const [filters, setFilters] = useState({
		structure: '',
		lastName: '',
		firstName: '',
	});

	const refresh = useCallback(() => {
		(async () => {
			try {
				await dispatch(findUsers(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, filters));
			} catch (e) {
				console.error(e);
				pushMessage('Une erreur est survenue.', 'error');
			}
		})();
	}, [filters, pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage])

	useEffectOnChange(() => {
		refresh();
	}, [refresh]);

	return (
		<Container className="p-habilitation-users-Index">

			<AppBarPortal>
				<Button
					style={{ float: 'right' }}
					variant="contained"
					onClick={() => setCreate(true)}
				>
					Créer un utilisateur
				</Button>
			</AppBarPortal>
			<Dialog
				open={create}
				onClose={() => setCreate(false)}
				maxWidth={'md'}
			>
				<CrudUserEdit
					onClose={() => setCreate(false)}
					onUpdate={refresh}
				/>
			</Dialog>

			<CrudUsersFilters
				className="u-clearfix"
				{...model(filters, setFilters)}
			/>

			<SortableTable
				heads={[
					{ name: 'Nom', sort: 'lastName' },
					{ name: 'Prénom', sort: 'firstName' },
					{ name: 'Structure', sort: 'structure' },
					{ name: 'Mail', sort: 'email' },
					{ name: 'Téléphone 1', sort: 'phone' },
					{ name: 'Téléphone 2', sort: 'phone2' },
					{ name: 'Mission', sort: 'mission' },
					{ name: 'Fonction', sort: 'fonction' },
					{ name: 'Admin' },
					{ name: 'Gestio.' },
					{ name: 'Syndic' },
					{ name: 'Opérat.' },
					{ name: 'Immo' },
					{ name: 'AS' },
					{ name: 'Obs' },
					{ name: 'Liq' },
					{ name: 'Actions', stickyRight: true },
				]}
				result={users}
				renderItem={(item) => (
					<PageUsersIndexUser
						user={item}
						onDelete={refresh}
						onUpdate={refresh}
					/>
				)}
				pageable={pageable}
			/>

		</Container>
	);
}


PageHabilitationIndex.getMetadata = () => {
	return {
		rights: ['HABILITATION'],
		title: 'Gestion des utilisateurs'
	}
}
