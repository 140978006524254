import './EditHeadChart.scss';
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from '@/libs/redux';
import { useEffect } from 'react';
import { findGraphFiches } from '@/stores/graphFiche';
import { Direction } from '@/models';
import { useSnackMessage } from '@/libs/hooks';

export interface CrudRecouvrementRecordEditProps {
}

export default function CrudRecouvrementRecordEditHeadChart(
	{}: CrudRecouvrementRecordEditProps
) {
	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const recouvrement = useSelector(state => state.recouvrement.recouvrement);
	const graphFiches = useSelector(state => state.graphFiche.graphFiches);
	
	useEffect(() => {
		(async () => {
			try {
				if (recouvrement) {
					await dispatch(findGraphFiches(
						0,
						'',
						Direction.ASC,
						200,
						{
							syndicat: [ recouvrement.syndicat ],
							proprietaire: [ recouvrement.proprietaire ],
						}
					));
				}
			} catch (e) {
				pushMessage('Une erreur est survenue', 'error')
			}
		})();
	}, [ recouvrement ]);
	
	const series = [
		{
			name: 'Impayé',
			type: 'line',
			data: graphFiches?.data?.map(gf => gf.impaye) || []
		}, {
			name: 'Appels',
			type: 'bar',
			data: graphFiches?.data?.map(gf => gf.appels) || []
		}, {
			name: 'Versement',
			type: 'bar',
			data: graphFiches?.data?.map(gf => gf.versements) || []
		}
	];
	
	return (
		<div className="c-Crud-RecouvrementRecord-EditHeadChart">
			<Chart
				options={{
					chart: {
						height: 180
					},
					plotOptions: {
						bar: {
							horizontal: false,
							columnWidth: '55%',
						},
					},
					dataLabels: {
						enabled: false
					},
					xaxis: {
						categories: graphFiches?.data?.map(gf => gf.trimestre) || [],
					},
					fill: {
						opacity: 1
					},
				}}
				series={series}
				height={180}
			/>
		</div>
	);
}
