import './Index.scss';
import { Button, Container, Dialog, IconButton, Tooltip } from '@mui/material';
import { useConnectedPage, useEffectOnChange, usePageable, useSnackMessage } from '@/libs/hooks';
import { useDispatch, useSelector } from '@/libs/redux';
import { useCallback, useState } from 'react';
import { deleteFileSlotById, findFileSlots } from '@/stores/fileSlot';
import { FileSlot } from '@/models';
import { model } from '@/libs/candy';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconModeEdit from '@mui/icons-material/ModeEdit';
import IconDelete from '@mui/icons-material/Delete';
import Confirm from '@/components/dialog/Confirm/Confirm';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import CrudFileSlotEdit from '@/components/crud/fileSlot/Edit/Edit';
import { useTranslation } from 'react-i18next';
import { toTextMonth } from '@/libs/utils';
import CrudFileSlotFilters from '@/components/crud/fileSlot/Filters/Filters';


export interface PageBaseFileSlotsIndexDocumentProps {
	fileSlot: FileSlot
	onDelete?: () => any,
	onUpdate?: () => any,
}

export function PageBaseFileSlotsIndexDocument(
	{
		fileSlot,
		onDelete = () => {},
		onUpdate = () => {},
	}: PageBaseFileSlotsIndexDocumentProps
) {

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [ deleteOpen, setDeleteOpen ] = useState(false);
	const [ edit, setEdit ] = useState(false);

	return (
		<TableRow>
			<TableCell>
				{fileSlot.id}
			</TableCell>
			<TableCell>
				{fileSlot.syndicat}
			</TableCell>
			<TableCell>
				{fileSlot.documentType.name}
			</TableCell>
			<TableCell>
				{t(`models.file_slot_periodicity.values.${fileSlot.periodicity}`)}
			</TableCell>
			<TableCell>
				{fileSlot.day && fileSlot.month ? `${fileSlot.day} ${toTextMonth(fileSlot.month)}` : '-' }
			</TableCell>
			<TableCell className="u-cell-sticky-right u-d-flex">
				<Tooltip title="Editer">
					<IconButton
						color="primary"
						onClick={() => setEdit(true)}
					>
						<IconModeEdit />
					</IconButton>
				</Tooltip>
				<Tooltip title="Supprimer">
					<IconButton
						color="error"
						onClick={() => setDeleteOpen(true)}
						disabled={fileSlot.used}
					>
						<IconDelete />
					</IconButton>
				</Tooltip>
				<Confirm
					{...model(deleteOpen, setDeleteOpen)}
					title="Suppression de l'emplacement de document"
					message={`Êtes--vous sûr de vouloir supprimer l'emplacement ${fileSlot.id} ?`}
					onConfirm={async () => {
						await dispatch(deleteFileSlotById(fileSlot.id));
						onDelete();
					}}
				/>
				<Dialog
					open={edit}
					onClose={() => setEdit(false)}
					maxWidth={'md'}
				>
					<CrudFileSlotEdit
						value={fileSlot}
						onClose={() => setEdit(false)}
						onUpdate={onUpdate}
					/>
				</Dialog>
			</TableCell>
		</TableRow>
	);
}

export default function PageBaseFileSlotsIndex() {

	useConnectedPage();

	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const fileSlots = useSelector(state => state.fileSlot.fileSlots);
	const pageable = usePageable();
	const [ create, setCreate ] = useState(false);

	const [ filters, setFilters ] = useState<any>({
		syndicat: [],
		documentType: [],
		periodicity: [],
	});
	
	const refresh = useCallback(async () => {
		try {
			await dispatch(findFileSlots(pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage, filters));
		} catch (e) {
			console.error(e);
			pushMessage('Une erreur est survenue.', 'error');
		}
	}, [ filters, pageable.page, pageable.order, pageable.direction, pageable.rowsPerPage ])
	
	useEffectOnChange(() => {
		refresh();
	}, [ refresh ]);


	return (
		<Container className="p-base-fileSlots-Index">

			<h2>
				Gestion des emplacements de document
				<Button
					style={{float: 'right'}}
					variant="contained"
					onClick={() => setCreate(true)}
				>
					Créer un emplacement
				</Button>
				<Dialog
					open={create}
					maxWidth={'md'}
				>
					<CrudFileSlotEdit
						onClose={() => setCreate(false)}
						onUpdate={refresh}
					/>
				</Dialog>
			</h2>

			<CrudFileSlotFilters
				className="u-clearfix"
				{...model(filters, setFilters)}
			/>

			<SortableTable
				heads={[
					{ name: 'Id', sort: 'id' },
					{ name: 'Syndicat', sort: 'syndicat' },
					{ name: 'Document', sort: 'documentType' },
					{ name: 'Périodicité', sort: 'periodicity' },
					{ name: 'Échéance' },
					{ name: 'Actions', stickyRight: true },
				]}
				result={fileSlots}
				renderItem={(item) => (
					<PageBaseFileSlotsIndexDocument
						fileSlot={item}
						onDelete={refresh}
						onUpdate={refresh}
					/>
				)}
				pageable={pageable}
			/>
		</Container>
	);
}


PageBaseFileSlotsIndex.getMetadata = () => {
	return {
		rights: [ 'MANAGE_FILE_SLOT' ]
	}
}
