import { Card, CardContent, CardHeader, Link } from '@mui/material';
import { useState } from 'react';
import { model } from '@/libs/candy';
import { useForm } from '@/libs/form';
import { useValidators } from '@/libs/validators';
import { useDispatch } from '@/libs/redux';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useSnackMessage } from '@/libs/hooks';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import Form from '@/components/forms/Form/Form';
import TextField from '@/components/inputs/TextField/TextField';
import './ResetPassword.scss';
import { resetPasswordRequest } from '@/stores/user';

export default function PageResetPassword() {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const validators = useValidators();
	const [ email, setEmail ] = useState('');
	const pushMessage = useSnackMessage();


	const form = useForm({
		handle: async () => {
			await form.call(async () => {
				await dispatch(resetPasswordRequest(email));
				navigate('/');
				pushMessage('Email de changement de mot de passe envoyé');
			});
		},
		fields: {
			email: {
				...model(email, setEmail),
				rules: [
					validators.required(),
					validators.email(),
				]
			},
		}
	});

	return (
		<div className="p-PageResetPassword">
			<Card className="p-PageResetPassword-popup">

				<CardHeader
					title="Mot de passe oublié"
					className="CardHeader--primary"
				/>

				<CardContent>
					<Form form={form}>

						<FormMainErrors form={form} />

						<div className="mb-1">
							<TextField
								label="Email"
								variant="standard"
								{...form.fields.email}
							/>
						</div>

						<p>
							<Link href="/">Se connecter</Link>
						</p>

						<div className="c-login-LoginForm-action">
							<LoadingButton
								variant="contained"
								type="submit"
								loading={form.loading}
							>
								Envoyer
							</LoadingButton>
						</div>
					</Form>
				</CardContent>
			</Card>
		</div>
	)
}

PageResetPassword.getMetadata = () => {
	return {
		layout: 'empty'
	};
};
