import Select, { SelectProps } from '@/components/inputs/Select/Select';
import { useTranslation } from 'react-i18next';
import { enumToValueText } from '@/libs/utils/EnumHelper';
import { CustomRowType } from '@/models';

export interface CustomTypeSelectProps extends Omit<SelectProps, 'items' | 'itemText' | 'itemValue'> {
}

export default function CustomTypeSelect(
  {
    ...props
  }: CustomTypeSelectProps
) {
  const { t } = useTranslation();
  return (
    <Select
      items={ enumToValueText(t, 'models.custom_row_type.values', CustomRowType) }
      {...props}
    />
  );
};
