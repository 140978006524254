import { buildModelHelper } from '@/libs/modelHelper';
import { DocumentTypeMode } from '@/models/DocumentTypeMode';
import { DocumentTypeFormat } from '@/models/DocumentTypeFormat';
import { DocumentTypeType } from '@/models/DocumentTypeType';
import { DocumentCustomField } from './DocumentCustomField';

export interface DocumentType {
	id: number;
	name: string;
	code: string;
	type: DocumentTypeType;
	mode: DocumentTypeMode;
	modeLabel: string;
	formats: DocumentTypeFormat[];
	public: boolean;
	used: boolean;
	customRows: DocumentCustomField[];
	isPromote: boolean;
}

export const hDocumentType = buildModelHelper<DocumentType>({

	get acceptFormat(): string[] {
		return this.formats
			.map((f: DocumentTypeFormat) => {
				switch (f) {
					case DocumentTypeFormat.CSV:
						return ['text/csv'];
					case DocumentTypeFormat.XLS:
						return [
							'application/vnd.ms-excel',
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						];
					case DocumentTypeFormat.XLSM:
						return [
							'application/vnd.ms-excel',
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
							'application/vnd.ms-excel.sheet.macroEnabled.12',
							'application/vnd.ms-excel.template.macroEnabled.12',
							'application/vnd.ms-excel.addin.macroEnabled.12',
							'application/vnd.ms-excel.template.macroEnabled.12'
						];
					case DocumentTypeFormat.XLSB:
						return [
							'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
						];
					case DocumentTypeFormat.XLSX:
						return [
							'application/vnd.ms-excel',
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
							'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

						];
					case DocumentTypeFormat.PDF:
						return ['application/pdf'];
				}
				return null;
			})
			.flat();
	}

});

export type HDocumentType = ReturnType<typeof hDocumentType>;
