export enum UserSyncicat {
	SP = 'SP',
	TR11 = 'TR11',
	TR12 = 'TR12',
	TR13 = 'TR13',
	TR14 = 'TR14',
	TR15 = 'TR15',
	TR16 = 'TR16',
	TR17 = 'TR17',
	TR18 = 'TR18',
	TR21 = 'TR21',
	TR24 = 'TR24',
	TR25 = 'TR25',
	TR26 = 'TR26',
	TR27 = 'TR27',
	TR28 = 'TR28',
	TR29 = 'TR29',
	TR30 = 'TR30',
	TR31 = 'TR31',
	TR33 = 'TR33',
	TR41 = 'TR41',
	TR42 = 'TR42',
	TR43 = 'TR43',
	TR44 = 'TR44',
	TR45 = 'TR45',
	TR46 = 'TR46',
	TR47 = 'TR47',
	TR48 = 'TR48',
	TR49 = 'TR49',
	TR79 = 'TR79',
	TR80 = 'TR80',
	TR8X = 'TR8X',
}
