import { Card, CardContent } from '@mui/material';
import { useState } from 'react';
import TextField from '@/components/inputs/TextField/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import { useEffectTimeout, usePropState } from '@/libs/hooks';
import { CardProps } from '@mui/material/Card/Card';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

export interface CrudUsersFiltersProps extends CardProps {
	value?: Nullable<any>;
	onChange?: (value: Nullable<any>) => any,
}

export default function CrudUsersFilters(
	{
		value,
		onChange,
		className = '',
		...rest
	}: CrudUsersFiltersProps
) {

	const [ filters, setFilters ] = usePropState<any>(value, onChange, {
		structure: '',
		lastName: '',
		firstName: '',
	});

	const [ structure, setStuctrure ] = useState<string>(filters.structure);
	const [ lastName, setLastName ] = useState<string>(filters.lastName);
	const [ firstName, setFirstName ] = useState<string>(filters.firstName);
	const [ open, setOpen] = useState<boolean>(false);
	
	useEffectTimeout(() => {
		if (
			filters.structure !== structure ||
			filters.lastName !== lastName ||
			filters.firstName !== firstName
		) {
			setFilters({
				structure,
				lastName,
				firstName,
			})
		}
	}, 500, [
		structure,
		lastName,
		firstName,
	])
	
	return (
		<Card
			{...rest}
			className={[
				className,
				'mt-1',
				'mb-1',
				'c-Crud-Users-Filters'
			].join(' ')}
		>
			<CardContent className="p-1">
				<div onClick={() => setOpen(!open)}>
					<h5 className="m-0" style={{cursor: 'pointer'}}>  {open ? <KeyboardArrowUp style={{ verticalAlign: '-7px' }} /> : <KeyboardArrowDown style={{ verticalAlign: '-7px' }} />}<span>Filtres :</span></h5>
				</div>
				{open && (
					<Grid  container spacing={1}>
						<Grid
							xs={12} md={4}
						>
							<TextField
								{...model(structure, setStuctrure)}
								clearable
								size="small"
								variant="standard"
								placeholder="Structure"
							/>
						</Grid>
						<Grid
							xs={12} md={4}
						>
							<TextField
								{...model(firstName, setFirstName)}
								clearable
								size="small"
								variant="standard"
								placeholder="Prénom"
							/>
						</Grid>
						<Grid
							xs={12} md={4}
						>
							<TextField
								{...model(lastName, setLastName)}
								clearable
								size="small"
								variant="standard"
								placeholder="Nom"
							/>
						</Grid>
					</Grid>
				)}
			</CardContent>
		</Card>
	)
}
