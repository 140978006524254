import { Native } from '@/libs/utils';
import { FormRule } from '@/libs/form';
import { Validators } from '@/libs/validators/useValidators';

export default function range(
	this: Validators,
	{
		min,
		max,
		messageMin,
		messageMax
	}: {
		min?: number,
		max?: number,
		messageMin?: string,
		messageMax?: string
	} = {}): FormRule {
	return (v: number) => {
		if (!Native.empty(v)) {
			if (min !== null && typeof min !== 'undefined') {
				if (v < min) {
					return messageMin || this.t('validators.range.min', { min });
				}
			}
			if (max !== null && typeof max !== 'undefined') {
				if (v > max) {
					return messageMax || this.t('validators.range.max', { max });
				}
			}
		}
		return true;
	};
}
