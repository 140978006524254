import { Alert, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useSelector } from '@/libs/redux';
import { SnackMessageType } from '@/stores/snackMessage';

export interface SnackMessageProps {
	text: string;
	type: SnackMessageType
}

export function SnackMessage(
	{
		text,
		type,
	}: SnackMessageProps
) {

	const [ open, setOpen ] = useState(false);

	useEffectOnce(() => {
		setOpen(true);
	});

	return (
		<Snackbar
			open={open}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			autoHideDuration={5000}
			onClose={() => setOpen(false)}
		>
			<Alert
				variant="filled"
				elevation={6}
				onClose={() => setOpen(false)}
				severity={type}
				sx={{ width: '100%' }}
			>
				{text}
			</Alert>
		</Snackbar>
	);
}

export default function SnackMessages() {
	const messages = useSelector(state => state.snackMessage.messages);

	return (
		<>
			{messages.map(message => (
				<SnackMessage
					key={message.id}
					text={message.text}
					type={message.type}
				/>
			))}

		</>
	);
}
