import { hMedia, HMedia, Media } from '@/models/Media';
import { buildModelHelper } from '@/libs/modelHelper';

export interface DocumentFile {
	id: number;
	media: Media;
}

export const hDocumentFile = buildModelHelper<DocumentFile>({
	get media(): HMedia {
		return hMedia(this._self.media);
	}
});

export type HDocumentFile = ReturnType<typeof hDocumentFile>;
