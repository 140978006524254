import { buildModelHelper } from '@/libs/modelHelper';
import { HReferent, hReferent, Referent } from '@/models/Referent';
import { hAdresse, HAdresse } from '@/models/Adresse';
import { HEmail } from '@/models/Email';
import { HTelephone } from '@/models/Telephone';

export interface Proprietaire {
	id: number;
	civilite: string;
	nom: string;
	libre1: string;
	libre2: string;
	referent?: Nullable<Referent>;
}


export const hProprietaire = buildModelHelper<Proprietaire>({
	get fullName(): string {
		return `${this.civilite || ''} ${this.nom || ''}`.trim();
	},
	get referent(): HReferent {
		return hReferent(this._self.referent);
	},
	get currentAddress(): Nullable<HAdresse> {
		return this?.referent?.currentAddress || null;
	},
	get currentEmail(): Nullable<HEmail> {
		return this?.referent?.currentEmail || null;
	},
	get currentTelephone(): Nullable<HTelephone> {
		return this?.referent?.currentTelephone || null;
	}
});

export type HProprietaire = ReturnType<typeof hProprietaire>;
