import { Native } from '@/libs/utils';
import { FormRule } from '@/libs/form';
import { Validators } from '@/libs/validators/useValidators';

export default function repeatField(
	this: Validators,
	{
		repeat,
	}: {
		repeat?: string,
	} = {}): FormRule {
	return (v: string) => Native.empty(v) || v === repeat || this.t('validators.repeat_field');
}
