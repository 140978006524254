import { mapReducer, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { Individu, Referent } from '@/models';
import { loadingCall } from '@/libs/stores/candy/loadingCall';

export class IndividuState {
}

export default {
	state: IndividuState,
	reducer: mapReducer([
	]),
} as SubStore;

// ACTIONS

export const putIndividu = (
	individu: Partial<Individu>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Individu> => {
	return  await caller.put<Individu>(`/api/individus/${individu.id}`, {
		civilite: individu.civilite,
		nom: individu.nom,
		dateNaissance: individu.dateNaissance,
		lieuNaissance: individu.lieuNaissance,
		nationalite: individu.nationalite,
	});
});

export const postIndividu = (
	referent: Referent,
	individu: Partial<Individu>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Individu> => {
	return  await caller.post<Individu>(`/api/individus/${encodeURIComponent(referent.id)}`, {
		civilite: individu.civilite,
		nom: individu.nom,
		dateNaissance: individu.dateNaissance,
		lieuNaissance: individu.lieuNaissance,
		nationalite: individu.nationalite,
	});
});

export const deleteIndividu = (
	individu: Individu,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Individu> => {
	return  await caller.delete<Individu>(`/api/individus/${individu.id}`);
});
