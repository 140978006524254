import { AnyAction } from 'redux';
import { SubStore } from '@/libs/redux';
// import { isSSR } from '@/libs/next'; // Only on next

export class LoaderState {
	public count: number = 1;
	public loading: boolean = true;
}

export enum ActionTypes {
	PUSH = 'LOADER_PUSH',
	POP = 'LOADER_POP',
}

export default {
	state: LoaderState,
	reducer(state: LoaderState, action: AnyAction) {
		// Only on next
		// if (isSSR()) { // force client side only
		// 	return state;
		// }
		switch (action.type) {
			case ActionTypes.PUSH:
				state.count++;
				break;
			case ActionTypes.POP:
				state.count--;
				break;
		}
		state.loading = !!state.count;
		return state;
	},
	onFirstRender(dispatch) {
		dispatch(loaderPop());
	}
} as SubStore;

// MUTATIONS

export const loaderPush = () => (dispatch: DispatchApp) => {
	return dispatch({ state: LoaderState, type: ActionTypes.PUSH });
};

export const loaderPop = () => (dispatch: DispatchApp) => {
	return dispatch({ state: LoaderState, type: ActionTypes.POP });
};
