import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { Acquisition, Direction, ResultType } from '@/models';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';
import { addDia, removeDia, updateDia } from './acquisition';
import { AnyAction } from '@reduxjs/toolkit';
import { AcquisitionDia } from '@/models/AcquisitionDia';


export class AcquisitionDiaState {
	public acquisitionDias: Nullable<ResultType<AcquisitionDia>> = null;
	public acquisitionDia: Nullable<AcquisitionDia> = null;
	acquisition: any;
}

export enum ActionTypes {
	ADD_DIA = 'ADD_DIA',
	UPDATE_DIA = 'UPDATE_DIA',
	REMOVE_DIA = 'REMOVE_DIA',
}

export default {
	state: AcquisitionDiaState,
	reducer: mapReducer([
		'acquisitionDias',
		'acquisitionDia',
	], (state: AcquisitionDiaState, action: AnyAction) => {
		switch (action.type) {
			case ActionTypes.ADD_DIA:
				if (state.acquisition) {
					state.acquisition.acquisitionDias = [
						...(state.acquisition.acquisitionDias || []),
						action.dia
					];
					state.acquisition = { ...state.acquisition };
				}
				break;
			case ActionTypes.UPDATE_DIA:
				if (state.acquisition) {
					const index = state.acquisition.acquisitionDias?.findIndex((d: AcquisitionDia) => d.id === action.dia.id);
					if (index !== undefined && index !== -1) {
						state.acquisition.acquisitionDias[index] = action.dia;
					}
					state.acquisition = { ...state.acquisition };
				}
				break;
			case ActionTypes.REMOVE_DIA:
				if (state.acquisition) {
					const index = state.acquisition.acquisitionDias?.findIndex((d: AcquisitionDia) => d.id === action.dia.id);
					if (index !== undefined && index !== -1) {
						state.acquisition.acquisitionDias.splice(index, 1);
					}
					state.acquisition = { ...state.acquisition };
				}
				break;
		}
		return state;
	}),
} as SubStore;

// MUTATIONS

export const setAcquisitionDias = (acquisitionDias: Nullable<ResultType<AcquisitionDia>>) => (dispatch: DispatchApp) => {
	dispatch({ state: AcquisitionDiaState, type: MapReducerAction.MAP, acquisitionDias });
};
export const setAcquisitionDia = (acquisitionDia: Nullable<AcquisitionDia>) => (dispatch: DispatchApp) => {
	dispatch({ state: AcquisitionDiaState, type: MapReducerAction.MAP, acquisitionDia });
};
export const putAcquisitionDia = (
	acquisitionDia: Partial<AcquisitionDia>
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<AcquisitionDia> => {
	const dia = await caller.put<AcquisitionDia>(`/api/acquisition-dia/${acquisitionDia.id}`, {
		date: acquisitionDia.date,
		commentaire: acquisitionDia.commentaire,
		montant: acquisitionDia.montant,
		prixm2: acquisitionDia.prixm2,
		surfaceCarrez: acquisitionDia.surfaceCarrez,
		montantCommissionAgence: acquisitionDia.montantCommissionAgence,
		chargeCommission: acquisitionDia.chargeCommission,
		nomAcquereur: acquisitionDia.nomAcquereur,
		dateDemandeUniqueVisite: acquisitionDia.dateDemandeUniqueVisite,
		dateDemandeDocs: acquisitionDia.dateDemandeDocs,
		dateReceptionDocs: acquisitionDia.dateReceptionDocs,
		decisionPreemptionEpf: acquisitionDia.decisionPreemptionEpf,
		decisionPreemptionEpfAdju: acquisitionDia.decisionPreemptionEpfAdju,
		dateCourrierPreemption: acquisitionDia.dateCourrierPreemption,
		retourProprietaire: acquisitionDia.retourProprietaire,
		acquisitionId: acquisitionDia.acquisitionId,
	});
	dispatch(updateDia(dia));
	return dia;
});

export const postAcquisitionDia = (acquisition: Acquisition, acquisitionDia: Partial<AcquisitionDia>) =>
	loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<AcquisitionDia> => {
	const dia = await caller.post<AcquisitionDia>(`/api/acquisition-dia/${encodeURIComponent(acquisition.id)}`, {
		date: acquisitionDia.date,
		commentaire: acquisitionDia.commentaire,
		montant: acquisitionDia.montant,
		prixm2: acquisitionDia.prixm2,
		surfaceCarrez: acquisitionDia.surfaceCarrez,
		montantCommissionAgence: acquisitionDia.montantCommissionAgence,
		chargeCommission: acquisitionDia.chargeCommission,
		nomAcquereur: acquisitionDia.nomAcquereur,
		dateDemandeUniqueVisite: acquisitionDia.dateDemandeUniqueVisite,
		dateDemandeDocs: acquisitionDia.dateDemandeDocs,
		dateReceptionDocs: acquisitionDia.dateReceptionDocs,
		decisionPreemptionEpf: acquisitionDia.decisionPreemptionEpf,
		decisionPreemptionEpfAdju: acquisitionDia.decisionPreemptionEpfAdju,
		dateCourrierPreemption: acquisitionDia.dateCourrierPreemption,
		retourProprietaire: acquisitionDia.retourProprietaire,
		acquisitionId: acquisitionDia.acquisitionId,
	});
	if (getState().acquisition.acquisition?.id === acquisition.id) {
		dispatch(addDia(dia));
	}
	return dia;
});

export const deleteAcquisitionDia = (
	acquisitionDia: AcquisitionDia,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<AcquisitionDia> => {
	const dia = await caller.delete<AcquisitionDia>(`/api/acquisition-dia/${acquisitionDia.id}`);
	dispatch(removeDia(acquisitionDia));
	return dia;
});
