import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '@/libs/redux';

export function useConnectedPage() {
	const connected = useSelector(state => state.user.connected);
	const firstRender = useSelector(state => state.firstRender);
	const navigate = useNavigate();
	
	useEffect(() => {
		if (firstRender && !connected) {
			navigate('/');
		}
	}, [ firstRender, connected, navigate ]);
}
