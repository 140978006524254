import { RouteObject } from 'react-router';
import PageIframe from '@/pages/Iframe/Iframe';
import Synoptique from '@/pages/documentaire/acteurs/Synoptique/Synoptique';

export const observatoire: RouteObject[] & any = [
	{
		path: '/observatoire/syndicats',
		element: <PageIframe />
	},
	{
		path: '/observatoire/synoptiques',
		element: <Synoptique />
	},
	{
		path: '/observatoire/suivi-impayes',
		element: <PageIframe/>
	},
];
