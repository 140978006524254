import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { Lot, ResultType } from '@/models';
import { Direction } from '@/models/Direction';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class LotState {
	public lots: Nullable<ResultType<Lot>> = null;
	public lot: Nullable<Lot> = null;
}

export default {
	state: LotState,
	reducer: mapReducer([
		'lots',
		'lot'
	]),
} as SubStore;

// MUTATIONS

export const setLots = (lots: Nullable<ResultType<Lot>>) => (dispatch: DispatchApp) => {
	dispatch({ state: LotState, type: MapReducerAction.MAP, lots });
};
export const setLot = (lot: Nullable<Lot>) => (dispatch: DispatchApp) => {
	dispatch({ state: LotState, type: MapReducerAction.MAP, lot });
};

// ACTIONS

export const findLotsByProprietaireId = (
	proprietaireId: number,
	page: number,
	order: string,
	direction: Direction,
	limit: number,
	filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<Lot>> => {
	const lots = await caller.get<ResultType<Lot>>(`/api/lots/proprietaire/${encodeURIComponent(proprietaireId)}${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);
	
	dispatch(setLots(lots));
	
	return lots;
});

export const findLots = (
	page: number,
	order: string,
	direction: Direction,
	limit: number,
	filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<Lot>> => {
	const lots = await caller.get<ResultType<Lot>>(`/api/lots${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);
	
	dispatch(setLots(lots));
	
	return lots;
});
