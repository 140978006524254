import { RouteObject } from 'react-router';
import PageAnnuaireActeurs from '@/pages/annuaire/Acteurs/Acteurs';
import Personnes from '@/pages/annuaire/Personnes/Personnes';
import Logements from '@/pages/annuaire/Logements/Logements';

export const annuaire: RouteObject[]&any = [
	{
		path: '/annuaire/acteurs',
		element: <PageAnnuaireActeurs/>
	},
	{
		path: '/annuaire/personnes',
		element: <Personnes/>
	},
	{
		path: '/annuaire/logements',
		element: <Logements/>
	},
];
