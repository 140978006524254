import { Card, CardContent } from '@mui/material';
import { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import { useEffectTimeout, usePropState } from '@/libs/hooks';
import { CardProps } from '@mui/material/Card/Card';
import { FileSlotPeriodicity, UserSyncicat } from '@/models';
import CrudDocumentTypeSelect from '@/components/crud/documentType/Select/Select';
import CrudFileSlotPeriodicitySelect from '@/components/crud/fileSlotPeriodicity/Select/Select';
import CrudUserSyncicatSelect from '@/components/crud/userSyncicat/Select/Select';

export interface CrudFileSlotFiltersProps extends CardProps {
	value?: Nullable<any>;
	onChange?: (value: Nullable<any>) => any,
}

export default function CrudFileSlotFilters(
	{
		value,
		onChange,
		className = '',
		...rest
	}: CrudFileSlotFiltersProps
) {

	const [ filters, setFilters ] = usePropState<any>(value, onChange, {
		type: [],
		syndicat: [],
		documentType: [],
		periodicity: [],
	});

	const [ syndicat, setSyndicat ] = useState<UserSyncicat>(filters.syndicat);
	const [ documentType, setDocumentType ] = useState<number>(filters.documentType);
	const [ periodicity, setPeriodicity ] = useState<FileSlotPeriodicity>(filters.periodicity);
	
	useEffectTimeout(() => {
		if (
			filters.syndicat !== syndicat ||
			filters.documentType !== documentType ||
			filters.periodicity !== periodicity
		) {
			setFilters({
				syndicat,
				documentType,
				periodicity,
			})
		}
	}, 500, [
		syndicat,
		documentType,
		periodicity
	]);
	
	return (
		<Card
			{...rest}
			className={[
				className,
				'mb-1',
				'c-Crud-FileSlot-Filters'
			].join(' ')}
		>
			<CardContent>
				<h5 className="my-0">Filtres :</h5>
				<Grid container spacing={1}>
					<Grid
						xs={12} md={4}
					>
						<CrudUserSyncicatSelect
							{...model(syndicat, setSyndicat)}
							multiple
							clearable
							size="small"
							variant="standard"
							label="Syndicats"
						/>
					</Grid>
					<Grid
						xs={12} md={4}
					>
						<CrudDocumentTypeSelect
							{...model(documentType, setDocumentType)}
							multiple
							clearable
							label="Documents"
							size="small"
							variant="standard"
						/>
					</Grid>
					<Grid
						xs={12} md={4}
					>
						<CrudFileSlotPeriodicitySelect
							{...model(periodicity, setPeriodicity)}
							multiple
							clearable
							size="small"
							variant="standard"
							label="Périodicité"
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	)
}
