import { useEffect, useState } from "react";
import { TitleDate, Indication, DateMaJ, checkData, RenderTable } from './Synoptiques';
import Select from 'src/components/inputs/Select/Select';
import { FiltreColor, FiltreBatiment } from "./Filtre/Filtre";

export function MyDocument(props: { hide: boolean, item: boolean, color: boolean, Majs: boolean, selectedTranche: any, selectedAdresse: any, handleTrancheChange: any, handleAdresseChange: any }) {
    // Déclaration des variables d'état pour les filtres
    const [filtre, setFiltre] = useState<string>('');
    const [filtre2, setFiltre2] = useState<string>('');
    const [filtre3, setFiltre3] = useState<string>('');
    const [Bg_color, setBg_color] = useState<Record<string, string>>({});
    const [Pallette, setPallette] = useState<string>('B');
    const [Colors, setColors] = useState<Array<any>>([]);
    const [Data, setData] = useState<Array<any>>([]);
    const [processing, setProcessing] = useState(false);
    const [filtreOptions, setFiltreOptions] = useState<Record<string, string>>({});
    const [filtre2Options, setFiltre2Options] = useState<Record<string, string>>({});
    const [etatMajs, setetatMajs] = useState<Array<any>>([]);
    const [selectedEtatMajs, setselectedEtatMajs] = useState<any | null>(null);

    const orderG = { "G1": 0, "G2": 1, "G3": 2, "FG3": 3, "FG2": 4, "FG1": 5 };
    const orderD = { "FD1": 0, "FD2": 1, "FD3": 2, "D3": 3, "D2": 4, "D1": 5 }

    // Déclaration des variables pour les tailles des cases (Exceptions)
    const Halfsize = "100px"
    const Fullsize = "200px"

    useEffect(() => {
        setData((props.item as any).data);
        setColors((props.color as any).data);
        setetatMajs((props.Majs as any).data);
        // Déclaration des options de filtres
        setFiltreOptions({
            'Occupation': 'occupation',
            'Impayé': 'impaye',
            'Retard': 'retard',
            'Statut Recouv.': 'statutRecouvrementSyndic',
            'Statut Acc. Social': 'typeAccompagnementSocial',
            'Etat Logement': 'etatVisiteRef',
            'Situation LHI': 'situationLhi',
        });
        setFiltre2Options({
            'Propriétaire': 'proprietaireName',
            'Occupation': 'occupation',
            'Impayé': 'impaye',
            'Retard': 'retard',
            'Statut Recouv.': 'statutRecouvrementSyndic',
            'Statut Acc. Social': 'typeAccompagnementSocial',
            'Etat Logement': 'etatVisiteRef',
            'Situation LHI': 'situationLhi'
        });
    }, [props.item, props.color, props.Majs])
    useEffect(() => {
        setselectedEtatMajs(etatMajs.find((etatMaj: any) => etatMaj.syndicat === props.selectedTranche));
    }, [props.selectedTranche, etatMajs])

    const handleChangeFiltre = (e: any) => {
        const newFiltre = e;
        setFiltre(newFiltre);
        setBg_color(checkData(newFiltre, Data, Colors));
    };
    const handleChangeFiltre2 = (e: any) => setFiltre2(e);
    const handleChangeFiltre3 = (e: any) => setFiltre3(e);
    const handleColorChange = (key: string, color: string) => setBg_color(prev => ({ ...prev, [key]: color }));
    const handlePalleteChange = (id: string) => setPallette(id);

    // const Data2 = hAnnuaireLogement((props.item as any).data);
    function FiltreIndicateur() {
        const selectOptions = [
            { label: "Indicateur principal (Couleur)", id: "Filtre1", value: filtre, options: filtreOptions, onChange: handleChangeFiltre },
            { label: "Indicateur 2", id: "Filtre2", value: filtre2, options: filtre2Options, onChange: handleChangeFiltre2 },
            { label: "Indicateur 3", id: "Filtre3", value: filtre3, options: filtre2Options, onChange: handleChangeFiltre3 },
        ];
        return (
            <div className='FormController' style={{ display: props.hide ? '' : 'none' }}>
                {selectOptions.map(({ label, id, value, options, onChange }) => (
                    <Select
                        key={id}
                        className='FormControl'
                        label={label}
                        id={id}
                        value={value}
                        items={Object.entries(options).map(([key, value]) => ({ value, label: key }))}
                        slotOption={(item: any) => (<span>{item.label}</span>)}
                        onChange={onChange}
                    />
                ))}
            </div>
        );
    }

    return (
        <div className='Marge background-image'>
            {/* Titre */}
            <h1 className='Margin' style={{ display: props.hide ? '' : 'none' }}>Synoptique</h1>
            {/* Lors de l'impression PDF Affiche le syndicat et la date si selectedEtatMajs est défini */}
            <TitleDate hide={props.hide} selectedEtatMajs={selectedEtatMajs} />
            <Indication />
            <DateMaJ selectedEtatMajs={selectedEtatMajs} />
            <FiltreColor Bg_color={Bg_color} Colors={Colors} handlePalleteChange={handlePalleteChange} handleColorChange={handleColorChange} Pallette={Pallette} />
            <FiltreBatiment
                selectedTranche={props.selectedTranche}
                handleTrancheChange={props.handleTrancheChange}
                selectedAdresse={props.selectedAdresse}
                handleAdresseChange={props.handleAdresseChange}
                hide={props.hide}
                Data={Data}
                etatMajs={etatMajs}
            />
            <FiltreIndicateur />
            <div className='Tableau Margin'>
                {processing && Colors && Data ? (
                    // Affiche un message de chargement
                    <p className='Margin'>Chargement de données en cours...</p>
                ) : (
                    <>
                        {/* Trie les logements par syndicat et bâtiment */}
                        <RenderTable
                            Data={Data}
                            props={props}
                            orderG={orderG}
                            orderD={orderD}
                            Halfsize={Halfsize}
                            Fullsize={Fullsize}
                            Bg_color={Bg_color}
                            filtre={filtre}
                            filtre2={filtre2}
                            filtre3={filtre3}
                        />
                    </>
                )}
            </div>
        </div>
    );
}