import { Card, CardContent, CardHeader, Container, Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import navigations, { NavigationItem } from '@/navigations';
import { useConnectedPage, useIsGranted } from '@/libs/hooks';
import './Home.scss';

interface PageHomeItemProps {
	item: NavigationItem;
}

function PageHomeItem (
	{
		item
	}: PageHomeItemProps
) {
	const isGranted = useIsGranted();
	
	return isGranted(item.granted) ? (
		<li>
			{item.children ? (
				<div>
					<span>{item.title}</span>
					<ul>
						{item.children.map((sub, j) => (
							<PageHomeItem key={j} item={sub} />
						))}
					</ul>
				</div>
			) : (
				<Link href={item.to}>{item.title}</Link>
			)}
		</li>
	) : <></>;
}

export default function PageHome() {

	useConnectedPage();

	const isGranted = useIsGranted();

	const items = navigations
		.slice(1)
	;

	return (
		<Container className="p-Home py-4">
			<h2>Accueil</h2>

			<Grid container spacing={2}>
				{items.map((item, i) => (
					isGranted(item.granted) && (
						<Grid
							key={i}
							xs={12} md={6} lg={4}
						>
							<Card elevation={4}>
								<CardHeader
									title={(
										<div className="p-Home-title">
											<div className="p-Home-title-icon">
												{item.icon}
											</div>
											{item.title}
										</div>
									)}
								/>
								<CardContent>
									<ul>
										{isGranted(item.granted) && (
											item.children ? (
												item.children.map((sub, j) => (
													<PageHomeItem key={j} item={sub} />
												))
											) : (
												<li>
													<Link href={item.to}>Accéder</Link>
												</li>
											)
										)}
									</ul>
								</CardContent>
							</Card>
						</Grid>
					)
				))}
			</Grid>
		</Container>
	)
}

PageHome.getMetadata = () => {
	return {
		title: 'Accueil'
	};
};
