import { useDispatch } from '@/libs/redux';
import { useCallback } from 'react';
import { snackMessagePush, SnackMessageType } from '@/stores/snackMessage';

export function useSnackMessage() {
	const dispatch = useDispatch();
	const push = useCallback((text: string, type: SnackMessageType = 'success') => {
		return dispatch(snackMessagePush(text, type));
	}, [ dispatch ]);

	return push;
}







