import { FieldAroundProps, FieldFormProps, useFormSelect } from '@/components/inputs/generic';
import {
	Checkbox as MUICheckbox,
	CheckboxProps as MUICheckboxProps,
	FormControl,
	FormControlLabel,
	FormControlProps,
	FormHelperText
} from '@mui/material';
import { ChangeEvent, ReactNode } from 'react';
import './Checkbox.scss';

export interface CheckboxPropsSpecific {
	label?: ReactNode;
	value?: any;
	onChange?: (v: any) => any,
	item?: any,
	itemText?: string;
	itemValue?: string;
	returnObject?: boolean;
	error?: boolean;
	helperText?: Nullable<string>;
	inputProps?: Partial<MUICheckboxProps>;
	onChangeOrigin?: (event: any) => any,
	readOnly?: boolean;
}

export type CheckboxProps = CheckboxPropsSpecific & FieldFormProps & FieldAroundProps & FormControlProps;

export default function Checkbox(
	{
		item = null,
		itemText = 'text',
		itemValue = 'value',
		returnObject = false,
		rules = [],
		clearable = false,
		appendIcon = null,
		prependIcon = null,
		appendIconProps = {},
		prependIconProps = {},
		appendSlot = null,
		prependSlot = null,
		value,
		onChange,
		onChangeOrigin = () => {},
		defaultValue = '',
		ref,
		label = '',
		className = '',
		onBlur = () => {},
		error = false,
		helperText = null,
		inputProps = {},
		readOnly = false,
		disabled = false,
		...rest
	}: CheckboxProps
) {
	const isBooleanValue = item === null;

	const generic = useFormSelect({
		items: [ item ],
		itemText,
		itemValue,
		returnObject,
		multiple: !isBooleanValue,
		value,
		onChange,
		defaultValue: isBooleanValue ? false : [],
		className: `c-inputs-Checkbox ${className}`,
		rules,
		ref,
		error,
		helperText,
	});

	const isChecked = isBooleanValue ? generic.value : generic.isSelected(item);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (readOnly || disabled) {
			return;
		}
		if (onChangeOrigin) {
			onChangeOrigin(e);
		}
		const checked = e.target.checked;
		if (isBooleanValue) {
			generic.onChange(checked);
		} else {
			const isSelected = generic.isSelected(item);
			if (checked === !isSelected) {
				generic.select(item);
			}
		}
	};
	
	return (
		<>
			<FormControl
				{...rest}
				className={generic.className}
				error={generic.error}
				ref={generic.ref}
				disabled={disabled}
			>
				<FormControlLabel
					control={(
						<MUICheckbox
							checked={isChecked}
							{...generic.props}
							{...inputProps}
							onChange={handleChange}
							readOnly={readOnly}
							disabled={disabled}
						/>
					)}
					label={label}
				/>
				{!!generic.helperText && <FormHelperText>{generic.helperText}</FormHelperText>}
			</FormControl>
		</>
	);
}
