import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { TriEvalSociale, ResultType } from '@/models';
import { Direction } from '@/models/Direction';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class TriEvalSocialeState {
	public triEvalSociales: Nullable<ResultType<TriEvalSociale>> = null;
	public triEvalSociale: Nullable<TriEvalSociale> = null;
}

export default {
	state: TriEvalSocialeState,
	reducer: mapReducer([
		'triEvalSociales',
		'triEvalSociale'
	]),
} as SubStore;

// MUTATIONS

export const setTriEvalSociales = (triEvalSociales: Nullable<ResultType<TriEvalSociale>>) => (dispatch: DispatchApp) => {
	dispatch({ state: TriEvalSocialeState, type: MapReducerAction.MAP, triEvalSociales });
};
export const setTriEvalSociale = (triEvalSociale: Nullable<TriEvalSociale>) => (dispatch: DispatchApp) => {
	dispatch({ state: TriEvalSocialeState, type: MapReducerAction.MAP, triEvalSociale });
};

// ACTIONS

export const findTriEvalSociales = (
	page: number,
	order: string,
	direction: Direction,
	limit: number,
	filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<TriEvalSociale>> => {
	const triEvalSociales = await caller.get<ResultType<TriEvalSociale>>(`/api/triAccompSocial${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);
	
	dispatch(setTriEvalSociales(triEvalSociales));
	
	return triEvalSociales;
});
