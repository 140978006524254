import { RouteObject } from 'react-router';
import Upload from '@/pages/base/Upload/Upload';
import Documents from '@/pages/base/Documents/Documents';
import Status from '@/pages/base/Status/Status';
import State from '@/pages/base/State/State';
import Evenements from '@/pages/base/Evenements/Evenements';
import DocumentTypesIndex from '@/pages/base/documentTypes/Index/Index';
import FileSlotsIndex from '@/pages/base/fileSlots/Index/Index';
import PageIframe from '@/pages/Iframe/Iframe';

export const base: RouteObject[]&any = [
	{
		path: '/base/upload',
		element: <Upload/>
	},
	{
		path: '/base/status',
		element: <Status/>
	},
	{
		path: '/base/data-available',
		element: <State/>
	},
	{
		path: '/base/Evenements',
		element: <Evenements/>
	},
	{
		path: '/base/documents',
		element: <Documents/>
	},
	{
		path: '/base/document-types',
		element: <DocumentTypesIndex/>
	},
	{
		path: '/base/file-slots',
		element: <FileSlotsIndex/>
	},
];
