import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { Direction, Document, DocumentStatus, ResultType } from '@/models';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class DocumentState {
	documents: Nullable<ResultType<Document>> = null;
}

export default {
	state: DocumentState,
	reducer: mapReducer([
		'documents'
	]),
} as SubStore;

// MUTATIONS
export const setDocuments = (documents: Nullable<ResultType<Document>>) => (dispatch: DispatchApp) => {
	dispatch({ state: DocumentState, type: MapReducerAction.MAP, documents });
};

// ACTIONS

export const findDocumentsPublic = (
	page: number,
	order: string,
	direction: Direction,
	limit: number,
	filters: any = {}
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<Document>> => {
	const documents = await caller.get<ResultType<Document>>(`/api/documents/public${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);

	dispatch(setDocuments(documents));

	return documents;
});

export const patchDocumentStatusById = (
	id: number,
	status: DocumentStatus,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Document> => {
	const document = await caller.patch<Document>(`/api/documents/${id}/status`, {
		status
	});
	
	return document;
});
