import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { SlotList, UserSyncicat } from '@/models';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { dateToDash, UriHelper } from '@/libs/utils';

export class RelanceSlotListState {
	public relanceSlotList: Nullable<SlotList> = null;
}

export default {
	state: RelanceSlotListState,
	reducer: mapReducer([
		'relanceSlotList',
	]),
} as SubStore;

// MUTATIONS

export const setRelanceSlotList = (relanceSlotList: Nullable<SlotList>) => (dispatch: DispatchApp) => {
	dispatch({ state: RelanceSlotListState, type: MapReducerAction.MAP, relanceSlotList });
};

// ACTIONS

export const findRelanceSlotListByBetweenDate = (
	start: Date,
	end: Date,
	filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Nullable<SlotList>> => {
	dispatch(setRelanceSlotList(null));
	const relanceSlotList = await caller.get<SlotList>(`/api/slot-lists/getRelances/${dateToDash(start)}/${dateToDash(end)}${UriHelper.queries({
		filters: JSON.stringify(filters)
	})}`);
	dispatch(setRelanceSlotList(relanceSlotList));
	return relanceSlotList;
});
