import Select, { SelectProps } from '@/components/inputs/Select/Select';
import { enumToValueText } from '@/libs/utils/EnumHelper';
import { LogModerationType } from '@/models';
import { useTranslation } from 'react-i18next';

export interface CrudLogModerationTypeSelectProp extends Omit<SelectProps, 'items'|'itemText'|'itemValue'> {
}

export default function CrudLogModerationTypeSelect(
	props: CrudLogModerationTypeSelectProp
) {
	const { t } = useTranslation();

	return (
		<Select
			items={enumToValueText(t, 'models.log_moderation_type.values', LogModerationType)}
			{...props}
		/>
	);
}
