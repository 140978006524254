import { buildModelHelper } from '@/libs/modelHelper';

export interface AcquisitionVisite {
    id: string;
	acquisitionId: Nullable<number>;
    date: Nullable<string>;
    contexteVisite: Nullable<string>;
    etatLogement: Nullable<string>;
    precisionsOccupation: Nullable<string>;
    nomBanque: Nullable<string>;
    montantDetteBanque: Nullable<number>;
    montantAutresDettes: Nullable<number>;
    commentaire: Nullable<string>;
	
}

export const hAcquisitionVisite = buildModelHelper<AcquisitionVisite>({
	get date(): Nullable<Date> { return this._self.date ? new Date(this._self.date) : null; },
	set date(value: Nullable<Date>) { this._self.date = value ? value.toISOString() : null; },
});

export type HAcquisitionVisite = ReturnType<typeof hAcquisitionVisite>;
