import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { Acquisition, Direction, ResultType } from '@/models';
import { AcquisitionVisite } from '@/models/AcquisitionVisite';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';
import { addVisite, removeVisite, updateVisite } from './acquisition';
import acquisition from './acquisition';

export class AcquisitionVisiteState {
	public acquisitionVisites: Nullable<ResultType<AcquisitionVisite>> = null;
	public acquisitionVisite: Nullable<AcquisitionVisite> = null;
}

export enum ActionTypes {
	ADD_VISITE = 'ADD_VISITE'
}

export default {
	state: AcquisitionVisiteState,
	reducer: mapReducer([
		'acquisitionVisites',
		'acquisitionVisite',
	]),
} as SubStore;

// MUTATIONS

export const setAcquisitionVisites = (acquisitionVisites: Nullable<ResultType<AcquisitionVisite>>) => (dispatch: DispatchApp) => {
	dispatch({ state: AcquisitionVisiteState, type: MapReducerAction.MAP, acquisitionVisites });
};
export const setAcquisitionVisite = (acquisitionVisite: Nullable<AcquisitionVisite>) => (dispatch: DispatchApp) => {
	dispatch({ state: AcquisitionVisiteState, type: MapReducerAction.MAP, acquisitionVisite });
};
export const putAcquisitionVisite = (
	acquisitionVisite: Partial<AcquisitionVisite>
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<AcquisitionVisite> => {

	const visit =  await caller.put<AcquisitionVisite>(`/api/acquisition-visite/${acquisitionVisite.id}`, {
		date: acquisitionVisite.date,
		commentaire: acquisitionVisite.commentaire,
		contexteVisite: acquisitionVisite.contexteVisite,
		etatLogement: acquisitionVisite.etatLogement,
		precisionsOccupation: acquisitionVisite.precisionsOccupation,
		nomBanque: acquisitionVisite.nomBanque,
		montantDetteBanque: acquisitionVisite.montantDetteBanque,
		montantAutresDettes: acquisitionVisite.montantAutresDettes,
		acquisitionId: acquisitionVisite.acquisitionId,
	});
	dispatch(updateVisite(visit))
	return visit;
});

export const postAcquisitionVisite = (acquisition: Acquisition, acquisitionVisite: Partial<AcquisitionVisite>) =>
	loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<AcquisitionVisite> => {
	const visite = await caller.post<AcquisitionVisite>(`/api/acquisition-visite/${encodeURIComponent(acquisition.id)}`, {
		date: acquisitionVisite.date,
		commentaire: acquisitionVisite.commentaire,
		contexteVisite: acquisitionVisite.contexteVisite,
		etatLogement: acquisitionVisite.etatLogement,
		precisionsOccupation: acquisitionVisite.precisionsOccupation,
		nomBanque: acquisitionVisite.nomBanque,
		montantDetteBanque: acquisitionVisite.montantDetteBanque,
		montantAutresDettes: acquisitionVisite.montantAutresDettes,
		acquisitionId: acquisitionVisite.acquisitionId,
	});
	if (getState().acquisition.acquisition?.id === acquisition.id) {
		dispatch(addVisite(visite));
	}
	return visite;
});

export const deleteAcquisitionVisite = (
	acquisitionVisite: AcquisitionVisite,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<AcquisitionVisite> => {
	const visite = await caller.delete<AcquisitionVisite>(`/api/acquisition-visite/${acquisitionVisite.id}`);
	dispatch(removeVisite(acquisitionVisite));
	return visite;
});
