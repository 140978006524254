import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { hToken, Redirect, ResultType, User } from '@/models';
import { setToken } from '@/stores/token';
import { Direction } from '@/models/Direction';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

declare const _paq: any;

export class UserState {
	public users: Nullable<ResultType<User>> = null;
	public user: Nullable<User> = null;
	public me: Nullable<User> = null;
	public connected: boolean = false;
}

export default {
	state: UserState,
	reducer: mapReducer([
		'users',
		'user',
		'me',
		'connected',
	], null, (state, value, key) => {
		switch (key) {
			case 'me':
				if (state.connected !== !!state.me) {
					state.connected = !!state.me;
				}
				if (state.me) {
					_paq.push(['setUserId', state.me.id]);
					_paq.push(['setCustomVariable', 1, 'email', state.me.email, 'visit']);
					_paq.push(['setCustomVariable', 2, 'roles', state.me.roles?.join(','), 'visit']);
				} else {
					_paq.push(['setUserId', null]);
					_paq.push(['setCustomVariable', 1, 'email', null, 'visit']);
					_paq.push(['setCustomVariable', 2, 'roles', null, 'visit']);
				}
				

				break;
		}
		return state;
	}),
} as SubStore;

// MUTATIONS

export const setUsers = (users: Nullable<ResultType<User>>) => (dispatch: DispatchApp) => {
	dispatch({ state: UserState, type: MapReducerAction.MAP, users });
};
export const setUser = (user: Nullable<User>) => (dispatch: DispatchApp) => {
	dispatch({ state: UserState, type: MapReducerAction.MAP, user });
};
export const setMe = (me: Nullable<User>) => (dispatch: DispatchApp) => {
	dispatch({ state: UserState, type: MapReducerAction.MAP, me });
};

// ACTIONS

export const findUsers = (
	page: number,
	order: string,
	direction: Direction,
	limit: number,
	filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<User>> => {
	const users = await caller.get<ResultType<User>>(`/api/users${UriHelper.queries({
		page,
		order,
		direction,
		limit,
		filters: JSON.stringify(filters)
	})}`);
	
	dispatch(setUsers(users));
	
	return users;
});

export const findUserById = (id: number) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Nullable<User>> => {
	const user = await caller.get<User>(`/api/users/${id}`);
	dispatch(setUser(user));
	return user;
});


export const deleteUserById = (id: number) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<User> => {
	const user = await caller.delete<User>(`/api/users/${id}`);
	dispatch(setUser(user));
	return user;
});

export const putUserById = (
	id: number,
	user: Partial<User>
) => async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Nullable<User>> => {
	const result = await caller.put<User>(`/api/users/${id}`, user);
	dispatch(setUser(result));
	return result;
}

export const postUser = (user: Partial<User>) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Nullable<User>> => {
	const result = await caller.post<User>(`/api/users`, user);
	dispatch(setUser(result));
	return result;
});

export const getMe = () => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Nullable<User>> => {
	const me = await caller.get<User>('/api/users/me');
	dispatch(setMe(me));
	dispatch(setToken(me.currentToken));
	return me;
});


export const initAuth = () => async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Nullable<User>> => {
	const token = hToken(getState().token.token);
	if (token && token.isValid) {
		return await dispatch(getMe());
	}

	return null;
};

export const resetPasswordRequest = (
	email: string
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<void> => {
	await caller.get<User>(`/api/users/me/reset-password-request/${encodeURIComponent(email)}`);
});

export const resetPasswordCheckToken = (
	email: string,
	search: string
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Redirect> => {
	return await caller.get<Redirect>(`/api/redirects/check-token-reset-password/${encodeURIComponent(email)}${search}`);
});

export const resetPasswordConfirm = (
	url: string,
	password: string
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<User> => {
	return await caller.patch<User>(url, {
		password
	}, {}, { fullUrl: true });
});

export const patchPassword = (password: string) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<User> => {
	let me = getState().user.me;
	me = await caller.patch<User>(`/api/users/password/${me?.id}`, {
		password
	});
	dispatch(setMe(me));
	return me;
});


export const patchCharte = () => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<User> => {
	const me = await caller.patch<User>('/api/users/me/charte');
	dispatch(setMe(me));
	return me;
});
