import FormError from '../FormError/FormError';
import { HTMLAttributes } from 'react';
import { Form } from '@/libs/form';
import './FormMainErrors.scss';

export interface FormMainErrorsProps extends HTMLAttributes<HTMLSpanElement> {
	/**
	 * Le formulaire
	 */
	form: Pick<Form, 'rootFieldErrors'>
}

export default function FormMainErrors(
	{
		className = '',
		form,
		...args
	}: FormMainErrorsProps
) {
	return form.rootFieldErrors.length ? (
		<div
			{...args}
			className={'c-forms-FormMainErrors ' + className}
		>
			{form.rootFieldErrors.map((error, i) => (
				<FormError key={i}>{error}</FormError>
			))}
		</div>
	) : <></>;
}
