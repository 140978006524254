import { Native } from '@/libs/utils';
import { FormRule } from '@/libs/form';
import { Validators } from '@/libs/validators/useValidators';

export default function onlyInteger(
	this: Validators,
	{message}: {
		message?: string,
	} = {}): FormRule {
	return (v: string) => Native.empty(v) || /^-?\d*$/i.test(v) || message || this.t('validators.only_number');
}
