import { FormRule } from '@/libs/form';
import { Validators } from '@/libs/validators/useValidators';

export default function notNull(
	this: Validators,
	{
		message
	}: {
		message?: string
	} = {}): FormRule {
	return (v: any) => (v !== null) || message || this.t('validators.not_null');
}
