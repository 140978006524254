import { Native } from '@/libs/utils';
import { FormRule } from '@/libs/form';
import { Validators } from '@/libs/validators/useValidators';

export default function minlength(
	this: Validators,
	{
		min,
		message
	}: {
		min: number,
		message?: string
	}): FormRule {

	return (v: string) => {
		if (!Native.empty(v)) {
			if (v.length < min) {
				return message || `Le texte doit contenir au moins ${min} charactères`;
			}
		}
		return true;
	};
}
