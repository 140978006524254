import './Status.scss';
import { Card, Container, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CrudUserSyncicatSelect from '@/components/crud/userSyncicat/Select/Select';
import { model } from '@/libs/candy';
import DatePicker from '@/components/inputs/DatePicker/DatePicker';
import { useCallback, useState } from 'react';
import {
	Document,
	DocumentStatus,
	FileSlotPeriodicity,
	hDocument,
	HDocument,
	hSlot,
	Slot,
	SlotStatus,
	UserSyncicat,
	hUser
} from '@/models';
import { useConnectedPage, useEffectOnChange, useIsGranted, useSnackMessage } from '@/libs/hooks';
import { findSlotListByBetweenDate } from '@/stores/slotList';
import { useDispatch, useSelector } from '@/libs/redux';
import SortableTable from '@/components/tables/StortableTable/SortableTable';
import { toDate } from '@/libs/utils';
import CrudSlotStatusShow from '@/components/crud/slotStatus/Show/Show';
import CrudDocumentTypeSelect from '@/components/crud/documentType/Select/Select';
import CrudSlotStatusSelect from '@/components/crud/slotStatus/Select/Select';
import CrudFileSlotPeriodicitySelect from '@/components/crud/fileSlotPeriodicity/Select/Select';
import { patchDocumentStatusById } from '@/stores/document';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import IconVisibility from '@mui/icons-material/Visibility';

export interface PageBaseStatusItemItemProps {
	item: Document;
}

export function PageBaseStatusItemItem(
	{
		item,
	}: PageBaseStatusItemItemProps
) {
	const isGranted = useIsGranted();
	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const document = hDocument(item);
	const token = useSelector(state => state.token.token);
	const [loading, setLoading] = useState(false);

	const canDownload = useSelector(state => state.token.token?.rights.includes('BASE_DOWNLOAD'));

	const handleStatus = async (status: DocumentStatus) => {
		setLoading(true);
		try {
			await dispatch(patchDocumentStatusById(document.id, status))
			pushMessage('Status changer avec succès');
			document.status = status;
		} catch (e) {
			console.error(e);
			pushMessage('Une erreur sur le changement de status', 'error');
		}
		setLoading(false);
	};

	return (
		<>
			<TableCell>
				<div key={document.id}>
					{canDownload ? (
						<>
							{(() => {
								//Solution temporaire pour télécharger les fichiers TESTER EN DEV // PROD A DETERMINER
								const baseHref = document.file.media.getHref({ token });
								const parts = baseHref.split('/');
								parts.pop();
								const newId = document.id;

								const newUrl = token
									? `${parts.join('/')}/${newId}?_token=${token.id}`
									: null;
								return (
									<div>
										{newUrl && (
											<>
												{isGranted() ?
													<>
														<a target="_blank" href={newUrl}>{document.fileName}</a>
														{document.isPDF && (
															<span className="pl-1" >
																<IconButton color="primary" target="_blank" href={`${newUrl}&view=1`} >
																	<IconVisibility />
																</IconButton>
															</span>
														)}
													</>
													:
													<Tooltip title="Vous n'avez pas les droits nécessaires pour télécharger ce document.">
														<span>{document.fileName}</span>
													</Tooltip>
												}
											</>
										)}
									</div>
								);
							})()}
						</>
					) : (
						<Tooltip title="Vous n'avez pas les droits nécessaires pour télécharger ce document.">
							<span>{document.fileName}</span>
						</Tooltip>
					)}
				</div>
			</TableCell>
			<TableCell>
				<CrudSlotStatusShow value={document.status} />
			</TableCell>
			<TableCell>
				{toDate(document.createdAt)}
			</TableCell>
			<TableCell className="u-cell-sticky-right u-d-flex u-align-start">
				{document.status === DocumentStatus.SENT && (
					<LoadingButton
						size="small"
						variant="contained"
						color="info"
						loading={loading}
						onClick={() => handleStatus(DocumentStatus.IN_PROGRESS)}
					>
						Marquer en traitement
					</LoadingButton>
				)}
				{document.status === DocumentStatus.IN_PROGRESS && (
					<>
						<LoadingButton
							size="small"
							variant="contained"
							loading={loading}
							onClick={() => handleStatus(DocumentStatus.SENT)}
						>
							Repasser à envoyer
						</LoadingButton>
						&nbsp;
						<LoadingButton
							size="small"
							color="success"
							variant="contained"
							loading={loading}
							onClick={() => handleStatus(DocumentStatus.VALIDATED)}
						>
							Marquer validé
						</LoadingButton>
						&nbsp;
						<LoadingButton
							size="small"
							color="error"
							variant="contained"
							loading={loading}
							onClick={() => handleStatus(DocumentStatus.REFUSED)}
						>
							Marquer refusé
						</LoadingButton>
					</>
				)}
				{(
					document.status === DocumentStatus.VALIDATED ||
					document.status === DocumentStatus.REFUSED
				) && (
						<LoadingButton
							size="small"
							color="info"
							variant="contained"
							loading={loading}
							onClick={() => handleStatus(DocumentStatus.IN_PROGRESS)}
						>
							Repasser à en traitement
						</LoadingButton>
					)}
			</TableCell>
		</>
	);
}

export interface PageBaseStatusItemItemHeadProps {
	item: Slot;
	rowSpan?: number;
}

export function PageBaseStatusItemItemHead(
	{
		item,
		rowSpan = 1
	}: PageBaseStatusItemItemHeadProps
) {
	const { t } = useTranslation();
	const slot = hSlot(item);

	return (
		<>
			<TableCell rowSpan={rowSpan}>
				{t(`models.user_syndicat.values.${slot.fileSlot.syndicat}`)}
			</TableCell>
			<TableCell style={{ maxWidth: '250px' }} rowSpan={rowSpan}>
				{slot.fileSlot.documentType.name}
			</TableCell>
			<TableCell rowSpan={rowSpan}>
				{slot.getPeriodicity()}
			</TableCell>
			<TableCell rowSpan={rowSpan}>
				{toDate(slot.date)}
			</TableCell>
		</>
	);
}

export interface PageBaseUploadItemProps {
	item: Slot,
	index: number,
	slotStatus: SlotStatus[]
}

export function PageBaseStatusItemGroupBy(
	{
		item,
		index,
		slotStatus,
	}: PageBaseUploadItemProps
) {

	const slot = hSlot(item);

	const className = index % 2 ? 'row-grey' : '';

	const showEmpty = !slotStatus.length || slotStatus.indexOf(SlotStatus.WAITING) !== -1;

	const documentFiltered = slot.documents
		.filter((d: HDocument) => !slotStatus.length || slotStatus.indexOf(d.status) !== -1)
		;

	const User = hUser(useSelector(state => state.user.me));
	const userSyndicats = useSelector((state) => User.syndicats);
	// Check if the file belongs to the user's syndicate
	const isUserSyndicatFile = userSyndicats.includes(slot.fileSlot.syndicat);
	return (
		<>
			{showEmpty && !slot.documents.length && (
				<TableRow className={className}>
					<PageBaseStatusItemItemHead item={slot} />
					{isUserSyndicatFile ? (
						<>
							<TableCell className="u-t-center u-t-italic">
								Aucun fichier
							</TableCell>
							<TableCell>
								<CrudSlotStatusShow value={slot.status} />
							</TableCell>
						</>
					) : (
						<>
							<TableCell className="u-t-center u-t-italic">
								<Tooltip title="Vous ne pouvez pas voir/télécharger les documents qui ne sont pas de votre syndicat">
									<span>{"Document non autorisé"}</span>
								</Tooltip>
							</TableCell>
							<TableCell />
						</>
					)}
					<TableCell />
					<TableCell className="u-cell-sticky-right u-d-flex u-align-start" />
				</TableRow>
			)}
			{documentFiltered.map((document: HDocument, i: number) => (
				<TableRow key={i} className={className}>
					{i === 0 && (
						<PageBaseStatusItemItemHead item={slot} rowSpan={documentFiltered.length} />
					)}
					{isUserSyndicatFile ? (
						<PageBaseStatusItemItem item={document} />
					) : (
						<>
							<TableCell className='noDocCenter'>
								<Tooltip title="Vous ne pouvez pas voir/télécharger les documents qui ne sont pas de votre syndicat">
									<span>{"Document non autorisé"}</span>
								</Tooltip>
							</TableCell>
							{[...Array(3)].map((_, index) => (
								<TableCell key={index} />
							))}
						</>
					)}
				</TableRow>
			))}
		</>
		// </TableRow>
	);
}



export default function PageBaseStatus() {

	useConnectedPage();

	const startInitial = new Date();
	const endInitial = new Date();
	startInitial.setMonth(endInitial.getMonth() - 6);
	endInitial.setDate(startInitial.getDate() + 14);

	const dispatch = useDispatch();
	const pushMessage = useSnackMessage();
	const [syndicat, setSyndicat] = useState<UserSyncicat[]>([]);
	const [slotStatus, setSlotStatus] = useState<SlotStatus[]>([]);
	const [documentType, setDocumentType] = useState<number[]>([]);
	const [periodicity, setPeriodicity] = useState<FileSlotPeriodicity[]>([]);
	const [start, setStart] = useState<Nullable<Date>>(startInitial);
	const [end, setEnd] = useState<Nullable<Date>>(endInitial);
	const slotList = useSelector(state => state.slotList.slotList);

	const refresh = useCallback(async () => {
		try {
			if (start && end) {
				await dispatch(findSlotListByBetweenDate(start, end));
			}
		} catch (e) {
			console.error(e);
			pushMessage('Une erreur est survenue', 'error');
		}
	}, [start, end]);

	useEffectOnChange(() => {
		refresh();
	}, [refresh]);

	const slotsFiltered = slotList?.slots
		?.filter(slot => !syndicat.length || syndicat.indexOf(slot.fileSlot.syndicat) !== -1)
		?.filter(slot => !documentType.length || documentType.indexOf(slot.fileSlot.documentType.id) !== -1)
		?.filter(slot => !periodicity.length || periodicity.indexOf(slot.fileSlot.periodicity) !== -1)
		?.filter(slot => !slotStatus.length || !slot.documents.length || !!slot.documents.filter(d => slotStatus.indexOf(d.status as any)! !== -1).length)
		?.filter(slot => !slotStatus.length || slotStatus.indexOf(SlotStatus.WAITING) !== -1 || !!slot.documents.length)
		?.filter((slot: Slot) => slot.status !== SlotStatus.VALIDATED); // Ajout de cette ligne pour filtrer les documents validés
		;

	return (
		<Container className="p-base-Status">
			<h2>Workflow des fichiers</h2>

			<Card className="u-clearfix p-2 mb-2" elevation={4}>
				<Grid container spacing={1}>
					<Grid
						xs={12} md={4}
					>
						<CrudUserSyncicatSelect
							{...model(syndicat, setSyndicat)}
							label="Sélectionnez un syndicat"
							filtered
							multiple
						/>
					</Grid>
					<Grid
						xs={12} md={4}
					>
						<DatePicker
							{...model(start, setStart)}
							label="Du"
						/>
					</Grid>
					<Grid
						xs={12} md={4}
					>
						<DatePicker
							{...model(end, setEnd)}
							label="Au"
						/>
					</Grid>
					<Grid
						xs={12} md={4}
					>
						<CrudDocumentTypeSelect
							{...model(documentType, setDocumentType)}
							label="Sélectionnez un document"
							multiple
						/>
					</Grid>
					<Grid
						xs={12} md={4}
					>
						<CrudFileSlotPeriodicitySelect
							{...model(periodicity, setPeriodicity)}
							label="Sélectionnez une périodicité"
							multiple
						/>
					</Grid>
					<Grid
						xs={12} md={4}
					>
						<CrudSlotStatusSelect
							{...model(slotStatus, setSlotStatus)}
							label="Sélectionnez un statut"
							multiple
						/>
					</Grid>

				</Grid>
			</Card>

			<SortableTable
				heads={[
					{ name: 'Syndicat' },
					{ name: 'Document', style: { maxWidth: '250px' } },
					{ name: 'Période' },
					{ name: 'Échéance d\'envoi' },
					{ name: 'Fichiers' },
					{ name: 'Statut' },
					{ name: 'Date d\'envoi' },
					{ name: 'Actions', stickyRight: true },
				]}
				result={slotsFiltered}
				renderItem={(item, i) => (
					<PageBaseStatusItemGroupBy
						index={i}
						item={item}
						slotStatus={slotStatus}
					/>
				)}
			/>
		</Container>
	);
}


PageBaseStatus.getMetadata = () => {
	return {
		rights: ['BASE_MANAGE']
	}
}
