import {
	Card,
	CardContent,
	CardHeader,
	Link,
	Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { model } from '@/libs/candy';
import IconVisibility from '@mui/icons-material/Visibility';
import IconVisibilityOff from '@mui/icons-material/VisibilityOff';
import TextField from '@/components/inputs/TextField/TextField';
import { useForm } from '@/libs/form';
import { useValidators } from '@/libs/validators';
import Form from '@/components/forms/Form/Form';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import { useDispatch } from '@/libs/redux';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { login } from '@/stores/token';
import './Login.scss';
import { useDisconnectedPage } from '@/libs/hooks';

export default function PageLogin() {

	useDisconnectedPage();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const validators = useValidators();
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ showPassword, setShowPassword ] = useState(false);

	const form = useForm({
		handle: async () => {
			await form.call(async () => {
				await dispatch(login(email, password));
				navigate('/home');
			});
		},
		genericErrorLabel: 'Email ou mot de passe incorrect.',
		fields: {
			email: {
				...model(email, setEmail),
				rules: [
					validators.required(),
					validators.email(),
				]
			},
			password: {
				...model(password, setPassword),
				rules: [
					validators.required(),
				]
			}
		}
	});

	return (
		<div className="p-Login">
			<div>
				<h2>
					<img
						width="400px"
						alt="Grigny 2 - Orcod-in"
						src="/images/logo.png"
					/>
				</h2>
			</div>
			<div>
				<Card className="p-Login-popup">

					<CardHeader
						title="Connexion"
						className="CardHeader--primary"
					/>

					<CardContent>
						<Form form={form}>

							<FormMainErrors form={form} />

							<div className="mb-2">
								<TextField
									clearable
									label="Email"
									variant="standard"
									type="email"
									autoComplete="username"
									{...form.fields.email}
								/>
							</div>

							<div className="mb-2">
								<TextField
									clearable
									label="Mot de passe"
									variant="standard"
									type={showPassword ? 'text' : 'password'}
									autoComplete="current-password"
									appendIcon={showPassword ? 
													<Tooltip arrow title="Cacher le mot de passe" placement="right"><IconVisibilityOff /></Tooltip> : 
													<Tooltip arrow title="Afficher le mot de passe" placement="right"><IconVisibility /></Tooltip>}
									appendIconProps={{
										'aria-label': "toggle password visibility",
										onClick: () => setShowPassword(!showPassword)
									}}
									{...form.fields.password}
								/>
							</div>

							<p>
								<Link href="/reset-password">Mot de passe oublié</Link>
							</p>

							<div className="c-login-LoginForm-action">
								<LoadingButton
									variant="contained"
									type="submit"
									loading={form.loading}
								>
									Se connecter
								</LoadingButton>
							</div>
						</Form>
					</CardContent>
				</Card>
			</div>
			<div>
				<img
					width="200px"
					src="/images/epf.png"
					alt=""
				/>
				<img
					width="200px"
					src="/images/anh.png"
					alt=""
				/>
				<img
					width="200px"
					src="/images/gps.png"
					alt=""
				/>
				<img
					width="200px"
					src="/images/grigny.png"
					alt=""
				/>
			</div>
		</div>
	)
}

PageLogin.getMetadata = () => {
	return {
		layout: 'empty'
	};
};
