import Select, { SelectProps } from '@/components/inputs/Select/Select';
import { enumToValueText } from '@/libs/utils/EnumHelper';
import { FileSlotPeriodicity } from '@/models';
import { useTranslation } from 'react-i18next';

export interface CrudFileSlotPeriodicitySelectProp extends Omit<SelectProps, 'items'|'itemText'|'itemValue'> {
}

export default function CrudFileSlotPeriodicitySelect(
	props: CrudFileSlotPeriodicitySelectProp
) {
	const { t } = useTranslation();
	
	return (
		<Select
			items={enumToValueText(t, 'models.file_slot_periodicity.values', FileSlotPeriodicity)}
			{...props}
		/>
	);
}
