import { Card, CardContent } from '@mui/material';
import { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import { useEffectTimeout, usePropState } from '@/libs/hooks';
import { CardProps } from '@mui/material/Card/Card';
import { RecouvrementCategorieImpayes, RecouvrementStatusOccupation, UserSyncicat } from '@/models';
import CrudUserSyncicatSelect from '@/components/crud/userSyncicat/Select/Select';
import CrudRecouvrementStatusOccupationSelect from '@/components/crud/recouvrementStatusOccupation/Select/Select';
import CrudRecouvrementCategorieImpayesSelect from '@/components/crud/recouvrementCategorieImpayes/Select/Select';
import TextField from '@/components/inputs/TextField/TextField';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

export interface CrudRecouvrementFiltersProps extends CardProps {
	value?: Nullable<any>;
	onChange?: (value: Nullable<any>) => any,
}

export default function CrudRecouvrementFilters(
	{
		value,
		onChange,
		className = '',
		...rest
	}: CrudRecouvrementFiltersProps
) {

	const [ filters, setFilters ] = usePropState<any>(value, onChange, {
		proprietaire: '',
		syndicat: [],
		nom: '',
		statutOccupation: [],
		categorieImpayes: [],
	});

	const [ proprietaire, setProprietaire ] = useState<string>(filters.proprietaire);
	const [ syndicat, setSyndicat ] = useState<UserSyncicat>(filters.syndicat);
	const [ nom, setNom ] = useState<string>(filters.nom);
	const [ statutOccupation, setStatutOccupation ] = useState<RecouvrementStatusOccupation>(filters.statutOccupation);
	const [ categorieImpayes, setCategorieImpayes ] = useState<RecouvrementCategorieImpayes>(filters.categorieImpayes);
	const [ soldeMin, setSoldeMin ] = useState<Nullable<number>>(filters.solde.min);
	const [ soldeMax, setSoldeMax ] = useState<Nullable<number>>(filters.solde.max);
	const [ open, setOpen] = useState<boolean>(false);
	
	useEffectTimeout(() => {
		if (
			filters.proprietaire !== proprietaire ||
			filters.syndicat !== syndicat ||
			filters.nom !== nom ||
			filters.statutOccupation !== statutOccupation ||
			filters.categorieImpayes !== categorieImpayes ||
			filters.solde.min !== soldeMin ||
			filters.solde.max !== soldeMax
		) {
			setFilters({
				proprietaire,
				syndicat,
				nom,
				statutOccupation,
				categorieImpayes,
				solde: {
					min: soldeMin,
					max: soldeMax,
				}
			})
		}
	}, 500, [
		proprietaire,
		syndicat,
		nom,
		statutOccupation,
		categorieImpayes,
		soldeMin,
		soldeMax,
	]);
	
	return (
		<Card
			{...rest}
			className={[
				className,
				'mt-2',
				'mb-1',
				'c-Crud-Recouvrement-Filters',
				open ? 'c-Crud-Recouvrement-Filters--open' : ''
			].join(' ')}
		>
			<CardContent className="p-1">
				<div onClick={() => setOpen(!open)}>
					<h5 className="m-0" style={{cursor: 'pointer'}}>  {open ? <KeyboardArrowUp style={{ verticalAlign: '-7px' }} /> : <KeyboardArrowDown style={{ verticalAlign: '-7px' }} />}<span>Filtres :</span></h5>
				</div>
				{open && (
					<Grid container spacing={1}>
						<Grid xs={12} md={4}>
							<CrudUserSyncicatSelect
								{...model(syndicat, setSyndicat)}
								multiple
								clearable
								size="small"
								variant="standard"
								label="Syndicats"
							/>
						</Grid>
						<Grid xs={12} md={4}>
							<TextField
								{...model(proprietaire, setProprietaire)}
								clearable
								size="small"
								variant="standard"
								label="ID Propriétaire"
							/>
						</Grid>
						<Grid xs={12} md={4}>
							<TextField
								{...model(nom, setNom)}
								clearable
								size="small"
								variant="standard"
								label="Nom"
							/>
						</Grid>
						<Grid xs={12} md={4}>
							<CrudRecouvrementStatusOccupationSelect
								{...model(statutOccupation, setStatutOccupation)}
								multiple
								clearable
								size="small"
								variant="standard"
								label="Occupation"
							/>
						</Grid>
						<Grid xs={12} md={4}>
							<Grid container spacing={1}>
								<Grid xs={6}>
									<TextField
										{...model.number(soldeMin, setSoldeMin)}
										clearable
										type="number"
										size="small"
										variant="standard"
										label="Solde"
										placeholder="min"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid xs={6}>
									<TextField
										{...model.number(soldeMax, setSoldeMax)}
										clearable
										type="number"
										size="small"
										variant="standard"
										label=" "
										placeholder="max"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid xs={12} md={4}>
							<CrudRecouvrementCategorieImpayesSelect
								{...model(categorieImpayes, setCategorieImpayes)}
								multiple
								clearable
								size="small"
								variant="standard"
								label="Categorie Impayes"
							/>
						</Grid>
					</Grid>
				)}
			</CardContent>
		</Card>
	)
}
