export class ScriptHelper {

  static loaded: { [name: string]: Promise<void> } = {};

  public static load(src: string, attrs: any = {}): Promise<void> {
    if (!this.loaded[src]) {
      this.loaded[src] = new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.onload = () => resolve();
        script.onerror = reject;
        script.src = src;

        if (attrs) {
          for (const key in attrs) {
            script.setAttribute(key, attrs[key]);
          }
        }

        document.body.appendChild(script);
      });
    }
    return this.loaded[src];
  }
}
