import './Aides.scss';
import { Container } from '@mui/material';

export default function Aides() {
	return (
		<Container className="p-documentaire-Aides">
			Aides
		</Container>
	);
}
