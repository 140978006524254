import { Card, CardActions, CardContent, CardHeader, Select, SelectChangeEvent, InputLabel, MenuItem, FormControl } from '@mui/material';
import { Acquisition } from '@/models';
import { AcquisitionVisite, HAcquisitionVisite } from '@/models/AcquisitionVisite';
import { SetStateAction, useState } from 'react';
import { useForm } from '@/libs/form';
import { useDispatch } from '@/libs/redux';
import { LoadingButton } from '@mui/lab';
import Form from '@/components/forms/Form/Form';
import FormMainErrors from '@/components/forms/FormMainErrors/FormMainErrors';
import Grid from '@mui/material/Unstable_Grid2';
import { model } from '@/libs/candy';
import { useValidators } from '@/libs/validators';
import { postAcquisitionVisite, putAcquisitionVisite } from '@/stores/acquisitionVisite';
import TextField from '@/components/inputs/TextField/TextField';
import DatePicker from '@/components/inputs/DatePicker/DatePicker'; // Importez si nécessaire pour la date
import dayjs from 'dayjs';
import { CheckBox } from '@mui/icons-material';

export interface CrudAcquisitionVisiteEditProps {
	acquisition: Acquisition,
	visiteAcqui: HAcquisitionVisite,
	onSave: (visite: AcquisitionVisite) => any;
	onClose: () => any;
}

export function CrudAcquisitionVisiteEdit({
	acquisition,
	visiteAcqui,
	onSave,
	onClose,
}: CrudAcquisitionVisiteEditProps) {

	// Définition de l'interface pour les items du Select
	interface SelectItem {
		label: string;
		value: string;
	}

	const dispatch = useDispatch();
	const validators = useValidators();
	const [date, setDate] = useState<Nullable<Date>>(visiteAcqui?.date || null);
	const [commentaire, setCommentaire] = useState<Nullable<string>>(visiteAcqui?.commentaire || null);
	const [contexteVisite, setContexteVisite] = useState<Nullable<string>>(visiteAcqui?.contexteVisite || null);
	const [etatLogement, setEtatLogement] = useState<Nullable<string>>(visiteAcqui?.etatLogement || null);
	const [precisionsOccupation, setPrecisionsOccupation] = useState<Nullable<string>>(visiteAcqui?.precisionsOccupation || null);
	const [nomBanque, setNomBanque] = useState<Nullable<string>>(visiteAcqui?.nomBanque || null);
	const [montantDetteBanque, setMontantDetteBanque] = useState<Nullable<number>>(visiteAcqui?.montantDetteBanque || null);
	const [montantAutresDettes, setMontantAutresDettes] = useState<Nullable<number>>(visiteAcqui?.montantAutresDettes || null);
	const [acquisitionId, setAcquisitionId] = useState<Nullable<number>>(acquisition.id || null);
	const acquiID = acquisition.id;

	const handleChange = (event: SelectChangeEvent<string>) => {
		setContexteVisite(event.target.value);  // Met à jour contexteVisite avec la sélection de l'utilisateur
	};

	const handleEtatLogementChange = (event: { target: { value: SetStateAction<Nullable<string>>; }; }) => {
		setEtatLogement(event.target.value);
	};

	// Définition des items pour contexteVisite et etatLogement avec le type explicite
	const contexteVisiteItems: SelectItem[] = [
		{ label: '', value: '' },
		{ label: 'Amiable', value: 'Amiable' },
		{ label: 'Préemption', value: 'Préemption' },
		{ label: 'Adjudication', value: 'Adjudication' }
	];

	const etatLogementItems: SelectItem[] = [
		{ label: '', value: '' },
		{ label: 'Excellent', value: 'Excellent' },
		{ label: 'Bon', value: 'Bon' },
		{ label: 'Correct', value: 'Correct' },
		{ label: 'Moyen', value: 'Moyen' },
		{ label: 'Mauvais', value: 'Mauvais' }
	];

	const form = useForm({
		handle: async () => {
			await form.call(async () => {
				onClose();
				const newAcquisitionVisite = {
					...visiteAcqui,
					// id,
					date: date,
					commentaire,
					contexteVisite,
					etatLogement,
					precisionsOccupation,
					nomBanque,
					montantDetteBanque: montantDetteBanque ? parseFloat(montantDetteBanque.toString()) : null,
					montantAutresDettes: montantAutresDettes ? parseFloat(montantAutresDettes.toString()) : null,
					acquisitionId,
				};
				if (newAcquisitionVisite.id) {
					const result = await dispatch(putAcquisitionVisite(newAcquisitionVisite));
					onSave(result);
				} else {
					const result = await dispatch(postAcquisitionVisite(acquisition, newAcquisitionVisite));
					onSave(result);
				}
			});
		},
		fields: {
			date: {
				label: 'Date de la visite',
				...model(date, setDate),
				rules: [
					validators.required(),
				],
				type: 'date',
			},
			commentaire: {
				label: 'Commentaire',
				...model(commentaire, setCommentaire),
				multiline: true,
				rows: 5,
			},
			contexteVisite: {
				label: 'Contexte de la visite',
				...model(contexteVisite, setContexteVisite),
				items: [
					{ label: '', value: '' },
					{ label: 'Amiable', value: 'Amiable' },
					{ label: 'Préemption', value: 'Préemption' },
					{ label: 'Adjudication', value: 'Adjudication' }
				],
			},
			etatLogement: {
				label: 'État du logement',
				...model(etatLogement, setEtatLogement),
				items: [
					{ label: 'Excellent', value: 'Excellent' },
					{ label: 'Bon', value: 'Bon' },
					{ label: 'Correct', value: 'Correct' },
					{ label: 'Moyen', value: 'Moyen' },
					{ label: 'Mauvais', value: 'Mauvais' }
				],
			},
			precisionsOccupation: {
				label: 'Précisions sur l\'occupation',
				...model(precisionsOccupation, setPrecisionsOccupation),
				multiline: true,
				rows: 5,
			},
			nomBanque: {
				label: 'Nom de la banque',
				...model(nomBanque, setNomBanque),
			},
			montantDetteBanque: {
				label: 'Montant de la dette bancaire',
				...model(montantDetteBanque, setMontantDetteBanque),
				type: 'number',

			},
			montantAutresDettes: {
				label: 'Montant des autres dettes',
				...model(montantAutresDettes, setMontantAutresDettes),
				type: 'number',

			},
			acquisitionId: {
				label: 'ID de l\'acquisition',
				...model(acquisitionId, setAcquisitionId),
				value: acquiID,
				hidden: true,
			},
		}
	})
	const hiddenStyle = {
		display: 'none'
	};
	
	return (
		<Form form={form}>
			<Card>
				<CardHeader
					title={visiteAcqui?.id ? "Édition de la visite" : "Création d'une visite"}
					className="CardHeader--primary"
				/>
				<CardContent>
					<FormMainErrors form={form} />
					<Grid container spacing={1}>
						<Grid xs={12} md={3} style={hiddenStyle}><TextField {...form.fields.acquisitionId} /></Grid>
						<Grid xs={12} md={4}><DatePicker {...form.fields.date} /></Grid>
						<Grid xs={12} md={4}>
							<FormControl fullWidth>
								<InputLabel id="contexte-visite-label">Contexte de la visite</InputLabel>
								<Select
									labelId="contexte-visite-label"
									value={contexteVisite || ''}
									onChange={handleChange}
									label="Contexte de la visite">
									{contexteVisiteItems.map((item: SelectItem) => (
										<MenuItem key={item.value} value={item.value}>
											{item.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid xs={12} md={4}>
							<FormControl fullWidth>
								<InputLabel id="etat-logement-label">État du logement</InputLabel>
								<Select
									labelId="etat-logement-label"
									value={etatLogement || ''}
									onChange={handleEtatLogementChange}
									label="État du logement"
								>
									{etatLogementItems.map((item: SelectItem) => (
										<MenuItem key={item.value} value={item.value}>
											{item.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid xs={12} md={12}><TextField {...form.fields.precisionsOccupation} multiline rows={5} /></Grid>
						<Grid xs={12} md={4}><TextField {...form.fields.nomBanque} /></Grid>
						<Grid xs={12} md={4}><TextField {...form.fields.montantDetteBanque}
							onWheel={(e) => {
								const target = e.target as HTMLInputElement;
								target.blur();
							}}
						/></Grid>
						<Grid xs={12} md={4}><TextField {...form.fields.montantAutresDettes}
							onWheel={(e) => {
								const target = e.target as HTMLInputElement;
								target.blur();
							}}
						/></Grid>
						<Grid xs={12} md={12}><TextField {...form.fields.commentaire} multiline rows={5} /></Grid>
					</Grid>
				</CardContent>
				<CardActions className="u-d-block">
					<LoadingButton loading={form.loading} onClick={onClose}>
						Fermer
					</LoadingButton>
					<LoadingButton
						type="submit"
						color="primary"
						variant="contained"
						loading={form.loading}
						style={{ float: 'right' }}
						onClick={(event) => {
							event.preventDefault(); // Prevent form from submitting traditionally
							form.handle(event); // Pass the event object as an argument
						}}
					>
						Enregistrer
					</LoadingButton>
				</CardActions>
			</Card>
		</Form>
	);
}
