import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { SlotList, UserSyncicat } from '@/models';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { dateToDash, UriHelper } from '@/libs/utils';

export class SlotListState {
	public slotList: Nullable<SlotList> = null;
}

export default {
	state: SlotListState,
	reducer: mapReducer([
		'slotList',
	]),
} as SubStore;

// MUTATIONS

export const setSlotList = (slotList: Nullable<SlotList>) => (dispatch: DispatchApp) => {
	dispatch({ state: SlotListState, type: MapReducerAction.MAP, slotList });
};

// ACTIONS

export const findSlotListByBetweenDate = (
	start: Date,
	end: Date,
	filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Nullable<SlotList>> => {
	dispatch(setSlotList(null));
	const slotList = await caller.get<SlotList>(`/api/slot-lists/${dateToDash(start)}/${dateToDash(end)}${UriHelper.queries({
		filters: JSON.stringify(filters)
	})}`);
	dispatch(setSlotList(slotList));
	return slotList;
});
