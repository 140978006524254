import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { Adresse, Referent, ResultType } from '@/models';
import { Direction } from '@/models/Direction';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class AdresseState {
}

export default {
	state: AdresseState,
	reducer: mapReducer([
	]),
} as SubStore;

// ACTIONS

export const putAdresse = (
	adresse: Partial<Adresse>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Adresse> => {
	return  await caller.put<Adresse>(`/api/adresses/${adresse.id}`, {
		adresse1: adresse.adresse1,
		adresse2: adresse.adresse2,
		adresse3: adresse.adresse3,
		commentaire: adresse.commentaire,
		source: adresse.source,
		pertinence: adresse.pertinence,
		dateMaj: adresse.dateMaj,
	});
});

export const postAdresse = (
	referent: Referent,
	adresse: Partial<Adresse>,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Adresse> => {
	const referentId = referent.adresses.map((a: any) => a.referent).join(','); // Combine the IDs into a single string
	return await caller.post<Adresse>(`/api/adresses/${encodeURIComponent(referentId)}`, {
		adresse1: adresse.adresse1,
		adresse2: adresse.adresse2,
		adresse3: adresse.adresse3,
		commentaire: adresse.commentaire,
		source: adresse.source,
		pertinence: adresse.pertinence,
		dateMaj: adresse.dateMaj,
	});
});


export const deleteAdresse = (
	adresse: Adresse,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<Adresse> => {
	return  await caller.delete<Adresse>(`/api/adresses/${adresse}`);
});
