import { Container } from '@mui/material';
import { CharteText } from '@/components/Charte/CharteText';

export default function PageDocumentaireCharte() {
	return (
		<Container className="p-documentaire-Charte">
			<CharteText />
		</Container>
	);
}


PageDocumentaireCharte.getMetadata = () => {
	return {
		title: 'Charte utilisateur'
	}
}
