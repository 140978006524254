import { buildModelHelper } from '@/libs/modelHelper';

export interface Adresse {
	id: number;
	type: number;
	adresse1: string;
	adresse2: string;
	adresse3: string;
	commentaire: string;
	source: string;
	pertinence: number;
	dateMaj: string;
}

export const hAdresse = buildModelHelper<Adresse>({
	get canEdit(): boolean {
		return this.type !== 1;
	},
});
export type HAdresse = ReturnType<typeof hAdresse>;
