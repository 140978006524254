import { mapReducer, MapReducerAction, SubStore } from '@/libs/redux';
import { GetState, Services } from '@/stores/index';
import { TriDia, ResultType } from '@/models';
import { Direction } from '@/models/Direction';
import { loadingCall } from '@/libs/stores/candy/loadingCall';
import { UriHelper } from '@/libs/utils';

export class TriDiaState {
    public triDias: Nullable<ResultType<TriDia>> = null;
    public triDia: Nullable<TriDia> = null;
    public currentAcquisitionId: Nullable<number> = null; // Store the current acquisition ID
}

export default {
    state: TriDiaState,
    reducer: mapReducer([
        'triDias',
        'triDia',
        'currentAcquisitionId' // Register the new state property
    ]),
} as SubStore;

// MUTATIONS

export const setTriDias = (triDias: Nullable<ResultType<TriDia>>) => (dispatch: DispatchApp) => {
    dispatch({ state: TriDiaState, type: MapReducerAction.MAP, triDias });
};
export const setTriDia = (triDia: Nullable<TriDia>) => (dispatch: DispatchApp) => {
    dispatch({ state: TriDiaState, type: MapReducerAction.MAP, triDia });
};
export const setCurrentAcquisitionId = (id: Nullable<number>) => (dispatch: DispatchApp) => {
    dispatch({ state: TriDiaState, type: MapReducerAction.MAP, currentAcquisitionId: id });
};

// ACTIONS

export const findTriDias = (
    page: number,
    order: string,
    direction: Direction,
    limit: number,
    filters: any = {},
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<ResultType<TriDia>> => {
    const triDias = await caller.get<ResultType<TriDia>>(`/api/tri-dias${UriHelper.queries({
        page,
        order,
        direction,
        limit,
        filters: JSON.stringify(filters)
    })}`);

    dispatch(setTriDias(triDias));

    return triDias;
});

export const updateAcquisitionList = (acquisitionId: number) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<void> => {
    await caller.post(`/api/acquisition/update-list/${acquisitionId}`);
    // After updating, you might want to refresh your acquisition list or handle the UI accordingly.
    dispatch(setCurrentAcquisitionId(null));  // Clear the current ID as it may no longer be relevant.
});

export const findTriDia = (
    id: number,
) => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<TriDia> => {
    dispatch(setTriDia(null));
    const triDia = await caller.get<TriDia>(`/api/tri-dias/${id}`);
    dispatch(setTriDia(triDia));
    return triDia;
});

export const deleteAllAcquisitions = () => loadingCall(async (dispatch: DispatchApp, getState: GetState, { caller }: Services): Promise<void> => {
    await caller.delete(`/api/acquisitions`);
    return;
});
